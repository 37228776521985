import { configureStore } from "@reduxjs/toolkit"
import userReducer from "./user-slice.js"
import pageReducer from "./page-slice.js"
import newsFeedReducer from "./news-feed-slice.js"
import searchReducer from "./saved-searches-alerts-slice.js"
import dropsReducer from "./dropdowns-types-slice.js"
// configureStore deprecates createStore from redux 
// does the same job as createStore but automatically sets up a store with the right defaults
// 1. turns on the redux dev tools extension
// 2. adds thunk middleware
// 3. turns on a couple of development checks

export const store = configureStore({
    reducer: {
        user: userReducer,
        page: pageReducer,
        newsFeed: newsFeedReducer,
        savedSearch: searchReducer,
        dropTypes: dropsReducer,
    }
})




