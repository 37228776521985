import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import countries from "i18n-iso-countries";




const DropdownCountry = ({ handleChacha }) => {

    const { i18n } = useTranslation();

    //if (i18n.language === "en-US") i18n.language = "en";

    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/el.json"));


    const [selectedCountry, setSelectedCountry] = React.useState("");

    const selectCountryHandler = (e) => {
        setSelectedCountry(e.target.value);
        handleChacha(e);
    }


    const countryObj = countries.getNames(i18n.language, { select: "official" });

    const countryArr = Object.entries(countryObj).map(([key, value]) => {
        return {
            label: value,
            value: key
        };
    });


    return (

        <Form.Select
            className="custom-select"
            name="country"
            value={selectedCountry}
            onChange={(e) => selectCountryHandler(e)}
            required>

            <option></option>
            {
                countryArr.map((country, key) => (
                    <option key={key} value={country.value}>
                        {country.label}
                    </option>
                ))
            }

        </Form.Select>
    )
}

export default DropdownCountry


/* function chooseVatCountry() {
    let inputValue = document.getElementById("country").value;
    document.getElementById("vatCountry").value = inputValue;
}

onChange={chooseVatCountry}

<InputGroup className="col-lg-12 mb-4 input-group">
    <InputGroup.Text>
        <Table className="text-primary" />
    </InputGroup.Text>
    <Form.Select id="vatCountry" name="vatCountry">
        <option></option>
        <option value="BE">BE</option>
        <option value="FR">FR</option>
        <option value="GB">GB</option>
        <option value="EL">EL</option>
    </Form.Select>
    <FormControl
        id="vatNumber"
        name="vatNumber"
        placeholder={`VAT ${t('words.number')}`}
        type="text"
        className="border-left-0" />
</InputGroup> */