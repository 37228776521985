import { store } from "../Redux/Store.js"
import { addSavedDropRedux, addSavedSearchRedux } from "../Redux/saved-searches-alerts-slice";
import TokenRefresher from "./TokenRefresher";

const saveOrEditSearch = async (action, payload, setLoading, setStatus) => {

    const url = action === 'POST' ? process.env.REACT_APP_SAVE_SEARCH : '';

    if (payload.type === 'job') payload.type = 'training-job'

    const sendSearch = async function () {

        try {

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;

            fetch(url, {
                method: action,
                headers: {
                    'Authorization': bearer_token,
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify(payload),
                credentials: 'include',
            })
                .then(res => res.json())
                .then(data => {

                    setStatus("success")

                    store.dispatch(addSavedSearchRedux(data))

                    const newData = {
                        value: data.id,
                        label: data.name,
                        type: data.type
                    }
                    store.dispatch(addSavedDropRedux(newData))

                })


        } catch (error) {
            console.log(error)
            setStatus("danger")

        } finally {
            setLoading(false)
        }
    }

    const sessionFetch = new TokenRefresher();
    sessionFetch.setTokenRefresh(sendSearch);
}


export default saveOrEditSearch