import { useRef } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import JoditEditor from "jodit-react";

const DescriptionJobCE = ({ jobOffer, contJobDesc, setContJobDesc, contCompany, setContCompany, contWage, setContWage, listing }) => {

    const { t } = useTranslation();

    const editor = useRef(null);

    const config = {
        readonly: false,
        useSearch: false,
        spellcheck: true,
        required: true,
        toolbarButtonSize: "small",
        showCharsCounter: false,
        showWordsCounter: false,
        showXPathInStatusbar: false,
        disablePlugins: "search,powered-by-jodit,about,class-span,copy-format,clipboard,image,media,video,file",
        height: 250,
        minHeight: null
    };


    return (
        <>

            <Col sm={12} className="text-white bg-warning mt-4 px-4 pt-3 pb-2">
                <h6>{t('words.desc')}</h6>
            </Col>

            <Col lg={12} className="bg-white shadow-sm p-4">
                <Row className="gy-3">

                    <Form.Group as={Col} md="12" controlId="lisExcerpt">
                        <Form.Label>{t('words.add')} {t('words.summary')}*</Form.Label>
                        <Form.Control
                            required
                            as="textarea"
                            rows={1}
                            defaultValue={listing && listing.article.excerpt}
                            maxLength="130"
                            name="excerpt"
                        />
                    </Form.Group>

                    <Col md={12} id="jodit-descip" className='my-4'>
                        <Form.Label>{jobOffer ? t('text.jobProfile') : t('text.jobMyProfile')} *</Form.Label>
                        <JoditEditor
                            ref={editor}
                            value={contJobDesc}
                            config={config}
                            tabIndex={1}
                            onBlur={(e) => setContJobDesc(e)}
                        />

                    </Col>

                    <Col md={12} className='mb-4'>
                        <Form.Label>{jobOffer ? t('text.myCompany') : t('text.companyLooking')}</Form.Label>
                        <JoditEditor
                            value={contCompany}
                            config={config}
                            tabIndex={1}
                            onBlur={(e) => setContCompany(e)}
                        />

                    </Col>

                    <Col md={12} className='mb-4'>
                        <Form.Label>{jobOffer ? t('text.packageOffer') : t('text.wageClaim')}</Form.Label>
                        <JoditEditor
                            value={contWage}
                            config={config}
                            tabIndex={1}
                            onBlur={(e) => setContWage(e)}
                        />

                    </Col>

                </Row>

            </Col>
        </>

    )
}

export default DescriptionJobCE