import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, Collapse } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { BriefcaseFill, Calendar2CheckFill, GearWideConnected, PinAngleFill } from 'react-bootstrap-icons';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { RiArticleLine } from 'react-icons/ri'
import ToolPage from '../../pages/ToolPage';


const CardToolbox = ({ countAds }) => {

  const { t } = useTranslation();

  const [openSet, setOpenSet] = React.useState(false);

  const toolbox = [
    { name: t('cats_tools.jnt'), value: 'job', icon: <BriefcaseFill size={14} className='me-2 text-lightblue mb-1' />, count: countAds.TrainingJob },
    { name: t('cats_tools.article'), value: 'article', icon: <RiArticleLine className='me-2 text-lightblue mb-1' />, count: countAds.Article },
    { name: t('cats_tools.benchmark'), value: 'benchmark', icon: <PinAngleFill className='me-2 text-lightblue mb-1' />, count: countAds.Benchmark },
    { name: `${t('cats_tools.crm')}-${t('cats_tools.softw')}`, value: 'crm', icon: <GearWideConnected className='me-2 text-lightblue mb-1' />, count: countAds.CRM },
    { name: t('cats_tools.event'), value: 'event', icon: <Calendar2CheckFill className='me-2 text-lightblue mb-1' />, count: countAds.Events },
  ];

  return (
    <Card className='mt-4 border-0 bg-info rounded-4'>

      <Button
        variant='link'
        size="lg"
        onClick={() => setOpenSet(!openSet)}
        aria-controls="toolbox-list"
        className='text-decoration-none text-white text-center p-1 p-xl-2'
      >
        {t('title.toolb')} {openSet ? <IoIosArrowUp className="ms-1" size={19} /> : <IoIosArrowDown className="ms-1" size={19} />}
      </Button>

      <Collapse id="toolbox-list" in={openSet} dimension="height">
        <div>
          <div className='flex-column d-flex mt-2 p-2 ps-3'>

            {toolbox.map((cat, key) => (

              <Link
                key={key}
                component={<ToolPage />}
                to={`/tool/${cat.value}`}
                className="link-sidebar text-white"
              >
                {cat.icon}
                {cat.name}
                ({cat.count})
              </Link>

            ))}
            
          </div>
        </div>
      </Collapse>

    </Card>
  )
}

export default CardToolbox