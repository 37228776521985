import PropTypes from "prop-types";
import { Col, Pagination } from "react-bootstrap";
import React from "react";



function MyPagination({
  itemsCount, itemsPerPage, currentPage, setCurrentPage, alwaysShown = true
}) {

  const pagesCount = Math.ceil(itemsCount / itemsPerPage);
  const isPaginationShown = alwaysShown ? true : pagesCount > 1;
  const isCurrentPageFirst = currentPage === 1;
  const isCurrentPageLast = currentPage === pagesCount;

  const changePage = number => {
    if (currentPage === number)
      return;
    setCurrentPage(number);
    //window.scrollTo(0, 0);
  };

  const onPageNumberClick = pageNumber => {
    changePage(pageNumber);
  };

  const onPreviousPageClick = () => {
    changePage(currentPage => currentPage - 1);
  };

  const onNextPageClick = () => {
    changePage(currentPage => currentPage + 1);
  };

  const setLastPageAsCurrent = () => {
    if (currentPage > pagesCount) {
      
      if (pagesCount === 0) {
        setCurrentPage(1);
      }
      else {
        setCurrentPage(pagesCount);
      }
      
    }
  };

  let isPageNumberOutOfRange;

  const pageNumbers = [...new Array(pagesCount)].map((_, index) => {

    const pageNumber = index + 1;
    const isPageNumberFirst = pageNumber === 1;
    const isPageNumberLast = pageNumber === pagesCount;
    const isCurrentPageWithinTwoPageNumbers = Math.abs(pageNumber - currentPage) <= 2;

    if (isPageNumberFirst ||
      isPageNumberLast ||
      isCurrentPageWithinTwoPageNumbers) {
      isPageNumberOutOfRange = false;
      return (
        <Pagination.Item
          key={pageNumber}
          onClick={() => onPageNumberClick(pageNumber)}
          active={pageNumber === currentPage}
        >
          {pageNumber}
        </Pagination.Item>
      );
    }

    if (!isPageNumberOutOfRange) {
      isPageNumberOutOfRange = true;
      return <Pagination.Ellipsis key={pageNumber} className="muted" />;
    }

    return null;
  });

  React.useEffect(setLastPageAsCurrent
    // eslint-disable-next-line
    , [pagesCount]);

  return (
    <>
      {isPaginationShown && (
        <Col xl={12}>
          <Pagination className="mt-5 justify-content-center flex-wrap">
            <Pagination.Prev
              onClick={onPreviousPageClick}
              disabled={isCurrentPageFirst} />
            {pageNumbers}
            <Pagination.Next
              onClick={onNextPageClick}
              disabled={isCurrentPageLast} />
          </Pagination>
        </Col>
      )}
    </>
  );
}

MyPagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  alwaysShown: PropTypes.bool
};

export default MyPagination;