import React, { useState } from 'react'
import { Form, Button, Toast, Spinner, Card, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import PersonalizationProf from "../../../jwtAPI/PersonalizationProf"
import ThemeCard from '../../Loaders/ThemeCard';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { toggleTheme } from "../../../Redux/page-slice"
import NotifEmailPreferences from './NotifEmailPreferences';
import NotifInsidePreferences from './NotifInsidePreferences';
import SimplePatchToggle from '../../../jwtAPI/SimplePatchToggle';


function Personalization() {

    const { t, i18n } = useTranslation();
    let url = `${process.env.REACT_APP_DOMAIN}/${i18n.language}/${process.env.REACT_APP_FEED_API}`
    const controller = new AbortController();
    const ac = controller.signal
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState("")
    const [show, setShow] = useState(false)
    const dispatch = useDispatch()


    const cards = [
        { name: `${t('words.recent')} ${t('words.members')}`, id: 'recent_members' },
        { name: `${t('words.for2')} ${t('cats_tools.rent')}`, id: 'rent' },
        { name: `${t('words.for2')} ${t('cats_tools.sale')}`, id: 'sale' },
        { name: t('cats_tools.request'), id: 'request' },
        { name: t('cats_tools.partnership'), id: 'partnership' },
        { name: t('cats_tools.training-job'), id: 'training-job' },
        { name: t('cats_tools.article'), id: 'article' },
        { name: t('cats_tools.benchmark'), id: 'benchmark' },
        { name: t('cats_tools.crm'), id: 'crm' },
        { name: t('cats_tools.event'), id: 'event' },
    ];

    async function saveNewsFeedPrefs() {

        setLoading(true)
        const fetchLists = new PersonalizationProf(ac);
        fetchLists.fetchListings(url, setLoading, optionsFeed, setShow, setStatus)
    }

    const customization = useSelector(state => state.newsFeed.customization)
    const darkTheme = useSelector(state => state.page.darkTheme)

    //Dark theme
    function togTheme() {
        const url = `${process.env.REACT_APP_USERINFO2_API}/toggle-dark-theme`;
        const toggleThis = new SimplePatchToggle();
        toggleThis.fetchListings(url);
        dispatch(toggleTheme());
    }

    let [optionsFeed, setOptionsFeed] = React.useState(
        {
            "own": false,
            "page": 1,
            "limit": 1,
            ...customization,
            "shop-company-sell": false,
            "tender-call": false,
            "library": false
        }
    );

    function clearAll() {
        setOptionsFeed({
            ...optionsFeed,
            "article": false,
            "sale": false,
            "rent": false,
            "request": false,
            "partnership": false,
            "shop-company-sell": false,
            "crm": false,
            "event": false,
            "training-job": false,
            "tender-call": false,
            "benchmark": false,
            "library": false,
            "recent_members": false
        })
    }

    function fillAll() {
        setOptionsFeed({
            ...optionsFeed,
            "article": true,
            "sale": true,
            "rent": true,
            "request": true,
            "partnership": true,
            "crm": true,
            "event": true,
            "training-job": true,
            "tender-call": true,
            "benchmark": true,
            "recent_members": true
        })
    }

    function handleChange(value) {
        setOptionsFeed({
            ...optionsFeed,
            [value]: !optionsFeed[value]
        })
        // console.log(optionsFeed)
    }


    return (
        <>



            <Card as={Row} border="white" className="flex-row p-4 mx-lg-4 mb-3 text-center rounded-4">

                <Toast
                    delay={3000} autohide
                    bg={status === 'success' ? 'success' : 'danger'}
                    onClose={() => setShow(false)}
                    show={show}
                >
                    <Toast.Body className='text-white'>{status === 'success' ? t('profile.prof1') : t('bigtext.errorWrong')}</Toast.Body>
                </Toast>

                <h5 className='text-center my-4'>{t('bigtext.customFeed1')}</h5>

                <Col lg={12} className="mb-5">

                    {cards.map((card, index) => (
                        <Form.Check
                            key={index}
                            inline
                            label={card.name}
                            type='checkbox'
                            checked={optionsFeed[card.id]}
                            value={card.id}
                            name={card.id}
                            id={'newsfeed_' + card.id}
                            onChange={() => handleChange(card.id)}
                            disabled={loading}
                        />
                    ))}

                    <div className='mt-4 d-flex justify-content-center'>
                        <Button disabled={loading} onClick={clearAll} className='text-white'> {t('words.clear')} {t('words.all')}</Button>
                        <Button disabled={loading} onClick={fillAll} className='mx-3 text-white'> {t('words.fill')} {t('words.all')}</Button>
                        <Button disabled={loading} onClick={saveNewsFeedPrefs} className='text-white'>

                            {loading ? <Spinner size="sm" animation="border" role="status" /> : <> {t('words.save')} {t('words.changes')}</>}
                        </Button>

                    </div>
                </Col>

                <hr />

                <h5 className='text-center mb-4'>{t('words.theme')} {t('words.preference')} </h5>

                <Col lg={12} className="mb-5 d-flex justify-content-center">

                    <Card
                        onClick={togTheme}
                        className='mt-sm-2 mt-md-0 me-md-2 cp rounded-2'
                        style={{ width: '18rem', height: "10rem" }}
                    >

                        <Card.Body style={{ background: "white", height: "6rem" }} className='border-bottom'>
                            <ThemeCard dark={false} />
                        </Card.Body>

                        <div className='d-flex flex-nowrap justify-content-between p-3'>
                            {t('words.light')} {t('words.theme')}
                            <Form.Check
                                type="radio"
                                checked={!darkTheme}
                                id="switch_menu_dark"
                                readOnly={true}
                            />
                        </div>
                    </Card>

                    <Card
                        onClick={togTheme}
                        className='mt-sm-2 mt-md-0 me-md-2 cp rounded-2'
                        style={{ width: '18rem', height: "10rem" }}
                    >

                        <Card.Body style={{ background: "rgb(3,22,31)", height: "6rem" }} className='border-bottom'>
                            <ThemeCard dark={true} />
                        </Card.Body>

                        <div className='d-flex flex-nowrap justify-content-between p-3'>
                            {t('words.dark')} {t('words.theme')}
                            <Form.Check
                                type="radio"
                                checked={darkTheme}
                                id="switch_menu_dark"
                                readOnly={true}
                            />
                        </div>
                    </Card>

                </Col>

                <hr />

                <NotifEmailPreferences />

                <hr />

                <NotifInsidePreferences />

            </Card>
        </>
    )
}
export default Personalization