import React from 'react';
import { setReduxUser } from '../../Redux/user-slice';
import { setFeedTrue } from '../../Redux/news-feed-slice';
import { useDispatch, useSelector } from 'react-redux'
import { Link } from "react-router-dom";
import { Col, Form, Button, Spinner, Alert, CloseButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SimplePage from '../../pages/SimplePage';
import ClearLocalConditionally from '../../functions/ClearLocalConditionally';
import { setGlobalLoader } from '../../Redux/page-slice';
import { EyeFill, EyeSlash } from 'react-bootstrap-icons';
import { setSavedSearchesDrop, setSavedSearches } from '../../Redux/saved-searches-alerts-slice';



function LoginFormNew() {

    const dispatch = useDispatch()
    const { t } = useTranslation();
    const testEnv = useSelector(state => state.page.testEnv)
    //Native Validation
    const [validated, setValidated] = React.useState(false);
    //Loading Spinner
    const [loading, setloading] = React.useState(false);
    //If error show alert
    const [showErr, setShowErr] = React.useState(false);

    //toggle pass
    const [eyePass, setEyePass] = React.useState(false);
    const showPass = () => setEyePass(!eyePass);


    //Checkbox Keep me logged in
    const loglog = localStorage.getItem('keepLogged');
    const [checkLogged, setCheckbox] = React.useState(loglog);
    function toggleCheck(e) {
        setCheckbox(!checkLogged);
        keepMeLogged(e);
    }
    //Set Logged In
    function keepMeLogged(e) {
        localStorage.setItem('keepLogged', e.currentTarget.checked);
    }


    //Handlers for form's fields
    const initialFormData = Object.freeze({
        username: "",
        password: ""
    });

    const [formData, updateFormData] = React.useState(initialFormData);

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            // Trimming any whitespace
            [e.target.name]: e.target.value.trim()
        });
    };
    // loops the array and fills the redux object
    function setCustom(arr) {
        for (let i = 0; i < arr.length; i++) {
            dispatch(setFeedTrue(arr[i]))
        }
    }
    //Bootstrap Validation, after calls fetches
    const handleSubmitValidation = (event) => {

        const form = event.currentTarget;
        //Clear Previous data
        ClearLocalConditionally();

        //Validation for empty fields
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);

        if (form.checkValidity() === true) {
            handleSubmit(event);
        }

    };



    /*************Fetches***************/

    //Error for Login Fetch
    function handleSubmitError(res) {
        if (res.status >= 200 && res.status <= 299) {
            return res.json();
        } else {
            setloading(false);
            setValidated(false);
            setShowErr(true);
            throw Error(res.statusText);
        }
    }

    //Login
    let handleSubmit = async (e) => {

        setloading(true);

        e.preventDefault();

        fetch(process.env.REACT_APP_LOGIN_API, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            }, credentials: 'include',
            body: JSON.stringify(formData),


        })
            .then(handleSubmitError)
            .then(res => {

                localStorage.setItem('token', res.token);
                if (testEnv) {
                    localStorage.setItem('refresh_token', res.refresh_token);
                }

                setloading(false)
                getUser();
            })
            .catch(err => {
                console.log(err);
                setloading(false)
                dispatch(setGlobalLoader(false))
            })

    };

    //Set User saves all the user json
    let getUser = async () => {

        setloading(false);
        dispatch(setGlobalLoader(true))
        const bearer_token = `Bearer ${localStorage.getItem('token')}`;

        fetch(process.env.REACT_APP_USERINFO_API, {
            method: 'GET',
            headers: {
                'Authorization': bearer_token,
            },
            credentials: 'include'
        })
            .then(res => res.json())
            .then(data => {

                localStorage.setItem('logged', true);
                dispatch(setReduxUser(data));
                setCustom(data.newsfeed_prefs);
                dispatch(setSavedSearches(data.saved_searches))
                return data.saved_searches
            })
            .then(dataSavedSrch => {

                const newData = []
                for (let i = 0; i < dataSavedSrch.length; i++) {
                    //we need speficic keys for select drop to work
                    newData[i] = {
                        value: dataSavedSrch[i].id,
                        label: dataSavedSrch[i].name,
                        type: dataSavedSrch[i].type
                    }
                }
                dispatch(setSavedSearchesDrop(newData))
                dispatch(setGlobalLoader(false))

            })

            .catch(err => {
                console.error(err)
                dispatch(setGlobalLoader(false))
            })
    }


    return (

        <Form noValidate validated={validated} onSubmit={handleSubmitValidation} className='text-start' id='login_form'>

            {/* {luser ?
                <Alert variant="success">
                    <p>
                        Welcome {luser}! {t('bigtext.suclogin')}
                    </p>
                </Alert>

                : */}<>
                {showErr ?
                    <Alert variant="danger" onClose={() => setShowErr(false)}>
                        <div className="d-flex justify-content-end">
                            <CloseButton onClick={() => setShowErr(false)} />
                        </div>
                        <p>
                            {t('bigtext.failogin')}
                        </p>
                    </Alert>

                    : ''
                }

                <p className='h4 fw-bold text-warning'>{t('title.login')} {t('words.form')}</p>
                <hr className='w-25 text-warning pt-1 opacity-100' />

                <Form.Group className="col-lg-12 mb-3">
                    <Form.Label>{t('title.user')}</Form.Label>
                    <Form.Control
                        required
                        name="username"
                        type="email"
                        autoComplete="username"
                        onChange={handleChange} />
                </Form.Group>

                <Form.Group className="col-lg-12 mb-3">
                    <Form.Label>{t('title.pass')}</Form.Label>
                    <div className="position-relative" >
                        <Form.Control
                            required
                            name="password"
                            type={!eyePass ? "password" : "text"}
                            autoComplete="current-password"
                            onChange={handleChange} />

                        {!eyePass
                            ? <EyeFill onClick={showPass} size={20} className='text-warning position-absolute top-0 end-0 me-2 mt-2' />
                            : <EyeSlash onClick={showPass} size={20} className='text-warning position-absolute top-0 end-0 me-2 mt-2' />
                        }
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check
                        type="checkbox"
                        name="receive_mail_notifications"
                        checked={checkLogged || ''}
                        onChange={toggleCheck}
                        label={t('bigtext.keeplog')} />
                </Form.Group>

                <Col md={12}>
                    <Button
                        variant="warning"
                        className='text-center text-white w-100 mt-3 rounded-4'
                        type="submit"
                        disabled={loading ? true : false}>

                        {loading ?
                            <>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                {t('title.loading')}
                            </>

                            :
                            t('title.login')
                        }

                    </Button>
                </Col>

                <Col md={12}>
                    <Link
                        component={<SimplePage />}
                        to={`/page/resetpass`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="d-block font-weight-bold text-secondary py-2 text-center mt-3"
                    >
                        {t('text.forgotpass')}
                    </Link>
                </Col>

                {/*  <Col md={12} className="text-center mt-3">
                        {t('title.sign_in')}  {t('words.with')}:

                        <Link
                            component={<SimplePage />}
                            to="/page/facebook"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-warning p-2"
                        >
                            <Facebook size={27} />
                        </Link>

                        <Link
                            component={<SimplePage />}
                            to="/page/linkedin"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-warning p-2"
                        >
                            <Linkedin size={27} />
                        </Link>

                    </Col> */}

            </>
            {/* } */}


        </Form >

    );
}

export default LoginFormNew