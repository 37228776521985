
import { store } from "../Redux/Store.js"
import { setReduxUser } from "../Redux/user-slice";
import { setGlobalLoader } from "../Redux/page-slice";
import { setFeedTrue, setEmailNotif, setSiteNotif } from "../Redux/news-feed-slice.js";
import { setSavedSearches, setSavedSearchesDrop } from "../Redux/saved-searches-alerts-slice.js";


const getUser = async (localLoader) => {

    try {
        const keepMeLogged = localStorage.getItem('keepLogged')
        let bearer_token = `Bearer ${localStorage.getItem('token')}`;
        if (localStorage.getItem('token') === undefined) return null
        if (!localStorage.getItem('logged')) return null


        if (keepMeLogged) {
            const res1 = await fetch(process.env.REACT_APP_REFRESHTOKEN_API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',

                },
                credentials: 'include'
            })
            const data1 = await res1.json()
            console.log(res1)
            console.log(data1)
            localStorage.setItem('token', data1.token);
            bearer_token = `Bearer ${data1.token}`;

        }


        const res = await fetch(process.env.REACT_APP_USERINFO_API, {
            method: 'GET',
            headers: {
                'Authorization': bearer_token,

            },
            credentials: 'include'
        })
        if (res.status >= 200 && res.status <= 299) {

            const data = await res.json()

            // console.log(data)
            // console.log(data.profile_photo)
            if (data) {
                await store.dispatch(setReduxUser(data))
                if (data.email_notif_prefs) { store.dispatch(setEmailNotif(data.email_notif_prefs)) }
                if (data.site_notif_prefs) { store.dispatch(setSiteNotif(data.site_notif_prefs)) }
                localStorage.setItem('logged', true);
                await setCustom(data.newsfeed_prefs)

                store.dispatch(setSavedSearches(data.saved_searches))
                const newData = []
                for (let i = 0; i < data.saved_searches.length; i++) {
                    //we need speficic keys for select drop to work
                    newData[i] = {
                        value: data.saved_searches[i].id,
                        label: data.saved_searches[i].name,
                        type: data.saved_searches[i].type
                    }
                }
                store.dispatch(setSavedSearchesDrop(newData))
            }


        }



    } catch (error) {
        console.log(error)
    } finally {
        if (localLoader) localLoader(false);
        store.dispatch(setGlobalLoader(false));
    }

}

function setCustom(arr) {
    for (let i = 0; i < arr.length; i++) {
        store.dispatch(setFeedTrue(arr[i]))
    }
}
export default getUser