//import
//const sessionFetch = new TokenRefresher();
//sessionFetch.setTokenRefresh(getUser);

import ClearLocalConditionally from '../functions/ClearLocalConditionally';
import { store } from "../Redux/Store.js"
export default class TokenRefresher {

    //Bearer token
    setTokenRefresh(fetchToCall) {

        //Library for decoding
        const jwt = require('jsonwebtoken');

        const decoded = jwt.decode(localStorage.getItem('token'));
        const testEnv = store.getState().page.testEnv
        if (decoded) {

            const now = Math.floor(Date.now() / 1000);//+(60*60);

            if (now * 1000 >= decoded.exp * 1000) {

                ClearLocalConditionally(true);
                window.location.href = '/';
                return;
            }
            else if (now * 1000 < decoded.exp * 1000 && now - localStorage.getItem('dateTok') > 100) {

                //with the above we check that the token is older than about a minute before we ask for a new one.
                localStorage.setItem('dateTok', now)
                refreshSessTok();
            }
            else {
                if (fetchToCall) {
                    fetchToCall();
                }
            }

        }

        async function refreshSessTok() {

            // let refresh_token2 = { refresh_token: localStorage.getItem('refresh_token') }

            if (testEnv) {
                let refresh_token2 = { refresh_token: localStorage.getItem('refresh_token') }
                await fetch(process.env.REACT_APP_REFRESHTOKEN_API, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    }, credentials: 'include',
                    body: JSON.stringify(refresh_token2),
                })
                    .then(handleSubmitError)
                    .then(res => {
                        localStorage.setItem('token', res.token);
                        localStorage.setItem('refresh_token', res.refresh_token);

                        if (fetchToCall) {
                            fetchToCall();
                        }

                    })
                    .catch(err => console.error(err))
            } else {
                await fetch(process.env.REACT_APP_REFRESHTOKEN_API, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    }, credentials: 'include',

                })
                    .then(handleSubmitError)
                    .then(res => {
                        localStorage.setItem('token', res.token);


                        if (fetchToCall) {
                            fetchToCall();
                        }

                    })
                    .catch(err => console.error(err))
            }


            //an lhgei to session refresh na parei to local ksana?
            //an opoiodhpote error session clear kai sthn arxikh
        }

        function handleSubmitError(res) {
            if (res.status >= 200 && res.status <= 299) {
                return res.json();
            } else {
                ClearLocalConditionally(true);
                window.location.href = '/';
            }
        }

    }
}

