import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Card, CloseButton, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import { BiMailSend } from 'react-icons/bi'
import sendEmailFromSite from '../../jwtAPI/SendEmail'


const AgentContactHorizontal = ({ agent }) => {

  const { t } = useTranslation();

  const [formData, updateFormData] = React.useState('');

  function handleChange(e) {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim()
    });
  };

  const [validated, setValidated] = React.useState(false);
  //Loading Spinner
  const [loading, setloading] = React.useState(false);

  //error or success on send
  const [success, setSuccess] = React.useState('')

  const handleSubmit = (event) => {

    setloading(true)

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setloading(false)
    }

    setValidated(true);
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === true) {
      const url = `${process.env.REACT_APP_DOMAIN}/en/api/user/${agent.id}/email`
      sendEmailFromSite(url, formData, setSuccess, setloading)
    }
  };


  const resetForm = () => {
    setSuccess('')
    updateFormData('')
    setValidated(false)
  }


  return (

    <Container fluid="lg" className='p-4 mt-4'>

      <Card as={Row} border="white" bg="secondary" className="justify-content-center rounded-4 gx-0 flex-row">


        {agent.profile_picture_url
          &&
          <Col lg={3}
            className="m-lg-5 position-relative mask1 text-center"
            style={{
              backgroundImage: `url(${process.env.REACT_APP_DOMAIN + agent.profile_picture_url}?photo_height=400&photo_width=400`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '250px',
              width: '220px'
            }}
          />
        }


        <Col className="text-white p-4 align-self-center">

          {success !== ''

            ?
            <Alert
              variant={success === 'success' ? 'success' : 'danger'}
              onClose={() => setSuccess('')}
              className="p-4 text-center"
            >
              <div className="d-flex end">
                <CloseButton onClick={() => setSuccess('')} />
              </div>
              <p>
                {success === 'success'
                  ? t('bigtext.succontactUser')
                  : t('bigtext.failcontact')
                }
              </p>
              <Button
                variant="outline-success"
                size="lg"
                onClick={resetForm}
                className='border-3'
              >
                {success === 'success'
                  ? t('bigtext.sendAnotherMesUser')
                  : t('text.retryMes')
                }
              </Button>
            </Alert>

            :

            <>
              <p className="text-center text-primary mt-3 mb-5 lead">
                {t('text.sendEmailUser')}&nbsp;
                <span className="fw-bold text-capitalize">{agent['blog_display_name'] ? agent['blog_display_name'].toLowerCase() : 'No Name'}</span>
              </p>

              <Form noValidate validated={validated} onSubmit={handleSubmit} id="contact_form">
                <Row className="mb-3">
                  <Form.Group as={Col} md="6">
                    <Form.Label>{t('title.fname')} &amp; {t('title.lname')} *</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      onChange={handleChange}
                      autoComplete="name"
                      maxLength="30"
                      name="name"
                    />
                    <Form.Control.Feedback type="invalid">{t('text.emptyField')}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6">
                    <Form.Label>Email *</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      onChange={handleChange}
                      name="email"
                      autoComplete="email"
                    />
                    <Form.Control.Feedback type="invalid">{t('text.emptyField')}</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="6">
                    <Form.Label>{t('words.subject')} *</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      onChange={handleChange}
                      autoComplete="off"
                      maxLength="30"
                      name="subject"
                    />
                    <Form.Control.Feedback type="invalid">{t('text.emptyField')}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6">
                    <Form.Label>{t('title.phone')}</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={handleChange}
                      autoComplete="phone"
                      maxLength="20"
                      name="phone"
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label>{t('words.msg')} *</Form.Label>
                    <Form.Control
                      required
                      name="body"
                      as="textarea"
                      rows={3}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">{t('text.emptyField')}</Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Button
                  variant="outline-primary"
                  className='rounded-4 border-2 float-end mt-4'
                  size="lg"
                  type="submit"
                  disabled={loading ? true : false}
                >
                  {loading ?
                    <>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      {t('title.loading')}
                    </>

                    :
                    <>
                      <BiMailSend size={25} className="me-2" /> {t('title.sendmes')}
                    </>
                  }
                </Button>

              </Form>

            </>
          }
        </Col>

      </Card>
    </Container>

  )
}

export default AgentContactHorizontal