// material-ui
//import { useTheme } from '@mui/material/styles';

// ==============================|| LOGO SVG ||============================== //

const Logo = ({ primaryColor }) => {


    return (
        <svg id="Calque_1" data-name="Calque 1" width="100%" viewBox="0 0 688.43 125" fill="none" xmlns="http://www.w3.org/2000/svg">

            <path fill={primaryColor} d="M236.6,259.32a30.41,30.41,0,0,1,9.07,1.19,16.89,16.89,0,0,1,6.2,3.37,13.11,13.11,0,0,1,3.58,5.18,18.31,18.31,0,0,1,1.15,6.63,14.63,14.63,0,0,1-2.17,8.41A13.5,13.5,0,0,1,248,289a14.4,14.4,0,0,1,6.55,5.09,14.62,14.62,0,0,1,2.31,8.54,18.62,18.62,0,0,1-1.15,6.64,13.17,13.17,0,0,1-3.59,5.17,17.19,17.19,0,0,1-6.19,3.41,29.13,29.13,0,0,1-9.08,1.24H210.31q-5,0-4.95-4.87v-50q0-4.87,4.95-4.87Zm-21,25.22h21.24q9.21,0,9.21-7.17a7.56,7.56,0,0,0-2.17-5.66c-1.45-1.42-3.76-2.12-7-2.12H216.77c-.77,0-1.15.35-1.15,1.06Zm0,23.19c0,.65.38,1,1.15,1h20.36q4.77,0,7-2a7.45,7.45,0,0,0,2.21-5.75q0-7.71-9.2-7.7H215.62Z" transform="translate(-78.2 -228.95)" />
            <path fill={primaryColor} d="M293.48,272.33q8.31,0,12,3.76t3.71,10.58q0,6.46-3.63,9.95t-12.21,3.5H271.17v3.36a6.69,6.69,0,0,0,1.64,4.65c1.09,1.21,2.94,1.81,5.53,1.81h28.59v9.12H278.34a21.17,21.17,0,0,1-7.47-1.15,13.39,13.39,0,0,1-5.05-3.23,12.34,12.34,0,0,1-2.88-4.87,20.26,20.26,0,0,1-.88-6.15V287.73a20.42,20.42,0,0,1,.88-6.11,12.35,12.35,0,0,1,2.88-4.91,13.52,13.52,0,0,1,5.05-3.23,21.17,21.17,0,0,1,7.47-1.15Zm-22.31,20.44h22c2.53,0,4.32-.5,5.35-1.5A5.42,5.42,0,0,0,300,287.2a5.72,5.72,0,0,0-1.6-4.25q-1.59-1.59-5-1.59H278.34c-2.59,0-4.44.62-5.53,1.86a6.7,6.7,0,0,0-1.64,4.6Z" transform="translate(-78.2 -228.95)" />
            <path fill={primaryColor} d="M317.91,272.33V261.27h9.47v11.06H340.3v9H327.38v22.12q0,4,1.46,5.49c1,1,2.58,1.51,4.83,1.51h6.46v8.58h-8.41a21.55,21.55,0,0,1-6.24-.8,9.28,9.28,0,0,1-4.29-2.65,11.53,11.53,0,0,1-2.48-5,31.48,31.48,0,0,1-.8-7.7V281.36h-6.37v-9Z" transform="translate(-78.2 -228.95)" />
            <g>
                <path fill={primaryColor} d="M384.31,280.13c-1.49,0-1.48.76-1.83,2.29l-6.61,28.28a12.25,12.25,0,0,1-4.23,7.22q-3.08,2.38-8.63,2.38a16.68,16.68,0,0,1-5.51-.79,10.09,10.09,0,0,1-3.7-2.2,9.74,9.74,0,0,1-2.24-3.4,19.45,19.45,0,0,1-1.15-4.36l-4.84-36.82H355.7L359.84,309a3.65,3.65,0,0,0,.92,2.25,3.34,3.34,0,0,0,2.43.75,3.47,3.47,0,0,0,2.33-.66,3.82,3.82,0,0,0,1.1-1.89l6.61-28.19a11.74,11.74,0,0,1,4.14-7c2.06-1.59,3.32-2.38,7-2.38" transform="translate(-78.2 -228.95)" />
                <path fill={primaryColor} d="M383.66,271.85c3.7,0,5,.79,7,2.38a11.84,11.84,0,0,1,4.14,7l6.6,28.19a4,4,0,0,0,1.1,1.89,3.48,3.48,0,0,0,2.34.66,3.31,3.31,0,0,0,2.42-.75,3.71,3.71,0,0,0,.93-2.25l4.14-36.29h10.13l-4.85,36.82a18.93,18.93,0,0,1-1.14,4.36,9.62,9.62,0,0,1-2.25,3.4,10,10,0,0,1-3.7,2.2,16.62,16.62,0,0,1-5.5.79q-5.55,0-8.64-2.38a12.35,12.35,0,0,1-4.23-7.22l-6.6-28.28c-.36-1.53-.35-2.29-1.83-2.29" transform="translate(-78.2 -228.95)" />
            </g>
            <path fill={primaryColor} d="M457.25,272.33q8.33,0,12,3.76T473,286.67q0,6.46-3.63,9.95t-12.22,3.5H435v3.36a6.64,6.64,0,0,0,1.64,4.65q1.64,1.81,5.53,1.81h28.59v9.12H442.12a21.27,21.27,0,0,1-7.48-1.15,13.39,13.39,0,0,1-5.05-3.23,12.44,12.44,0,0,1-2.87-4.87,20.26,20.26,0,0,1-.89-6.15V287.73a20.42,20.42,0,0,1,.89-6.11,12.45,12.45,0,0,1,2.87-4.91,13.52,13.52,0,0,1,5.05-3.23,21.27,21.27,0,0,1,7.48-1.15ZM435,292.77H456.9q3.81,0,5.35-1.5a5.38,5.38,0,0,0,1.55-4.07,5.75,5.75,0,0,0-1.59-4.25q-1.59-1.59-5-1.59h-15q-3.9,0-5.53,1.86a6.65,6.65,0,0,0-1.64,4.6Z" transform="translate(-78.2 -228.95)" />
            <path fill={primaryColor} d="M508.53,272.33q8.33,0,12,3.76t3.71,10.58q0,6.46-3.62,9.95t-12.22,3.5H486.23v3.36a6.64,6.64,0,0,0,1.64,4.65q1.63,1.81,5.53,1.81H522v9.12H493.4a21.23,21.23,0,0,1-7.48-1.15,13.39,13.39,0,0,1-5.05-3.23,12.44,12.44,0,0,1-2.87-4.87,20.26,20.26,0,0,1-.89-6.15V287.73a20.42,20.42,0,0,1,.89-6.11,12.45,12.45,0,0,1,2.87-4.91,13.52,13.52,0,0,1,5.05-3.23,21.23,21.23,0,0,1,7.48-1.15Zm-22.3,20.44h21.95c2.53,0,4.32-.5,5.35-1.5a5.42,5.42,0,0,0,1.55-4.07,5.75,5.75,0,0,0-1.59-4.25q-1.61-1.59-5.05-1.59h-15q-3.9,0-5.53,1.86a6.65,6.65,0,0,0-1.64,4.6Z" transform="translate(-78.2 -228.95)" />
            <path fill={primaryColor} d="M554.29,272.33q12,0,17.74,5.35t5.8,15.09v26.29h-9.47V292.77c0-3.71-1.1-6.55-3.32-8.49s-5.79-2.92-10.75-2.92H539c-.77,0-1.15.44-1.15,1.32v36.38h-9.38V279.59q0-4.17,1.5-5.71c1-1,2.69-1.55,5-1.55Z" transform="translate(-78.2 -228.95)" />
            <path fill={primaryColor} d="M613.09,272.33q9.28,0,14.73,5.8t5.44,17.57q0,11.85-5.44,17.61t-14.73,5.75H592.38v18.76H583V292.33q0-10.17,4.34-15.09t12.83-4.91Zm-20.71,37.61h19.73q6.1,0,8.9-3.31t2.78-10.93q0-7.62-2.78-11t-8.9-3.36h-9.2q-5.76,0-8.14,2.65c-1.6,1.77-2.39,4.69-2.39,8.76Z" transform="translate(-78.2 -228.95)" />
            <path fill={primaryColor} d="M669.93,272.33v9H653.47q-6,0-6,6v31.68h-9.38V286q0-7.08,2.88-10.36c1.91-2.18,5.18-3.27,9.78-3.27Z" transform="translate(-78.2 -228.95)" />
            <path fill={primaryColor} d="M700.67,272.33q9.3,0,14.78,5.8t5.49,17.57q0,11.85-5.49,17.61t-14.78,5.75h-9.82q-9.39,0-14.83-5.75t-5.44-17.61q0-11.86,5.44-17.62t14.83-5.75Zm-8.94,9q-6.11,0-8.89,3.36t-2.79,11q0,7.6,2.79,10.93t8.89,3.31h8.06q6.11,0,8.89-3.31t2.79-10.93q0-7.62-2.79-11t-8.89-3.36Z" transform="translate(-78.2 -228.95)" />
            <path fill={primaryColor} d="M764.78,272.33v9H738.93c-2.18,0-3.75.44-4.69,1.32a5.25,5.25,0,0,0-1.42,4,4.89,4.89,0,0,0,1.38,3.76c.91.86,2.67,1.28,5.26,1.28h11a25.72,25.72,0,0,1,8,1.06,12.94,12.94,0,0,1,4.95,2.84,9.54,9.54,0,0,1,2.53,4.16,18,18,0,0,1,.7,5.13,18.54,18.54,0,0,1-.84,5.71,11,11,0,0,1-2.7,4.47,12.88,12.88,0,0,1-4.77,2.92,20.5,20.5,0,0,1-7,1.06H724.15v-9.12h27.17c2.18,0,3.75-.39,4.69-1.19a4.88,4.88,0,0,0,1.42-3.94,4.8,4.8,0,0,0-1.55-4q-1.54-1.18-5.09-1.19H739.73a24,24,0,0,1-8-1.11,12.35,12.35,0,0,1-4.91-3,10.28,10.28,0,0,1-2.52-4.38,18.64,18.64,0,0,1-.71-5.13q0-6.29,3.54-10t11.68-3.67Z" transform="translate(-78.2 -228.95)" />
            <g>
                <path fill={primaryColor} d="M95.61,301.24V285.12a9.67,9.67,0,1,1,19.34,0v26.46a3.64,3.64,0,0,0,7.27,0V274a9.68,9.68,0,0,1,19.35,0v37.54a3.63,3.63,0,0,0,7.26,0v-26c0-5.6,4-10.12,9.68-10.12a9.69,9.69,0,0,1,9.67,9.67v16.12a3.65,3.65,0,0,0,3.64,3.64h13.82V264a11,11,0,0,0-4.95-8.58l-43.82-25.3a11,11,0,0,0-9.9,0l-43.82,25.3a11,11,0,0,0-5,8.58v40.87H92A3.65,3.65,0,0,0,95.61,301.24Zm62.9-39.33a5.66,5.66,0,1,1-5.66,5.66A5.65,5.65,0,0,1,158.51,261.91Zm-26.62-11.08a5.67,5.67,0,1,1-5.66,5.66A5.67,5.67,0,0,1,131.89,250.83Zm-26.61,11.08a5.66,5.66,0,1,1-5.66,5.66A5.65,5.65,0,0,1,105.28,261.91Z" transform="translate(-78.2 -228.95)" />
                <path fill={primaryColor} d="M162.14,301.24V285.12a3.66,3.66,0,0,0-3.63-3.64c-2.37,0-3.63,1.86-3.63,4.09v26a9.68,9.68,0,0,1-19.35,0V274a3.64,3.64,0,0,0-7.27,0v37.54a9.68,9.68,0,0,1-19.35,0V285.12a3.63,3.63,0,1,0-7.26,0v16.12A9.7,9.7,0,0,1,92,310.92H78.2v3.68a11,11,0,0,0,5,8.58L127,348.48a11,11,0,0,0,9.9,0l43.82-25.3a11,11,0,0,0,4.95-8.58v-3.68H171.82A9.7,9.7,0,0,1,162.14,301.24Z" transform="translate(-78.2 -228.95)" />
            </g>
        </svg>

    );
};



Logo.defaultProps = {
    primaryColor: '#18b0e7',
}


export default Logo

