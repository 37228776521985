import { Col, Row, Button, ButtonGroup } from "react-bootstrap"
import { BellFill } from "react-bootstrap-icons"
import { ImSearch } from "react-icons/im"
import { useTranslation } from 'react-i18next'


const SearchButtons = ({ resetFilters, saveSearch }) => {

    const { t } = useTranslation();

    return (
        <>
            <Row className="mt-3 justify-content-between mb-3" >
                <Col>
                    <ButtonGroup aria-label="FilterActions" className="d-flex flex-wrap align-items-center">

                        <Button
                            onClick={()=>resetFilters(true)}
                            id="reset_filters"
                            variant="outline-danger"
                            type="reset"
                            className="border-2 rounded-pill me-2 fw-bold my-1"
                        >
                            {t('words.clear')} {t('words.filters')}
                        </Button>

                        <Button
                            variant="yellow"
                            className="border-2 rounded-pill me-2 fw-bold text-white my-1"
                            id="save_search"
                            onClick={saveSearch}
                        >
                            <BellFill size={18} className="me-2" />{t('words.save')} {t('words.srch')}/{t('words.alert')}
                        </Button>

                        <Button
                            variant="success"
                            className="border-2 rounded-pill fw-bold px-3 my-1"
                            type="submit"
                        >
                            <ImSearch size={18} className="me-2" />{t('words.srch')}
                        </Button>

                    </ButtonGroup>
                </Col>
            </Row>
        </>
    )
}

export default SearchButtons
