import React from 'react'
import { Col, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'

const TutorialMenu = ({ toolbox }) => {

    const { t } = useTranslation();

    const tabArray = [
        { mainType: 'allListings', title: t('words.all') },
        { mainType: 'sale', title: `${t('words.for2')} ${t('cats_tools.sale')}` },
        { mainType: 'rent', title: `${t('words.for2')} ${t('cats_tools.rent')}` },
        { mainType: 'request', title: t('cats_tools.request') },
        { mainType: 'partnership', title: t('cats_tools.partnership') },
    ]

    const tabArrayTool = [
        { mainType: 'allListings', title: t('words.all') },
        { mainType: 'crm', title: t('cats_tools.crm') },
        { mainType: 'training-job', title: t('cats_tools.training-job') },
        { mainType: 'benchmark', title: t('cats_tools.benchmark') },
        { mainType: 'article', title: t('cats_tools.article') },
        { mainType: 'event', title: t('cats_tools.event') },
    ]

    return (
        <Col sm={12} className="px-0 px-lg-4 mt-1 mb-5">
            <Tabs
                defaultActiveKey="allListings"
                id="myListingsTut"
                className="mt-2 nav-tabs-custom"
            >
                {!toolbox
                    ?
                    tabArray.map((tabListing, key) => (
                        <Tab eventKey={tabListing.mainType} title={tabListing.title} key={key} />
                    ))

                    :
                    tabArrayTool.map((tabListing, key) => (
                        <Tab eventKey={tabListing.mainType} title={tabListing.title} key={key} />
                    ))

                }
            </Tabs>
        </Col>

    )
}

export default TutorialMenu