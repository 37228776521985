import React from "react"
import { Container, Image } from 'react-bootstrap'
import BlogHeaderSmall from "../../assets/BlogHeader1000.jpg"
import BlogHeaderNormal from "../../assets/BlogHeader2000.jpg"
//Image resize with screen
import useWindowDimensions from '../../functions/GetWidthHeight'


function HeroBlog() {

    const { width } = useWindowDimensions();

    return (
        <Container fluid className="bg-warning">
            <Container fluid="xxl" className="p-0">

                <Image fluid alt="hero" src={width < 1000 ? BlogHeaderSmall : BlogHeaderNormal} />

            </Container>
        </Container>

    )
}
export default HeroBlog

