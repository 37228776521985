import { createSlice } from "@reduxjs/toolkit";

export const dropTypes = createSlice({
    name: "dropdownTypes",
    initialState: {
        en: {},
        fr: {},
        de: {},
        nl: {},
        el: {},
    },
    reducers: {
        setDropdowns: (state, action) => {
            state[action.payload.language][action.payload.typeAd] = action.payload.values;
        },
    }
})

export const { setDropdowns } = dropTypes.actions
export default dropTypes.reducer