import React from 'react';
import { warning, graylight, primary } from '../../scss/custom_vars.module.scss'
import { Container, Navbar, NavDropdown, Image, Offcanvas, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux/es/exports';
import Logo from '../../assets/LogoSignature';
import { Link, useNavigate } from "react-router-dom"
import UserMenu from './UserMenu';
import Notifications from '../notifications/Notifications';
import { BiMenu } from 'react-icons/bi';
import { AiFillHome } from 'react-icons/ai';
import { IoPeopleCircle } from 'react-icons/io5';
import { MdNoteAdd } from 'react-icons/md';
import { FaGlobeEurope, FaUserTie, FaSearch, FaBlogger } from "react-icons/fa";


const MainMenuMobile = () => {

  const navigate = useNavigate()
  const { t, i18n } = useTranslation();

  const lngs = {
    en: { nativeName: 'English' },
    fr: { nativeName: 'Français' },
    nl: { nativeName: 'Nederlands' },
    de: { nativeName: 'Dansk' },
    el: { nativeName: 'Ελληνικά' }
  };

  const luser = useSelector(state => state.user);
  const [refresh, setRefresh] = React.useState(luser.refreshUserObject)
  //Dark Mode
  const darkTheme = useSelector(state => state.page.darkTheme)

  const [logoColor, setLogo] = React.useState(warning);

  //Mobile and tablet menu
  const [show, setShow] = React.useState(false);
  const handleClose = () => { setShow(false); setSelection('') };
  const handleShow = () => setShow(true);

  //Second Column
  const [selection, setSelection] = React.useState('');


  React.useEffect(() => {
    if (darkTheme) {
      setLogo(graylight)
    } else {
      setLogo(warning)
    }
  }, [darkTheme, luser.refreshUserObject]);

  React.useEffect(() => {
    setRefresh(luser.refreshUserObject)
  }, [luser.refreshUserObject]);

  const categories = [
    { name: t('cats_tools.sale'), value: 'sale', link: 'ad' },
    { name: t('cats_tools.rent'), value: 'rent', link: 'ad' },
    { name: t('cats_tools.request'), value: 'request', link: 'ad' },
    { name: t('cats_tools.partnership'), value: 'partnership', link: 'ad' },
    { name: t('cats_tools.training-job'), value: 'job', link: 'tool' },
    { name: t('cats_tools.article'), value: 'article', link: 'tool' },
    { name: t('cats_tools.benchmark'), value: 'benchmark', link: 'tool' },
    { name: t('cats_tools.crm'), value: 'crm', link: 'tool' },
    { name: t('cats_tools.event'), value: 'event', link: 'tool' },
  ];

  return (

    <Container fluid>

      <Navbar.Brand onClick={() => navigate("/home")} className="cp">
        <Logo primaryColor={logoColor} primaryColor2={primary} primaryColor3={logoColor} />
      </Navbar.Brand>

      <Button variant="warning" onClick={handleShow}>
        <BiMenu size={25} />
      </Button>

      <Offcanvas show={show} onHide={handleClose} className="overflow-scroll bg-white">
        <Offcanvas.Header closeButton>
          <Navbar.Brand onClick={() => navigate("/home")} className="cp">
            <Logo primaryColor={logoColor} primaryColor2={primary} primaryColor3={logoColor} />
          </Navbar.Brand>
        </Offcanvas.Header>

        <Row className='ms-2 me-1'>

          <Col xs={5}>

            <Link
              className="nav-mob"
              to="/"
              onClick={handleClose}
            >
              <AiFillHome size={17} />
              {t('title.home')}
            </Link>

            <Button
              variant='link'
              className="nav-mob"
              onClick={() => setSelection('search')}
            >
              <FaSearch size={17} />
              {t('words.srch')}
            </Button>

            <Link
              className="nav-mob"
              to="/network"
              onClick={handleClose}
            >
              <IoPeopleCircle size={20} />
              {t('title.net')}
            </Link>

            <Link
              className="nav-mob"
              to="/blog"
              onClick={handleClose}
            >
              <FaBlogger size={20} />
              {t('title.blog')}
            </Link>

            <Button
              variant='link'
              className="nav-mob text-nowrap"
              onClick={() => setSelection('add')}
            >
              <MdNoteAdd size={19} />
              {t('words.addNew')}
            </Button>

            <div style={{ marginLeft: "-0.95rem" }} onClick={() => setSelection('')}>
              <Notifications mobile={true} />
            </div>

            <Button
              variant='link'
              className="nav-mob"
              onClick={() => setSelection('lang')}
            >
              <FaGlobeEurope size={21} />
              {lngs[i18n.language].nativeName}
            </Button>

            <hr className='my-1' />

            <div onClick={() => setSelection('prof')} className='d-flex align-items-center text-warning fw-bold'>

              {luser.profile_picture_url
                ?
                <Image roundedCircle
                  style={{ objectFit: 'cover' }}
                  width="37px"
                  height="37px"
                  src={`${luser.profile_picture_url}?date=${refresh}}`}
                  className="me-2 border border-primary border-3"

                />

                : <FaUserTie size={35} className="me-2 border rounded-circle" />
              }

              <span>
                {t('title.profile')} &
                <br />{t('notif.notif11')}
              </span>

            </div>

          </Col>

          <Col xs={7} className='align-self-end ps-3'>
            {selection === 'search'
              ?
              categories.map((cats, key) => (
                <NavDropdown.Item
                  key={key}
                  className="bg-light my-1 rounded-4"
                  onClick={() => { navigate(`/${cats.link}/${cats.value}`); handleClose(); }}
                >
                  {cats.name}
                </NavDropdown.Item>
              ))

              : selection === 'add'
                ?
                categories.map((cats, key) => (
                  <NavDropdown.Item
                    key={key}
                    className="bg-light my-1 rounded-4"
                    onClick={() => { navigate(`/create-new/${cats.value}`); handleClose(); }}
                  >
                    {cats.name}
                  </NavDropdown.Item>
                ))

                : selection === 'lang'
                  ?
                  Object.keys(lngs).map((lng) => (
                    <NavDropdown.Item
                      key={lng}
                      className="bg-success bg-opacity-25 my-1 rounded-4"
                      style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }}
                      type="submit"
                      onClick={() => { i18n.changeLanguage(lng); setSelection(null); }}
                    >
                      {lngs[lng].nativeName}
                    </NavDropdown.Item>
                  ))

                  : selection === 'prof'
                  &&
                  <div onClick={handleClose}>
                    <UserMenu luser={luser} darkTheme={darkTheme} mobile={true} />
                  </div>
            }
          </Col>

        </Row>
      </Offcanvas>
    </Container>

  )
}

export default MainMenuMobile