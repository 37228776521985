import { Col, Row, Container, Button } from "react-bootstrap"
import { Facebook, Linkedin } from "react-bootstrap-icons"
import Logo from "../assets/Logo"
import { useTranslation } from 'react-i18next'
import { BiArrowToTop } from 'react-icons/bi'
import privacyEn from '../assets/privacyPolicy/cgu_privacy_en.pdf'
import privacyFr from '../assets/privacyPolicy/cgu_privacy_fr.pdf'


const Footer = () => {

    const { t, i18n } = useTranslation();

    const scrollTop = () => {
        window.scrollTo(0, 0);
    }

    const openPdf = () => {
        if (i18n.language === "fr") {
            window.open(privacyFr)
        } else {
            window.open(privacyEn)
        }
    }

    return (
        <Container fluid className="bg-secondary py-3 mt-2 text-white" >
            <Container fluid="xxl">
                <Row className="justify-content-between align-items-center gy-4">
                    <Col md={3} >
                        <div className="navbar-brand">
                            <Logo primaryColor={'white'} />
                        </div>
                    </Col>
                    <Col md={3} className="align-self-center">
                        <p className="mb-0"><small>{t('title.copyr')} &copy; 2023 Betweenpros</small></p>
                    </Col>
                    <Col md={3} className="fw-bold cp" id="policy-new-tab" onClick={openPdf}>
                        {t('words.privPol')}
                    </Col>
                    <Col md={3}>
                        {t('text.follow')}:
                        <a href="https://www.facebook.com/betweenpros/" target="_blank" rel="noreferrer" className="text-white p-2">
                            <Facebook size={22} />
                        </a >
                        <a href="https://www.linkedin.com/company/betweenpros/" target="_blank" rel="noreferrer" className="text-white p-2 pe-5">
                            <Linkedin size={22} />
                        </a>
                        <Button
                            onClick={scrollTop}
                            variant="gray"
                            className="text-white p-0 border-0 position-fixed"
                            style={{
                                zIndex: "20",
                                bottom: "20px",
                                right: "10px"
                            }}
                        >
                            <BiArrowToTop size={45} />
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Container >
    )
}

export default Footer
