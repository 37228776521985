import React, { useEffect, useState } from "react";
import { warning, graylight, primary } from "../scss/custom_vars.module.scss";
import Logo from "../assets/LogoSignature";

function Loader() {
  let initialColor = localStorage.getItem("themeCol");

  // let [themeColor, setThemeColor] = useState(initialColor);
  const [logoColor, setLogo] = useState(warning);

  useEffect(() => {
    if (initialColor === "dark") {
      setLogo(graylight);
    } else {
      setLogo(warning);
    }
  }, [initialColor]);

  
  return (
    <>
      <div id="loaderContainer">
        <div id="loaderWrapper">
          <div id="myLoader">
            <Logo
              primaryColor={logoColor}
              primaryColor2={primary}
              primaryColor3={logoColor}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default Loader;
