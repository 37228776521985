import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import FetchGetSetSimple from '../jwtAPI/FetchGetSetSimple';
import AgentContact from '../components/singleListing/AgentContact';
import SingleListDetails from '../components/singleListing/SingleListDetails';
import SingleListHero from '../components/singleListing/SingleListHero';
//import SingleListSimilar from '../components/singleListing/SingleListSimilar';
import SingleListVideo from '../components/singleListing/SingleListVideo';
import ButtonEditLis from '../components/singleListing/ButtonEditLis';
import LoadingData from '../components/createEdit/LoadingData';
import NeedLoginPage from './NeedLoginPage';
import { useTranslation } from 'react-i18next';


const PropertyPage = () => {

    const { i18n } = useTranslation();

    const [logged] = React.useState(localStorage.getItem('logged'));
    //const listing = true;
    const { type, idLis } = useParams();
    let url = `${process.env.REACT_APP_DOMAIN}/${i18n.language}/api/listing/${type}/${idLis}`;

    const [listing, setter] = React.useState(null);

    React.useEffect(() => {

        const fetchLists = new FetchGetSetSimple();
        fetchLists.fetchListings(url, setter);

        // eslint-disable-next-line
    }, [idLis, type, url]);



    return (
        <>
            {listing && logged ?

                <>

                    <Container fluid="xxl">

                        <SingleListHero excerptPhoto={listing.article.excerpt_photo_url} gallery={listing.article.photos_urls} />

                    </Container>

                    <Container fluid="xl">

                        <Row className="justify-content-between mt-5">

                            <Col xl={8} lg={12}>

                                <ButtonEditLis idAuthor={listing.article.author.id} type={type} idLis={idLis} />

                                <SingleListDetails type={type} property={listing} />

                                {listing.video_link &&
                                    <SingleListVideo link={listing.video_link} />
                                }

                                {/* <SingleListSimilar listing={listing} mainTypeListing={type} /> */}

                            </Col>

                            <Col xl={3} lg={12}>

                                <AgentContact agent={listing.article.author} />

                            </Col>

                        </Row>

                    </Container>

                    <Footer />

                </>

                : !logged
                    ? <NeedLoginPage />

                    : <LoadingData />
            }
        </>
    )
}

export default PropertyPage
