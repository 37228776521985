import { Form, Col, Row, CloseButton } from 'react-bootstrap'
import React from 'react';

import PriceRange from '../generalSearch/PriceRange'
import SearchButtons from '../generalSearch/SearchButtons'
import KeywordsSearch from '../generalSearch/KeywordsSearch'
import SearchLocationFilters from '../generalSearch/SearchLocationFilters';

import JobDropdown from './jobs/JobDropdown';
import ComparisonDropdown from './comparison/ComparisonDropdown';
import CrmDropdown from './crm/CrmDropdown';
import AgendaDropdown from './agenda/AgendaDropdown';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import countries from "i18n-iso-countries";
import SavedSearchesDrop from '../generalSearch/SavedSearchesDrop';




const ToolboxSearch = ({ mainTypeListing, setShowFilters, isOpen, setFiltersPayload, saveSearch, filtersFromSaved, setFiltersFromSaved }) => {

    const navigate = useNavigate();
    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/el.json"));

    const { i18n, t } = useTranslation();

    const closeBut = () => setShowFilters(!isOpen);

    //country
    const [valueCountry, setValueCountry] = React.useState(null);

    const [formData, setFormData] = React.useState();


    React.useEffect(() => {

        if (!filtersFromSaved) return;

        resetFilters(false);

        //we want to put the values on the dropdowns but because we fetch them from the server
        //i delay them 2sec and after 6sec it runs again in case the request was late
        //find another way later on
        setTimeout(function () {
            Object.entries(filtersFromSaved).forEach(key => {
                if (document.getElementById(key[0])) {

                    if (key[0] === 'is_active') {
                        document.getElementById(key[0]).checked = key[1]
                        return;
                    }

                    document.getElementById(key[0]).value = key[1].id ? key[1].id.toString() : key[1]
                }
            })
        }, 2000);

        setTimeout(function () {
            Object.entries(filtersFromSaved).forEach(key => {
                if (document.getElementById(key[0])) {

                    if (key[0] === 'is_active') {
                        document.getElementById(key[0]).checked = key[1]
                        return;
                    }

                    document.getElementById(key[0]).value = key[1].id ? key[1].id.toString() : key[1]
                }
            })
        }, 6000);

        setFormData(filtersFromSaved)

        //only country not coordinates
        if (filtersFromSaved && filtersFromSaved.location && filtersFromSaved.location.country && !filtersFromSaved.location.google_map) {
            setValueCountry({ label: countries.getName(filtersFromSaved.location.country, i18n.language, { select: "official" }), value: filtersFromSaved.location.country })
        }
        else if (filtersFromSaved && filtersFromSaved.location && filtersFromSaved.location.google_map) {
            setTimeout(function () {
                Object.entries(filtersFromSaved.location).forEach(key => {
                    if (document.getElementById(key[0])) {
                        document.getElementById(key[0]).value = key[1].id ? key[1].id.toString() : key[1]
                    }
                })
            }, 2000);

            setTimeout(function () {
                Object.entries(filtersFromSaved.location).forEach(key => {
                    if (document.getElementById(key[0])) {
                        document.getElementById(key[0]).value = key[1].id ? key[1].id.toString() : key[1]
                    }
                })
            }, 6000);
        }

        //eslint-disable-next-line
    }, [filtersFromSaved]);


    const handleChange = (e) => {
        if (e.target.value === '') {
            //const dataObj = formData
            if (formData && (e.target.name in formData)) delete formData[e.target.name]
            setFormData({ ...formData });
        }
        else {
            setFormData({
                ...formData,
                // Trimming any whitespace
                [e.target.name]: e.target.value
            });
        }
    };

    const handleChangeDrops = (e) => {
        if (e.target.value === 'all') {
            //const dataObj = formData
            if (formData && (e.target.name in formData)) delete formData[e.target.name]
            setFormData({ ...formData });
        }
        else {
            setFormData({
                ...formData,
                [e.target.name]:
                {
                    id: parseInt(e.target.value.trim())
                }
            });
        }
    };

    const handleChangeLocation = (e) => {
        if (e.google_map) {
            setFormData({
                ...formData,
                location: {
                    google_map: e.google_map,
                    country: e.country,
                    street: e.street
                }
            });
        }
        else {
            setFormData({
                ...formData,
                location: {
                    [e.target.name]: e.target.value
                }
            });
        }
    };

    const handleSwitch = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.checked
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        //when we delete from the formdata if is empty it doesnt update the state so the request doesnt happen. here we update manually to empty
        if (formData && Object.keys(formData).length === 0) {
            resetFilters(true);
        }

        setFiltersPayload(formData)
    };

    React.useEffect(() => {
        resetFilters(true);
        //eslint-disable-next-line
    }, [mainTypeListing]);

    //Reset Form Filters
    function resetFilters(all) {
        document.getElementById("form_filters").reset();
        setFormData(undefined);
        setFiltersPayload(undefined);
        setValueCountry(null);
        if (all) setFiltersFromSaved(undefined);
        navigate(`/tool/${mainTypeListing}`);
    }


    return (

        <Form onSubmit={handleSubmit} id="form_filters">

            <Row className='align-items-end  gy-2 ms-3'>


                <Col md={12} className="text-end mb-0">
                    <CloseButton aria-label="Hide" onClick={closeBut} />
                </Col>

                <KeywordsSearch
                    handleChange={handleChange}
                    col={mainTypeListing !== 'job' && mainTypeListing !== 'benchmark' ? 6 : 3}
                />


                {mainTypeListing === 'job'
                    ? <JobDropdown col={6} getDataDrops={handleChangeDrops} />

                    : mainTypeListing === 'benchmark'
                        ? <ComparisonDropdown col={6} getDataDrops={handleChangeDrops} />


                        : mainTypeListing === 'crm'
                            ? <CrmDropdown col={6} getDataDrops={handleChangeDrops} />

                            : mainTypeListing === 'event'
                                ? <>
                                    <AgendaDropdown col={6} getDataDrops={handleChangeDrops} />

                                    <Col md={6}>
                                        <Form.Check
                                            type="switch"
                                            className='ms-2 mb-0'
                                            id="is_active"
                                            name="is_active"
                                            label={t('text.eventActive')}
                                            onChange={handleSwitch}
                                        />
                                    </Col>
                                </>

                                :
                                <Col md={4}>
                                    <Form.Label>{t('words.lang')}</Form.Label>
                                    <Form.Select
                                        size="sm"
                                        id="locale"
                                        defaultValue={i18n.language.toUpperCase()}
                                        name="locale"
                                        onChange={handleChange}
                                    >
                                        <option value="FR">FR</option>
                                        <option value="EN">EN</option>
                                        <option value="NL">NL</option>
                                        <option value="EL">EL</option>

                                    </Form.Select>
                                </Col>
                }

                {mainTypeListing === 'benchmark'
                    &&
                    <Col md={6}>
                        <PriceRange handleChange={handleChange} />
                    </Col>
                }


                {(mainTypeListing !== 'article' && mainTypeListing !== 'crm')
                    &&
                    <SearchLocationFilters handleChange={handleChange} handleChangeLocation={handleChangeLocation} valueCountry={valueCountry} setValueCountry={setValueCountry} />
                }

                <SavedSearchesDrop typeListing={mainTypeListing} setFiltersFromSaved={setFiltersFromSaved} />

                <Col md={12} className="mt-4 shadow">
                    <SearchButtons saveSearch={saveSearch} resetFilters={resetFilters} />
                </Col>

            </Row>

        </Form>
    )
}

export default ToolboxSearch