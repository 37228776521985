import { Badge, Card, Row, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { GiCommercialAirplane, GiCoffeeCup } from 'react-icons/gi'
import { FaRegHospital, FaBusAlt, FaParking } from 'react-icons/fa'
import { MdOutlineSubway, MdSchool, MdLocalMall } from 'react-icons/md'
import { IoRestaurant } from 'react-icons/io5'
import { BsBank2 } from 'react-icons/bs'
import { AiFillMedicineBox } from 'react-icons/ai'
import { RiShoppingCart2Line } from 'react-icons/ri'



const LocationNearBy = ({ places }) => {

    const { t } = useTranslation();

    //Main Places
    let airport = places.filter(place => place['category'][0] === 'airport'); //airport and services
    let bus_station = places.filter(place => place['category'][0] === 'bus_station');
    let hospital = places.filter(place => place['category'][0] === 'hospital');
    let subway_station = places.filter(place => place['category'][0] === 'subway_station');

    //Removes duplicate entries
    airport = airport.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t.place === value.place && t.name === value.name
        ))
    )
    bus_station = bus_station.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t.place === value.place && t.name === value.name
        ))
    )
    hospital = hospital.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t.place === value.place && t.name === value.name
        ))
    )
    subway_station = subway_station.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t.place === value.place && t.name === value.name
        ))
    )

    //Secondary Places
    let cafe = places.filter(place => place['name'] === 'cafe');
    let bank = places.filter(place => place['name'] === 'bank');
    let drugstore = places.filter(place => place['name'] === 'drugstore');
    let parking = places.filter(place => place['name'] === 'parking');
    let restaurant = places.filter(place => place['name'] === 'restaurant');
    let school = places.filter(place => place['name'] === 'school');
    let shopping_mall = places.filter(place => place['name'] === 'shopping_mall');
    let supermarket = places.filter(place => place['name'] === 'supermarket');


    return (
        <Card className='p-4 border-0 shadow-sm mt-5'>

            <span className='lead mb-3'>{t('text.placesNearby')} (300m)</span>

            {(hospital.length > 0 || airport.length > 0 || subway_station.length > 0 || bus_station.length > 0) &&
                <Row >
                    <h5 className='mt-3'> <Badge bg="warning">{t('words.important')}</Badge></h5>
                    <Table striped bordered hover responsive>
                        <tbody>

                            {hospital.length > 0 &&
                                <tr>
                                    <td className='fw-bold'>
                                        <FaRegHospital className="nearby-table" />
                                        {t('words.hospital')}
                                    </td>
                                    <td>
                                        {hospital.map((name, key) =>
                                            <span key={key} className='text-capitalize'>
                                                {name['name']}
                                                <br />
                                            </span>
                                        )}
                                    </td>
                                </tr>
                            }

                            {airport.length > 0 &&
                                <tr>
                                    <td className='fw-bold'>
                                        <GiCommercialAirplane className="nearby-table" />
                                        {t('words.airport')} &amp; {t('title.services')}
                                    </td>
                                    <td>
                                        {airport.map((name, key) =>
                                            <span key={key} className='text-capitalize'>
                                                {name['name']}
                                                <br />
                                            </span>
                                        )}
                                    </td>
                                </tr>
                            }

                            {subway_station.length > 0 &&
                                <tr>
                                    <td className='fw-bold'>
                                        <MdOutlineSubway className="nearby-table" />
                                        {t('words.sub_station')}
                                    </td>
                                    <td>
                                        {subway_station.map((name, key) =>
                                            <span key={key} className='text-capitalize'>
                                                {name['name']}
                                                <br />
                                            </span>
                                        )}
                                    </td>
                                </tr>
                            }

                            {bus_station.length > 0 &&
                                <tr>
                                    <td className='fw-bold'>
                                        <FaBusAlt className="nearby-table" />
                                        {t('words.bus_station')}
                                    </td>
                                    <td>
                                        {bus_station.map((name, key) =>
                                            <span key={key} className='text-capitalize'>
                                                {name['name']}
                                                <br />
                                            </span>
                                        )}
                                    </td>
                                </tr>
                            }



                        </tbody>
                    </Table>

                </Row>
            }

            <Row >
                <h5 className='mt-3'> <Badge bg="warning">{t('words.stores')} &amp; {t('title.services')}</Badge></h5>

                <Table borderless hover responsive>
                    <tbody>

                        {cafe.length > 0 &&
                            <tr>
                                <td className='fw-bold col-md-3'>
                                    <GiCoffeeCup className="nearby-table-2" />
                                    {t('words.cafe')}
                                </td>
                                <td>
                                    {cafe[0]['category'][0]}
                                </td>
                            </tr>
                        }

                        {restaurant.length > 0 &&
                            <tr>
                                <td className='fw-bold'>
                                    <IoRestaurant className="nearby-table-2" />
                                    {t('words.restaurant')}
                                </td>
                                <td>
                                    {restaurant[0]['category'][0]}
                                </td>
                            </tr>
                        }

                        {bank.length > 0 &&
                            <tr>
                                <td className='fw-bold'>
                                    <BsBank2 className="nearby-table-2" />
                                    {t('words.bank')}
                                </td>
                                <td>
                                    {bank[0]['category'][0]}
                                </td>
                            </tr>
                        }

                        {drugstore.length > 0 &&
                            <tr>
                                <td className='fw-bold col-md-3'>
                                    <AiFillMedicineBox className="nearby-table-2" />
                                    {t('words.drugstore')}
                                </td>
                                <td>
                                    {drugstore[0]['category'][0]}
                                </td>
                            </tr>
                        }

                        {parking.length > 0 &&
                            <tr>
                                <td className='fw-bold'>
                                    <FaParking className="nearby-table-2" />
                                    {t('words.parking')}
                                </td>
                                <td>
                                    {parking[0]['category'][0]}
                                </td>
                            </tr>
                        }

                        {school.length > 0 &&
                            <tr>
                                <td className='fw-bold'>
                                    <MdSchool className="nearby-table-2" />
                                    {t('words.school')}
                                </td>
                                <td>
                                    {school[0]['category'][0]}
                                </td>
                            </tr>
                        }

                        {shopping_mall.length > 0 &&
                            <tr>
                                <td className='fw-bold'>
                                    <MdLocalMall className="nearby-table-2" />
                                    {t('words.shopping_mall')}
                                </td>
                                <td>
                                    {shopping_mall[0]['category'][0]}
                                </td>
                            </tr>
                        }

                        {supermarket.length > 0 &&
                            <tr>
                                <td className='fw-bold'>
                                    <RiShoppingCart2Line className="nearby-table-2" />
                                    {t('words.supermarket')}
                                </td>
                                <td>
                                    {supermarket[0]['category'][0]}
                                </td>
                            </tr>
                        }




                    </tbody>
                </Table>

            </Row>
        </Card>
    )
}

export default LocationNearBy