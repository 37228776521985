import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Collapse, Form, Modal } from 'react-bootstrap'
import { FaPlus } from 'react-icons/fa'
import EditUserCircle from '../../jwtAPI/EditUserCircle';
import GroupManageCard from './GroupManageCard';


const EditGroups = ({ circles, updateCircles }) => {

    const { t } = useTranslation();

    //Toggle Add/Remove Groups
    let [editGroups, setEditGroups] = React.useState(false);
    let toggleEditGroups = () => { setEditGroups(!editGroups); }

    //Add Modal
    let [groupName, setGroupName] = React.useState('')
    let [groupDesc, setGroupDesc] = React.useState('')

    let getGroupName = (e) => setGroupName(e.target.value);
    let getGroupDesc = (e) => setGroupDesc(e.target.value);

    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //New Group
    const newGroup = () => {

        if (groupName !== '' || groupDesc !== '') {

            const data = {
                "title": groupName,
                "description": groupDesc
            }

            const userCircle = new EditUserCircle();
            userCircle.fetchListings('create', data);

        }

        handleClose();
    };



    return (
        <>

            <Button
                variant="outline-secondary"
                onClick={toggleEditGroups}
                aria-controls="edit_groups"
                className='border-0 ps-0 ms-1'
            >
                <span className='fw-bold'>&#9654; {t('words.add')}/{t('words.remove')}&nbsp;{t('words.group2')}</span>
            </Button>

            <Collapse id="edit_groups" in={editGroups} dimension="height" className='collapse-height-scroll'>

                <div className='border border-2 rounded-4 border-warning pb-1'>

                    <div className='my-2'>
                        <Button variant="outline-success border-0 rounded-4 fw-bold" onClick={handleShow}>
                            <FaPlus className='mb-1' /> {t('words.add')} {t('words.new')} {t('words.group')}
                        </Button>
                    </div>

                    <ul style={{ maxHeight: '160px' }} className="list-group list-group-flush overflow-scroll">

                        {circles.map((circle, key) => (
                            <div className='position-relative' key={key}>
                                <GroupManageCard circle={circle} updateCircles={updateCircles} circles={circles} />
                            </div>
                        ))}

                    </ul>

                </div>

            </Collapse>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('words.add')} {t('words.group2')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="editGroupName">
                            <Form.Label>{t('words.group')} {t('title.name')}</Form.Label>
                            <Form.Control
                                type="text"
                                defaultValue={groupName}
                                onChange={getGroupName}
                                autoFocus
                                maxLength="30"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="editGroupDesc">
                            <Form.Label>{t('words.group')} {t('words.desc')}</Form.Label>
                            <Form.Control
                                type="text"
                                defaultValue={groupDesc}
                                onChange={getGroupDesc}
                                autoFocus
                                maxLength="60"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('words.cancel')}
                    </Button>
                    <Button variant="success" onClick={newGroup}>
                        {t('words.save')} {t('words.changes')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}

export default EditGroups