
export default function ClearLocalConditionally(condition) {

  let keysToRemove = ["keepLogged", "token", "refresh_token", "userData", "logged", "dateTok"];

  if (localStorage.getItem('keepLogged') === 'true' && !condition) {
    keysToRemove = ["token", "userData", "logged", "dateTok"];
  }

  keysToRemove.forEach(k =>
    localStorage.removeItem(k))
}