import TokenRefresher from "./TokenRefresher";


//Saves and uses json after fetch with the useState of setter listi
export default class FetchGetSetSimple {
    constructor(ac) {
        this.ac = ac
    }

    //Bearer token
    // setter : array of listings
    // setterSum : the count of listings
    async fetchListings(url, setter, setterSum, type_of_parse_for_fetch, setLoading) {

        let getListing = async () => {

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;

            fetch(url, {
                method: 'GET',
                signal: this.ac,
                headers: {
                    'Authorization': bearer_token,
                },
                credentials: 'include',
            })
                .then(res => res.json())
                .then(res => {

                    setter(false);

                    let myData = res;

                    //multiple or single listing
                    if (setterSum) {
                        if (res.records_filtered) {
                            setterSum(res.records_filtered);
                        }
                        else {
                            setterSum(res.records_total);
                        }


                        if (type_of_parse_for_fetch) {
                            myData = myData.records;
                        }
                        else {
                            myData = JSON.stringify(myData.records);
                        }
                        //myData = res.records;
                    }


                    return myData;
                })
                .then(myData => {
                    if (setLoading) setLoading(false)
                    setter(myData);
                })

                .catch(err => { console.error(err); if (setLoading) setLoading(false);})

        }



        //checks and refresh token
        const sessionFetch = new TokenRefresher();
        sessionFetch.setTokenRefresh(getListing);

    }

}

