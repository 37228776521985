import { Button, ButtonGroup, Col } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import { BiReset } from 'react-icons/bi'
import { MdAddTask } from 'react-icons/md'
import { FaRegSave } from 'react-icons/fa'


const ActionsCE = ({ completeForm, edit }) => {

  const { t } = useTranslation();

  const clearForm = () => {

    if (window.confirm(t('bigtext.resetForm')) === true) {
      window.location.reload(true)
    }
  }


  return (

    <Col lg={12} className="my-4 text-end ">

      <ButtonGroup aria-label="Basic example" className="flex-wrap">

        <Button
          size="lg"
          variant="outline-danger"
          className="fw-bold me-4 rounded-3 border-2 mt-1"
          onClick={clearForm}
        >
          <BiReset size={25} className="me-1 mb-1" />
          {edit
            ? <>{t('words.undo')} {t('words.changes')}</>
            : <>{t('words.clear')} {t('words.form')}</>
          }

        </Button>

        <Button
          size="lg"
          variant="outline-info"
          className="fw-bold me-md-4 rounded-3 border-2 mt-2 me-sm-0"
          type="submit"
          id="draft"
          onClick={completeForm}
        >
          <FaRegSave size={25} className="me-1 mb-1" />
          {t('text.save_draft')}
        </Button>

        <Button
          size="lg"
          variant="outline-success"
          className="fw-bold rounded-3 border-2 mt-2"
          type="submit"
          id="published"
          onClick={completeForm}
        >
          <MdAddTask size={25} className="me-1 mb-2" />
          {edit
            ? <>{t('words.save')} {t('words.changes')}</>
            : <>{t('words.publish')}</>
          }

        </Button>

      </ButtonGroup>

    </Col>

  )
}

export default ActionsCE