import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Card, CloseButton, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import { Facebook, Linkedin } from "react-bootstrap-icons"
import { warning, primary } from '../scss/custom_vars.module.scss'
import Logo from '../assets/LogoSignature';
import { BiMailSend } from 'react-icons/bi'
import sendEmailFromSite from '../jwtAPI/SendEmail'
import ReCAPTCHA from "react-google-recaptcha"
import { useLocation } from 'react-router-dom';


const ContactForm = () => {

    const { t } = useTranslation();

    const scrollView = useLocation().hash

    //scrollToView
    React.useEffect(() => {

        if (scrollView) {
            const element = document.getElementById('contact-form');
            if (element) {
                element.scrollIntoView({ behavior: "auto", block: 'center' });
            }
        }
        // eslint-disable-next-line
    }, []);

    //sumbit button disabled before captcha ver
    const [capVerified, setcCapVerified] = React.useState(false)

    //CAPTCHA VERIFICATION
    const recaptchaRef = React.useRef(null);

    async function verify() {

        const captchaToken = recaptchaRef.current.getValue();
        recaptchaRef.current.reset();

        const payload = {
            captcha_token: captchaToken
        }

        await fetch(process.env.REACT_APP_CAPTCHA_API, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        })
            .then(res => {
                if (res.status >= 200 && res.status <= 299) {
                    setcCapVerified(true)
                } else {
                    alert('We could not verify you, please try again')
                }
            })

            .catch(err => console.error(err))
    }

    const [formData, updateFormData] = React.useState('');

    function handleChange(e) {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        });
    };

    const [validated, setValidated] = React.useState(false);
    //Loading Spinner
    const [loading, setloading] = React.useState(false);

    //error or success on send
    const [success, setSuccess] = React.useState('')

    const handleSubmit = (event) => {

        setloading(true);
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setloading(false)
        }

        setValidated(true);
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === true) {
            const url = process.env.REACT_APP_SUPPORT_MAIL
            sendEmailFromSite(url, formData, setSuccess, setloading, true)
        }
    };


    const resetForm = () => {
        setSuccess('')
        updateFormData('')
        setValidated(false)
        setcCapVerified(false)
    }




    return (

        <Container fluid="xl" className='px-0 px-md-4' id="contact-form">
            <Card border="white" className="mt-5 justify-content-center rounded-4">

                <Row className='text-center p-4'>
                    <Col>
                        <p className='h1 fw-bold text-primary'>{t('title.contactus')}</p>
                        <p className='lead text-muted'>{t('bigtext.supportEmail1')}</p>
                    </Col>
                </Row>

                <Row className='bg-secondary gx-0 justify-content-center pt-4'>

                    <Col lg={4} id="contact-form-shape">

                        <div className="mt-5"></div>
                        <Logo primaryColor={warning} primaryColor2={primary} primaryColor3={warning} />

                        <a
                            href="https://www.facebook.com/betweenpros/"
                            target="_blank"
                            rel="noreferrer"
                            className="p-2 btn btn-outline-warning border-2 rounded-4 fw-bold mt-4"
                        >
                            <Facebook size={22} />&nbsp;&nbsp;{t('text.follow')} Facebook
                        </a >
                        <br />
                        <a
                            href="https://www.linkedin.com/company/betweenpros/"
                            target="_blank"
                            rel="noreferrer"
                            className="p-2 btn btn-outline-info border-2 rounded-4 fw-bold mt-2"
                        /* id="last-button" */
                        >
                            <Linkedin size={22} />&nbsp;&nbsp;{t('text.follow')} Linkedin &nbsp;&nbsp;
                        </a>

                    </Col>

                    <Col className="text-white p-4 align-self-center">

                        {success !== ''

                            ?
                            <Alert
                                variant={success === 'success' ? 'success' : 'danger'}
                                onClose={() => setSuccess('')}
                                className="p-4 text-center"
                            >
                                <div className="d-flex end">
                                    <CloseButton onClick={() => setSuccess('')} />
                                </div>
                                <p>
                                    {success === 'success'
                                        ? t('bigtext.succontact')
                                        : t('bigtext.failcontact')
                                    }
                                </p>
                                <Button
                                    variant="outline-success"
                                    size="lg"
                                    onClick={resetForm}
                                    className='border-3'
                                >
                                    {success === 'success'
                                        ? t('text.sendAnotherMes')
                                        : t('text.retryMes')
                                    }
                                </Button>
                            </Alert>

                            :

                            <>
                                <p className="text-center text-primary mt-3 mb-5">{t('bigtext.supportEmail2')}</p>

                                <Form noValidate validated={validated} onSubmit={handleSubmit} id="contact_form">
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>{t('title.fname')} &amp; {t('title.lname')} *</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                onChange={handleChange}
                                                autoComplete="name"
                                                maxLength="30"
                                                name="name"
                                            />
                                            <Form.Control.Feedback type="invalid">{t('text.emptyField')}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Email *</Form.Label>
                                            <Form.Control
                                                required
                                                type="email"
                                                onChange={handleChange}
                                                name="email"
                                                autoComplete="email"
                                            />
                                            <Form.Control.Feedback type="invalid">{t('text.emptyField')}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>{t('words.subject')} *</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                onChange={handleChange}
                                                autoComplete="off"
                                                maxLength="30"
                                                name="subject"
                                            />
                                            <Form.Control.Feedback type="invalid">{t('text.emptyField')}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>{t('title.phone')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                onChange={handleChange}
                                                autoComplete="phone"
                                                maxLength="20"
                                                name="phone"
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} md="12">
                                            <Form.Label>{t('words.msg')} *</Form.Label>
                                            <Form.Control
                                                required
                                                name="body"
                                                as="textarea"
                                                rows={3}
                                                onChange={handleChange}
                                            />
                                            <Form.Control.Feedback type="invalid">{t('text.emptyField')}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>

                                    <Row>
                                        <Col className='mt-3'>
                                            <ReCAPTCHA
                                                theme="dark"
                                                sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                                                ref={recaptchaRef}
                                                onChange={verify}
                                                className="captcha-style"
                                            />
                                        </Col>

                                        <Col>
                                            <Button
                                                variant="outline-primary"
                                                className={`rounded-4 border-2 float-end mt-4 ${!capVerified && 'd-none'}`}
                                                size="lg"
                                                type="submit"
                                                disabled={loading ? true : false}
                                            >
                                                {loading ?
                                                    <>
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                        {t('title.loading')}
                                                    </>

                                                    :
                                                    <>
                                                        <BiMailSend size={25} className="me-2" /> {t('title.sendmes')}
                                                    </>
                                                }
                                            </Button>
                                        </Col>

                                    </Row>

                                </Form>

                            </>
                        }
                    </Col>

                </Row>

            </Card>
        </Container>

    )
}

export default ContactForm