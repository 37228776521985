import React, { useState } from 'react'
// bootstrap
import { Col, Form, Row, Toast, Spinner, OverlayTrigger, Tooltip, Container, Button, Collapse } from 'react-bootstrap';
import EditAddress from './EditAddress';
import editUser from '../../../jwtAPI/EditUser';
import editUserAddress from '../../../jwtAPI/EditUserAddress';
import uploadUserCv from '../../../jwtAPI/UploadUserCV';
import { FiEdit, FiSave } from "react-icons/fi"
import { FaWindowClose } from "react-icons/fa"
import { BsPlusLg, BsFillQuestionCircleFill } from "react-icons/bs"
// for professions
import RegisterFunctions from '../../../components/loginRegister/RegisterFunctions';
import RegisterDropJobConstruction from '../../../components/loginRegister/RegisterDropJobConstruction';
import DropdownProfession from '../../../components/loginRegister/DropdownProfession';
import RegisterDropJob from '../../../components/loginRegister/RegisterDropJob';
import editUserTypes from '../../../jwtAPI/EditUserTypes';
import ProfileUpdator from "./ProfileUpdator"
import { useTranslation } from 'react-i18next';



function EditMyProfile({ user }) {
    // code in comments for sending the whole form in once

    // const [objToSend, setObjToSend] = useState({
    //     // first_name: user.first_name,
    //     // enterprise: user.enterprise,
    //     // title: user.title,
    //     // email: user.email,
    //     // professional_phone: user.professional_phone,
    //     // mobile: user.mobile,
    //     // description: user.description,
    //     // website: user.website,
    //     // video_link: user.video_link,
    // })

    // async function handleChange(e) {
    //     setObjToSend(
    //         {
    //             ...objToSend,
    //             [e.target.attributes.id.value]: e.target.value.trim()
    //         }
    //     )
    // }

    const { t, i18n } = useTranslation();

    //GET Professions

    const [proffesions, setData] = React.useState(null);
    const [disProf, setDisProf] = React.useState(true);
    const [default1, setDefault1] = useState(null)
    const [default2, setDefault2] = useState(null)
    const [default3, setDefault3] = useState(null)
    const [dataLanded, setDataLanded] = useState(false)
    const url = `${process.env.REACT_APP_DOMAIN}/${i18n.language}/${process.env.REACT_APP_PROFDROP_API}`;

    React.useEffect(() => {
        fetch(url)
            .then(res => res.json())
            .then(data => {
                const mydata = JSON.stringify(data);
                setData(mydata);
                setUsersType()
            })
            .catch(err => {
                console.error(err)
                setDataLanded(true)
            })

        // eslint-disable-next-line
    }, [url]);

    function setUsersType() {
        const typeOfPro = user.user_types[0];
        if (typeOfPro) {
            const arr = Object.keys(typeOfPro)

            setFieldJob(typeOfPro.pro.id.toString())
            setDefault1(typeOfPro.pro.id)

            if (arr.length > 2) {
                setConstrJob(typeOfPro[arr[2]].id.toString())
                setDefault2(typeOfPro[arr[2]].id.toString())

            }
            if (arr.length > 3) {
                setDefault3(typeOfPro[arr[3]].id.toString())

            }

        }
        setDataLanded(true)
    }

    let profe = JSON.parse(proffesions);
    //Jobs Dropdowns
    let [fieldJob, setFieldJob] = React.useState('');
    function getProfession(e) {
        setFieldJob(e.currentTarget.value);
        setConstrJob('');
        handleChangeProf(e);

    };


    //third dropdown for construction
    let [constrJob, setConstrJob] = React.useState('');
    function getConstruction(e) {
        setConstrJob(e.currentTarget.value);
        handleChangeJobs(e);
    };
    //Prepare json
    const regfunc = new RegisterFunctions();
    let initialFormData = regfunc.initialFormData();


    const [formData, updateFormData] = React.useState(initialFormData);

    //Fields Value
    // const handleChange = (e) => {
    //     regfunc.handleChangeVal(e, updateFormData, formData);
    // };


    //Fields Value only for Proffession
    const handleChangeProf = (e) => {
        regfunc.handleChangeProfVal(e, updateFormData, formData);
    };

    //Fields Value only for Jobs
    const handleChangeJobs = (e) => {
        regfunc.handleChangeJobsVal(e, updateFormData, formData);
    };

    //Fields Value only for Construction Jobs
    const handleChangeJobsConstr = (e) => {
        regfunc.handleChangeJobsConstrVal(e, updateFormData, formData);
    };
    function handleSubmitProf(e) {
        setLoading("pro")
        e.preventDefault()
        let objToSend = { user_types: formData.user_types }
        try {
            editUserTypes(setStatus, objToSend, setLoading, setShow, setDisProf, disProf)
        } catch (error) {
            console.log(error)
        }
    }


    // end of professions

    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState("")
    const [show, setShow] = useState(false)
    const [currentId, setCurrentId] = useState("")
    const [lockAddress, setLockAddress] = useState(true)
    const [lockLocationAddress, setLockLocationAddress] = useState(true)
    const [showLocationServices, setShowLocationServices] = useState(false)



    function editForm(id) {

        const input = document.getElementById(id)
        if (id === "editAddress") {
            if (currentId !== "editAddress" && currentId !== "") document.getElementById(currentId).disabled = true
            setLockAddress(!lockAddress)
            setCurrentId(id)
            return null
        }
        if (id === "local_services") {
            if (currentId !== "editAddress" && currentId !== "") document.getElementById(currentId).disabled = true
            setLockLocationAddress(!lockLocationAddress)
            setCurrentId(id)
            return null
        }
        if (id !== currentId && currentId !== "" && currentId !== "editAddress") {
            document.getElementById(currentId).disabled = true;
        }
        setLockAddress(true)
        input.disabled = false;
        input.focus()
        setCurrentId(id)
    }
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t('bigtext.editProf1')}
        </Tooltip>
    )
    async function uploadCv(e) {
        setLoading(e.target[0].id)
        e.preventDefault()
        const file = e.target[0].files

        if (file && file.length > 0) {
            const fileName = (file[0].name)
            let reader = new FileReader()

            reader.onloadend = async () => {
                let dataUrl = reader.result

                const payload = {
                    file_name: fileName,
                    file_data_uri: dataUrl
                };

                await uploadUserCv(setStatus, payload, setLoading, setShow)
            }

            reader.readAsDataURL(file[0])

        } else {
            setLoading("")
            setStatus(t('profile.edit1'))
            setShow(true)
            return null
        }

    }

    async function handleSave(e) {
        try {
            e.preventDefault()

            if (!document.getElementById(e.target[0].id)) return null
            if (document.getElementById(e.target[0].id).disabled) {
                setStatus(t('profile.edit2'))
                setShow(true)
                return null
            }

            if (user[e.target[0].id] === e.target[0].value) {
                setStatus(t('profile.edit3'))
                setShow(true)
                document.getElementById(e.target[0].id).disabled = true
                return null
            }

            setLoading(e.target[0].id)
            const payload = {
                [e.target[0].id]: e.target[0].value
            }

            setStatus("")
            await editUser(setStatus, payload, setLoading, setShow)

        } catch (error) {
            console.log(error)
        }

    }

    async function handleLocDelete(id) {
        setLoading(`delete${id}`)
        setStatus("")
        await editUser(setStatus, false, setLoading, setShow, false, id)

    }
    function handleAddressSave(e) {
        e.preventDefault()
        if (lockAddress) {
            setStatus(t('profile.edit4'))
            setShow(true)
            return null
        }
        setLoading("editAddress")
        let addressObj = {
            google_map: e.target[0].value,
            street: e.target[2].value,
            complement: e.target[3].value,
            country: e.target[5].value,
            city: e.target[6].value,
            state: e.target[7].value,
            postal_code: e.target[8].value
        }

        editUserAddress(setStatus, addressObj, setLoading, setShow, setLockAddress)
    }


    async function handleLocationServices(e) {
        e.preventDefault()
        if (lockLocationAddress) {
            setLoading("")
            setStatus(t('profile.edit5'))
            setShow(true)
            return null
        }
        setLoading("local_services")
        let addressObj = {
            google_map: e.target[0].value,
            street: e.target[2].value,
            complement: e.target[3].value,
            country: e.target[5].value,
            city: e.target[6].value,
            state: e.target[7].value,
            postal_code: e.target[8].value
        }

        let payload = {
            location_services: [

                addressObj
            ]

        }
        let location_service = true

        await editUser(setStatus, payload, setLoading, setShow, location_service, false, e)
        setLockLocationAddress(true)
    }

    return (
        <>


            <div id="badge-ribbon">
                <ProfileUpdator time={1} delay={0} />%
            </div>

            <Container className='bg-white shadow-sm rounded border '>

                <Toast
                    delay={3000} autohide
                    bg={status === 'success' ? 'success' : 'danger'}
                    onClose={() => setShow(false)}
                    show={show}
                >
                    <Toast.Body className='text-white'>{status === 'success' ? t('profile.prof1') : t('bigtext.errorWrong')}</Toast.Body>
                </Toast>

                <form onSubmit={handleSave}>
                    <Row className=' align-items-center mt-4'>

                        <Col xl={2} lg={2} md={2} >
                            <Form.Label htmlFor="first_name" className="labelForEditForm fs-5">
                                {t('title.fname')}
                            </Form.Label>
                        </Col>

                        <Col xl={5} lg={6} md={7}>
                            <Form.Control
                                type="text"
                                id="first_name"
                                className='formAddCss'
                                autoComplete='off'
                                disabled={true}
                                defaultValue={user.first_name}
                            />
                        </Col>

                        <Col xl={5} lg={4} md={3}>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>{t('profile.edit6')}</Tooltip>}
                            >
                                <button className='editFormBtn' type="button" onClick={() => editForm("first_name")}>
                                    <FiEdit style={{ fontSize: "1.2rem" }} />
                                </button>
                            </OverlayTrigger>

                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>{t('profile.edit7')}</Tooltip>}
                            >
                                <button className='editFormBtn' type="submit">
                                    {loading === "first_name" ? <Spinner size="sm" animation="border" role="status" /> : <FiSave style={{ fontSize: "1.2rem" }} />}
                                </button>
                            </OverlayTrigger>
                        </Col>

                    </Row>
                </form>

                <form onSubmit={handleSave}>
                    <Row className='mt-3 align-items-center'>

                        <Col xl={2} lg={2} md={2} >
                            <Form.Label htmlFor="last_name" className="labelForEditForm fs-5">
                                {t('title.lname')}
                            </Form.Label>
                        </Col>

                        <Col xl={5} lg={6} md={7}>
                            <Form.Control
                                type="text"
                                id="last_name"
                                className='formAddCss'
                                autoComplete='off'
                                defaultValue={user.last_name}
                                disabled={true}
                            />
                        </Col>

                        <Col xl={5} lg={4} md={3}>
                            <button className='editFormBtn' type="button" onClick={() => editForm("last_name")}>
                                <FiEdit style={{ fontSize: "1.2rem" }} />
                            </button>

                            <button className='editFormBtn' type="submit">
                                {loading === "last_name" ? <Spinner size="sm" animation="border" role="status" /> : <FiSave style={{ fontSize: "1.2rem" }} />}
                            </button>
                        </Col>

                    </Row>
                </form>

                <form onSubmit={handleSave}>
                    <Row className='mt-3 align-items-center'>

                        <Col xl={2} lg={2} md={2} >
                            <Form.Label htmlFor="email" className="labelForEditForm fs-5">
                                Email
                            </Form.Label>
                        </Col>

                        <Col xl={5} lg={6} md={7}>
                            <Form.Control
                                type="email"
                                id="email"
                                className='formAddCss'
                                placeholder='email@domain.com'
                                autoComplete='off'
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                disabled={true}
                                defaultValue={user.email}
                            />
                        </Col>

                        <Col xl={5} lg={4} md={3}>
                            <button className='editFormBtn' type="button" onClick={() => editForm("email")}>
                                <FiEdit style={{ fontSize: "1.2rem" }} />
                            </button>

                            <button className='editFormBtn' type="submit">
                                {loading === "email" ? <Spinner size="sm" animation="border" role="status" /> : <FiSave style={{ fontSize: "1.2rem" }} />}
                            </button>
                        </Col>

                    </Row>
                </form>

                <hr />
                <form onSubmit={handleSubmitProf}>
                    <Row className='mt-3 mb-3 align-items-center'>

                        <Col xl={2} lg={2} md={2} >
                            <Form.Label htmlFor="pro" className="labelForEditForm fs-5">{t('title.protyp')}</Form.Label>
                        </Col>

                        <Col xl={5} lg={6} md={7}>
                            {dataLanded && profe
                                ?
                                <Form.Group className="col-lg-12 mb-2">

                                    <DropdownProfession getProf={getProfession} profeList={profe} defaultValue1={default1} disabled={disProf} />
                                </Form.Group>
                                : ''
                            }

                            {dataLanded && (<>
                                {fieldJob === '120' || fieldJob === '222' || fieldJob === '' ? ''
                                    : <RegisterDropJob handleChange={getConstruction} job={fieldJob} profeList={profe} defaultValue2={default2} disabled={disProf} />
                                }

                                {constrJob === '149' || constrJob === '150' || constrJob === '151'
                                    ? <RegisterDropJobConstruction handleChange={handleChangeJobsConstr} job={constrJob} profeList={profe} defaultValue3={default3} disabled={disProf} />
                                    : ''
                                }
                            </>)}
                        </Col>

                        <Col xl={5} lg={4} md={3}>
                            <button className='editFormBtn' type="button" onClick={() => setDisProf(!disProf)}>
                                <FiEdit style={{ fontSize: "1.2rem" }} />
                            </button>
                            <button className='editFormBtn' type="submit">
                                {loading === "pro" ? <Spinner size="sm" animation="border" role="status" /> : <FiSave style={{ fontSize: "1.2rem" }} />}
                            </button>
                        </Col>

                    </Row>
                </form>

                <form onSubmit={handleSave}>
                    <Row className='mt-3 align-items-center'>

                        <Col xl={2} lg={2} md={2} >
                            <Form.Label htmlFor="title" className="labelForEditForm fs-5">
                                {t('title.jTitle')}
                            </Form.Label>
                        </Col>

                        <Col xl={5} lg={6} md={7}>
                            <Form.Control
                                type="text"
                                id="title"
                                className='formAddCss'
                                autoComplete='off'
                                defaultValue={user.title}
                                disabled={true}
                            />
                        </Col>

                        <Col xl={5} lg={4} md={3}>
                            <button className='editFormBtn' type="button" onClick={() => editForm("title")}>
                                <FiEdit style={{ fontSize: "1.2rem" }} />
                            </button>

                            <button className='editFormBtn' type="submit">
                                {loading === "title" ? <Spinner size="sm" animation="border" role="status" /> : <FiSave style={{ fontSize: "1.2rem" }} />}
                            </button>
                        </Col>

                    </Row>
                </form>

                <form onSubmit={handleSave}>
                    <Row className='mt-3 align-items-center'>
                        <Col xl={2} lg={2} md={2} >
                            <Form.Label htmlFor="enterprise" className="labelForEditForm fs-5">{t('title.compa')}</Form.Label>
                        </Col>

                        <Col xl={5} lg={6} md={7}>
                            <Form.Control
                                type="text"
                                id="enterprise"
                                className='formAddCss'
                                autoComplete='off'
                                defaultValue={user.enterprise}
                                disabled={true}
                            />
                        </Col>

                        <Col xl={5} lg={4} md={3}>
                            <button className='editFormBtn' type="button" onClick={() => editForm("enterprise")}>
                                <FiEdit style={{ fontSize: "1.2rem" }} />
                            </button>
                            <button className='editFormBtn' type="submit">
                                {loading === "enterprise" ? <Spinner size="sm" animation="border" role="status" /> : <FiSave style={{ fontSize: "1.2rem" }} />}
                            </button>
                        </Col>

                    </Row>
                </form>

                <form onSubmit={handleSave}>
                    <Row className='mt-3 align-items-center'>

                        <Col xl={2} lg={2} md={2} >
                            <Form.Label htmlFor="mobile" className="labelForEditForm fs-5">{t('title.mobile')}</Form.Label>
                        </Col>

                        <Col xl={5} lg={6} md={7}>
                            <Form.Control
                                type="tel"
                                id="mobile"
                                className='formAddCss'
                                autoComplete='off'
                                defaultValue={user.mobile}
                                disabled={true}
                            />
                        </Col>

                        <Col xl={5} lg={4} md={3}>
                            <button className='editFormBtn' type="button" onClick={() => editForm("mobile")}>
                                <FiEdit style={{ fontSize: "1.2rem" }} />
                            </button>
                            <button className='editFormBtn' type="submit">
                                {loading === "mobile" ? <Spinner size="sm" animation="border" role="status" /> : <FiSave style={{ fontSize: "1.2rem" }} />}
                            </button>
                        </Col>

                    </Row>
                </form>

                <form onSubmit={handleSave}>
                    <Row className='mt-3 align-items-center'>

                        <Col xl={2} lg={2} md={2} >
                            <Form.Label htmlFor="professional_phone" className="labelForEditForm fs-5">{t('title.phone')} {t('title.professional')}</Form.Label>
                        </Col>

                        <Col xl={5} lg={6} md={7}>
                            <Form.Control
                                type="tel"
                                id="professional_phone"
                                className='formAddCss'
                                minLength={10}
                                autoComplete='off'
                                defaultValue={user.professional_phone}
                                disabled={true}
                            />
                        </Col>

                        <Col xl={5} lg={4} md={3}>
                            <button className='editFormBtn' type="button" onClick={() => editForm("professional_phone")}>
                                <FiEdit style={{ fontSize: "1.2rem" }} />
                            </button>

                            <button className='editFormBtn' type="submit">
                                {loading === "professional_phone" ? <Spinner size="sm" animation="border" role="status" /> : <FiSave style={{ fontSize: "1.2rem" }} />}
                            </button>
                        </Col>

                    </Row>
                </form>
                <hr />

                <form onSubmit={handleSave}>
                    <Row className='mt-3 align-items-center'>

                        <Col xl={2} lg={2} md={2} >
                            <Form.Label htmlFor="video_link" className="labelForEditForm fs-5">{t('title.vLink')} </Form.Label>
                        </Col>

                        <Col xl={5} lg={6} md={7}>
                            <Form.Control
                                type="url"
                                id="video_link"
                                className='formAddCss'
                                autoComplete='off'
                                pattern="https://.*"
                                defaultValue={user.video_link}
                                disabled={true}
                            />
                        </Col>

                        <Col xl={5} lg={4} md={3}>
                            <button className='editFormBtn' type="button" onClick={() => editForm("video_link")}>
                                <FiEdit style={{ fontSize: "1.2rem" }} />
                            </button>
                            <button className='editFormBtn' type="submit">
                                {loading === "video_link" ? <Spinner size="sm" animation="border" role="status" /> : <FiSave style={{ fontSize: "1.2rem" }} />}
                            </button>
                        </Col>

                    </Row>
                </form>

                <form onSubmit={handleSave}>
                    <Row className='mt-3 align-items-center'>

                        <Col xl={2} lg={2} md={2} >
                            <Form.Label htmlFor="website" className="labelForEditForm fs-5">{t('words.website')}</Form.Label>
                        </Col>

                        <Col xl={5} lg={6} md={7}>
                            <Form.Control
                                type="url"
                                id="website"
                                className='formAddCss'
                                autoComplete='off'
                                pattern="https://.*"
                                defaultValue={user.website}
                                disabled={true}
                            />
                        </Col>

                        <Col xl={5} lg={4} md={3}>
                            <button className='editFormBtn' type="button" onClick={() => editForm("website")}>
                                <FiEdit style={{ fontSize: "1.2rem" }} />
                            </button>
                            <button className='editFormBtn' type="submit">
                                {loading === "website" ? <Spinner size="sm" animation="border" role="status" /> : <FiSave style={{ fontSize: "1.2rem" }} />}
                            </button>
                        </Col>

                    </Row>
                </form>

                <form onSubmit={handleSave}>
                    <Row className='mt-3 align-items-center'>

                        <Col xl={2} lg={2} md={2} >
                            <Form.Label htmlFor="linkedin_url" className="labelForEditForm fs-5">LinkedIn</Form.Label>
                        </Col>

                        <Col xl={5} lg={6} md={7}>
                            <Form.Control
                                type='url'
                                id="linkedin_url"
                                className='formAddCss'
                                placeholder='https://www.linkedin.com/in/betweenPros/'
                                autoComplete='off'
                                // onKeyUp={handleChange}
                                disabled={true}
                                defaultValue={user.linkedin_url}
                                pattern="https://www.linkedin.com/in/.*"
                            />
                        </Col>

                        <Col xl={5} lg={4} md={3}>
                            <button className='editFormBtn' type="button" onClick={() => editForm("linkedin_url")}>
                                <FiEdit style={{ fontSize: "1.2rem" }} />
                            </button>
                            <button className='editFormBtn' type="submit">
                                {loading === "linkedin_url" ? <Spinner size="sm" animation="border" role="status" /> : <FiSave style={{ fontSize: "1.2rem" }} />}
                            </button>
                        </Col>

                    </Row>
                </form>

                <form onSubmit={handleSave}>
                    <Row className='mt-3 align-items-center'>
                        <Col xl={2} lg={2} md={2} >
                            <Form.Label htmlFor="twitter_url" className="labelForEditForm fs-5">Twitter</Form.Label>
                        </Col>

                        <Col xl={5} lg={6} md={7}>
                            <Form.Control
                                type='url'
                                id="twitter_url"
                                className='formAddCss'
                                placeholder='https://twitter.com/BetweenPros'
                                autoComplete='off'
                                pattern="https://twitter.com/.*"
                                // onKeyUp={handleChange}
                                disabled={true}
                                defaultValue={user.twitter_url}
                            />
                        </Col>

                        <Col xl={5} lg={4} md={3}>
                            <button className='editFormBtn' type="button" onClick={() => editForm("twitter_url")}>
                                <FiEdit style={{ fontSize: "1.2rem" }} />
                            </button>
                            <button className='editFormBtn' type="submit">
                                {loading === "twitter_url" ? <Spinner size="sm" animation="border" role="status" /> : <FiSave style={{ fontSize: "1.2rem" }} />}
                            </button>
                        </Col>

                    </Row>
                </form>

                <form onSubmit={handleSave}>
                    <Row className='mt-3 align-items-center'>
                        <Col xl={2} lg={2} md={2} >
                            <Form.Label htmlFor="facebook_url" className="labelForEditForm fs-5">Facebook</Form.Label>
                        </Col>

                        <Col xl={5} lg={6} md={7}>
                            <Form.Control
                                type='url'
                                id="facebook_url"
                                className='formAddCss'
                                placeholder='https://www.facebook.com/BetweenPros'
                                autoComplete='off'
                                // onKeyUp={handleChange}
                                disabled={true}
                                defaultValue={user.facebook_url}
                                pattern="https://www.facebook.com/.*"
                            />
                        </Col>

                        <Col xl={5} lg={4} md={3}>
                            <button className='editFormBtn' type="button" onClick={() => editForm("facebook_url")}>
                                <FiEdit style={{ fontSize: "1.2rem" }} />
                            </button>

                            <button className='editFormBtn' type="submit">
                                {loading === "facebook_url" ? <Spinner size="sm" animation="border" role="status" /> : <FiSave style={{ fontSize: "1.2rem" }} />}
                            </button>
                        </Col>

                    </Row>
                </form>

                <hr />
                <form onSubmit={handleSave}>
                    <Row className='mt-3 align-items-center'>

                        <Col xl={2} lg={2} md={2} >
                            <Form.Label htmlFor="description" className="labelForEditForm fs-5">{t('words.desc')}</Form.Label>
                        </Col>

                        <Col xl={5} lg={6} md={7}>
                            <Form.Control
                                as="textarea"
                                id="description"
                                className='formAddCss'
                                placeholder={t('profile.edit8')}
                                autoComplete='off'
                                disabled={true}
                                defaultValue={user.description}
                                rows={4}
                            />
                        </Col>

                        <Col xl={5} lg={4} md={3}>
                            <button className='editFormBtn' type="button" onClick={() => editForm("description")}>
                                <FiEdit style={{ fontSize: "1.2rem" }} />
                            </button>
                            <button className='editFormBtn' type="submit">
                                {loading === "description" ? <Spinner size="sm" animation="border" role="status" /> : <FiSave style={{ fontSize: "1.2rem" }} />}
                            </button>
                        </Col>

                    </Row>
                </form>

                <hr />
                <form onSubmit={handleAddressSave}>
                    <Row className='mt-3 align-items-center'>

                        <Col xl={7} lg={8} md={9}>
                            <EditAddress user={user} disable={lockAddress} />
                        </Col>

                        <Col xl={5} lg={4} md={3}>
                            <button className='editFormBtn' type="button" onClick={() => editForm("editAddress")}>
                                <FiEdit style={{ fontSize: "1.2rem" }} />
                            </button>
                            <button className='editFormBtn my-auto' type="submit">
                                {loading === "editAddress" ? <Spinner size="sm" animation="border" role="status" /> : <FiSave style={{ fontSize: "1.2rem" }} />}
                            </button>
                        </Col>

                    </Row>
                </form>

                <hr />
                <Row className='mt-3 mb-3 align-items-center'>

                    <Col xl={7} lg={8} md={9}>
                        <p className="fs-5 m-0 ">
                            {t('profile.edit9')}
                        </p>
                    </Col>

                    <Col xl={5} lg={4} md={3}>
                        <OverlayTrigger
                            placement="left"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                        >
                            <Button className='border-0 bg-white noFocus'>
                                <BsFillQuestionCircleFill style={{ fontSize: "1.2rem" }} />
                            </Button>
                        </OverlayTrigger>

                        <button className='editFormBtn my-auto' onClick={() => setShowLocationServices(!showLocationServices)}>
                            <BsPlusLg style={{ fontSize: "1.2rem", color: "green" }} />
                        </button>
                    </Col>

                    <div className="tags-pill mt-2">
                        <div className='d-flex flex-wrap'>
                            {user.location_services?.length > 0 && <>
                                {user.location_services.map((add, index) => (
                                    <div key={index + 4} className='d-flex align-items-center border rounded p-1 mt-1 me-1'> <a key={index} className="my-1 mx-1" href="#0" rel="tag">{add.city}</a>

                                        <Button key={index + 1} variant='light' className='noFocus border-0  p-0 editFormBtn' onClick={() => handleLocDelete(add.id)}>
                                            {loading === `delete${add.id}` ? <Spinner style={{ color: "#852121" }} key={index + 2} size="sm" animation="border" role="status" /> : <FaWindowClose key={index + 3} style={{ fontSize: "1.2rem", color: "#852121" }} />}
                                        </Button>
                                    </div>


                                ))}
                            </>}
                        </div>

                    </div>

                    <Collapse id="loc_serv" in={showLocationServices} dimension="height">
                        <div className=' '>
                            <form id="local_services" onSubmit={handleLocationServices} >
                                <Row className='mt-3 align-items-center'>

                                    <Col xl={7} lg={8} md={9}>
                                        <EditAddress user={user} location_service={true} disable={lockLocationAddress} index={0} />
                                    </Col>
                                    <Col xl={5} lg={4} md={3}>
                                        <button className='editFormBtn' type="button" onClick={() => editForm("local_services")}>
                                            <FiEdit style={{ fontSize: "1.2rem" }} />
                                        </button>
                                        <button className='editFormBtn my-auto' type="submit">
                                            {loading === "local_services" ? <Spinner size="sm" animation="border" role="status" /> : <FiSave style={{ fontSize: "1.2rem" }} />}
                                        </button>
                                    </Col>
                                </Row>
                            </form>

                        </div>


                    </Collapse>


                </Row>

                <hr />


                <form onSubmit={uploadCv}>
                    <Row className='mt-3 mb-3 align-items-center'>

                        <Col xl={2} lg={2} md={2} >
                            <Form.Label htmlFor="cvUpload" className="labelForEditForm fs-5">{t('profile.edit10')}</Form.Label>
                        </Col>

                        <Col xl={5} lg={6} md={7}>
                            <Form.Control
                                type="file"
                                id="cvUpload"
                                className='formAddCss'
                                accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            />
                        </Col>

                        <Col xl={5} lg={4} md={3}>
                            <button className='editFormBtn' type="submit">
                                {loading === "cvUpload" ? <Spinner size="sm" animation="border" role="status" /> : <FiSave style={{ fontSize: "1.2rem" }} />}
                            </button>
                        </Col>

                    </Row>
                </form>

            </Container>

        </>
    )
}
export default EditMyProfile