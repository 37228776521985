import React, { useEffect } from "react";
//Routes
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./AppRoutes";
// import { setReduxLoggedIn } from "./Redux/user-slice";
import { toggleTheme } from "./Redux/page-slice";
import { useSelector, useDispatch } from "react-redux/";
// import TokenKeepLogged from './jwtAPI/TokenKeepLogged'
import getUser from "./jwtAPI/SetUser";
import Loader from "./components/Loader";
import MainMenu from "./components/mainMenu/MainMenu";
import MainMenuVisitor from "./components/mainMenu/MainMenuVisitor";


function App() {
  const user = useSelector(state => state.user)
  const page = useSelector(state => state.page)
  const dispatch = useDispatch()

  useEffect(() => {
    //Dark Mode
    if (localStorage.getItem('themeCol') === "dark") dispatch(toggleTheme())
    getUser()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (page.darkTheme) {
      document.body.setAttribute('data-theme', "dark")
      localStorage.setItem("themeCol", "dark")
    } else {
      document.body.setAttribute('data-theme', "light")
      localStorage.setItem("themeCol", "light")
    }
    // eslint-disable-next-line
  }, [page.darkTheme])

  return (
    page.loader ? <Loader /> : (
      <Router>
        {user.loggedIn ? <MainMenu /> : <MainMenuVisitor />}
        <AppRoutes />
      </Router>
    )

  );
}
export default App;