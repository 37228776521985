import React from 'react';
import { useParams } from 'react-router-dom';

import Footer from '../components/Footer';
import SaleRentCreate from '../components/createEdit/createByListing/SaleRentCreate';
import ReqParCE from '../components/createEdit/createByListing/ReqParCE';
import ArticleCE from '../components/createEdit/createByListing/ArticleCE';
import ComparisonCE from '../components/createEdit/createByListing/ComparisonCE';
import AgendaCE from '../components/createEdit/createByListing/AgendaCE';
import CrmCE from '../components/createEdit/createByListing/CrmCE';
import JobCE from '../components/createEdit/createByListing/JobCE';

import LoadingData from '../components/createEdit/LoadingData';
import NeedLoginPage from './NeedLoginPage';


const CreateNew = () => {


    const { type } = useParams();
    const [logged] = React.useState(localStorage.getItem('logged'));

    return (
        <>
            {logged ?
                <>
                    {type === 'sale' || type === 'rent' ? <SaleRentCreate type={type} />
                        : type === 'request' || type === 'partnership' ? <ReqParCE type={type} />
                            : type === 'article' ? <ArticleCE type={type} />
                                : type === 'benchmark' ? <ComparisonCE type={type} />
                                    : type === 'event' ? <AgendaCE type={type} />
                                        : type === 'crm' ? <CrmCE type={type} />
                                            : type === 'job' ? <JobCE type={type} />
                                                : ''
                    }
                    <Footer />
                </>

                : !logged
                    ? <NeedLoginPage />

                    : <LoadingData />
            }
        </>
    )
}

export default CreateNew
