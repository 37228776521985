import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Badge, Card, Col, Image, Row } from 'react-bootstrap';
import { PersonCircle } from 'react-bootstrap-icons';
import AgentPage from '../../../pages/AgentPage';
import ArticlePage from '../../../pages/ArticlePage';
import houseExample from "../../../assets/houseExample.jpg"
import OverlayListing from "../../profile/listings/OverlayListing";
import ToolCardActions from '../ToolCardActions';



const ArticleCard = ({ listing, profile = false, status = null }) => {

    const { t } = useTranslation();

    let descrip = listing['content'] ? listing['content'] : listing['excerpt'];
    if (descrip && descrip.length > 300) {
        descrip = listing['content'].substring(0, 300) + " [...]"
    }


    return (
        <div className="position-relative card-listing-container">
            <Card className='tool-listing p-3 pt-0'>

                <ToolCardActions
                    listing={listing}
                    listingType="article"
                />

                <Badge bg='info' className='small text-end mb-2'>{t('cats_tools.article')}</Badge>
                <h6><strong>
                    {listing['title']}
                    {listing.number
                        && <span className='small fw-bold text-muted'>&nbsp;({listing.number})</span>
                    }
                </strong></h6>

                <Row className='small align-items-center justify-content-between'>
                    <Col>

                        <Link
                            component={<AgentPage />}
                            to={`/agent/${listing['author']['id']}/`}
                            className="text-decoration-none text-dark text-capitalize"
                        >

                            {listing['author']['profile_picture_url']
                                ? <Image roundedCircle
                                    style={{ objectFit: 'cover' }}
                                    width="35px"
                                    height="35px"
                                    src={`${process.env.REACT_APP_DOMAIN + listing['author']['profile_picture_url']}?photo_height=50&photo_width=50`}
                                    className="me-2" />

                                : <PersonCircle className='text-warning me-2' size={33} />
                            }

                            {listing['author']['blog_display_name'].toLowerCase()}
                        </Link>
                    </Col>

                    <Link
                        component={<ArticlePage />}
                        to={`/listing/article/${listing["id"]}/${listing["slug"]}`}
                        className="text-decoration-none text-dark"
                    >

                        <hr className='mt-3' />
                        <Row>
                            <Col xs={5}>

                                <Image
                                    alt="excerpt-photo"
                                    height="117"
                                    width="100%"
                                    style={{ objectFit: "cover" }}
                                    src={
                                        listing.excerpt_photo_url
                                            ? `${process.env.REACT_APP_DOMAIN}${listing['excerpt_photo_url']}?photo_height=400&photo_width=400`
                                            : houseExample
                                    }
                                />

                            </Col>
                            <Col xs={7} style={{ minHeight: '57px' }}>
                                <p dangerouslySetInnerHTML={{ __html: descrip }} />
                            </Col>
                        </Row>

                    </Link>

                </Row>

            </Card>
            {profile
                ?
                <OverlayListing
                    title={listing.title}
                    type={"article"}
                    link={`/listing/article/${listing.id}/${listing.slug}/`}
                    id={listing.id}
                    edit={`/edit/article/${listing.id}`}
                    status={status}
                    articleId={listing.id}
                />
                : null
            }

        </div>
    )
}

export default ArticleCard
