import React from "react";
import ContentLoader from "react-content-loader";
const ListingLoader = (props) => {
  const width = "500";
  const height = "20";

  return (
    <ContentLoader
      speed={1}
      width={500}
      height={300}
      viewBox="0 0 400 150"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      style={{ maxWidth: "90%", maxHeight: `90%` }}
      {...props}
    >
      <rect x="27" y="6" rx="5" ry="5" width={width} height={height} />
      <rect x="25" y="35" rx="5" ry="5" width={width} height={height} />
      <rect x="25" y="66" rx="5" ry="5" width={width} height={height} />
      <rect x="25" y="94" rx="5" ry="5" width={width} height={height} />
      <rect x="27" y="128" rx="0" ry="0" width={width} height={height} />
      <rect x="28" y="242" rx="0" ry="0" width={width} height={height} />
      <rect x="29" y="279" rx="0" ry="0" width={width} height={height} />
    </ContentLoader>
  );
};

export default ListingLoader;
