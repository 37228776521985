import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next';


const RegisterDropJobConstruction = ({ job, handleChange, profeList, defaultValue3 = false, disabled = false }) => {

    const { t } = useTranslation();
    let nameJob = '';

    let x = job;
    switch (x) {
        case '151':
            nameJob = 'parachevement';
            break;
        case '149':
            nameJob = 'entrepreneur_spe';
            break;
        case '150':
            nameJob = 'electro_technique';
            break;
        default:
            nameJob = 0;
    }

    return (
        <>
            <Form.Group className="col-lg-12 mb-2">
                <Form.Select disabled={disabled} id={nameJob} name={nameJob} defaultValue={defaultValue3 ? defaultValue3 : ""} onChange={handleChange} className="form-control">
                    <option value="">{t('text.choose_cat')}</option>
                    {

                        profeList[nameJob].sort((a, b) => a.name > b.name ? 1 : -1).map((jjj, key) => (
                            <option key={key} id={jjj.id} value={jjj.id} >{jjj.name}</option>
                        ))

                    }
                    <option value="222" id="kyb">{t('words.other')}</option>
                </Form.Select>
            </Form.Group>
        </>
    )
}

export default RegisterDropJobConstruction