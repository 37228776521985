import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, ListGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import SimplePage from '../../../pages/SimplePage'

const ForgotPass = () => {

  const { t } = useTranslation();

  const resetSteps = [
    { number: 1, text: t('profile.pass5') },
    { number: 2, text: t('profile.pass6') },
    { number: 3, text: t('profile.pass7') },
    { number: 4, text: t('profile.pass8') },
  ];


  return (
    <>
      <Col xs={12} className="mt-3"><hr /></Col>

      <Col md={12}>


        {resetSteps.map((step, key) => (

          <ListGroup horizontal="xxl" className="my-2" key={key}>
            <ListGroup.Item variant="warning" className='text-nowrap'>{t('words.step')} {step.number}</ListGroup.Item>
            <ListGroup.Item>{step.text}</ListGroup.Item>
          </ListGroup>

        ))}


        <Link
          component={<SimplePage />}
          to={`/page/resetpass`}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-lg btn-warning mt-3"
        >
          {t('profile.pass4')}
        </Link>
      </Col>

    </>
  )
}

export default ForgotPass