import TokenRefresher from "./TokenRefresher";


//Saves and uses json after fetch with the useState of setter listi
export default class FavoriteToggle {

    //we need it to run even if user changes page
    /* constructor(ac) {
        this.ac = ac
    }*/

    async fetchListings(url, id, onError, setOnError) {

        let getListing = async () => {

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;

            fetch(url, {
                method: 'GET',
                /* signal: this.ac, */
                headers: {
                    'Authorization': bearer_token,
                },
                credentials: 'include',
            })
                .then(res => {

                    if (res.status >= 200 && res.status <= 299) {
                        
                    } else setOnError(onError + 1)

                })

                .catch(err => console.log(err))

        }



        //checks and refresh token
        const sessionFetch = new TokenRefresher();
        sessionFetch.setTokenRefresh(getListing);

    }

}

