//Saves and uses json after fetch with the useState of setter listi
export default class BlogPostSingle {
    constructor(ac) {
        this.ac = ac
    }

    //Bearer token
    // setter : array of listings
    // setterSum : the count of listings
    async fetchListings(url, setter) {

        let getListing = async () => {

            fetch(url, {
                method: 'GET',
            })
                .then(res => res.json())
                .then(res => {

                    setter(res);
                })

                .catch(err => console.error(err))

        }


        getListing();

    }

}

