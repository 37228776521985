import React from 'react';
import { Container } from 'react-bootstrap';
import Footer from '../components/Footer';
import { useParams } from 'react-router-dom';
import ToolboxIndex from '../components/toolBox/ToolboxIndex';
import NeedLoginPage from './NeedLoginPage';
import LoadingData from '../components/createEdit/LoadingData';


const ToolPage = () => {

    const [logged] = React.useState(localStorage.getItem('logged'));

    const { value } = useParams();

    return (
        <>

            {logged ?
                <>


                    <Container fluid="xxl" className='p-0'>
                        <ToolboxIndex typeListing={value} />
                    </Container>

                    <Footer />

                </>
                
                : !logged
                    ? <NeedLoginPage />

                    : <LoadingData />
            }
        </>
    )
};

export default ToolPage;


