import React from 'react';
import { Button, Card, Row, Col, Container, Badge } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BlogPostSingle from '../jwtAPI/BlogPostSingle';

import Footer from '../components/Footer';
import SingleListHero from '../components/singleListing/SingleListHero';
import ButtonEditLis from '../components/singleListing/ButtonEditLis';
import OverviewTitleShare from '../components/singleListing/OverviewTitleShare';
import LoadingData from '../components/createEdit/LoadingData';
import DateOnly from '../functions/DateOnly';
import SingleListVideo from '../components/singleListing/SingleListVideo';




const BlogPostPage = () => {

    const { t, i18n } = useTranslation();
    const [logged] = React.useState(localStorage.getItem('logged'));
    const { id } = useParams();
    let url = `${process.env.REACT_APP_DOMAIN}/${i18n.language}/api/public-article/${id}`
    const [listing, setter] = React.useState(null);


    React.useEffect(() => {

        const fetchLists = new BlogPostSingle();
        fetchLists.fetchListings(url, setter);

        // eslint-disable-next-line
    }, [id, url]);


    return (
        <>
            {listing ?

                <>

                    <Container fluid="xxl">
                        <SingleListHero excerptPhoto={listing.excerpt_photo_url} gallery={listing.photos_urls} />
                    </Container>


                    <Container fluid="xl">

                        <Row className="justify-content-center mt-5">

                            <Col xl={9} lg={12}>

                                <ButtonEditLis idAuthor={listing.author.id} type="article" idLis={id} />

                                <Button variant='warning' size="sm" className='w-auto px-2 py-0'>{t('cats_tools.article')}</Button>

                                <h1 className='fw-bold mt-2'>{listing.title}</h1>

                                {listing.number
                                    && <p className='fs-6 fw-bold'>ID: ({listing.number})</p>
                                }

                                {listing.locale
                                    && <p className='fs-6 fw-bold'>{t('words.lang')}: {listing.locale.toUpperCase()}</p>
                                }

                                <p><strong>{t('words.pubDate')}: </strong> <DateOnly dateRaw={listing.published_at} /></p>


                                <Card className='p-4 border-0 shadow-sm my-5'>

                                    <OverviewTitleShare
                                        link={window.location.href}
                                        title={listing.title}
                                        isFav={listing.favorited_by}
                                        lisId={listing.id}
                                        listingType='article'
                                    />

                                    {listing.excerpt && <h5 className='text-muted mt-4'>{listing.excerpt}</h5>}

                                    {listing.content && <p dangerouslySetInnerHTML={{ __html: listing.content }} />}


                                </Card>

                                {listing.video_link &&
                                    <SingleListVideo link={listing.video_link} />
                                }



                            </Col>

                            {logged === "edit" &&
                                <Col xl={9} lg={12}>

                                    <Card className='p-4 border-0 shadow-sm mb-5'>

                                        <div className='d-inline-flex h5'>
                                            <Badge bg="success" className='me-4'>Article Likes: 200</Badge>
                                            <Badge bg="info">Article Shares: 200</Badge>
                                        </div>

                                        <p className='h4 mt-4'>Comments</p>

                                    </Card>


                                </Col>
                            }

                        </Row>

                    </Container>

                    <Footer />

                </>

                : <LoadingData />
            }
        </>
    )
}

export default BlogPostPage
