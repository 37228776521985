import React from "react";
import { useTranslation } from 'react-i18next';
import { Badge, /*Button,*/ Card, Col, Row, Table } from 'react-bootstrap'
import { FaCheckCircle, FaRegTimesCircle } from 'react-icons/fa'


const SelectedBadge = () => {
    return <span style={{ zIndex: "2", top: "-20px" }} className="fs-3 position-absolute end-0"><Badge pill bg="success">&#10003;</Badge></span>
}

const Plans = () => {

    const { t } = useTranslation();

    const [logged] = React.useState(localStorage.getItem('logged'));

    const [plan,/* setPlan*/] = React.useState(logged ? 'paid' : '');

    return (

        <Row className="py-4 px-0 px-md-4">

            <Col lg={6} md={12} className="mb-4 position-relative px-0 px-md-2">

                {(plan === 'free' && logged === 'true') && <SelectedBadge />}

                <Card className={`${(plan === 'free' && logged === 'true') && 'shadow selected-plan'} text-center rounded-4 h-100`}>

                    <Card.Header as="h4" className='fw-bold text-info my-3'>{t('profile.plan9')}</Card.Header>

                    <Card.Body>
                        <Card.Title><span className="h2">€0</span>/{t('words.month')}</Card.Title>

                        <Card.Text>
                            {t('profile.plan10')}
                        </Card.Text>

                        <Table striped bordered hover size="sm">
                            <thead className='bg-warning text-light'>
                                <tr>
                                    <th>{t('profile.plan11')}</th>
                                    <th colSpan={2}></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{t('profile.plan12')}</td>
                                    <td colSpan={2}><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                </tr>
                                {/* <tr>
                                    <td>Use the integrated messaging service</td>
                                    <td colSpan={2}><FaRegTimesCircle className='p-0 text-danger fw-bold lead' /></td>
                                </tr> */}
                                <tr>
                                    <td>{t('profile.plan13')}</td>
                                    <td colSpan={2}><FaRegTimesCircle className='p-0 text-danger fw-bold lead' /></td>
                                </tr>
                            </tbody>

                            <thead className='bg-warning text-light'>
                                <tr>
                                    <th>{t('words.propertyLis')}</th>
                                    <th>{t('words.consult')}</th>
                                    <th>{t('words.publish')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{t('words.for2')} {t('cats_tools.sale')}</td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                    <td><FaRegTimesCircle className='p-0 text-danger fw-bold lead' /></td>
                                </tr>
                                <tr>
                                    <td>{t('words.for2')} {t('cats_tools.rent')}</td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                    <td><FaRegTimesCircle className='p-0 text-danger fw-bold lead' /></td>
                                </tr>
                                <tr>
                                    <td>{t('cats_tools.request')}</td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                    <td><FaRegTimesCircle className='p-0 text-danger fw-bold lead' /></td>
                                </tr>
                                <tr>
                                    <td>{t('cats_tools.partnership')}</td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                    <td><FaRegTimesCircle className='p-0 text-danger fw-bold lead' /></td>
                                </tr>
                            </tbody>

                            <thead className='bg-warning text-light'>
                                <tr>
                                    <th>{t('words.toolbLis')}</th>
                                    <th>{t('words.consult')}</th>
                                    <th>{t('words.publish')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{t('cats_tools.jobSearch')}</td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                </tr>
                                <tr>
                                    <td>{t('cats_tools.jobOffer')}</td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                    <td><FaRegTimesCircle className='p-0 text-danger fw-bold lead' /></td>
                                </tr>
                                <tr>
                                    <td>{t('profile.plan14')}</td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                    <td><FaRegTimesCircle className='p-0 text-danger fw-bold lead' /></td>
                                </tr>
                                <tr>
                                    <td>{t('profile.plan15')}</td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                    <td><FaRegTimesCircle className='p-0 text-danger fw-bold lead' /></td>
                                </tr>
                                <tr>
                                    <td>{t('cats_tools.article')}</td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                    <td><FaRegTimesCircle className='p-0 text-danger fw-bold lead' /></td>
                                </tr>
                                <tr>
                                    <td>{t('cats_tools.event')}</td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                    <td><FaRegTimesCircle className='p-0 text-danger fw-bold lead' /></td>
                                </tr>
                            </tbody>

                        </Table>

                        {/* {(plan === 'paid' && logged === 'true') &&
                            <Button variant="outline-warning" size="lg" onClick={() => setPlan('free')}>{t('profile.plan16')}</Button>
                        } */}

                    </Card.Body>

                </Card>

            </Col>

            <Col lg={6} md={12} className="mb-4 position-relative px-0 px-md-2">

                {(plan === 'paid' && logged === 'true') && <SelectedBadge />}

                <Card className={`${(plan === 'paid' && logged === 'true') && 'shadow selected-plan'} text-center rounded-4 h-100`}>

                    <Card.Header as="h4" className='fw-bold text-success my-3 mb-0'>{t('profile.plan17')}</Card.Header>

                    <Card.Body>

                        <div className='bg-primary text-light rounded-pill position-relative'>
                            {/* <span>Free of charge until 30/06/2022</span> */}
                            <Card.Title>
                                <span className="h2">€59</span>/{t('words.month')}
                                &nbsp;&nbsp;&nbsp;
                                <span className="h2">€599</span>/{t('words.year')}
                            </Card.Title>
                            <div className="delete-line"></div>
                            <div className="free-for-now"></div>
                        </div>

                        <Card.Text className="mt-4">
                            {t('profile.plan18')}
                        </Card.Text>

                        <Table striped bordered hover size="sm">
                            <thead className='bg-warning text-light'>
                                <tr>
                                    <th>{t('profile.plan11')}</th>
                                    <th colSpan={2}></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{t('profile.plan12')}</td>
                                    <td colSpan={2}><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                </tr>
                                {/* <tr>
                                    <td>Use the integrated messaging service</td>
                                    <td colSpan={2}><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                </tr> */}
                                <tr>
                                    <td>{t('profile.plan13')}</td>
                                    <td colSpan={2}><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                </tr>
                            </tbody>

                            <thead className='bg-warning text-light'>
                                <tr>
                                    <th>{t('words.propertyLis')}</th>
                                    <th>{t('words.consult')}</th>
                                    <th>{t('words.publish')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{t('words.for2')} {t('cats_tools.sale')}</td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                </tr>
                                <tr>
                                    <td>{t('words.for2')} {t('cats_tools.rent')}</td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                </tr>
                                <tr>
                                    <td>{t('cats_tools.request')}</td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                </tr>
                                <tr>
                                    <td>{t('cats_tools.partnership')}</td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                </tr>
                            </tbody>

                            <thead className='bg-warning text-light'>
                                <tr>
                                    <th>{t('words.toolbLis')}</th>
                                    <th>{t('words.consult')}</th>
                                    <th>{t('words.publish')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{t('cats_tools.jobSearch')}</td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                </tr>
                                <tr>
                                    <td>{t('cats_tools.jobOffer')}</td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                </tr>
                                <tr>
                                    <td>{t('profile.plan14')}</td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                </tr>
                                <tr>
                                    <td>{t('profile.plan15')}</td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                </tr>
                                <tr>
                                    <td>{t('cats_tools.article')}</td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                </tr>
                                <tr>
                                    <td>{t('cats_tools.event')}</td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                    <td><FaCheckCircle className='p-0 text-success fw-bold lead' /></td>
                                </tr>
                            </tbody>

                        </Table>
                        {/* {
                            (plan === 'free' && logged === 'true')
                                ? < Button variant="outline-warning" size="lg" onClick={() => setPlan('paid')}>{t('profile.plan16')}</Button>

                                : (plan === 'paid' && logged === 'true')
                                    ?
                                    < Button variant="outline-warning" size="lg" onClick={() => setPlan('paid')}>
                                        {t('words.renew')} {t('title.subscription')}
                                    </Button>

                                    : ''
                        } */}
                    </Card.Body>

                </Card>

            </Col>
        </Row >
    )
}

export default Plans