import TokenRefresher from "./TokenRefresher";


//Saves and uses json after fetch with the useState of setter listi
export default class SignInActivityFetch {

    //Bearer token
    // setter : array of listings
    // setterSum : the count of listings
    async fetchListings(url, setter, setterSum, setLoading) {

        let getListing = async () => {

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;

            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': bearer_token,
                },
                credentials: 'include',
            })
                .then(res => res.json())
                .then(res => {

                    setter(res.records);
                    setterSum(res.number_of_records);
                    setLoading(false)

                })

                .catch(err => { console.error(err); setLoading(false); })

        }



        //checks and refresh token
        const sessionFetch = new TokenRefresher();
        sessionFetch.setTokenRefresh(getListing);

    }

}

