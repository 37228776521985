import React from "react";

const FragmentedCircleLoader = ({ color }) => {
  return (
    <div className={"lds-spinner " + color}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default FragmentedCircleLoader;
