import { Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FcInfo } from 'react-icons/fc'

const KeywordsSearch = ({ handleChange, col }) => {

    const { t } = useTranslation();

    return (
        <>
            <Col md={col}>
                <Form.Group>
                    <Form.Label className='small mb-0'> {t('text.searchTitle')} </Form.Label>
                    <Form.Control
                        name="keywords"
                        size="sm"
                        type="text"
                        onChange={handleChange}
                        maxLength="40"
                        id="keywords"
                    />
                </Form.Group>
            </Col>
            <Col md={col}>
                <Form.Group>
                    <OverlayTrigger placement="top" overlay={<Tooltip >{t('text.searchIdInfo')}</Tooltip>}>
                        <Form.Label className='small mb-0'> {t('text.searchId')} <FcInfo /></Form.Label>
                    </OverlayTrigger>
                    <Form.Control
                        name="search"
                        size="sm"
                        type="text"
                        onChange={handleChange}
                        id="search"
                    />
                </Form.Group>
            </Col>
        </>
    )
}

export default KeywordsSearch
