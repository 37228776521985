/*global google*/
import React from 'react'
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
    Autocomplete,
} from "@react-google-maps/api";


const SearchByPlace = ({ setGooglePlaceObj, category }) => {

    const { t } = useTranslation();

    let ObjToSend = {}

    function initialize() {

        const input = document.getElementById(`lisStreetId-${category}`);
        const autocomplete = new google.maps.places.Autocomplete(input);


        google.maps.event.addListener(autocomplete, 'place_changed', function () {

            const place = autocomplete.getPlace();

            if (place.address_components) {
                //Area
                let area = place.address_components.filter(item => item.types[0] === "locality");
                if (area.length === 0) {
                    area = place.address_components.filter(item => item.types[0] === "administrative_area_level_2");
                }
                if (area.length === 0) {
                    area = place.address_components.filter(item => item.types[0] === "administrative_area_level_3");
                }
                if (area.length === 0) {
                    area = place.address_components.filter(item => item.types[0] === "administrative_area_level_4");
                }

                //Country
                const country = place.address_components.filter(item => item.types[0] === "country");

                //City
                let city = place.address_components.filter(item => item.types[0] === "locality");
                if (city.length === 0) {
                    city = place.address_components.filter(item => item.types[0] === "administrative_area_level_1");
                }
                if (city.length === 0) {
                    city = place.address_components.filter(item => item.types[0] === "administrative_area_level_2");
                }
                if (city.length === 0) {
                    city = place.address_components.filter(item => item.types[0] === "administrative_area_level_3");
                }
                if (city.length === 0) {
                    city = place.address_components.filter(item => item.types[0] === "postal_town");
                }
               /*  if (city.length === 0) {
                    city = place.address_components.filter(item => item.types[0] === "locality");
                } */

                //ZIP
                const postal = place.address_components.filter(item => item.types[0] === "postal_code");


                /********* Insert to Fields *********/
                //Area
                if (area.length > 0) { ObjToSend.area = area[0].long_name }
                else { ObjToSend.area = "" }

                //Country
                if (country.length > 0) { ObjToSend.country = country[0].short_name }
                else { ObjToSend.country = "" }

                //City
                if (city.length > 0) { ObjToSend.city = city[0].long_name }
                else { ObjToSend.city = "" }

                //ZIP
                if (postal.length > 0) { ObjToSend.postal = postal[0].long_name }
                else { ObjToSend.postal = "" }

                //Coordinates
                ObjToSend.coordinates = `${place.geometry.location.lat()},${place.geometry.location.lng()}`
                ObjToSend.name = place.name

                setGooglePlaceObj(ObjToSend)

            }

        });
    }

    React.useEffect(() => {
        initialize();
        //eslint-disable-next-line
    }, []);

    return (
        <Autocomplete>
            <Form.Control
                type="text"
                name="google_place"
                size="lg"
                id={`lisStreetId-${category}`}
                placeholder={`${t('words.eg')}: Brussels `}
                autoComplete="xcvb"
                className='fs-5 fw-bold'
            //onKeyUp={resetCoordinates}
            />
        </Autocomplete>
    )
}

export default SearchByPlace