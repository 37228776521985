import React from 'react'
import { useTranslation } from 'react-i18next'
import { Spinner, ToggleButton } from 'react-bootstrap';
import NotificationsGet from '../../jwtAPI/NotificationsGet';
import SingleNotification from './SingleNotification';
import { Check2Circle, GearFill, Trash } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import MyPagination from '../MyPagination';
import markAsReadNotif from "../../jwtAPI/MarkNotificationRead"
import useWindowDimensions from '../../functions/GetWidthHeight'



const NotificationsAllModal = ({ setViewAll, notif, setNotif }) => {

  const { t, i18n } = useTranslation();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const [sum, setterSum] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);


  const [radioValue, setRadioValue] = React.useState('all');

  const changeRadio = (e) => {

    const selected = e.target.value

    if (selected === 'edit') {
      navigate('/profile/Personalization');
      setViewAll(false);
    }
    else if (selected === 'all') {
      setRadioValue(selected);
    }
    else if (selected === 'unread') {
      setRadioValue(selected);
    }
    else if (selected === 'deleteAll') {
      if (window.confirm(t('notif.notif12'))) {
        markAsReadNotif('id', 'DELETE');
        setNotif(null)
      }
    }
    else if (selected === 'markRead') {
      markAsReadNotif('id', 'PATCH');
      for (let i = 0; i < notif.length; i++) {
        notif[i]['viewed'] = true
      }
    }
  }


  const url = `${process.env.REACT_APP_DOMAIN}/${i18n.language}/api/notifications?page=${currentPage}&limit=10&type=${radioValue}`

  React.useEffect(() => {

    const fetchLists = new NotificationsGet();
    fetchLists.fetchListings(url, setNotif, setterSum, setLoading);

    //eslint-disable-next-line
  }, [url]);

  const radios = [
    { name: t('words.all'), value: 'all' },
    { name: t('words.unread'), value: 'unread' },
    { name: <><Check2Circle size={18} className='me-1 fw-bold' /> {t('notif.notif15')} </>, value: 'markRead' },
    { name: <><Trash size={18} className='me-1' /> {t('words.delete')} {t('words.all')}</>, value: 'deleteAll' },
    { name: <>{t('notif.notif11')} <GearFill size={18} /></>, value: 'edit' },
  ];



  return (

    <>

      <div className="notif-drop-btn d-flex flex-wrap mb-3 justify-content-between">

        {radios.map((radio, idx) => (
          <ToggleButton
            key={idx}
            id={`notifRadio-${idx}`}
            type="radio"
            variant="lightblue my-1"
            className={idx === 2 ? 'ms-auto me-2 text-success' : idx === 3 ? 'text-danger mx-2' : 'mx-2'}
            name="radioNotif"
            value={radio.value}
            checked={radioValue === radio.value}
            onChange={changeRadio}
          >
            {radio.name}
            {radioValue === radio.value && <>({sum})</>}
          </ToggleButton>
        ))}

      </div>

      {loading
        ?
        <div className='w-100 d-flex justify-content-center'>
          <Spinner animation="border" variant="info" />
        </div>


        :
        notif && notif.length > 0
        &&
        notif.map((noti, key) => (

          <SingleNotification key={key} noty={noti} modal={width > 991 ? true : false} />

        ))
      }

      {(!loading && sum > 10) &&
        <MyPagination
          itemsCount={sum}
          itemsPerPage={10}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          alwaysShown={false}
        />
      }


    </>

  )
}

export default NotificationsAllModal