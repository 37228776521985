import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import React from "react"
import { Archive, Eye, PencilSquare, Share } from 'react-bootstrap-icons';
import { FaFileDownload } from 'react-icons/fa';



const TutorialOverlayListing = () => {

    const { t } = useTranslation();

    return (

        <div className='overlay-user-listing'>

            <div className="text-center">
                <Button variant="light" className="trans-2" size="sm" >
                    <Eye className="me-1" size={30} /><br />
                    {t('words.view')}
                </Button>

                <Button variant="light" className="trans-2 flex-column" size="sm" >
                    <PencilSquare className="me-1 p-1" size={30} /><br />
                    {t('words.edit')}
                </Button>

                <Button variant="light" className="trans-2" size="sm" >
                    <Share className="me-1 p-1 text-dark" size={30} /><br />
                    <span className="text-dark">{t('words.copy')} {t('words.link')}</span>
                </Button>

                <Button variant="light" className="trans-2 text-dark" size="sm">
                    <Archive className="me-1 p-1 text-dark" size={30} /><br />
                    {t('words.archive')}
                </Button>
            </div>

            <div>
                <Button variant="light" className="trans-2 px-3 text-dark" size="sm">
                    <FaFileDownload className="me-1 p-1 text-dark" size={30} />
                    <>{t('words.downl')} {t('words.report2')} </>
                </Button>
            </div>
        </div>
    )
}

export default TutorialOverlayListing
