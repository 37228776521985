import React from "react";
import ContentLoader from "react-content-loader";

function MemberLoader(props) {
  const width = "190";
  const height = "19";
  return (
    <>
      <ContentLoader
        speed={1}
        width={178}
        height={102}
        viewBox="0 0 178 102"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        style={{ width: "100%", height: `100%` }}
        {...props}
      >
        <rect x="16" y="10" rx="0" ry="0" width={width} height={height} />
        <rect x="16" y="43" rx="0" ry="0" width={width} height={height} />
        <rect x="16" y="77" rx="0" ry="0" width={width} height={height} />
      </ContentLoader>
    </>
  );
}
export default MemberLoader;
