import React from "react"
import { Col, Button } from "react-bootstrap"
import CarouselItemListing from "../listings/CarouselItemListing";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import useWindowDimensions from "../../functions/GetWidthHeight";
import FetchPostMixed from "../../jwtAPI/MixedFetch";


function LandingCardCarousel() {

    const [listi, setter] = React.useState(null);


    const reqMixed = [
        {
            "type": "sale",
            "number": 4
        },
        {
            "type": "rent",
            "number": 4
        },
        {
            "type": "request",
            "number": 2
        },
        {
            "type": "partnership",
            "number": 2
        }
    ]



    //Saves and uses json after fetch with the useState of setter listi
    React.useEffect(() => {

        const mixedFetch = new FetchPostMixed();
        mixedFetch.fetchListings(reqMixed, setter);

        // eslint-disable-next-line
    }, [setter]);



    //Carousel Settings
    const responsive = {
        largeDesktop: {
            breakpoint: { max: 3000, min: 2000 },
            items: 4,
        },
        desktop: {
            breakpoint: { max: 2000, min: 1240 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1240, min: 576 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 576, min: 0 },
            items: 1,
        }
    };

    const ButtonGroupCarousel = ({ next, previous, goToSlide, ...rest }) => {
        const { carouselState: { currentSlide } } = rest;

        return (
            <div className="carousel-button-group">
                <Button variant="transparent" className={currentSlide === 0 ? '' : "carousel-control-prev-icon"} onClick={() => previous()} />
                <Button variant="transparent" className={currentSlide === 0 ? '' : "carousel-control-next-icon"} onClick={() => next()} />
            </div>
        );
    };

    const { width } = useWindowDimensions();


    return (

        <>

            {listi ?


                <Col md={12} lg={9} className="mt-5 mb-5 pb-4 position-relative">

                    <Carousel
                        swipeable={true}
                        draggable={true}
                        centerMode={false}
                        showDots={true}
                        responsive={responsive}
                        ssr={false} // means to render carousel on server-side.
                        infinite={true}
                        arrows={false}
                        customButtonGroup={width > 576 ? <ButtonGroupCarousel /> : null}
                        keyBoardControl={true}
                        customTransition="transform 200ms ease-in-out"
                        transitionDuration={200}
                        containerClass="carousel-container"
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px pb-3"
                    >

                        {listi.map((listing, key) => (

                            <div key={key} className="mx-2">

                                <CarouselItemListing listing={listing} mainTypeListing={listing.mainTypeListing} withCardActions={false}/>

                            </div>

                        ))}

                    </Carousel>

                </Col>

                : ''}
        </>
    )
}

export default LandingCardCarousel