//import
//const regfunc = new RegisterFunctions();
//regfunc.initialFormData()


export default class RegisterFunctions {



    /***************Submit with api******************/
    //Prepare json
    initialFormData() {

        const initialFormData = Object.freeze({
            first_name: "",
            last_name: "",
            email: "",
            plain_password: "",
            country: "",
            referer: "",

            user_types: [
                {
                    pro: null,
                    architecture: null,
                    immo: null,
                    construction: null,
                    entrepreneur_spe: null,
                    electro_technique: null,
                    parachevement: null,
                    droit: null,
                    finance: null,
                    vente_materiel: null,
                    service_ict: null
                }
            ],
            receive_mail_notifications: false
        });

        return initialFormData;

    }


    //Fields Value
    handleChangeVal(e, updateFormData, formData) {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        });
    };


    //Fields Value only for Proffession
    handleChangeProfVal(e, updateFormData, formData) {

        const index = e.target.selectedIndex;
        const optionSelected = e.target.childNodes[index];

        updateFormData({
            ...formData,
            user_types: [
                {

                    pro:
                    {
                        id: optionSelected.getAttribute('id'),
                    },
                    architecture: null,
                    immo: null,
                    construction: null,
                    entrepreneur_spe: null,
                    electro_technique: null,
                    parachevement: null,
                    droit: null,
                    finance: null,
                    vente_materiel: null,
                    service_ict: null

                }
            ]
        })
    };

    //Fields Value only for Jobs
    handleChangeJobsVal(e, updateFormData, formData) {

        //Fill Profession
        const indexProf = document.getElementById("pro").selectedIndex;
        const optionProfSelected = document.getElementById("pro").childNodes[indexProf];

        //Fill Job
        const index = e.target.selectedIndex;
        const optionSelected = e.target.childNodes[index];

        updateFormData({
            ...formData,
            user_types: [
                {

                    pro:
                    {
                        id: optionProfSelected.getAttribute('id'),
                    },
                    architecture: null,
                    immo: null,
                    construction: null,
                    entrepreneur_spe: null,
                    electro_technique: null,
                    parachevement: null,
                    droit: null,
                    finance: null,
                    vente_materiel: null,
                    service_ict: null,
                    [e.target.name]:
                    {
                        id: optionSelected.getAttribute('id'),
                    },

                }
            ]
        })

    };

    //Fields Value only for Construction Jobs
    handleChangeJobsConstrVal(e, updateFormData, formData) {

        //Fill Job
        const indexProf = document.getElementById("pro").selectedIndex;
        const optionProfSelected = document.getElementById("pro").childNodes[indexProf];

        //Fill Construction
        const indexConstr = document.getElementById("construction").selectedIndex;
        const optionConstrSelected = document.getElementById("construction").childNodes[indexConstr];

        //Fill Construction Job
        const index = e.target.selectedIndex;
        const optionSelected = e.target.childNodes[index];

        updateFormData({
            ...formData,
            user_types: [
                {

                    pro:
                    {
                        id: optionProfSelected.getAttribute('id'),
                    },
                    architecture: null,
                    immo: null,
                    construction:
                    {
                        id: optionConstrSelected.getAttribute('id'),
                    },
                    entrepreneur_spe: null,
                    electro_technique: null,
                    parachevement: null,
                    droit: null,
                    finance: null,
                    vente_materiel: null,
                    service_ict: null,
                    [e.target.name]:
                    {
                        id: optionSelected.getAttribute('id'),
                    },

                }
            ]
        })

    };

    //Password check strength
    checkStrength(password, strength, setStrength, passwordStrength, lowUpperCase, number, specialChar, eightChar) {
        strength = 0;

        //If password contains both lower and uppercase characters
        if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
            strength += 1
            lowUpperCase.classList.remove('text-danger');
            lowUpperCase.classList.add('text-success');
        } else {
            lowUpperCase.classList.add('text-danger');
            lowUpperCase.classList.remove('text-success');
        }
        //If it has numbers and characters
        if (password.match(/([0-9])/)) {
            strength += 1
            number.classList.remove('text-danger');
            number.classList.add('text-success');
        } else {
            number.classList.add('text-danger');
            number.classList.remove('text-success');
        }
        //If it has one special character
        if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
            strength += 1
            specialChar.classList.remove('text-danger');
            specialChar.classList.add('text-success');
        } else {
            specialChar.classList.add('text-danger');
            specialChar.classList.remove('text-success');
        }
        //If password is greater than 7
        if (password.length > 7) {
            strength += 1
            eightChar.classList.remove('text-danger');
            eightChar.classList.add('text-success');
        } else {
            eightChar.classList.add('text-danger');
            eightChar.classList.remove('text-success');
        }

        // If value is less than 2
        if (strength <= 1) {
            passwordStrength.classList.remove('progress-bar-warning');
            passwordStrength.classList.remove('progress-bar-success');
            passwordStrength.classList.remove('progress-bar-primary');
            passwordStrength.classList.add('progress-bar-danger');
            passwordStrength.style = 'width: 10%';
            setStrength(1);
        } else if (strength === 2) {
            passwordStrength.classList.remove('progress-bar-success');
            passwordStrength.classList.remove('progress-bar-danger');
            passwordStrength.classList.remove('progress-bar-primary');
            passwordStrength.classList.add('progress-bar-warning');
            passwordStrength.style = 'width: 30%';
            setStrength(2);
        } else if (strength === 3) {
            passwordStrength.classList.remove('progress-bar-success');
            passwordStrength.classList.remove('progress-bar-danger');
            passwordStrength.classList.remove('progress-bar-warning');
            passwordStrength.classList.add('progress-bar-primary');
            passwordStrength.style = 'width: 60%';
            setStrength(3);
        } else if (strength === 4) {
            passwordStrength.classList.remove('progress-bar-warning');
            passwordStrength.classList.add('progress-bar-primary');
            passwordStrength.classList.remove('progress-bar-danger');
            passwordStrength.classList.add('progress-bar-success');
            passwordStrength.style = 'width: 100%';
            setStrength(4);
        }
    }


}