import React from "react"
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import LoginFormNew from "./LoginFormNew"
import RegisterFormNew from "./RegisterFormNew"
import { FaUserCircle } from "react-icons/fa";
import useWindowDimensions from '../../functions/GetWidthHeight'


function LoginRegisterModal() {

    const { t } = useTranslation();

    const { width } = useWindowDimensions();

    const [show, setShow] = React.useState(false);

    const [login, setLogin] = React.useState(true);

    return (
        <>
            <Button variant="lightblue text-nowrap px-1 px-md-2" onClick={() => {setShow(true); setLogin(true)}} id="open_login">
                <FaUserCircle size={27} className="text-secondary"/> 
                {t('title.login')} / {width > 576 ? t('title.createacc') : t('notif.notif13')}
            </Button>

            <Button id="open_register" className="d-none" onClick={() => {setShow(true); setLogin(false)}}/>


            <Modal
                show={show}
                onHide={() => setShow(false)}
                backdrop="static"
                keyboard={false}
                centered
                size="lg"
                fullscreen="md-down"
            >
                <Modal.Header closeButton>
                    <div className="text-center w-100">

                        <Button variant="outline-primary" className="border-2 fw-bold shadow mt-2" size="lg" onClick={() => setLogin(true)}>
                            {t('title.login')}
                        </Button>

                        <Button variant="success" className="ms-4 fw-bold text-white shadow mt-2" size="lg" onClick={() => setLogin(false)}>
                            {t('title.createacc')} {t('bigtext.youLogin5')}
                        </Button>

                    </div>
                </Modal.Header>
                <Modal.Body className={login ? 'modal-login-bg' : 'modal-register-bg'}>
                    {login
                        ? <LoginFormNew />
                        :
                        <Row>
                            <Col lg={3}></Col>
                            <Col lg={9} className="bg-white">
                                <RegisterFormNew />
                            </Col>
                        </Row>

                    }
                </Modal.Body>
            </Modal>

        </>
    )
}

export default LoginRegisterModal

