import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, ListGroup, Button, Form, Spinner } from 'react-bootstrap'
import { TiWarning } from 'react-icons/ti'
import ToastTimer from '../../ToastTimer';
import { EyeFill, EyeSlash } from 'react-bootstrap-icons';
import deleteAccountPermantly from '../../../jwtAPI/DeleteAccount'



const DeleteAccount = () => {

  const { t } = useTranslation();

  const resetSteps = [
    { number: 1, text: t('bigtext.delAcc1') },
    { number: 2, text: t('bigtext.delAcc2') },
    { number: 3, text: t('bigtext.delAcc3') },
    { number: 4, text: t('bigtext.delAcc5') },
  ];

  const [eyePass, setEyePass] = React.useState(false);
  const [seePass, setSeePass] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const [validated, setValidated] = React.useState(false);
  const [formData, updateFormData] = React.useState('');

  function handleChange(e) {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim()
    });
  };

  function showPass(e) {
    setEyePass(!eyePass);
    if (!eyePass) {
      setSeePass(e.currentTarget.id);
    }
    else {
      setSeePass('');
    }
  }

  const deleteAccount = () => {

    if (window.confirm(t('bigtext.delAcc4')) === true) {
      deleteAccountPermantly(setStatus, formData, setLoading)
    }
    setLoading(false);
  }

  const handleSubmitValidation = (event) => {

    setStatus(null)
    const form = event.currentTarget;

    //Validation for empty fields
    if (form.checkValidity() === false) {

      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity() === true) {
      event.preventDefault();
      event.stopPropagation();
      setLoading(true);
      console.log(formData)
      deleteAccount(setStatus, formData, setLoading)
    }

  };


  return (
    <>
      <Col xs={12} className="mt-3"><hr /></Col>

      <Col md={6}>


        {resetSteps.map((step, key) => (

          <ListGroup horizontal="xxl" className="my-2" key={key}>
            <ListGroup.Item variant="danger" className='text-nowrap'>{t('words.step')} {step.number}</ListGroup.Item>
            <ListGroup.Item>{step.text}</ListGroup.Item>
          </ListGroup>

        ))}

      </Col>

      <Col md={6}>

        {status === 'error' &&
          <ToastTimer color={status} text={status === 'error' && 'error credentials'} time={10000} />
        }

        <Form noValidate validated={validated} onSubmit={handleSubmitValidation} style={{ maxWidth: "500px" }} className="mt-2">

          <ListGroup horizontal="xxl" className="my-2">
            <ListGroup.Item variant="warning" className='w-25'>Email &nbsp; </ListGroup.Item>
            <ListGroup.Item className='p-1 w-100 position-relative'>
              <Form.Control
                required
                name="email"
                type="email"
                autoComplete="email"
                className='bg-transparent'
                onChange={handleChange} />

              <Form.Control.Feedback type="invalid">
                {t('text.emptyField')}
              </Form.Control.Feedback>

            </ListGroup.Item>
          </ListGroup>

          <ListGroup horizontal="xxl" className="my-2">
            <ListGroup.Item variant="warning" className='w-25'>{t('title.pass')} &nbsp; </ListGroup.Item>
            <ListGroup.Item className='p-1 w-100 position-relative'>
              <Form.Control
                required
                name="password"
                placeholder={t('profile.pass3')}
                autoComplete="current-password"
                type={seePass ? "text" : "password"}
                className='bg-transparent'
                onChange={handleChange}
              />

              {eyePass
                ? <EyeFill onClick={showPass} id="password" size={30} className='text-warning bg-white p-1 position-absolute top-0 end-0 me-2 mt-2' />
                : <EyeSlash onClick={showPass} id="password" size={30} className='text-warning bg-white p-1 position-absolute top-0 end-0 me-2 mt-2' />
              }

              <Form.Control.Feedback type="invalid">
                {t('text.emptyField')}
              </Form.Control.Feedback>

            </ListGroup.Item>
          </ListGroup>

          <Button
            variant="danger"
            className='float-end w-auto'
            size="lg"
            type="submit"
            disabled={loading ? true : false}
          >
            {loading ?
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                {t('title.loading')}
              </>

              : <><TiWarning size={23} className="me-2" /> {t('text.deleteAcc')}</>
            }
          </Button>

        </Form>

      </Col>

    </>
  )
}

export default DeleteAccount