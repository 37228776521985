import React from 'react';
import { Button, Col, ListGroup, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';

import { FiSave, FiHeart } from 'react-icons/fi';
import { ImCoinEuro } from 'react-icons/im';
import { BiNote, BiHelpCircle } from 'react-icons/bi';
import { CgToolbox } from 'react-icons/cg';
import { RiAdvertisementLine } from 'react-icons/ri';
import { MdSecurity, MdPersonOutline, MdOutlineEditNote, MdOutlineSettingsSuggest, MdOutlineRemoveRedEye } from 'react-icons/md';
import AgentPage from '../../pages/AgentPage';
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';
// import { MdConnectWithoutContact } from 'react-icons/md';


const ProfileMenu = ({ type, userId }) => {

    const { t } = useTranslation();

    const [showViewPublic, setShowViewPublic] = React.useState(false)

    const [showMenu, setShowMenu] = React.useState(true);
    const toggleShowMenu = () => {
        setShowMenu(!showMenu);
        window.scrollTo(0, 0);
    }


    const menuProfile = [

        { name: `${t('words.my')} ${t('title.profile')}`, btnLink: "MyProfile", icon: <MdPersonOutline size='22' className='me-2 mb-1' />, hr: false },
        { name: `${t('title.modify')} ${t('words.my')} ${t('title.profile')}`, btnLink: "EditProfile", icon: <MdOutlineEditNote size='22' className='me-2 mb-1' />, hr: true },

        { name: t('profile.menu1'), btnLink: "MyListings", icon: <RiAdvertisementLine size='22' className='me-2 mb-1' />, hr: false },
        { name: t('profile.menu2'), btnLink: "MyToolBoxListings", icon: <CgToolbox size='22' className='me-2 mb-1' />, hr: true },

        { name: t('profile.menu3'), btnLink: "Searches", icon: <FiSave size='22' className='me-2 mb-1' />, hr: false },
        { name: t('words.favs'), btnLink: "Favorites", icon: <FiHeart size='22' className='me-2 mb-1' />, hr: true },

        //dark theme, newsletter, language, newsfeed
        { name: t('title.personalization'), btnLink: "Personalization", icon: <MdOutlineSettingsSuggest size='22' className='me-2 mb-1' />, hr: true },

        { name: `${t('words.my')} ${t('words.plan')}`, btnLink: "MyPlan", icon: <BiNote size='22' className='me-2 mb-1' />, hr: false },
        { name: `${t('title.billing')} & ${t('title.subscription')}`, btnLink: "Subscription", icon: <ImCoinEuro size='22' className='me-2 mb-1' />, hr: true },

        { name: `${t('title.pass')} & ${t('words.security')}`, btnLink: "Security", icon: <MdSecurity size='22' className='me-2 mb-1' />, hr: false },
        { name: t('title.help'), btnLink: "Help", icon: <BiHelpCircle size='22' className='me-2 mb-1' />, hr: false },

    ]


    return (
        <>
            <Button
                variant="primary text-white"
                className="d-lg-none fixed-bottom rounded-pill mb-4 ms-4"
                style={{ zIndex: 10, width: "300px" }}
                onClick={toggleShowMenu}
            >
                {showMenu
                    ?
                    <>
                        <AiOutlineMenuFold size={20} />
                        {t('words.close')} {t('title.profile')} Menu
                    </>
                    :
                    <>
                        <AiOutlineMenuUnfold size={20} />
                        {t('words.open')} {t('title.profile')} Menu
                    </>
                }
            </Button>

            {showMenu &&
                <div className='slideLeft'>
                    <Col className='text-end mt-0 mt-lg-4 mb-1'>

                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip >{t('words.view')} {t('words.public')} {t('title.profile')} </Tooltip>}
                        >
                            <Button variant="light" className='px-1 py-0 rounded-4' onClick={() => setShowViewPublic(true)}>
                                <MdOutlineRemoveRedEye size="20" />
                            </Button>
                        </OverlayTrigger>
                    </Col>

                    <ListGroup variant="flush" className='d-lg-none'>

                        {menuProfile.map((btn, key) => (
                            <Link key={key} to={`/profile/${btn.btnLink}`} onClick={() => setShowMenu(false)} className='text-decoration-none mb-0'>
                                <ListGroup.Item
                                    action
                                    className={
                                        (btn.hr && type !== btn.btnLink) ? 'border-0 border-bottom border-1 border-gray mb-2'
                                            : type === btn.btnLink ? 'border-0 bg-secondary text-white'
                                                : 'border-0'
                                    }
                                    id={btn.btnLink}

                                >

                                    {btn.icon} {btn.name}
                                </ListGroup.Item>
                            </Link>
                        ))}

                    </ListGroup>

                    <ListGroup variant="flush" className='d-none d-lg-block'>

                        {menuProfile.map((btn, key) => (
                            <Link key={key} to={`/profile/${btn.btnLink}`} className='text-decoration-none mb-0'>
                                <ListGroup.Item
                                    action
                                    className={
                                        (btn.hr && type !== btn.btnLink) ? 'border-0 border-bottom border-1 border-gray mb-2'
                                            : type === btn.btnLink ? 'border-0 bg-secondary text-white'
                                                : 'border-0'
                                    }
                                    id={btn.btnLink}

                                >

                                    {btn.icon} {btn.name}
                                </ListGroup.Item>
                            </Link>
                        ))}

                    </ListGroup>

                    <Modal show={showViewPublic} onHide={() => setShowViewPublic(false)} size="xl">
                        <Modal.Header closeButton>
                            <Modal.Title>{t('words.view')} {t('words.public')} {t('title.profile')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="bg-light">
                            <AgentPage userId={userId} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowViewPublic(false)}>
                                {t('words.close')}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            }
        </>
    )
}

export default ProfileMenu