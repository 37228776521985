import React, { useState, useRef } from 'react'
import { Modal, Button, Form, Container, Toast, Row, Col, Spinner, OverlayTrigger, Tooltip, } from 'react-bootstrap';
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
} from 'react-image-crop'
import { useTranslation } from 'react-i18next'
import canvasPreview from "../../../functions/CanvasPreview"
import DebounceEffect from '../../../functions/DebounceEffect';
import 'react-image-crop/dist/ReactCrop.css'
import UploadPic from '../../../jwtAPI/UploadPic';
import FragmentedCircleLoader from "../../Loaders/FragmentedCircleLoader"
import heic2any from "heic2any";


//  center a % aspect crop
function centerAspectCrop(
    mediaWidth,
    mediaHeight,
    aspect,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 100,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

function ImageEditor({ show, setShow, profile, user, refreshCover }) {

    const { t } = useTranslation();

    const [imgSrc, setImgSrc] = useState('')
    const previewCanvasRef = useRef(null)
    const imgRef = useRef(null)
    const [crop, setCrop] = useState()
    const [completedCrop, setCompletedCrop] = useState()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState(profile ? 16 / 16 : 113 / 20)
    const [loading, setLoading] = useState(false)
    const [pageLoader, setPageLoader] = useState(true)
    const [status, setStatus] = useState(0)
    const [file, setFile] = useState(null)
    const [newUser, setNewUser] = useState(false)
    const [showToast, setShowToast] = useState(false)
    const canvasStyle1 = {
        objectFit: 'contain',
        maxWidth: "100%",
        // width: completedCrop.width,
        // height: completedCrop.height,
        borderRadius: "50%",
        border: "2px solid white",
        background: "white",
        width: "300px",
        height: "300px",
    }
    const canvasStyle2 = {
        objectFit: 'contain',
        maxWidth: "100%",
        // width: completedCrop.width,
        // height: completedCrop.height,
        borderRadius: "2px",
        border: "2px solid white",
        background: "white",
        width: "300px",
        height: "53px",
    }
    React.useEffect(() => {

        const getBase64FromUrl = async (url) => {
            const data = await fetch(url);
            const blob = await data.blob();
            const convertedData = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    const base64data = reader.result;
                    resolve(base64data);
                }
            });

            setImgSrc(convertedData)
            setPageLoader(false)
        }
        if (profile) {
            if (user.profile_picture_url !== null) {

                getBase64FromUrl(`${process.env.REACT_APP_USERINFO2_API}/${user.id}/avatar`)
            } else {
                setNewUser(true)
                setPageLoader(false)

            }
        } else {
            if (user.cover_photo_url) {

                getBase64FromUrl(`${process.env.REACT_APP_USERINFO2_API}/${user.id}/cover`)
            } else {
                setNewUser(true)
                setPageLoader(false)

            }
        }


        return () => {
            cleanUp()
        }
        // eslint-disable-next-line 
    }, [])
    function cleanUp() {
        setImgSrc("")
        setCrop()
        setCompletedCrop()
        setScale(1)
        setRotate(0)
        setAspect(profile ? 16 / 16 : 113 / 20)
        setLoading(false)
        setStatus(0)
        setFile()
        setNewUser(false)
        setShowToast(false)
    }
    React.useEffect(() => {
        if (status === 400) setShowToast(true)
        if (status === 200 && !profile) refreshCover(Date.now())
        if (status === 200) setShow(false)

        // eslint-disable-next-line
    }, [status])

    function onSelectFile(e) {
        if (newUser) setNewUser(false)
        setPageLoader(true)
        setFile(e.target.files)
        let scopedFile = e.target.files

        if (scopedFile && scopedFile.length > 0) {
            //if ((scopedFile[0].size / 1024 / 1024 > 2)) alert(scopedFile[0].size / 1024 / 1024)

            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result.toString() || ''),
            )

            //convert heic for preview
            if (scopedFile[0].type === 'image/heic' || scopedFile[0].name.endsWith('.heic')) {
                heic2any({ blob: scopedFile[0], toType: "image/jpg", quality: 1 }).then(
                    (newImage) => {
                        reader.readAsDataURL(newImage)
                    }
                );
            }
            else {
                reader.readAsDataURL(scopedFile[0])
            }

        }
        setPageLoader(false)

    }

    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }


    function handleSave() {

        setLoading(true)
        document.getElementById("savePic").blur()
        let payload = {}

        if (file) {

            const fileNameType = file[0].name;

            payload = {
                file_name:
                    fileNameType.endsWith('.heic')
                        ? 'pic.jpeg'

                        : fileNameType.endsWith('.gif')
                            ? 'pic.jpeg'

                            : fileNameType,
                file_data_uri:
                    previewCanvasRef.current?.toDataURL()
                        ? previewCanvasRef.current.toDataURL(`image/${fileNameType.endsWith('.png') ? 'png' : 'jpeg'}`, 0.75)
                        : imgSrc
            }

            /* if ((previewCanvasRef.current.toDataURL(`image/${fileType}`, 0.75).length / 1024 / 1024) > 2) {
                alert("Final photo is bigger than 2 mb, try with a smaller photo or crop it more!")
                return
            } */
        }
        else {
            payload = {
                file_name: profile ? `user_${user.id}_avatar` : `user_${user.id}_cover_photo`,
                file_data_uri: previewCanvasRef.current?.toDataURL() ? previewCanvasRef.current.toDataURL() : imgSrc
            }
            /* if ((previewCanvasRef.current.toDataURL().length / 1024 / 1024) > 2) {
                alert("Final photo is bigger than 2 mb, try with a smaller photo or crop it more!")
                return
            } */
        }



        UploadPic(payload, profile, setLoading, setStatus, user)
    }
    DebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )
    function changeZoom(zoom) {
        setScale(zoom / 50)
    }

    function changeRotatation(rotation) {
        //setRotate(Math.min(180, Math.max(-180, (rotation - 50))))
        setRotate(rotation + 0.1)
    }
    function handleToggleAspectClick() {
        if (aspect) {
            setAspect(undefined)
        } else if (imgRef.current) {
            const { width, height } = imgRef.current
            setAspect(profile ? 16 / 16 : 113 / 20)
            setCrop(centerAspectCrop(width, height, profile ? 16 / 16 : 113 / 20))
        }
    }

    return (
        <>
            <Toast
                delay={3000} autohide
                bg={status === 'success' ? 'success' : 'danger'}
                onClose={() => setShowToast(false)}
                show={showToast}
            >
                <Toast.Body className='text-white'>{status === 'success' ? t('profile.prof1') : t('bigtext.errorWrong')}</Toast.Body>
            </Toast>

            <Form.Control className='d-none' type="file" id="upload_pic" onChange={onSelectFile} accept=".png, .jpg, .jpeg, .gif, .heic" />
            <Modal
                size="xl"
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        {t('title.profile')} {t('words.image')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <Container className='d-flex flex-column ' >

                        <Row style={{ minHeight: "50vh" }}>
                            {

                                newUser
                                    ? <p className='text-center my-auto'> {t('profile.prof8')}</p>

                                    : pageLoader
                                        ? <div className='text-center mt-4'>  <FragmentedCircleLoader color={"black"} /> </div>

                                        :
                                        <>
                                            <Col md={8} className="d-flex  flex-column align-items-center justify-content-center p-0 " >
                                                <div className="border border-secondary border-5 ">

                                                    {Boolean(imgSrc) && (
                                                        <ReactCrop
                                                            style={{ maxHeight: "70vh" }}
                                                            crop={crop}
                                                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                                                            onComplete={(c) => setCompletedCrop(c)}
                                                            aspect={aspect}
                                                            disabled={loading}
                                                        >
                                                            <img
                                                                ref={imgRef}
                                                                alt="Crop me"
                                                                src={imgSrc}
                                                                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                                                onLoad={onImageLoad}

                                                            />
                                                        </ReactCrop>
                                                    )}

                                                </div>

                                            </Col>

                                            <Col lg={4} className="d-flex bg-secondary flex-column align-items-center justify-content-center" >

                                                <h4 className='text-light mb-3'>{t('words.preview')}</h4>
                                                {completedCrop
                                                    &&
                                                    <>
                                                        <div className="  bg-warning" />

                                                        {Boolean(completedCrop) && (
                                                            <canvas
                                                                ref={previewCanvasRef}
                                                                style={profile ? canvasStyle1 : canvasStyle2}
                                                            />
                                                        )}

                                                    </>
                                                }

                                            </Col>
                                        </>
                            }
                        </Row>



                        {!newUser &&
                            <>
                                <Form.Label>{t('words.rotate')}</Form.Label>
                                <Form.Range
                                    defaultValue={180}
                                    min="0" max="360" step="45"
                                    disabled={loading}
                                    onChange={(e) => changeRotatation(e.target.value)}
                                />
                                <Form.Label>{t('words.zoom')}</Form.Label>
                                <Form.Range
                                    defaultValue={50}
                                    disabled={loading}
                                    onChange={(e) => changeZoom(e.target.value)}
                                />
                            </>
                        }

                        {aspect !== (profile ? 16 / 16 : 113 / 20) &&
                            <p className='text-danger fs-6 fst-italic'>{t('profile.prof9')}</p>
                        }


                        <div className='d-flex'>

                            <Button
                                variant="secondary"
                                disabled={loading}
                                onClick={() => document.getElementById('upload_pic').click()}
                            >
                                {t('words.upload')} {t('words.image')}
                            </Button>

                            {!newUser &&
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>{t('profile.prof10')}</Tooltip>}
                                >
                                    <Button className='mx-4' variant="info" onClick={handleToggleAspectClick} >
                                        {t('words.change')} {t('words.ratio')}
                                    </Button>
                                </OverlayTrigger>
                            }

                            {!newUser &&
                                <Button
                                    variant='success'
                                    id="savePic"
                                    disabled={loading}
                                    className='ms-4 ms-auto me-4'
                                    onClick={handleSave}
                                >
                                    {loading ? <Spinner size="sm" animation="border" role="status" /> : t('words.save')}
                                </Button>
                            }

                        </div>

                    </Container>

                </Modal.Body>
            </Modal>
        </>
    )
}
export default ImageEditor