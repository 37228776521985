import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Form, ListGroup, Spinner } from 'react-bootstrap'
import { EyeFill, EyeSlash } from 'react-bootstrap-icons';
import RegisterFunctions from '../../loginRegister/RegisterFunctions';
import changeUserPass from '../../../jwtAPI/ChangePassword'
import ToastTimer from '../../ToastTimer';
//const createfunc = new CreateFunctions();
//createfunc.initialFormData()

const ChangePass = () => {

  const { t } = useTranslation();

  const resetSteps = [
    { number: 1, text: t('bigtext.changePass1') },
    { number: 2, text: t('bigtext.changePass2') },
    { number: 3, text: t('bigtext.changePass3') },
    { number: 4, text: t('bigtext.changePass4') },
  ];

  const [eyePass, setEyePass] = React.useState(false);
  const [seePass, setSeePass] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState(null);

  function showPass(e) {
    setEyePass(!eyePass);
    if (!eyePass) {
      setSeePass(e.currentTarget.id);
    }
    else {
      setSeePass('');
    }
  }


  //********Password Strength

  let [strength, setStrength] = React.useState(0);

  const regfunc = new RegisterFunctions();

  let password = document.getElementById("new_pass_field");
  let passwordStrength = document.getElementById("password-strength");
  let lowUpperCase = document.querySelector(".low-upper-case");
  let number = document.querySelector(".one-number");
  let specialChar = document.querySelector(".one-special-char");
  let eightChar = document.querySelector(".eight-character");

  if (password) {
    password.onkeyup = funfun;
  }

  function funfun() {
    let password = document.getElementById("new_pass_field").value;
    regfunc.checkStrength(password, strength, setStrength, passwordStrength, lowUpperCase, number, specialChar, eightChar);
  }


  //**********Form
  const [validated, setValidated] = React.useState(false);

  const [formData, updateFormData] = React.useState('');

  function handleChange(e) {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim()
    });
  };

  //*********Validation Form
  const handleSubmitValidation = (event) => {

    setStatus(null)
    const form = event.currentTarget;

    //Validation for empty fields
    if (form.checkValidity() === false) {

      event.preventDefault();
      event.stopPropagation();
    }

    //Confirm Password
    const plain_password = document.getElementById("new_pass_field")
      , passwordConfirm = document.getElementById("new_pass_confirm");

    if (strength < 4) {
      plain_password.setCustomValidity('Wrong');
    }
    else {

      plain_password.setCustomValidity('');

      if (plain_password.value !== passwordConfirm.value) {
        passwordConfirm.setCustomValidity('Wrong');
        event.preventDefault();
        event.stopPropagation();

      } else {
        passwordConfirm.setCustomValidity('');
      }

    }

    setValidated(true);

    if (form.checkValidity() === true) {
      event.preventDefault();
      event.stopPropagation();
      setLoading(true);
      //console.log(formData)
      changeUserPass(setStatus, formData, setLoading)
    }

  };




  return (
    <>
      <Col xs={12} className="mt-3"><hr /></Col>

      <Col md={6}>


        {resetSteps.map((step, key) => (

          <ListGroup horizontal="xxl" className="my-2" key={key}>
            <ListGroup.Item variant="success" className='text-nowrap'>{t('words.step')} {step.number}</ListGroup.Item>
            <ListGroup.Item className='w-100'>{step.text}</ListGroup.Item>
          </ListGroup>

        ))}

      </Col>

      <Col md={6}>

        {status !== null &&
          <ToastTimer color={status} text={status === 'success' ? t('bigtext.changePassSuccess') : t('bigtext.changePassError')} time={10000} />
        }

        <Form noValidate validated={validated} onSubmit={handleSubmitValidation} style={{ maxWidth: "500px" }} className="mt-2">

          <ListGroup horizontal="xxl" className="my-2">
            <ListGroup.Item variant="primary" className='w-50'>{t('words.old')} {t('title.pass')}</ListGroup.Item>
            <ListGroup.Item className='p-1 w-100 position-relative'>
              <Form.Control
                required
                name="current_password"
                placeholder={t('profile.pass1')}
                autoComplete="current-password"
                type={seePass ? "text" : "password"}
                className='bg-transparent'
                onChange={handleChange}
              />

              {eyePass
                ? <EyeFill onClick={showPass} id="current_password" size={30} className='text-warning bg-white p-1 position-absolute top-0 end-0 me-2 mt-2' />
                : <EyeSlash onClick={showPass} id="current_password" size={30} className='text-warning bg-white p-1 position-absolute top-0 end-0 me-2 mt-2' />
              }

              <Form.Control.Feedback type="invalid">
                {t('text.emptyField')}
              </Form.Control.Feedback>

            </ListGroup.Item>
          </ListGroup>

          <ListGroup horizontal="xxl" className="my-2">
            <ListGroup.Item variant="primary" className='w-50'>{t('words.new2')} {t('title.pass')}</ListGroup.Item>
            <ListGroup.Item className='p-1 w-100'>
              <Form.Control
                required
                name="password"
                id="new_pass_field"
                placeholder={t('profile.pass2')}
                autoComplete="new-password"
                type={seePass ? "text" : "password"}
                className='bg-transparent'
                onChange={handleChange}
              />

              {eyePass
                ? <EyeFill onClick={showPass} id="password" size={30} className='text-warning bg-white p-1 position-absolute top-0 end-0 me-2 mt-2' />
                : <EyeSlash onClick={showPass} id="password" size={30} className='text-warning bg-white p-1 position-absolute top-0 end-0 me-2 mt-2' />
              }

              <Form.Control.Feedback type="invalid">
                {t('bigtext.changePass5')}
              </Form.Control.Feedback>

              <div className='mt-2 small fw-bold'>

                <div className="progress">
                  <div id="password-strength"
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="40"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: "0%" }}>
                  </div>
                </div>

                <span className="low-upper-case text-danger me-1">
                  &nbsp;{t('words.lcase')} &amp; {t('words.ucase')}
                </span>
                -
                <span className="one-number text-danger mx-1">
                  &nbsp;{t('words.number')} (0-9)
                </span>
                -
                <span className="one-special-char text-danger mx-1">
                  &nbsp;{t('words.special')} {t('words.char')} (!@#$%^&*)
                </span>
                -
                <span className="eight-character text-danger mx-1">
                  &nbsp;{t('text.char8')}
                </span>

              </div>

            </ListGroup.Item>
          </ListGroup>

          <ListGroup horizontal="xxl" className="my-2">
            <ListGroup.Item variant="primary" className='w-50'>{t('words.confirmPass')}</ListGroup.Item>
            <ListGroup.Item className='p-1 w-100'>
              <Form.Control
                required
                name="confirm_password"
                id="new_pass_confirm"
                autoComplete="no"
                placeholder={`${t('words.confirmPass')}`}
                aria-label="Confirm password"
                type={seePass ? "text" : "password"}
                className='bg-transparent'
                onChange={handleChange}
              />

              {eyePass
                ? <EyeFill onClick={showPass} id="confirm_password" size={30} className='text-warning bg-white p-1 position-absolute top-0 end-0 me-2 mt-2' />
                : <EyeSlash onClick={showPass} id="confirm_password" size={30} className='text-warning bg-white p-1 position-absolute top-0 end-0 me-2 mt-2' />
              }

              <Form.Control.Feedback type="invalid">
                {t('text.emptyField')}
              </Form.Control.Feedback>

            </ListGroup.Item>
          </ListGroup>

          <Button
            variant="success"
            className='float-end w-auto'
            size="lg"
            type="submit"
            disabled={loading ? true : false}
          >
            {loading ?
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                {t('title.loading')}
              </>

              : t('text.changepass')
            }
          </Button>


        </Form>


      </Col>

    </>
  )
}

export default ChangePass