import React from 'react'
import { Alert, Badge, Button, Col, Form, Image, ListGroup, Modal, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { BiSearchAlt } from 'react-icons/bi';
import SearchByPlace from './SearchByPlace';
import houseExample from "../../assets/houseExample.jpg"
import RentCatDropdown from '../listings/rent/RentCatDropdown';
import SaleCatDropdown from '../listings/sale/SaleCatDropdown';


const SearchBarLanding = ({ category, tabKey, googlePlaceObj, setGooglePlaceObj }) => {

    const { t } = useTranslation();

    const [loading, setLoading] = React.useState(false)
    const [results, setResults] = React.useState(null)
    const [openModal, setOpenModal] = React.useState(false)
    const closeModal = () => {
        setOpenModal(false)
        setGooglePlaceObj(null)
        setResults(null)
        document.getElementById(`lisStreetId-${category}`).value = ""
    }

    const [rentSaleType, setRentSaleType] = React.useState('')


    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        setLoading(true)

        if (!googlePlaceObj) {
            alert(t('bigtext.hero1'));
            setLoading(false)
        }
        else {

            const url = `${process.env.REACT_APP_DOMAIN}/en/api/${category}/search-by-location?postal_code=${googlePlaceObj.postal && googlePlaceObj.postal}&city=${googlePlaceObj.city && googlePlaceObj.city}&country=${googlePlaceObj.country && googlePlaceObj.country}&ad_type=${rentSaleType}`;

            fetch(url)
                .then(res => res.json())
                .then(res => {
                    setResults(res);
                    setOpenModal(true)
                    setLoading(false)
                })

                .catch(err => { console.error(err); setLoading(false); })

            /* if (category !== 'inquiries') {
                const url = `${process.env.REACT_APP_DOMAIN}/en/api/${category}/search-by-location?postal_code=${googlePlaceObj.postal && googlePlaceObj.postal}&country=${googlePlaceObj.country && googlePlaceObj.country}`;

                fetch(url)
                    .then(res => res.json())
                    .then(res => {
                        setResults(res);
                        setOpenModal(true)
                        setLoading(false)
                    })

                    .catch(err => { console.error(err); setLoading(false); })
            }
            else {

                const url1 = `${process.env.REACT_APP_DOMAIN}/en/api/request/search-by-location?postal_code=${googlePlaceObj.postal && googlePlaceObj.postal}&country=${googlePlaceObj.country && googlePlaceObj.country}`;
                const url2 = `${process.env.REACT_APP_DOMAIN}/en/api/partnership/search-by-location?postal_code=${googlePlaceObj.postal && googlePlaceObj.postal}&country=${googlePlaceObj.country && googlePlaceObj.country}`;

                fetch(url1)
                    .then(res => res.json())
                    .then(res1 => {

                        fetch(url2)
                            .then(res => res.json())
                            .then(res2 => {

                                const resReqPar = {}
                                resReqPar.number_of_records = res1.number_of_records + res2.number_of_records;
                                resReqPar.number_of_records_in_country = res1.number_of_records_in_country + res2.number_of_records_in_country;
                                resReqPar.records = res1.records;
                                resReqPar.records.push(res2.records[0])

                                setResults(resReqPar);
                                setOpenModal(true)
                                setLoading(false)
                            })
                            .catch(err => { console.error(err); setLoading(false); })

                    })
                    .catch(err => { console.error(err); setLoading(false); })
            } */
        }

    };


    React.useEffect(() => {

        if (tabKey !== category) {
            return;
        } else {
            closeModal()
        }
        //eslint-disable-next-line
    }, [tabKey]);


    const loginOpen = () => {
        //open login modal
        document.getElementById('open_login').click();
    }

    const handleChangeDrops = (e) => {
        if (e.target.value === 'all') {
            setRentSaleType('');
        }
        else {
            setRentSaleType(e.target.value.trim())
        }
    };


    return (
        <>
            <Form onSubmit={handleSubmit}>

                <h4 className='text-white text-center mb-2'>{t('bigtext.hero2')}</h4>

                {tabKey === 'rent' && tabKey === category
                    ?
                    <RentCatDropdown col={6} onlyFirstDrop={true} getDataDrops={handleChangeDrops} />

                    : tabKey === category
                        ?
                        <Col lg={6}>
                            <SaleCatDropdown getDataDrops={handleChangeDrops} />
                        </Col>
                        : ''
                }

                <br />

                <span className='text-white small'>{t('bigtext.hero3')}</span>


                <Row className='justify-content-center'>
                    <Col md={8} lg={12} xl={8} className="mb-2">
                        <SearchByPlace setGooglePlaceObj={setGooglePlaceObj} category={category} />
                    </Col>
                    <Col md={4}>
                        <Button
                            size="lg"
                            variant="primary"
                            className='text-center px-4 ms-md-3 ms-lg-0 mt-4 mt-md-0 d-inline-flex'
                            type="submit"
                            disabled={loading ? true : false}
                        >
                            <BiSearchAlt size={26} /> {loading ? t('title.loading') : t('words.srch')}
                        </Button>
                    </Col>
                </Row>

            </Form>


            <Modal show={openModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <BiSearchAlt size={26} /> {t('landing.searchbar10')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">

                    {results && (results.number_of_records > 0 || results.number_of_records_in_country > 0) // || results.number_of_records_in_city > 0 )


                        ?
                        results.number_of_records > 0 ?

                            <>
                                <p className='text-center lead fw-bold mt-3'>
                                    {t('landing.searchbar1')} {results.number_of_records} {t('landing.searchbar2')}
                                </p>

                                <Alert variant="warning" className='border-4'>
                                    {t('landing.searchbar3')}
                                    <Button variant='success rounded-4' onClick={loginOpen}>
                                        {t('title.login')} / {t('title.createacc')} <strong> {t('bigtext.youLogin5')}</strong>
                                    </Button>
                                    {t('landing.searchbar4')}
                                </Alert>

                                <ListGroup className='ps-2 overflow-scroll' style={{ height: "350px" }} >

                                    <ListGroup.Item>
                                        <Badge bg="yellow" className='fs-6'>{t('cats_tools.' + category + '')}</Badge>
                                        <Badge bg="success" className='fs-6 ms-2'>{googlePlaceObj.name && googlePlaceObj.name}</Badge>
                                    </ListGroup.Item>

                                    {results.records.map((res, key) => (

                                        <ListGroup.Item key={key}>
                                            <Image
                                                src={
                                                    res.article.excerpt_photo_url
                                                        ? process.env.REACT_APP_DOMAIN + res.article.excerpt_photo_url
                                                        : houseExample
                                                }
                                                width="90px"
                                                height="65px"
                                                style={{ objectFit: 'cover' }}
                                                rounded
                                                onError={(e) => { e.target.onerror = null; e.target.src = houseExample }}
                                            />
                                            <span className='lead fw-bold ps-3'>{res.article.title}</span>
                                        </ListGroup.Item>

                                    ))}

                                    {results.number_of_records > 10 &&
                                        <ListGroup.Item as="li" active>
                                            {t('landing.searchbar11')}
                                        </ListGroup.Item>
                                    }

                                </ListGroup>
                            </>

                            :
                            <>
                                <p className='lead text-center mt-4'>
                                    {t('landing.searchbar5')}
                                    &nbsp;<span className='fw-bold'>{results.number_of_records_in_country}</span>&nbsp;
                                    {t('landing.searchbar6')}
                                </p>
                                <Alert variant="success" className='border-4'>
                                    {t('landing.searchbar7')}
                                    <Button variant='success rounded-4' onClick={loginOpen}>
                                        {t('title.login')} / {t('title.createacc')} <strong> {t('bigtext.youLogin5')}</strong>
                                    </Button>
                                    {t('landing.searchbar4')}
                                </Alert>
                            </>




                        :
                        <Alert variant="danger" className='border-4'>
                            <span className='lead'>{t('landing.searchbar8')}</span>
                            <br />
                            <Button variant='success rounded-4' onClick={loginOpen}>
                                {t('title.login')} / {t('title.createacc')} <strong> {t('bigtext.youLogin5')}</strong>
                            </Button>
                            {t('landing.searchbar9')}
                        </Alert>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        {t('words.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SearchBarLanding
