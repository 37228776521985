import React, { useState } from 'react'
import { Form, Card, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import FetchUserListings from "../../../jwtAPI/FetchUserListings"
import MyPagination from '../../MyPagination';
import FavoritesListingType from './FavoritesListingType';


function Favorites() {

    const { t, i18n } = useTranslation();
    let url = `${process.env.REACT_APP_DOMAIN}/${i18n.language}/api/favorite-articles`
    const controller = new AbortController();
    const ac = controller.signal

    const [loading, setLoading] = useState(false);
    const [listings, setListings] = useState(null);
    const [sumListing, setSumListing] = useState(null);
    const [currentPage, setCurrentPage] = React.useState(1);

    let [optionsFeed, setOptionsFeed] = React.useState(
        {
            "page": currentPage,
            "limit": 6,
            "article": true,
            "sale": true,
            "rent": true,
            "request": true,
            "partnership": true,
            "shop-company-sell": false,
            "crm": true,
            "training-job": true,
            "tender-call": false,
            "benchmark": true,
            "library": false,
            "event": true
        }
    );

    const cards = [
        { name: `${t('words.for2')} ${t('cats_tools.rent')}`, id: 'rent' },
        { name: `${t('words.for2')} ${t('cats_tools.sale')}`, id: 'sale' },
        { name: t('cats_tools.request'), id: 'request' },
        { name: t('cats_tools.partnership'), id: 'partnership' },
        { name: t('cats_tools.training-job'), id: 'training-job' },
        { name: t('cats_tools.article'), id: 'article' },
        { name: t('cats_tools.benchmark'), id: 'benchmark' },
        { name: t('cats_tools.crm'), id: 'crm' },
        { name: t('cats_tools.event'), id: 'event' },
    ];

    React.useEffect(() => {

        setLoading(true);
        const fetchLists = new FetchUserListings(ac);
        fetchLists.fetchListings(url, setListings, false, optionsFeed, setLoading, setSumListing)
        // eslint-disable-next-line
    }, [optionsFeed, url]);

    React.useEffect(() => {
        if (optionsFeed.page === currentPage) {
            return;
        }
        setOptionsFeed({
            ...optionsFeed,
            "page": currentPage
        })
        // eslint-disable-next-line
    }, [currentPage]);


    function handleChange(value) {
        setOptionsFeed({
            ...optionsFeed,
            [value]: !optionsFeed[value]
        })
        // console.log(optionsFeed)
    }


    return (

        <Card as={Row} border="white" className="flex-row p-4 mx-lg-4 mb-3 text-center rounded-4">

            <h5 className='text-center my-4 fw-bold'>{t('words.favs')} ({sumListing})</h5>

            <Col lg={12} className="mb-5">

                {cards.map((card, index) => (
                    <Form.Check
                        key={index}
                        inline
                        label={card.name}
                        type='checkbox'
                        checked={optionsFeed[card.id]}
                        id={'favs_' + card.id}
                        onChange={() => handleChange(card.id)}
                        disabled={loading}
                    />
                ))}

            </Col>

            <hr />

            <Col lg={12} className="text-start d-flex flex-wrap">

                {listings &&

                    listings.map((listing, key) => (
                        <div className="d-inline-block my-2 ms-0 me-4" key={key}>

                            <FavoritesListingType listing={listing} />

                        </div>
                    ))
                }
            </Col>

            {(sumListing > 0 && !loading) &&
                <MyPagination
                    itemsCount={sumListing}
                    itemsPerPage={6}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    alwaysShown={false}
                />
            }


        </Card>
    )
}
export default Favorites