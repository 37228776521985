import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown } from 'react-bootstrap';
import { MdOutlinePrint, MdOutlineShare } from "react-icons/md";
import ShareToSocialAll from '../ShareToSocialAll';
import { HiOutlineHeart, HiHeart } from "react-icons/hi";
import FavoriteToggle from '../../jwtAPI/FavoriteToggle';
import { useSelector } from "react-redux"

function OverviewTitleShare({ link, title, isFav, lisId, listingType }) {


    const { t } = useTranslation();

    const [favorite, setFavorite] = React.useState(false);
    const [onError, setOnError] = React.useState(false);

    const luser = useSelector(state => state.user);

    React.useEffect(() => {

        if (isFav && isFav.length > 0) {

            const checkIsFav = isFav.includes(luser.id);

            if (checkIsFav) {
                setFavorite(true)
            }
        }

        // eslint-disable-next-line
    }, [])


    React.useEffect(() => {

        if (onError > 0) {
            alert(t('bigtext.errorWrong'))
            setFavorite(!favorite)
            setOnError(0)
        }

        // eslint-disable-next-line
    }, [onError])


    function favor() {

          setFavorite(!favorite)
      
          const url = `${process.env.REACT_APP_FAVORITE_API}/${lisId}`
          const togFavor = new FavoriteToggle();
          togFavor.fetchListings(url, lisId, onError, setOnError);
    }

    function printElem() {
        window.scrollTo(0, 0);
        setTimeout(function () {
            window.print();
            window.close();
        }, 1000);
    }


    return (

        <div className='d-inline-flex justify-content-between mb-3'>
            <span className='lead'>{t('title.overview')}</span>
            <div>
                <Dropdown className='d-inline-block m-1'>
                    <Dropdown.Toggle variant='outline-warning' size="sm" className='border-0 w-auto px-3'>
                        <MdOutlineShare size={23} /> {t('title.shareTo')}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <ShareToSocialAll link={link} title={title} type={t('cats_tools.' + listingType + '')}/>
                    </Dropdown.Menu>
                </Dropdown>
                <Button onClick={favor} variant='outline-warning' size="sm" className='border-0 w-auto px-3 m-1'>
                    {!favorite
                        ? <HiOutlineHeart size={23} />
                        : <HiHeart color='red' size={23} />
                    }
                    &nbsp;{t('words.fav')}
                </Button>
                <Button variant='outline-warning' onClick={printElem} size="sm" className='border-0 w-auto px-3 m-1'>
                    <MdOutlinePrint size={23} /> {t('text.exportPdf')}
                </Button>

            </div>
        </div>

    );
}

export default OverviewTitleShare;
