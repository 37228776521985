import React from 'react'
import { useTranslation } from "react-i18next";
import countries from "i18n-iso-countries";
import { Badge, Card, Col, Image, Row } from "react-bootstrap"
import { PersonCircle, GeoAltFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import AgentPage from '../../../pages/AgentPage';
import houseExample from "../../../assets/houseExample.jpg"
import ComparisonPage from '../../../pages/ComparisonPage';
import OverlayListing from "../../profile/listings/OverlayListing";
import ToolCardActions from '../ToolCardActions';



const ComparisonCard = ({ listing, profile = false, status = null }) => {

    const { t, i18n } = useTranslation();

    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/el.json"));

    let descrip = listing['article']['excerpt'] ? listing['article']['excerpt'] : 'No description';
    if (descrip && descrip.length > 180) {
        descrip = listing['article']['excerpt'].substring(0, 180) + " [...]"
    }


    return (
        <div className="position-relative card-listing-container">
            <Card className='tool-listing p-3 pt-0'>

                <ToolCardActions
                    listing={listing}
                    listingType="benchmark"
                />

                <Badge bg='secondary' className='small text-end mb-2'>{t('cats_tools.benchmark')}</Badge>

                <h6><strong>
                    {listing['article']['title']}
                    {listing.number
                        && <span className='small fw-bold text-muted'>&nbsp;({listing.number})</span>
                    }
                </strong></h6>

                <Row className='small align-items-center justify-content-between'>
                    <Col>

                        <Link
                            component={<AgentPage />}
                            to={`/agent/${listing['article']['author']['id']}/`}
                            className="text-decoration-none text-dark text-capitalize text-nowrap"
                        >

                            {listing['article']['author']['profile_picture_url']
                                ? <Image roundedCircle
                                    style={{ objectFit: 'cover' }}
                                    width="35px"
                                    height="35px"
                                    src={`${process.env.REACT_APP_DOMAIN + listing['article']['author']['profile_picture_url']}?photo_height=50&photo_width=50`}
                                    className="me-2" />

                                : <PersonCircle className='text-warning me-2' size={33} />
                            }

                            {listing['article']['author']['blog_display_name'].toLowerCase()}
                        </Link>
                    </Col>

                    <Col className='text-warning text-end'>
                        <GeoAltFill />
                        {listing['address'] && listing['address']['city']
                            ? listing['address']['city']

                            : listing['address'] && listing['address']['country']
                                ? countries.getName(listing['address']['country'], i18n.language, { select: "official" })

                                : ''
                        }
                    </Col>

                    <Link
                        component={<ComparisonPage />}
                        to={`/listing/benchmark/${listing["id"]}/${listing["article"]["slug"]}`}
                        className="text-decoration-none text-dark"
                    >
                        <hr className='mt-3' />


                        <Row>
                            <Col xs={5}>

                                <Image
                                    alt="excerpt-photo"
                                    height="117"
                                    width="100%"
                                    style={{ objectFit: "cover" }}
                                    src={
                                        listing.article.excerpt_photo_url
                                            ? `${process.env.REACT_APP_DOMAIN}${listing.article.excerpt_photo_url}?photo_height=400&photo_width=400`
                                            : houseExample
                                    }
                                />

                            </Col>
                            <Col xs={7} style={{ minHeight: '57px' }}>
                                {descrip}
                            </Col>
                        </Row>

                        <hr className='mt-3' />
                        <Row>
                            {listing.estate_type &&
                                <Col xs={12}>
                                    <strong>{t('words.estate')}:</strong> {listing['estate_type']['name']}
                                </Col>
                            }
                            {listing.transaction &&
                                <Col xs={6}>
                                    <strong>{t('title.transa')}:</strong> {listing['transaction']['name']}
                                </Col>
                            }
                            {listing.price &&
                                <Col xs={6} className="text-end text-primary">
                                    <strong>{listing.price} &euro;</strong>
                                </Col>
                            }
                        </Row>

                    </Link>

                </Row>

            </Card >
            {profile ?
                <OverlayListing
                    title={listing.article.title}
                    type={"benchmark"}
                    link={`/listing/benchmark/${listing.id}/${listing.article.slug}/`}
                    id={listing.id}
                    edit={`/edit/benchmark/${listing.id}`}
                    status={status}
                    articleId={listing.article.id}
                />
                : null}
        </div>
    )
}

export default ComparisonCard
