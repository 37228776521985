import { Accordion, Button, Card, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLocation } from "react-router-dom";
import { useState } from 'react';


const Faq = () => {

    const { t } = useTranslation();
    const location = useLocation();

    //Hide email from bots
    const [showingEmail, setShowingEmail] = useState(false);

    return (

        <Card as={Row} border="white" className="justify-content-center rounded-4 mx-lg-4 p-4">

            <h1 className='fw-bold text-primary text-center'>FAQ</h1>

            <h3 className='text-warning text-center'>
                {t('title.faq')}
            </h3>

            <h4 className='text-info fst-italic text-center'>
                {t('faq.faq1')}&nbsp;
                {showingEmail
                    ?
                    <a href="mailto:info@betweenpros.com" className="text-decoration-none fw-bold text-secondary" >
                        info@betweenpros.com
                    </a>

                    :
                    <Button size="lg" variant="outline-warning" className='rounded-pill' onClick={() => setShowingEmail(true)}>
                        {t('words.show')}&nbsp;Email
                    </Button>
                }
            </h4>


            <h5 className='mt-5 mb-3'>→ {t('faq.faq2')}</h5>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{t('faq.faq3')}</Accordion.Header>
                    <Accordion.Body>
                        {t('faq.faq4')}
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>{t('faq.faq5')}</Accordion.Header>
                    <Accordion.Body>
                        {t('faq.faq6')}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            {location.pathname !== '/' &&
                <>
                    <h5 className='mt-5 mb-3'>→ {t('faq.faq22')}</h5>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>{t('faq.faq7')}</Accordion.Header>
                            <Accordion.Body>
                                {t('faq.faq8')}&nbsp;
                                <Link to="/profile/Subscription" replace >
                                    {t('title.billing')} &amp; {t('title.subscription')} {t('words.page')}
                                </Link>
                                &nbsp;
                                {t('faq.faq9')}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>{t('faq.faq10')}</Accordion.Header>
                            <Accordion.Body>
                                {t('faq.faq11')}&nbsp;
                                <Link to="/profile/Subscription" replace >
                                    {t('title.billing')} &amp; {t('title.subscription')} {t('words.page')}
                                </Link>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>{t('faq.faq12')}</Accordion.Header>
                            <Accordion.Body>
                                {t('faq.faq13')}&nbsp;
                                <Link to="/profile/Subscription" replace >
                                    {t('title.billing')} &amp; {t('title.subscription')} {t('words.page')}
                                </Link>
                                {t('faq.faq14')}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </>
            }


            <h5 className='mt-5 mb-3'>→ {t('faq.faq15')}</h5>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{t('faq.faq16')}</Accordion.Header>
                    <Accordion.Body>
                        {t('faq.faq17')}
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>{t('faq.faq18')}</Accordion.Header>
                    <Accordion.Body>
                        {t('faq.faq19')}
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>{t('faq.faq20')}</Accordion.Header>
                    <Accordion.Body>
                        {t('faq.faq21')}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>


        </Card>

    )
}

export default Faq