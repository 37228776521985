import React from "react"
import { Col, Container, Image, Row, Tab, Nav, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import HeroImageSmall from "../assets/heroimage1000.jpg"
import HeroImageNormal from "../assets/heroimage2000.jpg"
import HeroImageBig from "../assets/heroimage3000.jpg"
import CookieImg from "../assets/cookie.png"
import LandingCardCarousel from "../components/landing/LandingCardCarousel"

import SectionExplain from "../components/landing/SectionExplain"
import SectionServices from "../components/landing/SectionServices"
import ForYourBusiness from "../components/landing/ForYourBusiness"
import ContactForm from "../components/ContactForm"
import Faq from "../components/Faq"

//Image resize with screen
import useWindowDimensions from '../functions/GetWidthHeight'
import SectionProfessions from "../components/landing/SectionProfessions"
import Plans from "../components/profile/Plans"
//import SectionTestimonials from "../components/landing/SectionTestimonials"
import Hero from "../components/landing/Hero"

//Cookies
import CookieConsent/*,  { Cookies } */ from "react-cookie-consent";



function LandingPage() {

    /* 
    const handleDeclineCookie = () => {
        //Cookies.remove("DV");
    };
 */

    const { t } = useTranslation();

    const { width } = useWindowDimensions();

    function openLogin() {
        //open login modal
        document.getElementById('open_login').click()
    }

    function openPolicy() {
        //open login modal
        document.getElementById('policy-new-tab').click()
    }


    return (

        <>

            <Container fluid id="hero-section">

                <Image id="hero-image" alt="hero" src={width < 1000 ? HeroImageSmall : width < 2000 ? HeroImageNormal : HeroImageBig} />

                <Hero />

            </Container>


            <Container fluid="lg">

                <Row className="my-5"></Row>
                <SectionExplain />

                <Row className="my-5"></Row>
                <ForYourBusiness />

                <Row className="my-5"></Row>
                <SectionServices />

            </Container>

            <Container fluid>

                <Row className="justify-content-center mt-6 mb-6 bg-secondary pt-4">
                    <span className="fs-3 mt-5 text-lightblue text-center">
                        <a href="#0" onClick={openLogin} className="draw-white-border">
                            {t('title.login2')} {t('words.or')} {t('title.createacc')}
                        </a>
                        {t('bigtext.discoverLis')}
                    </span>
                    <LandingCardCarousel />
                </Row>

            </Container>

            <Container fluid="lg">

                <SectionProfessions />

            </Container>


            <Container fluid="lg" className="pt-5">
                <h5 className="my-5">&rarr; {t('text.comparePlans')}</h5>
            </Container>
            <Container fluid className="bg-gradient-animation">

                <Container fluid="lg">
                    <Plans />
                </Container>

            </Container>

            {/* <Container fluid="lg">

                <SectionTestimonials />

            </Container> */}


            <Container fluid="xxl" className="pt-5">
                <Tab.Container defaultActiveKey="first">
                    <Row id="help-landing" >
                        <Col md={3} className="mt-5">
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="first" className="pb-4">{t('title.contactus')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">{t('title.faq')}</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col md={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <ContactForm />
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <div className="mt-5"></div>
                                    <Faq />
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>

            <CookieConsent
                location="bottom"
                buttonText={t('words.accept')}
                //declineButtonText={}
                cookieName="CookieNotice"
                style={{ background: "#6666ff" }}
                buttonClasses='secondary rounded-pill bg-secondary text-white fw-bold px-3'
                //declineButtonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
                //enableDeclineButton
                //onDecline={handleDeclineCookie}
                flipButtons
                overlay
            >
                <Image width="30px" src={CookieImg} className="me-2" />
                {t('landing.cookies1')}
                <Button size="sm" className="ms-4 text-white" onClick={openPolicy}>{t('landing.cookies2')}</Button>
            </CookieConsent>

        </>

    )
}
export default LandingPage

