import TokenRefresher from "./TokenRefresher";
import { store } from "../Redux/Store.js"
import { setReduxAvatarUrl, refreshReduxUserObject, setReduxCoverPhotoUrl } from "../Redux/user-slice"
const UploadPic = async (payload, profile = true, setLoading, setStatus, user) => {


    const url = `${process.env.REACT_APP_DOMAIN}/en/api/user/profile/picture/upload`
    const url1 = `${process.env.REACT_APP_DOMAIN}/en/api/user/profile/cover/upload`



    const picUploading = async function () {
        try {


            const bearer_token = `Bearer ${localStorage.getItem('token')}`;

            const res = await fetch(profile ? url : url1, {
                method: 'PATCH',
                headers: {
                    'Authorization': bearer_token,
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify(payload),
                credentials: 'include',
            })
            if (res.status >= 200 && res.status <= 299) {

                if (profile && !user.profile_picture_ur) store.dispatch(setReduxAvatarUrl(`${process.env.REACT_APP_DOMAIN}/en/api/user/${user.id}/avatar`))
                if (!profile && !user.cover_photo_url) store.dispatch(setReduxCoverPhotoUrl(`${process.env.REACT_APP_DOMAIN}/en/api/user/${user.id}/cover`))
                if (profile) store.dispatch(refreshReduxUserObject())
                setStatus(200)

            } else {

                setStatus(400)
            }



        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)

        }
    }
    const sessionFetch = new TokenRefresher();
    sessionFetch.setTokenRefresh(picUploading);
}


export default UploadPic