import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from "react-router-dom";

import { Badge, Card, Col, Row, Image } from 'react-bootstrap'
import houseExample from "../../assets/houseExample.jpg"

import FeedItemActions from './FeedItemActions';
import FeedItemAuthor from './FeedItemAuthor';


const FeedItemArticle = ({ listing, catType }) => {

  const { t } = useTranslation();


  const lisId = listing.id;
  const lisSlug = listing.slug;
  const lisPhoto = listing.excerpt_photo_url;
  const lisLink = `${process.env.REACT_APP_URL}/listing/${catType}/${lisId}/${lisSlug}/`
  const lisTitle = listing.title;
  let lisDesc = listing.excerpt;


  return (


    <Card className="mt-4 border-0 shadow">
      <Row className='p-3 justify-content-between'>

        <h6><strong>
          {lisTitle}
          {listing.number
            && <span className='small fw-bold text-muted'>&nbsp;({listing.number})</span>
          }
        </strong></h6>

        <Col>

          <span className='d-inline-flex'>
            <Badge bg="warning" className='text-light me-2 text-capitalize'>
              {t('cats_tools.article')}
            </Badge>
          </span>

        </Col>

        <FeedItemActions
          lisLink={lisLink}
          listingType={catType}
          listing={listing}
        />

      </Row>

      <Link
        to={`/listing/article/${lisId}/${lisSlug}`}
        className="text-decoration-none text-dark"
      >
        <Row className='gx-0 px-3 bg-white'>

          <Col sm={6}>
            <div className="ratio-cont">
              <Image
                className="ratio"
                src=
                {
                  lisPhoto
                    ? process.env.REACT_APP_DOMAIN + lisPhoto + '?photo_height=400&photo_width=400'
                    : houseExample
                }
                alt="listing" />
            </div>
          </Col>



          <Col className="bg-light small px-4 py-2">

            {lisDesc &&
              <div className='bg-white p-2 rounded-4 mb-2'>
                <p className='mb-0'>{lisDesc}</p>
              </div>
            }

          </Col>

        </Row>

      </Link>

      <hr />


      <FeedItemAuthor author={listing.author} />

    </Card >

  )
}

export default FeedItemArticle