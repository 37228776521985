/*global google*/
import React from 'react'
import { Form, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import {
    Autocomplete,
} from "@react-google-maps/api";
import countries from "i18n-iso-countries";
import Select from 'react-select';
import { FcInfo } from 'react-icons/fc';


function SearchLocationFilters({ handleChangeLocation, handleChange, setValueCountry, valueCountry }) {

    const { t, i18n } = useTranslation();

    const inputElement = React.useRef();

    const [disabledRadius, setDisableRadius] = React.useState(true)
    const [valueRadius, setValueRadius] = React.useState(0)
    const changeValueRadius = (e) => setValueRadius(e.target.value);


    React.useEffect(() => {

        if (valueRadius > 0) {
            //we reset country field because its not needed when google places
            const radiusObj = {
                target: {
                    name: "radius",
                    value: valueRadius.toString()
                }
            }
            handleChange(radiusObj)
        }
        else {
            const radiusObj = {
                target: {
                    name: "radius",
                    value: ''
                }
            }
            handleChange(radiusObj)
        }
        //eslint-disable-next-line
    }, [valueRadius])

    //for countries dropdown
    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/el.json"));

    const countryObj = countries.getNames(i18n.language, { select: "official" });

    const countryArr = Object.entries(countryObj).map(([key, value]) => {
        return {
            label: value,
            value: key
        };
    });

    //for google place
    React.useEffect(() => {
        const input = document.getElementById('street');
        const autocomplete = new google.maps.places.Autocomplete(input);

        google.maps.event.addListener(autocomplete, 'place_changed', function () {

            setValueCountry(null)
            setDisableRadius(false)
            const place = autocomplete.getPlace();

            if (place.geometry) {

                //Coordinates
                document.getElementById('google_map').value = `${place.geometry.location.lat()},${place.geometry.location.lng()}`;
                //Country
                const thisCountry = place.address_components.filter(item => item.types[0] === "country");

                //enters it on formData in Filters
                const coorObj = {
                    google_map: inputElement.current.value,
                    country: thisCountry[0].short_name,
                    street: input.value
                }
                handleChangeLocation(coorObj)

                if (valueRadius < 1 || valueRadius === '') {
                    setValueRadius(1)
                }
            }
            else {
                const coorObj = {
                    target: {
                        name: inputElement.current.name,
                        value: '',
                    }
                }
                handleChangeLocation(coorObj)
            }
        })

        //eslint-disable-next-line
    }, []);



    //change country
    const onChangeDrop = (e) => {
        setDisableRadius(true)
        setValueCountry(e)
        const countryObj = {
            target: {
                name: "country",
                value: e.value
            }
        }
        handleChangeLocation(countryObj)

        document.getElementById('street').value = ''
        setValueRadius('0')
    }

    //on Keyup on places
    function resetCoordinates(e) {
        //auto update formdata if empty without the user pressing enter
        if (e.target.value === '') {
            const coorObj = {
                target: {
                    name: "google_map",
                    value: '',
                }
            }
            handleChangeLocation(coorObj)
        }
        document.getElementById('google_map').value = ''
    }


    return (
        <>

            <span className='mt-3 text-warning'><strong>&#9654;{t('text.searchLoc')}</strong></span>


            <Col md={4} className="mt-0">
                <Form.Label className='small mb-0'> {t('title.country')} </Form.Label>
                <Select
                    value={valueCountry}
                    onChange={onChangeDrop}
                    options={countryArr}
                    className="react-select-sm"
                    placeholder={t('title.country')}
                />
            </Col>

            <Col md={6} className="mt-0">
                <span className='small'>{t('words.srch')} {t('words.place')} (by Google Maps) </span>
                <Autocomplete>
                    <Form.Control
                        type="text"
                        name="street"
                        id="street"
                        placeholder={t('words.enter') + ' ' + t('title.loc')}
                        autoComplete="xcvb"
                        size="sm"
                        onKeyUp={resetCoordinates}
                    />
                </Autocomplete>
                <Form.Control
                    ref={inputElement}
                    type="text"
                    id="google_map"
                    name="google_map"
                    autoComplete="xcvb"
                    className='d-none'
                />
            </Col>

            <Col md={2} className="mt-0">
                <Form.Group>
                    <OverlayTrigger placement="top" overlay={<Tooltip>{t('text.distanceLoc')}</Tooltip>}>
                        <Form.Label className='small mb-0'>Radius km <FcInfo /></Form.Label>
                    </OverlayTrigger>
                    <Form.Control
                        name="radius"
                        id="radius"
                        size="sm"
                        type="number"
                        onChange={changeValueRadius}
                        placeholder="km"
                        value={valueRadius}
                        disabled={disabledRadius}
                    />
                </Form.Group>
            </Col>
        </>
    )
}

export default SearchLocationFilters