import React, { useState, useRef } from 'react'
import { CardList, Briefcase, Building, Envelope, Facebook, Globe2, Linkedin, Person, Phone, PhoneVibrate, Twitter } from 'react-bootstrap-icons';
import { Col, Row, Card, Button, Overlay } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';



const MyProfile = ({ user }) => {
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const { t } = useTranslation();

    //Check if user types and how many
    let userProf1 = [];
    let userProf2 = [];

    if (user.user_types && user.user_types[0] && user.user_types[0] !== 0) {
        // array empty or does not exist

        userProf1 = Object.keys(user.user_types[0]);

        for (let i = 0; i < userProf1.length; i++) {
            if (userProf1[i] !== 'id') {
                userProf2.push(
                    <span key={i} className='ms-1 me-2 text-nowrap text-muted'>&bull; {user.user_types[0][userProf1[i]].name}</span>
                )
            }
        }
    }


    return (

        <Card as={Row} border="white" className="flex-row p-4 rounded-4 mx-lg-4">

            <Col lg={5} className="mt-4">

                <div className="d-inline-flex bg-white shadow-sm">
                    {user.facebook_url && <a href={user.facebook_url} className="p-2" target={"blank"}>
                        <Facebook size={22} className="text-info" />
                    </a >}
                    {user.linkedin_url && <a href={user.linkedin_url} className="p-2" target={"blank"}>
                        <Linkedin size={22} className="text-info" />
                    </a>}
                    {user.twitter_url && <a href={user.twitter_url} className="p-2" target={"blank"}>
                        <Twitter size={22} className="text-info" />
                    </a>}

                </div>

                <ul className="list-agent-info" >
                    {user.title
                        && <li><Person />&nbsp;&nbsp;{t('title.title')}: {user.title.toLowerCase()}</li>
                    }
                    {userProf2.length > 0
                        && <li style={{ overflow: "auto" }}><Briefcase />&nbsp;&nbsp;{t('title.protyp')}:{userProf2}</li>
                    }
                    {user.enterprise
                        && <li><Building />&nbsp;&nbsp;{t('title.compa')}: {user.enterprise.toLowerCase()}</li>
                    }
                    {user.professional_phone
                        &&
                        <li>
                            <a href={`tel:${user.professional_phone}`} >
                                <Phone />&nbsp;&nbsp;{t('title.professional')} {t('title.phone')}: {user.professional_phone}
                            </a>
                        </li>
                    }
                    {user.mobile
                        &&
                        <li>
                            <a href={`tel:${user.mobile}`} >
                                <PhoneVibrate />&nbsp;&nbsp;{t('title.mobile')}: {user.mobile}
                            </a>
                        </li>
                    }
                    {user['email']
                        &&
                        <li>
                            <a href={`mailto:${user.email}`} style={{ textTransform: 'initial' }} >
                                <Envelope />&nbsp;&nbsp;Email: {user.email}
                            </a>
                        </li>
                    }
                    {user.website
                        &&
                        <li>
                            <a target="_blank" rel="noreferrer" href={user.website} style={{ textTransform: 'initial' }} >
                                <Globe2 />&nbsp;&nbsp;{t('words.website')}: {user.website}
                            </a>
                        </li>
                    }
                </ul>

                <p className="mt-5 fs-5 fw-bold">{t('title.loc')} {t('words.and')} {t('title.sareas')}</p>
                <div className="tags-pill">
                    {(user.address && user.address.city) && <a href="#0" rel="tag">{user.address.city}</a>}
                    {user.location_services?.length > 0 && <>
                        {user.location_services.map((add, index) => (
                            <a key={index} href="#0" rel="tag">{add.city}</a>

                        ))}
                    </>}
                    {/* <a href="#0" rel="tag">New York</a>
                    <a href="#0" rel="tag">Manhattan</a>
                    <a href="#0" rel="tag">Residential</a>
                    <a href="#0" rel="tag">Commercial</a> */}
                </div>

            </Col>

            <Col lg={7} className="mt-4">

                <p className="fs-5 fw-bold">{t('title.abtme')}</p>
                <p className="text-muted">{user.description}</p>


                {user.cvUploaded ? <a href={`${process.env.REACT_APP_USERINFO2_API}/${user.id}/cv`} className='btn btn-info btn-sm fw-bold rounded-4' target="_blank" rel="noreferrer">
                    <CardList size={25} className="mb-1" /> {t('text.seeRes')}
                </a> : (<>

                    <Button className='btn btn-info btn-sm fw-bold rounded-4 ' ref={target} onClick={() => setShow(!show)}>
                        <CardList size={25} className="mb-1" /> {t('text.seeRes')}
                    </Button>
                    <Overlay target={target.current} show={show} placement="right">
                        {({ placement, arrowProps, show: _show, popper, ...props }) => (
                            <div
                                {...props}

                                className="btn btn-info text-light btn-sm fw-bold rounded-4' text-decoration-none "
                            >
                                <Link to={`/profile/EditProfile`} className='text-decoration-none text-light mb-0'>
                                    {t('profile.prof11')}
                                </Link>
                            </div>
                        )}
                    </Overlay>

                </>)}




            </Col>

        </Card>
    )
}

export default MyProfile
