import TokenRefresher from "./TokenRefresher";


//Saves and uses json after fetch with the useState of setter listi
export default class FetchReport {


    async fetchReport(url, filename, setDownloading) {

        setDownloading(true);

        let getExcel = async () => {

            setDownloading(true);

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;

            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': bearer_token,
                },
                //credentials: 'include',
            })
                .then(response => response.blob())
                .then(blob => {
                    function download(blob, filename) {
                        const url = window.URL.createObjectURL(new Blob([blob], { type: 'application/xls' }));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", filename); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    }

                    download(blob, filename);
                    setDownloading(false)

                })
                .catch(err => { console.error(err); setDownloading(false) })

        }



        //checks and refresh token
        const sessionFetch = new TokenRefresher();
        sessionFetch.setTokenRefresh(getExcel);

    }

}

