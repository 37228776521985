import React from "react"
import { Card } from 'react-bootstrap'
import { BarChart, Calendar2Week, Laptop, People, Search } from "react-bootstrap-icons";
import { GoLocation } from "react-icons/go";
import { useTranslation } from 'react-i18next'



function SectionServices() {

    const { t } = useTranslation();

    const servicesIcons = [
        { name: t('landing.servName1'), icon: <Laptop className="mx-auto" />, value: 1, explain: t('landing.services1') },
        { name: t('landing.servName2'), icon: <Search className="mx-auto" />, value: 2, explain: t('landing.services2') },
        { name: t('landing.servName3'), icon: <GoLocation className="mx-auto" />, value: 3, explain: t('landing.services3') },
        { name: t('landing.servName4'), icon: <People className="mx-auto" />, value: 4, explain: t('landing.services4') },
        { name: t('landing.servName5'), icon: <BarChart className="mx-auto" />, value: 5, explain: t('landing.services5') },
        { name: t('landing.servName6'), icon: <Calendar2Week className="mx-auto" />, value: 6, explain: t('landing.services6') },
    ];


    return (
        <>
            <h5 className="my-5">&rarr; {t('title.ourServ')}</h5>

            <div className="d-flex justify-content-around flex-wrap">

                {servicesIcons.map((service, key) => (

                    <div key={key} className="text-center align-items-center p-1" style={{ width: "170px" }}>

                        <div className="text-wrap fw-bold h-25" >
                            {service.name}
                        </div>

                        <Card className="card-services">

                            <span className="unflipped">
                                {service.icon}
                            </span>

                            <span className="flipped">
                                {service.explain}
                            </span>

                        </Card>

                    </div>
                ))}

            </div>

        </>

    )
}
export default SectionServices
