
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormSelect, Nav, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import SearchButtonAnimated from '../../SearchButtonAnimated';


const ButtonNewProfList = ({ agentId, changeLimit, title, changeSort, changeStatus, search, loading }) => {

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    function createNew(e) {
        if (e.target.name === 'all') {
            //open menu mobile
            if (!document.getElementById('main-navbar-nav').classList.contains("show")) {
                document.getElementById('main-navbar-nav').classList.add("show");
                document.getElementById('add-new').click();
            }
            else {
                document.getElementById('add-new').click();
            }
        }
        else {
            if (title === "training-job") navigate(`/create-new/job`)
            else navigate(`/create-new/${title}`);

        }
    }

    return (

        <Nav className='mt-3'>

            <FormSelect
                id="myListsLimit"
                onChange={(e) => changeLimit(e.target.value)}
                className="w-auto ms-2 ps-1 border-0 rounded-0 bg-white text-primary"
                style={{ padding: '0 1.6rem 0 0' }}
            >
                <option value="6">6</option>
                <option value="12">12</option>
                <option value="24">24</option>
                <option value="50">50</option>
            </FormSelect>

            <span className="me-2 py-3">{t('words.per')} {t('words.page')}</span>

            <span className='ms-3 py-3'>{t('words.sortby')}:</span>

            <FormSelect
                id="profListSort"
                onChange={(e) => changeSort(e.target.value)}
                className="w-auto ms-2 ps-1 border-0 rounded-0 bg-white text-primary"
                style={{ padding: '0 1.6rem 0 0' }}
            >
                <option value="dateasc">{t('title.date')} &darr;</option>
                <option value="datedesc">{t('title.date')} &uarr;</option>
                <option value="nameasc">{t('title.name')} &darr;</option>
                <option value="namedesc">{t('title.name')} &uarr;</option>
            </FormSelect>

            <SearchButtonAnimated searchFunc={search} idSearch='profSearch' loading={loading} />

            {!agentId &&
                <>
                    {title !== 'allListings' &&
                        <Nav.Item>
                            <Nav.Link className="btn btn-success rounded-pill text-white" name={title} onClick={createNew}>{t('words.create')} {t('words.new')}</Nav.Link>
                        </Nav.Item>
                    }

                    <ToggleButtonGroup
                        type="radio"
                        name="lisStatus"
                        className='m-auto flex-wrap'
                        defaultValue={`${process.env.REACT_APP_DOMAIN}/${i18n.language}/${process.env.REACT_APP_USER_LISTINGS_API}`}
                        onChange={(e) => changeStatus(e)}
                    >
                        <ToggleButton variant="outline-warning" id="tbg-radio-1" value={`${process.env.REACT_APP_DOMAIN}/${i18n.language}/${process.env.REACT_APP_USER_LISTINGS_API}`}>
                            {t('words.published')}
                        </ToggleButton>
                        <ToggleButton variant="outline-warning" id="tbg-radio-2" value={`${process.env.REACT_APP_DOMAIN}/${i18n.language}/api/me/drafted-articles`}>
                            {t('words.draft')}
                        </ToggleButton>
                        <ToggleButton variant="outline-warning" id="tbg-radio-3" value={`${process.env.REACT_APP_DOMAIN}/${i18n.language}/api/me/archived-articles`}>
                            {t('words.archived')}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </>
            }

        </Nav>

    )
}

export default ButtonNewProfList
