import React from "react"
import { Button, ButtonGroup } from "react-bootstrap";
import { useTranslation } from 'react-i18next'
import { FaMapMarkedAlt } from "react-icons/fa";




function ButtonsMapShow({ open, setOpen }) {

    const { t } = useTranslation();

    return (

        <>
            <span><FaMapMarkedAlt size={18} className="me-2" /></span>
            <ButtonGroup>
                <Button
                    variant='secondary'
                    size="sm"
                    onClick={() => setOpen(0)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    className={open === 0 ? 'd-none' : 'p-0 px-2 me-2 rounded-3'}
                >

                    <span>{open === 2 ? `${t('words.half')} ${t('words.map')}` : `${t('words.show')} ${t('words.map')}`}</span>
                </Button>

                <Button
                    variant='secondary'
                    size="sm"
                    onClick={() => setOpen(1)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    className={open === 1 ? 'd-none' : 'p-0 px-2 me-2 rounded-3'}
                >
                    <span>{t('words.hide')} {t('words.map')}</span>
                </Button>

                <Button
                    variant='secondary d-none d-xl-block'
                    size="sm"
                    onClick={() => setOpen(2)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    className={open === 2 ? 'd-none' : 'p-0 px-2 me-2 rounded-3'}
                >
                    <span>{t('words.fullscreen')}</span>
                </Button>
            </ButtonGroup>
        </>

    )
}

export default ButtonsMapShow

