import React from 'react'
import { Button, Card, Col, Image } from 'react-bootstrap'
import { BellFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next'
import ImageNotFound from '../../assets/notFound.png'


const NoListingsFound = () => {

    const { t } = useTranslation();

    const resetFilters = () => {
        document.getElementById('reset_filters').click()
    }

    const saveSearch = () => {
        document.getElementById('save_search').click()
    }

    return (
        <Col lg={12} className="mt-5">
            <Card className='px-4 py-5 text-center rounded-4 flex-row'>

                <Col ms={4}>
                    <Image fluid src={ImageNotFound}/>
                </Col>
                <Col md={8}>

                    <h2>{t('bigtext.notFoundFilters1')}</h2>
                    <p>{t('bigtext.notFoundFilters2')}</p>
                    <p>{t('bigtext.notFoundFilters3')}</p>

                    <div className="d-grid gap-2 d-sm-block">
                        <Button
                            onClick={resetFilters}
                            variant="outline-danger"
                            type="button"
                            className="border-2 rounded-pill me-2 fw-bold"
                        >
                            {t('words.clear')} {t('words.filters')}
                        </Button>
                        <Button
                            variant="yellow"
                            type="button"
                            className="border-2 rounded-pill me-2 fw-bold text-white"
                            onClick={saveSearch}
                        >
                            <BellFill size={18} className="me-2" />{t('words.save')} {t('words.srch')}/{t('words.alert')}
                        </Button>
                    </div>
                </Col>
            </Card>
        </Col>
    )
}

export default NoListingsFound