import TokenRefresher from "./TokenRefresher";
import { store } from "../Redux/Store.js"

const ArchiveUserListing = async (type, id, setLoading, setStatus) => {
    
    setLoading(true)

    const url = type === "article" ? `${process.env.REACT_APP_ARCHIVE_ARTICLE}${id}` : `${process.env.REACT_APP_USER_SET_ARCHIVE_LISTING}/${type}/${id}`


    const archiveListing = async function () {
        try {


            const bearer_token = `Bearer ${localStorage.getItem('token')}`;

            const res = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Authorization': bearer_token,
                    'Content-Type': 'application/json',

                },
                credentials: 'include'
            })
            if (res.status >= 200 && res.status <= 299) {


                setStatus(200)


            } else {

                setStatus(400)
            }

            setTimeout(() => {
                document.getElementById(`myListings-tab-${store.getState().page.myListingTab}`).click()
            }, 1000)

        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)

        }
    }
    const sessionFetch = new TokenRefresher();
    sessionFetch.setTokenRefresh(archiveListing);
}


export default ArchiveUserListing