
import React from 'react';
import { Image } from 'react-bootstrap'
import UserPhoto from '../../assets/user-svgrepo-com.svg'
import Banner from '../../assets/banner-empty.jpg'


const ProfileHeaderAgent = ({ user }) => {


    return (
        <>
            <div className='position-relative'>

                <Image
                    id="cover_img"
                    src={user.cover_photo_url ? process.env.REACT_APP_DOMAIN + user.cover_photo_url+ '?photo_width=1076' : Banner}
                    height="198px"
                    width="100%"
                    className='bg-warning bg-gradient'
                    style={{ objectFit: 'contain' }}
                />


                <div
                    className="position-absolute mask1 bg-warning"
                    style={{ left: '15.5px', width: "210px", height: "240px", top: "47.5%" }}
                />

                <Image
                    className="position-absolute top-50 mask1"
                    style={{ left: '20px', objectFit: 'cover' }}
                    width="200px"
                    height="230px"
                    id="profile_img"
                    src={user.profile_picture_url ? process.env.REACT_APP_DOMAIN + user.profile_picture_url : UserPhoto}
                />

                <h2
                    className='position-absolute bg-white p-2 text-capitalize fw-bold text-warning'
                    style={{ top: "100%", left: "227px" }}
                >

                    {user.first_name && user.last_name ? user.first_name.toLowerCase() + " " + user.last_name.toLowerCase() :
                        user.blog_display_name ? user.blog_display_name.toLowerCase() : ''
                    }

                </h2>

            </div>

            <div style={{ height: "140px" }} />


        </>
    )
}

export default ProfileHeaderAgent