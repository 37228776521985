import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Button, Col, Image, Row } from 'react-bootstrap';
import houseExample from "../../assets/houseExample.jpg"
import { AiOutlineDoubleRight } from "react-icons/ai"



const BlogCard = ({ listing }) => {

    const { t } = useTranslation();

    let descrip = listing['excerpt'] ? listing['excerpt'] : listing['content'];
    if (descrip && descrip.length > 300) {
        descrip = descrip.substring(0, 300) + " [...]"
    }


    return (
        <Col xl={6} className='p-3'>
            <Link
                to={`/listing/blog/${listing["id"]}/${listing["slug"]}`}
                className="text-decoration-none text-dark "
            >
                <Row className='p-0 bg-white rounded-2 m-2 shadow h-100'>
                    <Col sm={5} className='p-0'>
                        <Image
                            alt="article-photo"
                            height="100%"
                            width="100%"
                            style={{ objectFit: 'cover' }}
                            src={
                                listing.excerpt_photo_url
                                    ? `${process.env.REACT_APP_DOMAIN}${listing['excerpt_photo_url']}?photo_height=600&photo_width=600`
                                    : houseExample
                            }
                        />
                    </Col>
                    <Col sm={7} className='p-4'>
                        <h5><strong>{listing['title']}</strong></h5>
                        <hr />
                        <p dangerouslySetInnerHTML={{ __html: descrip }} />
                        <Button variant="outline-secondary" className='rounded-pill float-end px-3'>
                            {t('text.seeMore')} <AiOutlineDoubleRight className='ms-2' />
                        </Button>

                    </Col>
                </Row>
            </Link>
        </Col>
    )
}

export default BlogCard
