import React from 'react'
import { useTranslation } from "react-i18next";
import countries from "i18n-iso-countries";
import { Badge, Card, Col, Image, Row } from "react-bootstrap"
import { PersonCircle, GeoAltFill } from 'react-bootstrap-icons';
import { FaGlobe } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AgentPage from '../../../pages/AgentPage';
import OverlayListing from "../../profile/listings/OverlayListing";
import ToolCardActions from '../ToolCardActions';
import DateWithHour from '../../../functions/DateWithHour';



const AgendaCard = ({ listing, profile = false, status = null }) => {

    const { t, i18n } = useTranslation();

    let descrip = listing['article']['excerpt'];
    if (descrip && descrip.length > 180) {
        descrip = listing['article']['excerpt'].substring(0, 180) + " [...]"
    }



    return (
        <div className="position-relative card-listing-container">
            <Card className='tool-listing p-3 pt-0'>

                <ToolCardActions
                    listing={listing}
                    listingType="event"
                />

                {new Date(listing['start_datetime']) < new Date()
                    ?
                    <Badge bg='danger' className='small text-end mb-2'>{t('cats_tools.event')} is over</Badge>
                    :
                    <Badge bg='primary' className='small text-end mb-2'>{t('cats_tools.event')}</Badge>
                }

                <h6><strong>
                    {listing['article']['title']}
                    {listing.number
                        && <span className='small fw-bold text-muted'>&nbsp;({listing.number})</span>
                    }
                </strong></h6>

                <Row className='small align-items-center justify-content-between'>
                    <Col>

                        <Link
                            component={<AgentPage />}
                            to={`/agent/${listing['article']['author']['id']}/`}
                            className="text-decoration-none text-dark text-capitalize"
                        >

                            {listing['article']['author']['profile_picture_url']
                                ? <Image roundedCircle
                                    style={{ objectFit: 'cover' }}
                                    width="35px"
                                    height="35px"
                                    src={`${process.env.REACT_APP_DOMAIN + listing['article']['author']['profile_picture_url']}?photo_height=50&photo_width=50`}
                                    className="me-2" />

                                : <PersonCircle className='text-warning me-2' size={33} />
                            }

                            {listing['article']['author']['blog_display_name'].toLowerCase()}
                        </Link>
                    </Col>

                    <Col className='text-primary text-end'>
                        <GeoAltFill />
                        {listing['address'] && listing['address']['city']
                            ? listing['address']['city']

                            : listing['address'] && listing['address']['country']
                                ? countries.getName(listing['address']['country'], i18n.language, { select: "official" })

                                : ''
                        }
                    </Col>


                    <Col xs={12}>

                        <hr className='mt-3' />
                        <a href={listing['website']} className="text-decoration-none pe-4"><FaGlobe className='mb-1' /> {t('words.official')} {t('words.site')}</a>
                        <a href={listing['registration']} className="text-decoration-none text-success"><FaGlobe className='mb-1' /> {t('words.registration')}</a>

                        <Link
                            to={`/listing/event/${listing.id}/${listing.article.slug}`}
                            className="text-decoration-none text-dark"
                        >

                            <hr className='mt-3' />
                            {listing.event_type &&
                                <Col xs={12}>
                                    <strong>{t('words.eventType')}:</strong> {listing['event_type']['name']}
                                </Col>
                            }
                            <Col xs={12} style={{ minHeight: '57px' }}>
                                {descrip}
                            </Col>

                        </Link>
                    </Col>

                </Row>

                <Link
                    to={`/listing/event/${listing.id}/${listing.article.slug}`}
                    className="text-decoration-none text-dark"
                >
                    <Row className='justify-content-around pb-2 text-capitalize text-center'>
                        <Col xs={5}>
                            From
                        </Col>
                        <Col xs={5}>
                            To
                        </Col>
                        <Col xs={5} className="bg-graylight text-black rounded-3">
                            <DateWithHour dateRaw={listing['start_datetime']} />
                        </Col>
                        <Col xs={5} className="bg-graylight text-black rounded-3">
                            <DateWithHour dateRaw={listing['end_datetime']} />
                        </Col>
                    </Row>
                </Link>

            </Card >
            {
                profile ?
                    <OverlayListing
                        title={listing.article.title}
                        type={"event"}
                        link={`/listing/event/${listing.id}/${listing.article.slug}/`}
                        id={listing.id}
                        edit={`/edit/event/${listing.id}`}
                        status={status}
                        articleId={listing.article.id}
                    />
                    : null
            }
        </div>
    )
}

export default AgendaCard
