import React from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AgendaDropdown from '../toolBox/agenda/AgendaDropdown';
import CrmDropdown from '../toolBox/crm/CrmDropdown';
import JobDropdown from '../toolBox/jobs/JobDropdown';
import FilesUpBtn from './FilesUpBtn';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";



const OverviewToolsCE = ({ type, getData, getDataDrops, setDocumentsUri, listing }) => {

    const { t, i18n } = useTranslation();

    const [startDate, setStartDate] = React.useState(
        listing
            ? new Date(listing.start_datetime)
            : setHours(setMinutes(new Date(), 30), 0, 0)
    );

    const [endDate, setEndDate] = React.useState(
        listing
            ? new Date(listing.end_datetime)
            : setHours(setMinutes(new Date(), 30), 0, 0)
    );

    const [eventAllDay, setEventAllDay] = React.useState(
        listing
            ? listing.all_day
            : false
    );


    React.useEffect(() => {

        if (startDate > endDate) {
            setEndDate(startDate)
            alert(t('bigtext.dateWrong'))
        }
        // eslint-disable-next-line
    }, [endDate]);


    return (

        <>
            <Col sm={12} className="text-white bg-warning mt-4 px-4 pt-3 pb-2">
                <h6>{t('title.overview')}</h6>
            </Col>

            <Col lg={12} className="bg-white shadow-sm p-4">

                <Row className="gy-3">

                    <Form.Group as={Col} md="12" controlId="lisTitle">
                        <Form.Label>{t('title.title')}*</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            defaultValue={listing && listing.article.title}
                            maxLength="60"
                            name="title"
                        />
                    </Form.Group>

                    {type === 'article' &&
                        <>
                            <Col md={6}>
                                <Form.Label>{t('words.lang')}</Form.Label>
                                <Form.Select
                                    id="locale"
                                    defaultValue={(listing && listing.locale) ? listing.locale : i18n.language}
                                    name="locale"
                                >
                                    <option value="fr">FR</option>
                                    <option value="en">EN</option>
                                    <option value="nl">NL</option>
                                    <option value="el">EL</option>

                                </Form.Select>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="video_link">
                                    <Form.Label>{t('title.vLink')} (YouTube)</Form.Label>
                                    <Form.Control
                                        type="text"
                                        defaultValue={listing && listing.video_link}
                                        name="video_link"
                                    />
                                </Form.Group>
                            </Col>
                        </>
                    }

                    {type === 'crm' &&
                        <>
                            <CrmDropdown create={!listing && true} listing={listing} col={4} getDataDrops={getDataDrops} />

                            <Form.Group as={Col} md={4} controlId="lisAuthor">
                                <Form.Label>{t('words.author')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    maxLength="20"
                                    defaultValue={listing && listing.auteur}
                                    name="auteur"
                                    onChange={getData}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={4} controlId="lisEdition">
                                <Form.Label>{t('words.edition')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    maxLength="10"
                                    defaultValue={listing && listing.edition}
                                    name="edition"
                                    onChange={getData}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md={4} controlId="lisPrice">
                                <Form.Label>{t('words.price')}</Form.Label>
                                <Form.Control
                                    type="number"
                                    defaultValue={listing && listing.price}
                                    name="price"
                                    onChange={getData}
                                />
                            </Form.Group>

                            <Col md={4}>
                                <Form.Label>{t('words.price')} {t('words.currency')}</Form.Label>
                                <Form.Select
                                    id="price_currency"
                                    defaultValue={listing && listing.price_currency}
                                    name="price_currency"
                                    onChange={getData}
                                >
                                    <option></option>
                                    <option value="USD">$ (USD)</option>
                                    <option value="EUR">€ (EUR)</option>
                                    <option value="GBP">£ (GBP)</option>

                                </Form.Select>
                            </Col>

                            <Form.Group as={Col} controlId="lisLink">
                                <Form.Label>{t('words.link')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    defaultValue={listing && listing.link}
                                    name="link"
                                    onChange={getData}
                                />
                            </Form.Group>
                        </>
                    }

                    {type === 'job' &&
                        <>
                            <JobDropdown create={!listing && true} listing={listing} col={4} getDataDrops={getDataDrops} />

                            <FilesUpBtn listing={listing} setDocumentsUri={setDocumentsUri} />

                            <Form.Group as={Col} md={4} controlId="lisVideo">
                                <Form.Label>{t('title.vLink')} (YouTube)</Form.Label>
                                <Form.Control
                                    type="text"
                                    defaultValue={listing && listing.video_link}
                                    name="video_link"
                                    onChange={getData}
                                />
                            </Form.Group>
                        </>
                    }

                    {type === 'event' &&
                        <>
                            <AgendaDropdown create={!listing && true} listing={listing} col={4} getDataDrops={getDataDrops} />

                            <Form.Group as={Col} md="4" controlId="lisTitle">
                                <Form.Label>{t('words.registration')} *</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    defaultValue={listing && listing.registration}
                                    maxLength="60"
                                    name="registration"
                                    onChange={getData}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md="4" controlId="lisTitle">
                                <Form.Label>{t('text.eventWeb')} *</Form.Label>
                                <Form.Control
                                    type="text"
                                    defaultValue={listing && listing.website}
                                    maxLength="60"
                                    name="website"
                                    onChange={getData}
                                />
                            </Form.Group>
                            <Col md={4}>
                                <Form.Label>{t('words.startDate')}*</Form.Label>
                                <DatePicker
                                    required
                                    selected={startDate}
                                    onChange={(date) => { setStartDate(new Date(date)); setEndDate(new Date(date)); }}
                                    id="start_datetime"
                                    withPortal
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="time"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                />
                            </Col>
                            <Col md={4}>
                                <Form.Label className='d-inline-flex'>
                                    {t('words.endDate')}
                                    <Form.Check
                                        type="switch"
                                        className='ms-2 mb-0'
                                        id="all_day_event"
                                        label={`${t('words.allDay')} ${t('cats_tools.event')}`}
                                        checked={eventAllDay}
                                        onChange={() => setEventAllDay(!eventAllDay)}
                                    />
                                </Form.Label>
                                <DatePicker
                                    className={eventAllDay ? 'd-none' : ''}
                                    selected={endDate}
                                    onChange={(date) => setEndDate(new Date(date))}
                                    id="end_datetime"
                                    withPortal
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="time"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    minDate={startDate}
                                />
                            </Col>

                        </>
                    }

                </Row>

            </Col>

        </>

    )
}

export default OverviewToolsCE