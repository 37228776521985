import { createSlice } from "@reduxjs/toolkit";


export const savedSearchesAlerts = createSlice({
    name: "searchAndAlert",
    initialState: {
        saved_searches_alerts: [],
        saved_searches_dropdown: [],
    },
    reducers: {
        setSavedSearches: (state, action) => {
            state.saved_searches_alerts = action.payload
        },
        setSavedSearchesDrop: (state, action) => {
            state.saved_searches_dropdown = action.payload
        },
        addSavedSearchRedux: (state, action) => {
            state.saved_searches_alerts.push(action.payload)
        },
        addSavedDropRedux: (state, action) => {
            state.saved_searches_dropdown.push(action.payload)
        },
        deleteSavedSearchRedux: (state, action) => {
            if (state.saved_searches_alerts.some(obj => obj.id === action.payload.id)) {
                state.saved_searches_alerts = state.saved_searches_alerts.filter(obj => obj.id !== action.payload.id);
            }
            if (state.saved_searches_dropdown.some(obj => obj.value === action.payload.id)) {
                state.saved_searches_dropdown = state.saved_searches_dropdown.filter(obj => obj.value !== action.payload.id);
            }
        },
        changeFrequencyRedux: (state, action) => {
            const index = state.saved_searches_alerts.findIndex(obj => obj.id === action.payload.id);
            state.saved_searches_alerts[index].alert_frequency = action.payload.value;
        }
    }
})

export const { setSavedSearches, setSavedSearchesDrop, deleteSavedSearchRedux, changeFrequencyRedux, addSavedSearchRedux, addSavedDropRedux } = savedSearchesAlerts.actions
export default savedSearchesAlerts.reducer