import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Button, Spinner, Alert, CloseButton } from 'react-bootstrap';
import RegisterDropJob from './RegisterDropJob';
import DropdownProfession from './DropdownProfession';
import DropdownCountry from './DropdownCountry';
import RegisterDropJobConstruction from './RegisterDropJobConstruction';
import RegisterFunctions from './RegisterFunctions';
import PasswordField from './PasswordField';
import { EyeFill, EyeSlash } from 'react-bootstrap-icons';
import ClearLocalConditionally from '../../functions/ClearLocalConditionally';
//redux
import { setReduxUser } from '../../Redux/user-slice';
import { setFeedTrue } from '../../Redux/news-feed-slice';
import { useDispatch, useSelector } from 'react-redux'
import { setGlobalLoader } from '../../Redux/page-slice';


const RegisterFormNew = () => {

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch()
    const testEnv = useSelector(state => state.page.testEnv)
    
    //GET Professions
    const [proffesions, setData] = React.useState(null);
    const url = `${process.env.REACT_APP_DOMAIN}/${i18n.language}/${process.env.REACT_APP_PROFDROP_API}`;

    React.useEffect(() => {
        fetch(url)
            .then(res => res.json())
            .then(data => {
                const mydata = JSON.stringify(data);
                setData(mydata);
            })
            .catch(err => console.error(err))


    }, [url]);

    let profe = JSON.parse(proffesions);

    //Jobs Dropdowns
    let [fieldJob, setFieldJob] = React.useState('');
    function getProfession(e) {
        setFieldJob(e.currentTarget.value);
        setConstrJob('');
        handleChangeProf(e);
    };


    //third dropdown for construction
    let [constrJob, setConstrJob] = React.useState('');
    function getConstruction(e) { setConstrJob(e.currentTarget.value); handleChangeJobs(e); };


    //Newsletter Checkbox state
    const [checkmail, setCheckbox] = React.useState(false);
    function toggleCheck(e) {
        setCheckbox(!checkmail);
        updateFormData({
            ...formData,
            [e.target.name]: e.target.checked
        });
    };

    //Loading Spinner
    const [loading, setloading] = React.useState(false);
    //If error show alert
    const [showErr, setShowErr] = React.useState(false);

    //Password strength
    let [strength, setStrength] = React.useState(0);

    //toggle pass
    const [eyePass, setEyePass] = React.useState(false);
    const showPass = () => setEyePass(!eyePass);


    /****************Native validation*******************/

    const [validated, setValidated] = React.useState(false);

    const handleSubmitValidation = (event) => {

        const form = event.currentTarget;

        //Validation for empty fields
        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
        }

        //Confirm Password
        const plain_password = document.getElementById("plain_password")
            , passwordConfirm = document.getElementById("passwordConfirm");

        if (strength < 4) {
            plain_password.setCustomValidity('Wrong');
        }
        else {

            plain_password.setCustomValidity('');

            function validatePassword() {
                if (plain_password.value !== passwordConfirm.value) {
                    passwordConfirm.setCustomValidity('Wrong');
                    event.preventDefault();
                    event.stopPropagation();

                } else {
                    passwordConfirm.setCustomValidity('');
                    //setValidated(true);
                }
            }
            plain_password.onchange = validatePassword;
            passwordConfirm.onkeyup = validatePassword;

        }

        setValidated(true);

        if (form.checkValidity() === true) {
            handleSubmit(event);
        }

    };


    /***************Submit with api******************/
    //Prepare json
    const regfunc = new RegisterFunctions();
    let initialFormData = regfunc.initialFormData();


    const [formData, updateFormData] = React.useState(initialFormData);

    //Fields Value
    const handleChange = (e) => {
        regfunc.handleChangeVal(e, updateFormData, formData);
    };


    //Fields Value only for Proffession
    const handleChangeProf = (e) => {
        regfunc.handleChangeProfVal(e, updateFormData, formData);
    };

    //Fields Value only for Jobs
    const handleChangeJobs = (e) => {
        regfunc.handleChangeJobsVal(e, updateFormData, formData);
    };

    //Fields Value only for Construction Jobs
    const handleChangeJobsConstr = (e) => {
        regfunc.handleChangeJobsConstrVal(e, updateFormData, formData);
    };


    //Fetch

    // loops the array and fills the redux object
    function setCustom(arr) {
        for (let i = 0; i < arr.length; i++) {
            dispatch(setFeedTrue(arr[i]))
        }
    }

    //Error for Fetch
    function handleSubmitError(res) {
        if (res.status >= 200 && res.status <= 299) {
            return res.json();
        } else {
            setloading(false);
            setValidated(false);
            if (res.status === 400) { setShowErr(true); window.scrollTo(0, 0); }
            throw Error(res.statusText);
        }
    }


    //Register Fetch
    let handleSubmit = async (e) => {

        setloading(true);
        e.preventDefault();

        ClearLocalConditionally();

        fetch(process.env.REACT_APP_REGISTER_API, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),

        })
            .then(handleSubmitError)
            .then(res => {
                localStorage.setItem('token', res.token);
                if (testEnv) {
                    localStorage.setItem('refresh_token', res.refresh_token);
                }

                setloading(false)
                getUser();
            })
            .catch(err => {
                console.log(err);
                setloading(false)
            })

    };


    let getUser = async () => {

        setloading(false);
        dispatch(setGlobalLoader(true))
        const bearer_token = `Bearer ${localStorage.getItem('token')}`;

        fetch(process.env.REACT_APP_USERINFO_API, {
            method: 'GET',
            headers: {
                'Authorization': bearer_token,
            },
            credentials: 'include'
        })
            .then(res => res.json())
            .then(data => {

                localStorage.setItem('logged', true);
                dispatch(setReduxUser(data));
                setCustom(data.newsfeed_prefs);
                dispatch(setGlobalLoader(false))
            })

            .catch(err => {
                console.error(err)
                dispatch(setGlobalLoader(false))
            })
    }




    return (

        <Form noValidate validated={validated} onSubmit={handleSubmitValidation} id="registerForm" className='text-start'>

            {showErr ?
                <Alert variant="danger" onClose={() => setShowErr(false)}>
                    <div className="d-flex justify-content-end">
                        <CloseButton onClick={() => setShowErr(false)} />
                    </div>
                    <p>
                        {t('bigtext.failreg')}
                    </p>
                </Alert>

                : ''
            }

            <p className='h4 fw-bold text-secondary'>{t('words.registration')} {t('words.form')}</p>
            <hr className='w-25 text-secondary pt-1 opacity-100' />

            <Form.Group className="col-lg-12 mb-2">
                <Form.Label>{t('title.fname')}</Form.Label>
                <Form.Control
                    required
                    name="first_name"
                    type="text"
                    onChange={handleChange} />
            </Form.Group>

            <Form.Group className="col-lg-12 mb-2">
                <Form.Label>{t('title.lname')}</Form.Label>
                <Form.Control
                    required
                    name="last_name"
                    type="text"
                    onChange={handleChange} />
            </Form.Group>

            <Form.Group className="col-lg-12 mb-2">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    required
                    name="email"
                    type="email"
                    autoComplete="username"
                    onChange={handleChange} />
            </Form.Group>

            <PasswordField strength={strength} setStrength={setStrength} handleChange={handleChange} nameField="plain_password" />

            <Form.Group className="col-lg-12 mb-2">
                <Form.Label>{t('title.confpass')}</Form.Label>
                <div className="position-relative" >
                    <Form.Control
                        required
                        id="passwordConfirm"
                        name="passwordConfirm"
                        type={!eyePass ? "password" : "text"}
                        autoComplete="new-password"
                    // onChange={handleChange} 
                    />

                    {!eyePass
                        ? <EyeFill onClick={showPass} size={20} className='text-warning position-absolute top-0 end-0 me-2 mt-2' />
                        : <EyeSlash onClick={showPass} size={20} className='text-warning position-absolute top-0 end-0 me-2 mt-2' />
                    }
                </div>

                <Form.Control.Feedback className="fw-bold" type="invalid">{t('text.incPass')}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="col-lg-12 mb-2">
                <Form.Label>{t('title.wtcountry')}</Form.Label>
                <DropdownCountry handleChacha={handleChange} />
            </Form.Group>

            {profe
                ?
                <Form.Group className="col-lg-12 mb-2">
                    <Form.Label>{t('text.wtpro')}</Form.Label>
                    <DropdownProfession getProf={getProfession} profeList={profe} />
                </Form.Group>
                : ''
            }


            {fieldJob === '120' || fieldJob === '222' || fieldJob === '' ? ''
                : <RegisterDropJob handleChange={getConstruction} job={fieldJob} profeList={profe} />
            }

            {constrJob === '149' || constrJob === '150' || constrJob === '151'
                ? <RegisterDropJobConstruction handleChange={handleChangeJobsConstr} job={constrJob} profeList={profe} />
                : ''
            }

            <Form.Group className="col-lg-12 mb-2">
                <Form.Label>{t('text.wrhear')}</Form.Label>
                <Form.Select name="referer" defaultValue="" required onChange={handleChange} className="form-control">
                    <option></option>
                    <option value="facebook">Facebook</option>
                    <option value="google">Google</option>
                    <option value="linkedin">Linkedin</option>
                    <option value="mouth">{t('text.mouth')}</option>
                </Form.Select>
            </Form.Group>


            <div className="form-check small text-muted">
                <input required className="form-check-input" type="checkbox" value="" id="agreeTermAndConditions" />
                <label className="form-check-label" htmlFor="agreeTermAndConditions">
                    {t('bigtext.reg1')} <a href="https://betweenpros.com/Legal/cgu_en.pdf" target="#0">{t('text.terms')}</a>, {t('words.and')} <a href="https://betweenpros.com/Legal/privacy_en.pdf" target="#0">{t('text.policy')}</a> {t('bigtext.reg2')}
                </label>
            </div>

            <Form.Group className="small text-muted mb-4" controlId="formBasicCheckbox">
                <Form.Check
                    type="checkbox"
                    name="receive_mail_notifications"
                    checked={checkmail}
                    onChange={toggleCheck}
                    label={t('bigtext.reg3')} />
            </Form.Group>


            <Col md={12}>
                <Button variant="warning" className='text-center text-white w-100' type="submit" disabled={loading ? true : false}>

                    {loading ?
                        <>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            {t('title.loading')}
                        </>

                        : t('title.createacc')
                    }

                </Button>
            </Col>

        </Form>

    )
}

export default RegisterFormNew