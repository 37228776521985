import React from 'react'
import { CardList, Briefcase, Building, Envelope, Facebook, Globe2, Linkedin, Person, Phone, PhoneVibrate, Twitter, ThreeDots } from 'react-bootstrap-icons';
import { Col, Row, Card, Button, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import ReportUserOrList from '../ReportUserOrList';
import SimpleFetchNet from '../../jwtAPI/SimpleFetchNet';
import { IoPersonAdd } from 'react-icons/io5';
import ToastTimer from '../ToastTimer';



const ProfileAgent = ({ user }) => {

    const { t } = useTranslation();

    const [status, setStatus] = React.useState(null);
    //Hide email from bots
    const [showingEmail, setShowingEmail] = React.useState(false);

    let userProf1 = [];
    let userProf2 = [];

    function userProffesion() {
        if (user.user_types && user.user_types[0] && user.user_types[0] !== 0) {
            // array empty or does not exist

            userProf1 = Object.keys(user.user_types[0]);

            for (let i = 0; i < userProf1.length; i++) {
                if (userProf1[i] !== 'id') {
                    userProf2.push(
                        <span key={i} className='ms-1 me-2 text-nowrap text-muted'>&bull; {user.user_types[0][userProf1[i]].name}</span>
                    )
                }
            }
        }
    }


    let userAttributes, userAttrObject;

    function filterOutEmptyAttributes() {
        userAttributes = user.attributes.filter(att => att.value !== undefined)
        //create object with the names as keys from filtered array
        userAttrObject = userAttributes.reduce((obj, att) => ({ ...obj, [att.definition.name]: att.value, }), {})
    }

    filterOutEmptyAttributes();
    userProffesion();

    function addUserNet() {
        const url = `${process.env.REACT_APP_DOMAIN}/en/api/friend-request/${user.id}`;
        const userCircle = new SimpleFetchNet();
        userCircle.fetchListings(url, 'GET', setStatus);
    }

    function blockUser() {
        if (window.confirm(t('bigtext.blockUser'))) {
            const url = `${process.env.REACT_APP_DOMAIN}/en/api/block-user/${user.id}`;
            const userCircle = new SimpleFetchNet();
            userCircle.fetchListings(url);
        }
    }



    return (

        <Card as={Row} border="white" className="flex-row p-4 rounded-4 mx-lg-4">

            <Col lg={12} className="d-inline-flex justify-content-end">

                {status !== null
                    ?
                    <ToastTimer color={status === 'success' ? 'success' : 'danger'} text={status === 'success' ? t('title.success') : status} time={6000} />

                    :
                    <Button variant="success" className="rounded-0 rounded-start" onClick={addUserNet}>
                        <IoPersonAdd size={20} />&nbsp;&nbsp;{t('title.addyour')}&nbsp;{t('title.net')}
                    </Button>
                }
                <Dropdown>
                    <Dropdown.Toggle variant="outline-success rounded-0 rounded-end" className='border-3 p-1'>
                        <ThreeDots size={20} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={blockUser}>
                            {t('words.block')}&nbsp;{t('title.userSin')}
                        </Dropdown.Item>
                        <ReportUserOrList theName={user['blog_display_name']} theID={user.id} />
                    </Dropdown.Menu>
                </Dropdown>
            </Col>

            <Col lg={5} className="mt-4">

                <div className="d-inline-flex bg-white shadow-sm">
                    {userAttrObject.facebook_url && <a href={userAttrObject.facebook_url} className="p-2" target={"blank"}>
                        <Facebook size={22} className="text-info" />
                    </a >}
                    {userAttrObject.linkedin_url && <a href={userAttrObject.linkedin_url} className="p-2" target={"blank"}>
                        <Linkedin size={22} className="text-info" />
                    </a>}
                    {userAttrObject.twitter_url && <a href={userAttrObject.twitter_url} className="p-2" target={"blank"}>
                        <Twitter size={22} className="text-info" />
                    </a>}

                </div>

                <ul className="list-agent-info" >
                    {userAttrObject.title
                        && <li><Person />&nbsp;&nbsp;{t('title.title')}: {userAttrObject.title.toLowerCase()}</li>
                    }
                    {userProf2.length > 0
                        && <li style={{ overflow: "auto" }}><span className='text-nowrap'><Briefcase />&nbsp;&nbsp;{t('title.protyp')}</span>:{userProf2}</li>
                    }
                    {userAttrObject.enterprise
                        && <li><Building />&nbsp;&nbsp;{t('title.compa')}: {userAttrObject.enterprise.toLowerCase()}</li>
                    }
                    {userAttrObject.professional_phone
                        &&
                        <li>
                            <a href={`tel:${userAttrObject.professional_phone}`} >
                                <Phone />&nbsp;&nbsp;{t('title.professional')} {t('title.phone')}: {userAttrObject.professional_phone}
                            </a>
                        </li>
                    }
                    {userAttrObject.mobile
                        &&
                        <li>
                            <a href={`tel:${userAttrObject.mobile}`} >
                                <PhoneVibrate />&nbsp;&nbsp;{t('title.mobile')}: {userAttrObject.mobile}
                            </a>
                        </li>
                    }
                    {user.email
                        &&
                        <li>
                            <Envelope />&nbsp;&nbsp;Email:&nbsp;
                            {showingEmail
                                ?
                                <a href={`mailto:${user.email}`} className="text-decoration-none" >
                                    {user.email}
                                </a>

                                :
                                <Button size="sm" variant="outline-darkGray" onClick={() => setShowingEmail(true)}>
                                    {t('text.showEmail')}
                                </Button>
                            }
                        </li>
                    }
                    {userAttrObject.website
                        &&
                        <li>
                            <a target="_blank" rel="noreferrer" href={userAttrObject.website} style={{ textTransform: 'initial' }} >
                                <Globe2 />&nbsp;&nbsp;Website: {userAttrObject.website}
                            </a>
                        </li>
                    }
                </ul>

                {((user.address?.city && user.address.city !== '') || user.location_services?.length > 0) &&
                    <>
                        <p className="mt-5 fs-5 fw-bold">{t('title.loc')} {t('words.and')} {t('title.sareas')}</p>
                        <div className="tags-pill">
                            {(user.address?.city) && <a href="#0" rel="tag">{user.address.city}</a>}
                            {user.location_services?.length > 0 && <>
                                {user.location_services.map((add, index) => (
                                    <a key={index} href="#0" rel="tag">{add.city}</a>

                                ))}
                            </>}
                        </div>
                    </>
                }

            </Col>

            <Col lg={7} className="mt-4">

                {(userAttrObject.description || user.cv_url) &&
                    <>
                        <p className="fs-5 fw-bold">{t('title.abtme')}</p>
                        <p className="text-muted">{userAttrObject.description}</p>
                        {user.cv_url &&
                            <Button
                                href={`${process.env.REACT_APP_USERINFO2_API}/${user.id}/cv`}
                                target="_blank"
                                rel="noreferrer"
                                variant="info"
                                className='rounded-4 fw-bold'
                            >
                                <CardList size={25} className="mb-1" /> {t('text.seeRes')}
                            </Button>
                        }
                    </>
                }

            </Col>

        </Card>
    )
}

export default ProfileAgent
