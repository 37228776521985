import React from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import RentCatDropdown from '../listings/rent/RentCatDropdown';
import PartnershipDropdown from '../listings/reqPar/PartnershipDropdown';
import RequestDropdown from '../listings/reqPar/RequestDropdown';
import SaleCatDropdown from '../listings/sale/SaleCatDropdown';
import ComparisonDropdown from '../toolBox/comparison/ComparisonDropdown';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FilesUpBtn from './FilesUpBtn';



const OverviewCE = ({ type, getData, getDataDrops, setDocumentsUri, listing }) => {

    const { t } = useTranslation();

    const [startDate, setStartDate] = React.useState(
        listing
            ? new Date(listing.transaction_date)
            : new Date()
    );


    const max6numbers = (e) => {
        if(e.target.value.length > 6) {
            e.target.value = e.target.value.slice(0, 6)
        }
    }

    return (

        <>
            <Col sm={12} className="text-white bg-warning mt-4 px-4 pt-3 pb-2">
                <h6>{t('title.overview')}</h6>
            </Col>

            <Col lg={12} className="bg-white shadow-sm p-4">

                <Row className="gy-3">

                    <Form.Group as={Col} md="12" controlId="lisTitle">
                        <Form.Label>{t('title.title')}*</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            defaultValue={listing && listing.article.title}
                            maxLength="60"
                            name="title"
                        />
                    </Form.Group>


                    {type === 'benchmark'
                        &&
                        <>
                            <ComparisonDropdown col={4} create={!listing && true} listing={listing} getDataDrops={getDataDrops} />

                            <Col md={4}>
                                <Form.Label>{t('title.transa')} {t('title.date')} </Form.Label>
                                <DatePicker
                                    selected={startDate}
                                    onSelect={(date) => setStartDate(new Date(date))}
                                    id="transaction_date"
                                />
                            </Col>
                        </>
                    }

                    {type === 'sale'
                        &&
                        <Col md={4}>
                            <SaleCatDropdown create={!listing && true} listing={listing} getDataDrops={getDataDrops} />
                        </Col>
                    }

                    {type === 'rent'
                        && <RentCatDropdown create={!listing && true} listing={listing} col={4} getDataDrops={getDataDrops} />
                    }

                    {type === 'request'
                        && <RequestDropdown create={!listing && true} listing={listing} col={4} getDataDrops={getDataDrops} />
                    }

                    {type === 'partnership'
                        && <PartnershipDropdown create={!listing && true} listing={listing} col={4} getDataDrops={getDataDrops} getData={getData} />
                    }

                    {(type === 'sale') &&
                        <Form.Group as={Col} md={4} controlId="lisPrice">
                            <Form.Label>{t('words.price')} &euro; </Form.Label>
                            <Form.Control
                                required
                                type="number"
                                defaultValue={listing && listing.price}
                                name="price"
                                onChange={getData}
                            />
                        </Form.Group>
                    }

                    {(type === 'rent' || type === 'benchmark') && //not required price
                        <Form.Group as={Col} md={2} controlId="lisPrice">
                            <Form.Label>{t('words.price')} &euro;</Form.Label>
                            <Form.Control
                                type="number"
                                defaultValue={listing && listing.price}
                                name="price"
                                onChange={getData}
                            />
                        </Form.Group>
                    }

                    {type === 'rent' &&
                        <Col md={2}>
                            <Form.Label>{t('words.per')}</Form.Label>
                            <Form.Select
                                id="lease_per"
                                defaultValue={listing && listing.lease_per !== '' ? listing.lease_per : "month"}
                                name="lease_per"
                                onChange={getData}
                            >
                                <option></option>
                                <option value="hour">{t('words.per')} {t('words.hour')}</option>
                                <option value="day">{t('words.per')} {t('words.day')}</option>
                                <option value="week">{t('words.per')} {t('words.week')}</option>
                                <option value="month">{t('words.per')} {t('words.month')}</option>
                                <option value="year">{t('words.per')} {t('words.year')}</option>
                                <option value="other">{t('words.other')}</option>

                            </Form.Select>
                        </Col>
                    }

                    {type !== 'benchmark' &&
                        <Form.Group as={Col} md="4" controlId="lisM2">
                            <Form.Label>{t('text.area_m2')}</Form.Label>
                            <Form.Control
                                type="number"
                                defaultValue={listing && listing.area ? listing.area : ""}
                                name="area"
                                onChange={getData}
                                onKeyUp={max6numbers}
                            />
                        </Form.Group>
                    }

                    {(type === 'rent' || type === 'sale') &&
                        <Form.Group as={Col} md="4" controlId="lisVideo">
                            <Form.Label>{t('title.vLink')} (YouTube) </Form.Label>
                            <Form.Control
                                type="text"
                                defaultValue={(listing && listing.video_link) && listing.video_link}
                                name="video_link"
                                onChange={getData}
                            />
                        </Form.Group>
                    }

                    <FilesUpBtn listing={listing} setDocumentsUri={setDocumentsUri} />


                    {(type === 'sale' || type === 'rent' || type === 'benchmark')
                        &&
                        <Col xs={12} className="mt-4">
                            <Form.Check
                                type="switch"
                                id="offer_price"
                                //defaultValue={listing && listing.negotiable_price ? listing.negotiable_price : true}
                                defaultChecked={listing && listing.negotiable_price === false ? false : true}
                                label={t('bigtext.makeOffer')}
                            />
                        </Col>
                    }

                </Row>

            </Col>
        </>
    )
}

export default OverviewCE