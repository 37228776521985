import React from 'react';
import { Button, Card, Row, Col, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DateWithHour from '../functions/DateWithHour';

import FetchGetSetSimple from '../jwtAPI/FetchGetSetSimple';

import Footer from '../components/Footer';
//import AgentContact from '../components/singleListing/AgentContact';
import Address from '../components/singleListing/Address';
import ButtonEditLis from '../components/singleListing/ButtonEditLis';
import SingleListHero from '../components/singleListing/SingleListHero';
import FetchEventFollow from '../jwtAPI/FetchEventFollow';
import OverviewTitleShare from '../components/singleListing/OverviewTitleShare';

// redux 
import { useSelector } from 'react-redux'
import AgentContact from '../components/singleListing/AgentContact';
import NeedLoginPage from './NeedLoginPage';
import LoadingData from '../components/createEdit/LoadingData';
import DateOnly from '../functions/DateOnly';


const AgendaPage = () => {

    const { t, i18n } = useTranslation();

    const luser = useSelector(state => state.user)

    const [logged] = React.useState(localStorage.getItem('logged'));
    const { id } = useParams();
    let url = `${process.env.REACT_APP_DOMAIN}/${i18n.language}/api/listing/event/${id}`

    //let url = `${process.env.REACT_APP_SINGLE_EVENT_API}/${id}`;


    const [listing, setter] = React.useState();
    const [hasJoined, setHasJoined] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {

        const fetchLists = new FetchGetSetSimple();
        fetchLists.fetchListings(url, setter);

        // eslint-disable-next-line
    }, [id, url]);

    React.useEffect(() => {

        if (listing && listing.participants) {
            let hasJoinEvent = listing.participants.filter(user => user.participant.id === luser.id);
            if (hasJoinEvent.length === 1) {
                setHasJoined(true);
            }
        }

        // eslint-disable-next-line
    }, [listing]);


    const fetchLists = new FetchEventFollow();

    function joinEvent() {
        setLoading(true);
        const url = `${process.env.REACT_APP_SINGLE_EVENT_API}/${id}/follow`
        fetchLists.fetchListings(url, 'PATCH', setLoading, setHasJoined);
    }

    function removeEvent() {
        setLoading(true);
        const url = `${process.env.REACT_APP_SINGLE_EVENT_API}/${id}/unfollow`
        fetchLists.fetchListings(url, 'DELETE', setLoading, setHasJoined);
    }




    return (
        <>
            {(listing && logged) ?

                <>

                    <Container fluid="xxl">
                        <SingleListHero excerptPhoto={listing.article.excerpt_photo_url} gallery={listing.article.photos_urls} />
                    </Container>


                    <Container fluid="xl">

                        <Row className="justify-content-between mt-5">

                            <Col xl={8} lg={12}>

                                <ButtonEditLis idAuthor={listing.article.author.id} type="event" idLis={id} />

                                <Row className='align-items-end'>
                                    <Col sm={9}>
                                        <Button variant='warning' size="sm" className='w-auto px-2 py-0'>{t('cats_tools.event')}</Button>

                                        <h1 className='fw-bold mt-2'>{listing.article.title}</h1>
                                        {listing.number
                                            && <p className='fs-6 fw-bold'>ID: ({listing.number})</p>
                                        }

                                        <p><strong>{t('words.pubDate')}: </strong> <DateOnly dateRaw={listing.article.published_at} /></p>

                                        {listing.article.excerpt ? <h5 className='text-muted'>{listing.article.excerpt}</h5> : ''}

                                    </Col>
                                    <Col sm={3}>
                                        <Button
                                            variant={hasJoined ? "light shadow border-danger" : "success"}
                                            size="lg"
                                            disabled={loading}
                                            className="bg-gradient rounded-0 w-100 mb-1"
                                            onClick={hasJoined ? removeEvent : joinEvent}
                                        >
                                            {hasJoined ? t('words.unfollow') : t('words.follow')}
                                            <br />
                                            {t('cats_tools.event')}
                                        </Button>
                                    </Col>
                                </Row>

                                <Address address={listing.address} />

                                <Card className='p-4 border-0 shadow-sm mt-5'>

                                    <OverviewTitleShare
                                        link={window.location.href}
                                        title={listing.article.title}
                                        isFav={listing.article.favorited_by}
                                        lisId={listing.article.id}
                                        listingType='event'
                                    />

                                    <Row className='row-overview long'>

                                        {listing.event_type
                                            &&
                                            <Col>
                                                <span className='text-warning fs-6'>{t('words.eventType')}: </span>
                                                <br />
                                                {listing.event_type.name}
                                            </Col>
                                        }
                                        {listing.website
                                            &&
                                            <Col>
                                                <span className='text-warning fs-6'>{t('words.official')} {t('words.site')}: </span>
                                                <br />
                                                <a href={listing.website}>{listing.website}</a>
                                            </Col>
                                        }
                                        {listing.registration
                                            &&
                                            <Col>
                                                <span className='text-warning fs-6'>{t('words.registration')}: </span>
                                                <br />
                                                <a href={listing.registration}>{listing.registration}</a>
                                            </Col>
                                        }

                                        <Col sm={6}>
                                            <span className='text-warning fs-6'>
                                                {listing['start_datetime'] !== listing['end_datetime'] ? t('words.start') : t('title.date')}:
                                            </span>
                                            <br />
                                            <DateWithHour dateRaw={listing['start_datetime']} />
                                        </Col>

                                        {listing['start_datetime'] !== listing['end_datetime'] &&
                                            <Col sm={6}>
                                                <span className='text-warning fs-6'>{t('words.end')}: </span>
                                                <br />
                                                <DateWithHour dateRaw={listing['end_datetime']} />
                                            </Col>
                                        }

                                    </Row>

                                    {listing.article.content
                                        ?
                                        <>
                                            <p className='mt-4 text-warning fs-5'>{t('words.desc')}</p>
                                            <p dangerouslySetInnerHTML={{ __html: listing.article.content }} />
                                        </>
                                        : ''
                                    }


                                </Card>

                            </Col>

                            <Col xl={3} lg={12}>

                                <AgentContact agent={listing.article.author} />

                            </Col>

                        </Row>

                    </Container>

                    <Footer />

                </>

                : !logged
                    ? <NeedLoginPage />

                    : <LoadingData />
            }
        </>
    )
}

export default AgendaPage
