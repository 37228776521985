import { useTranslation } from 'react-i18next'
import { Card } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { FaRegSave } from 'react-icons/fa';
import { useSelector } from "react-redux"


const CardSaved = () => {

  const { t } = useTranslation();

  const users_favs = useSelector(state => state.user.number_of_favorites);
  const users_saved = useSelector(state => state.savedSearch.saved_searches_dropdown);

  const cats = [
    { name: t('profile.menu3'), link: 'Searches', value: users_saved.length },
    { name: t('words.favs'), link: 'Favorites', value: users_favs },
  ];

  return (
    <Card className='mt-5 p-3 border-0 bg-light position-relative'>

      <span
        className='m-1 position-absolute bg-success lead pb-1 px-2 border border-white border-4 rounded-4 text-white'
        style={{ top: '-25px', right: '1%' }}
      >
        <FaRegSave />
      </span>

      <div className='flex-column d-flex text-start'>

        {cats.map((cat, key) => (

          <Link
            key={key}
            to={`/profile/${cat.link}`}
            className="link-sidebar"
          >
            {cat.name}
            ({cat.value})

          </Link>

        ))}

      </div>

    </Card>
  )
}

export default CardSaved