import React from 'react';
import { useTranslation } from 'react-i18next'
import { Button, Col, Container, Row } from 'react-bootstrap';
import Footer from '../components/Footer';
import LoginFormNew from '../components/loginRegister/LoginFormNew';
import RegisterFormNew from '../components/loginRegister/RegisterFormNew';
import { BiSmile } from 'react-icons/bi';


const NeedLoginPage = () => {

    const { t } = useTranslation();

    const [login, setLogin] = React.useState(false);


    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <>

            <Container fluid="xxl">

                <Row className='justify-content-between bg-waves'>

                    <Col lg={7}>

                        <Row className='justify-content-center mt-5'>
                            <Col lg={6} className="p-4 mt-4 bg-light">

                                <h1 className='display-4 fw-bold mb-4'>{t('bigtext.youLogin1')}</h1>

                                <span className='fs-2 px-2 py-1 bg-gradient-animation fw-bold rounded-4 text-white'>
                                    {t('bigtext.youLogin2')} <BiSmile className='mb-1' />
                                </span>

                                <p className='mt-3'>&rarr; {t('bigtext.youLogin3')}</p>
                                <p>
                                    {t('bigtext.youLogin4')} <strong>{t('bigtext.youLogin5')}</strong> {t('bigtext.youLogin6')}
                                </p>
                            </Col>
                        </Row>

                        <Row className='justify-content-end my-5'>
                            <Col lg={5} className="mb-5">
                                <Button
                                    href="/"
                                    variant="yellow"
                                    className="rounded-pill text-white fs-5 p-3"
                                >
                                    {t('bigtext.youLogin7')}
                                </Button>
                            </Col>
                        </Row>

                    </Col>


                    <Col lg={5} className="text-center py-5">

                        <Button variant="transparent" className="fw-bold text-white shadow mb-4  bg-gradient-animation" size="lg" onClick={() => setLogin(false)}>
                            {t('title.createacc')} {t('bigtext.youLogin5')}
                        </Button>
                        <Button variant="outline-primary" className="ms-4 border-2 fw-bold shadow mb-4" size="lg" onClick={() => setLogin(true)}>
                            {t('title.login')}
                        </Button>


                        {login
                            ?
                            <Row className='justify-content-center'>
                                <Col lg={9} className='' style={{ maxWidth: "400px" }}>
                                    <LoginFormNew />
                                </Col>
                            </Row>

                            :
                            <Row className='justify-content-center'>
                                <Col lg={9} className="bg-white">
                                    <RegisterFormNew />
                                </Col>
                            </Row>
                        }

                    </Col>

                </Row>
            </Container>

            <div style={{ marginTop: "-0.5rem" }}>
                <Footer />
            </div>
        </>
    )
};

export default NeedLoginPage;


