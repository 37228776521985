import React from 'react'
import { useTranslation } from 'react-i18next'
import { Badge, Button, ButtonGroup, Collapse, ToggleButton } from 'react-bootstrap'


const SearchGroups = ({ circles, sumNet, setSearchByGroup, searchByGroup }) => {

    const { t } = useTranslation();

    //Toggle Groups
    let [showGroups, setShowGroups] = React.useState(false);
    let toggleGroups = () => { setShowGroups(!showGroups); }

    let groupsName = [];

    for (let i = 0; i < circles.length; i++) {
        groupsName.push(
            <ToggleButton
                key={i}
                type="radio"
                variant="outline-warning"
                className="d-flex justify-content-between align-items-center border-0"
                value={circles[i].id}
                id={`group_name_net_${i}`}
                checked={parseInt(searchByGroup) === circles[i].id}
                onChange={(e) => setSearchByGroup(e.currentTarget.value)}
            >
                {circles[i].title}

                {parseInt(searchByGroup) === circles[i].id &&
                    <Badge bg="light" className="text-dark" pill>{circles[i].users.length}</Badge>
                }
            </ToggleButton>
        )
    }


    return (
        <>

            <Button
                variant="outline-secondary"
                onClick={toggleGroups}
                aria-controls="see_groups"
                className='border-0 ps-0 ms-1'
            >
                <span className='fw-bold'>&#9654; {t('words.srch')}&nbsp;{t('words.with')}&nbsp;{t('words.groups')}</span>
            </Button>

            <Collapse id="see_groups" in={showGroups} dimension="height" className='collapse-height-scroll'>
                <div className='border border-2 rounded-4 border-warning overflow-scroll' style={{ Maxheight: '180px' }}>
                    <ButtonGroup vertical className="w-100 rounded-4 my-2 ps-2">

                        <ToggleButton
                            type="radio"
                            variant="outline-warning"
                            className="d-flex justify-content-between align-items-center border-0"
                            value='All'
                            id='group_name_net_all'
                            checked={searchByGroup === "All"}
                            onChange={(e) => setSearchByGroup(e.currentTarget.value)}
                        >
                            {t('title.userMul')}

                            {searchByGroup === "All" &&
                                <Badge bg="light" className="text-dark" pill>{sumNet}</Badge>
                            }
                        </ToggleButton >

                        {groupsName}

                    </ButtonGroup >
                </div>
            </Collapse>

        </>
    )
}

export default SearchGroups