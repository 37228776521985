import TokenRefresher from "./TokenRefresher";


export default class FiltersPost {

    constructor(ac) {
        this.ac = ac
    }

    //Bearer token
    async fetchListings(url, setter, setterSum, payload, filtersPayload, coorPolygon, setLoading) {

        const data = payload;

        if (coorPolygon.length > 0) {

            const geometry = {
                "google_polygon": {
                    "geometry": {
                        "type": "Polygon",
                        "coordinates": coorPolygon[0]
                    }
                }
            }
            Object.assign(data, geometry)
        }

        if (filtersPayload) {
            Object.assign(data, filtersPayload)
        }


        let getListing = async () => {

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;

            fetch(url, {
                method: 'POST',
                signal: this.ac,
                headers: {
                    'Authorization': bearer_token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
                credentials: 'include',

            })
                .then(handleSubmitError)
                .then(myData => {
                    setter(JSON.stringify(myData.records))
                    setterSum(myData.number_of_records)
                    setLoading(false)
                })

                .catch(err => console.error(err))

        }
        // Abort Fetch


        //Error for Fetch
        async function handleSubmitError(res) {

            if (res.status >= 200 && res.status <= 299) {
                return res.json();
            } else {
                setLoading(false)
                throw Error(res.status);
            }
        }


        //checks and refresh token
        const sessionFetch = new TokenRefresher();
        sessionFetch.setTokenRefresh(getListing);

    }

}

