import React from 'react'
import { Col } from 'react-bootstrap';
import FetchGetSetSimpleNetwork from '../../jwtAPI/FetchGetSetSimpleNetwork';
import MyPagination from '../MyPagination';
import UserCard from './UserCard';
import FragmentedCircleLoader from '../Loaders/FragmentedCircleLoader';
import { useTranslation } from 'react-i18next'
import InviteUser from './InviteUser';




const AllNetwork = ({ url }) => {

    const { t } = useTranslation();

    const controller = new AbortController()
    const ac = controller.signal

    let [allNetwork, setAllNetwork] = React.useState(null);


    const [currentPage, setCurrentPage] = React.useState(1);
    const [sumListing, setterSum] = React.useState(0);
    const [loading, setLoading] = React.useState(false)

    url = url.replace(/page=1/, 'page=' + currentPage);


    React.useEffect(() => {
        setLoading(true)
        const fetchLists = new FetchGetSetSimpleNetwork(ac);
        fetchLists.fetchListings(url, setAllNetwork, setterSum, setLoading);
        // eslint-disable-next-line
    }, [url, currentPage]);

    const cleanUp = function () {
        setAllNetwork(null)
        setCurrentPage(1)
        setterSum(0)
    }

    React.useEffect(() => {
        return () => {
            cleanUp()
        }
    }, [])

    return (
        <>
            {loading
                ? <div style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}> <FragmentedCircleLoader /> </div>

                : allNetwork && sumListing !== 0
                    ?
                    <>
                        {allNetwork.map((agent, key) => (
                            <Col xxl={6} xl={9} className="mt-3" key={key}>
                                <UserCard
                                    agent={agent}
                                    myNet={false}
                                    allNetwork={allNetwork}
                                    setAllNetwork={setAllNetwork}
                                />
                            </Col>
                        ))}

                        {sumListing > 0 &&
                            <MyPagination
                                itemsCount={sumListing}
                                itemsPerPage={12}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                alwaysShown={false}
                            />
                        }

                    </>

                    :
                    <>
                        <p className='text-center'>{t('bigtext.noUserSearch')}</p>
                        <InviteUser />
                    </>

            }
        </>

    )
}

export default AllNetwork