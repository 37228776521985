import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next';


const RegisterDropJob = ({ job, handleChange, profeList, defaultValue2 = null, disabled = false }) => {

    const { t } = useTranslation();
    let nameJob = '';


    let x = job;
    switch (x) {
        case '114':
            nameJob = 'architecture';
            break;
        case '115':
            nameJob = 'construction';
            break;
        case '119':
            nameJob = 'service_ict';
            break;
        case '116':
            nameJob = 'droit';
            break;
        case '117':
            nameJob = 'finance';
            break;
        case '113':
            nameJob = 'immo';
            break;
        case '118':
            nameJob = 'vente_meteriel';
            break;
        default:
            nameJob = 0;
            break;
    }

    return (
        <>
            <Form.Group className="col-lg-12 mb-2">
                <Form.Select disabled={disabled} id={nameJob} name={nameJob} defaultValue={defaultValue2 ? defaultValue2 : ""} onChange={handleChange} className="form-control">
                    <option value="">{t('text.choose_cat')}</option>
                    {

                        profeList[nameJob].sort((a, b) => a.name > b.name ? 1 : -1).map((jjj, key) => (
                            <option key={key} id={jjj.id} value={jjj.id} >{jjj.name}</option>
                        ))

                    }
                    <option value="222" id="kyb">{t('words.other')}</option>
                </Form.Select>
            </Form.Group>
        </>
    )
}

export default RegisterDropJob