import { Button } from 'react-bootstrap';
import Overview from './Overview'
import Address from './Address';
import LocationNearBy from './LocationNearBy';
import { useTranslation } from 'react-i18next'
import DateOnly from '../../functions/DateOnly';



function SingleListDetails({ type, property }) {

    const { t } = useTranslation();

    property.article.content = property.article.content.replace('<p>&nbsp;</p>', '')

    return (
        <>

            <Button variant='primary' size="sm" className='w-auto px-2 py-0 text-white'>{t('cats_tools.' + type + '')}</Button>

            {type === 'sale' || type === 'rent' ?

                <Button variant='primary' size="sm" className='w-auto px-2 py-0 text-white ms-2'>{property.ad_type.name}</Button>

                : type === 'request' ?
                    <>
                        <Button variant='primary' size="sm" className='w-auto px-2 py-0 text-white ms-2'>{property.request_type.name}</Button>
                        <Button variant='primary' size="sm" className='w-auto px-2 py-0 text-white ms-2'>
                            {
                                property.buy_type
                                    ? property.buy_type.name

                                    : property.rent_type
                                        ? property.rent_type.name

                                        : property.development_type
                                            ? property.development_type.name

                                            : ''
                            }
                        </Button>
                    </>

                    :
                    <>
                        <Button variant='primary' size="sm" className='w-auto px-2 py-0 text-white ms-2'>{property.partner_type.name}</Button>

                        {!property.price &&
                            <Button variant='primary' size="sm" className='w-auto px-2 py-0 text-white ms-2'>
                                {
                                    property.partner_type.id === 47
                                        ? property.start_up_type.name

                                        : property.partner_type.id === 33
                                            ? property.know_how_type.name

                                            : property.partner_type.id === 30
                                                ? property.project_development_type.name

                                                : ''
                                }
                            </Button>
                        }
                    </>

            }

            <h1 className='fw-bold mt-2'>{property.article.title}</h1>

            {property.number
                && <p className='fs-6 fw-bold'>ID: ({property.number})</p>
            }

            <p><strong>{t('words.pubDate')}: </strong> <DateOnly dateRaw={property.article.published_at} /></p>

            {property.article.excerpt ? <h5 className='text-muted'>{property.article.excerpt}</h5> : ''}


            <Address address={property.address} />

            <Overview property={property} type={type} />

            {property.article.nearby_places.length > 1 &&
                <LocationNearBy places={property.article.nearby_places} />
            }


        </>
    );
}

export default SingleListDetails;
