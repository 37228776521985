import React from 'react'
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap'
//import { useTranslation } from 'react-i18next'
import Footer from '../components/Footer'
import FetchGetSetSimple from '../jwtAPI/FetchGetSetSimple'
import ProfileAgent from '../components/agent/ProfileAgent';
import ProfileHeaderAgent from '../components/agent/ProfileHeaderAgent';
import ProfileListings from '../components/profile/listings/ProfileListings';
import ProfileMyToolboxListings from '../components/profile/listings/ProfileMyToolboxListings';
import AgentContactHorizontal from '../components/agent/AgentContactHorizontal';
import NeedLoginPage from './NeedLoginPage';
import LoadingData from '../components/createEdit/LoadingData';



const AgentPage = ({ userId }) => {

    //const { t } = useTranslation();

    const [logged] = React.useState(localStorage.getItem('logged'));
    let [agent, setAgent] = React.useState(null)

    let { id } = useParams();

    if (userId) { id = userId }

    let url = process.env.REACT_APP_USERINFO_API.replace(/me/, id);

    React.useEffect(() => {

        const fetchLists = new FetchGetSetSimple();
        fetchLists.fetchListings(url, setAgent);

    }, [url]);


    return (
        <>


            {logged && agent
                ?
                <>
                    <Container fluid="lg" className="bg-light">
                        <ProfileHeaderAgent user={agent} />
                        <ProfileAgent user={agent} />
                    </Container>

                    <Container fluid="lg" className="bg-light mt-5 pt-5">
                        <ProfileListings agentId={id} />
                    </Container>

                    <Container fluid="lg" className="bg-light mt-5 pt-5">
                        <ProfileMyToolboxListings agentId={id} />
                    </Container>

                    <AgentContactHorizontal agent={agent} />
                </>

                : !logged
                    ? <NeedLoginPage />

                    : <LoadingData />
            }

            {(!userId && logged) &&
                <Footer />
            }

        </>
    )
}

export default AgentPage