import React from 'react'
import { useTranslation } from 'react-i18next'
import { Badge, Button, Modal, NavDropdown, Spinner, ToggleButton } from 'react-bootstrap';
import NotificationsGet from '../../jwtAPI/NotificationsGet';
import SingleNotification from './SingleNotification';
import { BellFill, GearFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import NotificationsAllModal from './NotificationsAllModal';



const Notifications = ({ mobile = false }) => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  let [notif, setNotif] = React.useState(null);
  let [unread, setUnread] = React.useState(0);
  const [viewAll, setViewAll] = React.useState(false);
  const [loading, setLoading] = React.useState(false)

  const [radioValue, setRadioValue] = React.useState('all');

  const changeRadio = (e) => {

    const selected = e.target.value

    if (selected === 'edit') {
      navigate('/profile/Personalization');
      setRadioValue('all');
    }
    else if (selected === 'all') {
      setRadioValue(selected);
    }
    else if (selected === 'unread') {
      setRadioValue(selected);
    }
  }

  const url = `${process.env.REACT_APP_DOMAIN}/${i18n.language}/api/notifications?page=1&limit=10&type=${radioValue}`

  React.useEffect(() => {

    const fetchLists = new NotificationsGet();
    fetchLists.fetchListings(url, setNotif, false, setLoading);

    //eslint-disable-next-line
  }, [url]);

  const radios = [
    { name: t('words.all'), value: 'all' },
    { name: t('words.unread'), value: 'unread' },
    { name: <>{t('notif.notif11')} <GearFill size={20} /></>, value: 'edit' },
  ];



  React.useEffect(() => {

    if (notif) {

      let count = 0;

      for (let i = 0; i < notif.length; i++) {
        if (notif[i]['viewed'] === false) {
          count++
        }
      }
      setUnread(count)
    }

  }, [notif]);


  return (

    <>
      <NavDropdown id="notif-drop" align={mobile ? "center" : "end"} className={mobile ? "mx-1" : "mx-2"} title={
        <div className="position-relative d-inline-block" >

          <BellFill size={mobile ? 19 : 22} className="mb-1 me-1 position-relative" />
          {mobile && <span className='fw-bold text-warning'>{t('title.notifs')}</span>}

          {unread > 0
            ?
            <>
              <Badge pill bg="danger"
                style={{ top: '-6px', left: mobile ? "-10px" : "16px" }}
                className='position-absolute'>
                {unread}
              </Badge>
              <span className="visually-hidden">{t('words.unread')} {t('title.notifs')}</span>
            </>
            : ''
          }

        </div>}
      >

        <h4 className='fw-bolder px-2 text-warning'>{t('title.notifs')}</h4>

        <div className="notif-drop-btn d-flex mb-3">
          {radios.map((radio, idx) => (
            <ToggleButton
              key={idx}
              id={`radioN-${idx}`}
              type="radio"
              variant="lightblue"
              className={idx === 2 ? 'ms-auto me-2' : 'mx-2'}
              name="radioN"
              value={radio.value}
              checked={radioValue === radio.value}
              onChange={changeRadio}
            >
              {radio.name}
            </ToggleButton>
          ))}
        </div>

        {loading
          ?
          <div className='w-100 d-flex justify-content-center'>
            <Spinner animation="border" variant="info" />
          </div>


          :
          notif && notif.length > 0
          &&
          notif.map((noti, key) => (

            <SingleNotification key={key} noty={noti} />

          ))
        }

        <div className='text-center mt-2'>
          <Button
            size="sm"
            variant="lightblue"
            className='fw-bold text-warning rounded-pill'
            onClick={() => setViewAll(true)}
          >
            {t('words.view')} {t('words.all')}
          </Button>
        </div>

      </NavDropdown>

      <Modal
        show={viewAll}
        onHide={() => setViewAll(false)}
        backdrop="static"
        size="lg"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className='fw-bolder text-warning'>{t('title.notifs')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-0 p-lg-2'>
          <NotificationsAllModal setViewAll={setViewAll} notif={notif} setNotif={setNotif} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setViewAll(false)}>
            {t('words.close')}
          </Button>
        </Modal.Footer>
      </Modal>

    </>

  )
}

export default Notifications