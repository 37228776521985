import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Table } from 'react-bootstrap'
import SignInActivityFetch from '../../../jwtAPI/SignInActivityFetch';
import FragmentedCircleLoader from '../../Loaders/FragmentedCircleLoader';
import MyPagination from '../../MyPagination';
import DateWithHour from '../../../functions/DateWithHour';


const SignInActivity = () => {

  const [signInAct, setSignInAct] = React.useState('');
  const [sum, setterSum] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false)

  const { t } = useTranslation();


  React.useEffect(() => {

    setLoading(true);
    const url = `${process.env.REACT_APP_DOMAIN}/en/api/sign-in-reports?page=${currentPage}&limit=10`//process.env.REACT_APP_FRIENDREQ_LIST_API.replace(/page=1/, 'page=' + currentPage);
    const fetchLists = new SignInActivityFetch();
    fetchLists.fetchListings(url, setSignInAct, setterSum, setLoading);

  }, [currentPage]);

  return (

    <Col xs={12} className="mt-5">

      {loading &&
        <div className="d-flex justify-content-center mt-5">
          <FragmentedCircleLoader color={"black"} />
        </div>
      }
      {(signInAct && !loading) &&
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>{t('words.action')}</th>
                <th>{t('title.date')}</th>
                <th>IP</th>
              </tr>
            </thead>
            <tbody>
              {signInAct.map((act, key) => (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>{t('title.login')}</td>
                  <td><DateWithHour dateRaw={act.date} /></td>
                  <td>{act.ip_address}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          {(!loading && sum > 0) &&
            <MyPagination
              itemsCount={sum}
              itemsPerPage={10}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              alwaysShown={false}
            />
          }

        </>
      }

    </Col>

  )
}

export default SignInActivity