
export default class FetchBlog {

    constructor(ac) {
        this.ac = ac
    }
    //Bearer token
    async fetchListings(url, setAllListings, parameters, setLoading, setSumListing) {

        let getListing = async () => {

            fetch(url, {
                method: 'POST',
                signal: this.ac ? this.ac : undefined,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(parameters),
            })
                .then(handleSubmitError)
                .then(myData => {

                    setSumListing(myData.number_of_records)

                    setAllListings(myData.records);

                    if (setLoading) setLoading(false)
                })

                .catch(err => {
                    console.error(err)
                    if (setLoading) setLoading(false)
                })

        }

        //Error for Fetch
        function handleSubmitError(res) {

            if (res.status >= 200 && res.status <= 299) {

                return res.json();
            } else {

                throw Error(res.status);
            }
        }

        getListing();

    }

}

