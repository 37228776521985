import React from 'react'
import { Form, Col, ListGroup, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
// Redux
import { useSelector, useDispatch } from 'react-redux';
import notificationEmail from '../../../jwtAPI/NotificationEmail';
import { toggleNewsletter } from '../../../Redux/user-slice';


function NotifEmailPreferences() {

    const { t } = useTranslation();
    const dispatch = useDispatch()
    const [notifEmail, setNotifEmail] = React.useState(useSelector(state => state.newsFeed.email_notifications));
    const [loading, setLoading] = React.useState(false);


    const items1 = [
        { name: t('title.newsletter'), id: 'notif_newsletter', checked: notifEmail.includes('newsletter') },
        { name: `${t('title.net')} ${t('title.notifs')}`, id: 'notif_network', checked: notifEmail.includes('network') },
        { name: `${t('words.saved')} ${t('words.searches')} & ${t('words.alerts')}`, id: 'notif_saved-searches', checked: notifEmail.includes('saved-searches') },
    ]
    const items2 = [
        { name: `${t('words.important')} ${t('title.notifs')}`, id: 'notif_important', checked: notifEmail.includes('important') },
        { name: `${t('words.new')} ${t('title.messages')} & Email`, id: 'notif_messaging', checked: notifEmail.includes('messaging') },
        { name: `${t('words.prom')} & ${t('words.offers')}`, id: 'notif_promotions', checked: notifEmail.includes('promotions') },
    ]

    const togglePref = (e) => {

        setLoading(true);

        const thisName = e.target.id.replace('notif_', '');

        let newArray = [...notifEmail];

        //only for newsletter we have it alone in the user redux object
        if (thisName === 'newsletter') {
            dispatch(toggleNewsletter(e.target.checked));
        }

        if (notifEmail.includes(thisName)) {
            newArray = newArray.filter(item => item !== thisName);
        }
        else {
            newArray.push(thisName);
        }

        setNotifEmail(newArray);
        notificationEmail("email", newArray, setLoading);

    }


    return (
        <>

            <h5 className='text-center mb-4'>{t('words.notiFreq')} Email </h5>

            <Col md={6} className="mb-2">

                <ListGroup>

                    {items1.map((item, key) => (
                        <ListGroup.Item key={key} className='d-flex flex-nowrap justify-content-between'>
                            {item.name}

                            {!loading
                                ?
                                <Form.Check
                                    className='ms-2'
                                    type="switch"
                                    checked={item.checked}
                                    id={item.id}
                                    onChange={togglePref}
                                />
                                :
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            }
                        </ListGroup.Item>
                    ))}
                </ListGroup>

            </Col>

            <Col md={6} className="mb-5">

                <ListGroup>
                    {items2.map((item, key) => (
                        <ListGroup.Item key={key} className='d-flex flex-nowrap justify-content-between'>
                            {item.name}
                            {!loading
                                ?
                                <Form.Check
                                    className='ms-2'
                                    type="switch"
                                    checked={item.checked}
                                    id={item.id}
                                    onChange={togglePref}
                                />
                                :
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            }
                        </ListGroup.Item>
                    ))}
                </ListGroup>

            </Col>
        </>
    )
}
export default NotifEmailPreferences