import React from 'react';
import { Button, Card, Row, Col, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { MdMarkEmailRead } from "react-icons/md";

import FetchGetSetSimple from '../jwtAPI/FetchGetSetSimple';

import Footer from '../components/Footer';
import AgentContact from '../components/singleListing/AgentContact';
import SingleListHero from '../components/singleListing/SingleListHero';
import Address from '../components/singleListing/Address';
import SingleListPDF from '../components/singleListing/SingleListPDF';
import ButtonEditLis from '../components/singleListing/ButtonEditLis';
import OverviewTitleShare from '../components/singleListing/OverviewTitleShare';
import NeedLoginPage from './NeedLoginPage';
import LoadingData from '../components/createEdit/LoadingData';
import DateOnly from '../functions/DateOnly';




const ComparisonPage = () => {

    const { t, i18n } = useTranslation();

    const [logged] = React.useState(localStorage.getItem('logged'));
    const { id } = useParams();
    let url = `${process.env.REACT_APP_DOMAIN}/${i18n.language}/api/listing/benchmark/${id}`

    const [listing, setter] = React.useState(null);


    React.useEffect(() => {

        const fetchLists = new FetchGetSetSimple();
        fetchLists.fetchListings(url, setter);

        // eslint-disable-next-line
    }, [id, url]);

    return (
        <>
            {(listing && logged) ?

                <>

                    <Container fluid="xxl">
                        <SingleListHero excerptPhoto={listing.article.excerpt_photo_url} gallery={listing.article.photos_urls} />
                    </Container>


                    <Container fluid="xl">

                        <Row className="justify-content-between mt-5">

                            <Col xl={8} lg={12}>

                                <ButtonEditLis idAuthor={listing.article.author.id} type="benchmark" idLis={id} />

                                <Button variant='warning' size="sm" className='w-auto px-2 py-0'>{t('cats_tools.benchmark')}</Button>
                                <Button variant='primary' size="sm" className='w-auto px-2 py-0 text-white ms-2'>{listing.estate_type.name}</Button>

                                <h1 className='fw-bold mt-2'>{listing.article.title}</h1>

                                {listing.number
                                    && <p className='fs-6 fw-bold'>ID: ({listing.number})</p>
                                }

                                <p><strong>{t('words.pubDate')}: </strong> <DateOnly dateRaw={listing.article.published_at} /></p>

                                {listing.article.excerpt ? <h5 className='text-muted'>{listing.article.excerpt}</h5> : ''}


                                <Address address={listing.address} />

                                <Card className='p-4 border-0 shadow-sm mt-5'>

                                    <OverviewTitleShare
                                        link={window.location.href}
                                        title={listing.article.title}
                                        isFav={listing.article.favorited_by}
                                        lisId={listing.article.id}
                                        listingType='benchmark'
                                    />

                                    <Row className='row-overview long'>

                                        {listing.estate_type &&
                                            <Col>
                                                <span className='text-warning fs-6'>{t('words.estate')}: </span>
                                                <br />
                                                {listing.estate_type.name}
                                            </Col>
                                        }

                                        {(listing.price || listing.negotiable_price)
                                            &&
                                            <Col>
                                                <span className='text-warning fs-6'>{t('words.price')}: </span>
                                                <br />

                                                {listing.price !== -1 && <>{listing.price}&euro;</>}

                                                {(listing.negotiable_price === true) &&

                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip>{t('bigtext.priceNego')}</Tooltip>}
                                                    >
                                                        <span>
                                                            <MdMarkEmailRead size={23} className='ms-1 text-success' />
                                                        </span>

                                                    </OverlayTrigger>

                                                }

                                            </Col>
                                        }
                                        {listing.transaction &&
                                            <Col>
                                                <span className='text-warning fs-6'>{t('title.transa')}: </span>
                                                <br />
                                                {listing.transaction.name}
                                            </Col>
                                        }
                                        {listing.transaction_date &&
                                            <Col>
                                                <span className='text-warning fs-6'>{t('title.transa')} {t('title.date')}: </span>
                                                <br />
                                                <DateOnly dateRaw={listing['transaction_date']} />
                                            </Col>
                                        }


                                    </Row>

                                    {listing.article.content &&
                                        <>
                                            <p className='mt-4 text-warning fs-5'>{t('words.desc')}</p>
                                            <p dangerouslySetInnerHTML={{ __html: listing.article.content }} />
                                        </>
                                    }


                                    {listing.documents_urls.length > 0 &&

                                        <SingleListPDF docNames={listing.documents_file_names} docUrls={listing.documents_urls} />

                                    }

                                </Card>

                            </Col>

                            <Col xl={3} lg={12}>

                                <AgentContact agent={listing.article.author} />

                            </Col>

                        </Row>

                    </Container>

                    <Footer />

                </>

                : !logged
                    ? <NeedLoginPage />

                    : <LoadingData />
            }
        </>
    )
}

export default ComparisonPage
