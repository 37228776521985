import TokenRefresher from "./TokenRefresher";


//Saves and uses json after fetch with the useState of setter listi
export default class FetchGetSetSimpleCircles {


    constructor(ac) {
        this.ac = ac
    }


    //Bearer token
    async fetchListings(url, setter, setterSum, setLoader) {

        let getListing = async () => {

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;

            fetch(url, {
                method: 'GET',
                signal: this.ac,
                headers: {
                    'Authorization': bearer_token,
                },
                credentials: 'include',
            })
                .then(res => res.json())
                .then(res => {

                    setter(false);

                    let myData = res.circles;

                    return myData;
                })
                .then(myData => {
                    if (setLoader) setLoader(false)
                    setter(myData);
                })

                .catch(err => {
                    console.error(err);
                    if (setLoader) setLoader(false)
                })

        }



        //checks and refresh token
        const sessionFetch = new TokenRefresher();
        sessionFetch.setTokenRefresh(getListing);

    }

}

