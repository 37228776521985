import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MultiImgEdit from './MultiImgEdit';
import MultiImgUpBtn from './MultiImgUpBtn';
import SingleImgEdit from './SingleImgEdit';
import SingleImgUpBtn from './SingleImgUpBtn';

const PicturesCE = ({ listing, setExcerptUri, setGalleryUris }) => {

    const { t } = useTranslation();

    return (

        <>
            <Col sm={12} className="text-white bg-warning mt-4 px-4 pt-3 pb-2">
                <h6>{t('words.pictures')}</h6>
            </Col>

            <Col lg={12} className="bg-white shadow-sm p-4">

                <Row className="gy-3">

                    <Col md={4}>
                        <Form.Label>{t('text.upPic')}</Form.Label>

                        {
                            (listing && listing.article.excerpt_photo_url && listing.article.excerpt_photo_url !== "")
                                ? <SingleImgEdit picture={listing.article.excerpt_photo_url} setExcerptUri={setExcerptUri} />
                                : <SingleImgUpBtn setExcerptUri={setExcerptUri} />
                        }

                    </Col>

                    <Col md={8}>
                        <Form.Label>{t('text.upGal')}</Form.Label>

                        {
                            (listing && listing.article.photos_urls && listing.article.photos_urls.length > 0)
                                ? <MultiImgEdit pictures={listing.article.photos_urls} picturesId={listing.article.photos} setGalleryUris={setGalleryUris} />
                                : <MultiImgUpBtn setGalleryUris={setGalleryUris} />
                        }


                    </Col>

                </Row>

            </Col>
        </>
    )
}

export default PicturesCE