import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Col, Form, Spinner, ProgressBar } from 'react-bootstrap';
import PasswordField from '../loginRegister/PasswordField';


const ResetPassStep2 = ({ token }) => {

    const { t } = useTranslation();

    const [validated, setValidated] = React.useState(false);
    const [loading, setloading] = React.useState(false);
    const [sucPass, setSucPass] = React.useState('');
    let [progressSec, setSec] = React.useState(0);
    //Password strength
    let [strength, setStrength] = React.useState(0);

    const initialFormData = Object.freeze({
        confirmation_token: token.replace('?confirmation_token=', ''),
        password: "",
        confirm_password: ""
    });

    const [formData, updateFormData] = React.useState(initialFormData);

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        });
    };


    const handleSubmitValidation = (event) => {

            const form = event.currentTarget;
    
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            }
    
            setValidated(true);
    
            if (form.checkValidity() === true) {
                handleSubmit(event);
            }

    };



    /************* Reset Pass ***************/

    function handleSubmitError(res) {
        if (res.status >= 200 && res.status <= 299) {
            return;
        } else {
            setloading(false);
            setValidated(false);
            throw Error(res.statusText);
        }
    }

    let handleSubmit = async (e) => {

        setloading(true);
        e.preventDefault();

        fetch(process.env.REACT_APP_RESETPASS_API, {

            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then(handleSubmitError)
            .then(() => {
                setloading(false);
                setSucPass(true);
            })
            .catch(err => console.error(err))
    };

    //Progress Bar
    if (sucPass) {
        let timer = setInterval(function () {

            if (progressSec >= 10) {
                clearInterval(timer);
                window.location.href = "/";
            }
            progressSec += 0.1;
            setSec(progressSec)

        }, 100);
    }



    return (

        <Col md={6} lg={5} >
            <Card bg="light" border="warning" className='p-4 text-center shadow-lg'>

                {!sucPass
                    ?
                    <>

                        <h4 className='mb-4'>{t('words.new')} {t('title.pass')}</h4>


                        <Form noValidate validated={validated} onSubmit={handleSubmitValidation} >

                            <PasswordField strength={strength} setStrength={setStrength} handleChange={handleChange} nameField="password" />

                            <Form.Group className="mb-4 text-start">
                                <Form.Label>{t('title.confpass')}</Form.Label>
                                <Form.Control
                                    required
                                    id="confirm_password"
                                    name="confirm_password"
                                    type="password"
                                    autoComplete="new-password"
                                    isInvalid={formData.confirm_password !== formData.password ? true : false}
                                    onChange={handleChange} />
                                <Form.Control.Feedback type="invalid">{t('text.incPass')}</Form.Control.Feedback>
                            </Form.Group>

                            <Button variant="warning" type="submit" disabled={loading ? true : false} className="text-white rounded-pill">
                                {loading ?
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        {t('title.loading')}
                                    </>

                                    :
                                    t('text.resetPass')
                                }
                            </Button>

                        </Form>

                    </>

                    :
                    <>
                        <h5> {t('bigtext.sucPass')} </h5>

                        <a href="/?login" className="position-relative mt-4" >
                            <ProgressBar style={{ height: '2rem' }} variant="warning" max="10" now={progressSec} />
                        </a>

                    </>
                }

            </Card>
        </Col>

    );
};

export default ResetPassStep2;
