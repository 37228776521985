import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import RegisterFunctions from './RegisterFunctions';
import { EyeFill, EyeSlash } from 'react-bootstrap-icons';



const PasswordField = ({ strength, setStrength, handleChange, nameField }) => {

    const { t } = useTranslation();
    const regfunc = new RegisterFunctions();

    const [eyePass, setEyePass] = React.useState(false);
    const showPass = () => setEyePass(!eyePass);


    //Password Strength

    let password = document.getElementById(nameField);
    let passwordStrength = document.getElementById("password-strength");
    let lowUpperCase = document.querySelector(".low-upper-case");
    let number = document.querySelector(".one-number");
    let specialChar = document.querySelector(".one-special-char");
    let eightChar = document.querySelector(".eight-character");

    if (password) {
        password.onkeyup = funfun;
    }

    function funfun() {
        let password = document.getElementById(nameField).value;
        regfunc.checkStrength(password, strength, setStrength, passwordStrength, lowUpperCase, number, specialChar, eightChar);
    }



    return (


        <Form.Group className="col-lg-12 mb-2">
            <Form.Label>{t('words.create')} {t('title.pass')}</Form.Label>

            <div className="position-relative" >
                <Form.Control
                    required
                    id={nameField}
                    name={nameField}
                    type={!eyePass ? "password" : "text"}
                    autoComplete="new-password"
                    onChange={handleChange}
                />

                {!eyePass
                    ? <EyeFill onClick={showPass} size={20} className='text-warning position-absolute top-0 end-0 me-2 mt-2' />
                    : <EyeSlash onClick={showPass} size={20} className='text-warning position-absolute top-0 end-0 me-2 mt-2' />
                }
            </div>

            <div className='mt-2 small fw-bold'>

                <div className="progress">
                    <div id="password-strength"
                        className="progress-bar"
                        role="progressbar"
                        aria-valuenow="40"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: "0%" }}>
                    </div>
                </div>

                <span className="low-upper-case text-danger me-1">
                    &nbsp;{t('words.lcase')} &amp; {t('words.ucase')}
                </span>
                -
                <span className="one-number text-danger mx-1">
                    &nbsp;{t('words.number')} (0-9)
                </span>
                -
                <span className="one-special-char text-danger mx-1">
                    &nbsp;{t('words.special')} {t('words.char')} (!@#$%^&*)
                </span>
                -
                <span className="eight-character text-danger mx-1">
                    &nbsp;{t('text.char8')}
                </span>

            </div>
        </Form.Group>

    )
}

export default PasswordField