//import
//const userCircle = new EditUserCircle();
//EditUserCircle.fetchListings(action, data, id)

import TokenRefresher from "./TokenRefresher";
import ToastTimer from '../components/ToastTimer';
import ReactDOM from 'react-dom';
import { t } from "i18next";

//Saves and uses json after fetch with the useState of setter listi
export default class EditUserCircle {

    //Bearer token
    async fetchListings(action, data, id) {



        let getListing = async () => {

            let url = process.env.REACT_APP_EDITCIRCLE_API
            
            let methodFetch = 'POST'

            if (action === 'remove-users' || action === 'add-users') {
                url = `${url}/${id}/${action}`;
                methodFetch = action === 'remove-users' ? 'DELETE' : methodFetch;
            }
            else if (action === 'edit') {
                url = `${url}/${action}/${id}`;
                methodFetch = 'PATCH'
            }
            else {
                url = `${url}/${action}`;
            }

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;

            fetch(url, {
                method: methodFetch,
                headers: {
                    'Authorization': bearer_token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
                credentials: 'include',
            })
                .then(res => {

                    ReactDOM.render(< ToastTimer color="success" text={t('title.success')} />, document.getElementById('net-img-hero'));

                    if (action === 'create' || action === 'edit') {
                        setTimeout(() => {
                            ReactDOM.unmountComponentAtNode(document.getElementById('net-img-hero'));
                            window.location.reload(true);
                        }, 2000);
                    }

                    setTimeout(() => {
                        ReactDOM.unmountComponentAtNode(document.getElementById('net-img-hero'));
                    }, 2000);

                })

                .catch(err => console.error(err))

        }



        //checks and refresh token
        const sessionFetch = new TokenRefresher();
        sessionFetch.setTokenRefresh(getListing);

    }

}

