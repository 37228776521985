import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from "react-router-dom";

import { Badge, Card, Col, Row, Image } from 'react-bootstrap'
import houseExample from "../../assets/houseExample.jpg"
import FeedItemActions from './FeedItemActions';
import FeedItemAuthor from './FeedItemAuthor';

import { GeoAltFill } from 'react-bootstrap-icons';
import { FaGlobe } from 'react-icons/fa';
import { MdAppRegistration } from 'react-icons/md';

import DateWithHour from '../../functions/DateWithHour';
import countries from "i18n-iso-countries";



const FeedItemTool = ({ listing, catType }) => {

  const { t, i18n } = useTranslation();

  const lisLink = `${process.env.REACT_APP_URL}/listing/${catType}/${listing.id}/${listing.article.slug}`

  return (

    <Card className="mt-4 border-0 shadow">
      <Row className='p-3 justify-content-between'>

        <h6><strong>
          {listing['article']['title']}
          {listing.number
            && <span className='small fw-bold text-muted'>&nbsp;({listing.number})</span>
          }
        </strong></h6>

        <Col>

          <span className='d-inline-flex'>
            <Badge bg="warning" className='text-light me-2 text-capitalize'>

              {catType === 'job' ? t('cats_tools.training-job')
                : catType === 'event' ? t('cats_tools.event')
                  : catType === 'benchmark' ? t('cats_tools.benchmark')
                    : catType === 'crm' ? t('cats_tools.crm')
                      : ''}

            </Badge>

            {catType === 'job' &&
              <Badge bg="graylight" className='text-black'>
                {listing.type === "offer"
                  ? <>{t('cats_tools.training-job')} {t('words.offer')}</>
                  : <>{t('cats_tools.training-job')} {t('cats_tools.request')}</>
                }
              </Badge>
            }
          </span>

        </Col>

        <FeedItemActions
          lisLink={lisLink}
          listingType={catType}
          listing={listing}
        />

      </Row>

      <Link
        to={`/listing/${catType}/${listing.id}/${listing.article.slug}`}
        className="text-decoration-none text-dark"
      >
        <Row className='gx-0 px-3 bg-white'>

          <Col sm={6} className="position-relative">

            <div className="ratio-cont">
              <Image
                className="ratio"
                src=
                {
                  listing.article.excerpt_photo_url
                    ? process.env.REACT_APP_DOMAIN + listing.article.excerpt_photo_url + '?photo_height=400&photo_width=400'
                    : houseExample
                }
                alt="listing" />
            </div>

            {catType === 'event'
              &&
              <Row className='justify-content-center pb-2 mt-4 me-0 text-capitalize text-center position-absolute top-0 bg-white bg-opacity-75'>
                <Col xs={10}>
                  From
                </Col>
                <Col xs={10} className="bg-light rounded-3">
                  <DateWithHour dateRaw={listing['start_datetime']} />
                </Col>

                <Col xs={10} className="mt-4">
                  To
                </Col>
                <Col xs={10} className="bg-light rounded-3">
                  <DateWithHour dateRaw={listing['end_datetime']} />
                </Col>
              </Row>
            }

          </Col>


          <Col className="bg-light small px-4 py-2">

            {listing.article.excerpt &&
              <div className='bg-primary p-2 rounded-4 mb-2 text-white'>
                <p className='mb-0'>{listing.article.excerpt}</p>
              </div>
            }

            <div className='bg-white p-2 rounded-4'>

              {catType === 'job' ?
                <>
                  {listing.job && <><span className='fw-bold'>{t('cats_tools.training-job')}: </span>{listing.job.name}<br /></>}

                  {listing.sector && <><span className='fw-bold'>{t('title.sect')}: </span>{listing.sector.name}<br /></>}

                  {listing.work_plan && <><span className='fw-bold'>{t('title.work')} {t('words.plan')}: </span>{listing.work_plan.name}<br /></>}

                  {listing.address && <><span className='fw-bold'>{t('title.loc')}: </span>{listing.address.city ? listing.address.city : listing.address.country}<br /></>}
                </>

                : (catType === 'crm' || catType === 'benchmark') ?
                  <>

                    {listing.estate_type && <><span className='fw-bold'>{t('words.estate')}: </span>{listing.estate_type.name}<br /></>}

                    {listing.transaction && <><span className='fw-bold'>{t('title.transa')}: </span>{listing.transaction.name}<br /></>}

                    {listing.price && <><span className='fw-bold'>{t('words.price')}: </span>{listing.price} &euro;<br /></>}

                    {listing.subject && <><span className='fw-bold'>{t('words.topic')}: </span>{listing.subject.name}<br /></>}

                    {listing.auteur && <><span className='fw-bold'>{t('words.author')}: </span>{listing.auteur}<br /></>}

                    {listing.edition && <><span className='fw-bold'>{t('words.edition')}: </span>{listing.edition}<br /></>}

                  </>

                  : //event
                  <>
                    <p className='text-primary mb-1'>
                      <GeoAltFill className='me-1 mb-1' />
                      {listing.address.city
                        ? listing.address.city
                        : countries.getName(listing.address.country, i18n.language, { select: "official" })}
                    </p>

                    {listing.website &&
                      <p className='text-warning mb-1'>
                        <FaGlobe className='mb-1 me-1' /> {t('words.official')} {t('words.site')}: {listing.website}
                      </p>
                    }

                    {listing.registration &&
                      <p>
                        <MdAppRegistration size={17} className='mb-1' /> {t('words.registration')}: {listing.registration}
                      </p>
                    }
                  </>
              }

            </div>

          </Col>

        </Row>

      </Link>
      <hr />

      <FeedItemAuthor author={listing.article.author} />

    </Card >

  )
}

export default FeedItemTool