import TokenRefresher from "./TokenRefresher";

const sendEmailFromSite = async (url, payload, setStatus, setLoading, withoutToken) => {

    const emailThis = async function () {

        try {

            if (withoutToken) {

                const res = await fetch(url, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', },
                    body: JSON.stringify(payload),
                })
                if (res.status >= 200 && res.status <= 299) setStatus("success");
                else setStatus("danger");
            }
            else {

                const bearer_token = `Bearer ${localStorage.getItem('token')}`;
                const res = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': bearer_token,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                    credentials: 'include'
                })
                if (res.status >= 200 && res.status <= 299) setStatus("success");
                else setStatus("danger");
            }

        } catch (error) {
            console.log(error)
            setStatus("danger")

        } finally {
            setLoading(false)

        }
    }

    if (withoutToken) {
        emailThis();
    }
    else {
        const sessionFetch = new TokenRefresher();
        sessionFetch.setTokenRefresh(emailThis);
    }
}


export default sendEmailFromSite