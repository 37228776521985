import React from 'react'
import FeedItemArticle from './FeedItemArticle';
import FeedItemMain from './FeedItemMain';
import FeedItemTool from './FeedItemTool';


const FeedItemIndex = ({ listing }) => {

  let mainTypeListing = 'article';

  if (listing.article) {

    switch (listing.article.object_type) {
      case "sale":
        mainTypeListing = 'sale';
        break;
      case "rent":
        mainTypeListing = 'rent';
        break;
      case "request":
        mainTypeListing = 'request';
        break;
      case "partnership":
        mainTypeListing = 'partnership';
        break;
      case "crm":
        mainTypeListing = 'crm';
        break;
      case "training-job":
        mainTypeListing = 'job';
        break;
      case 'benchmark':
        mainTypeListing = "benchmark";
        break;
      case "event":
        mainTypeListing = 'event';
        break;
      default:
        break;
    }

  } else {
    mainTypeListing = 'article';
  }




  return (

    <>
      {
        mainTypeListing === 'rent' || mainTypeListing === 'sale' || mainTypeListing === 'request' || mainTypeListing === 'partnership'
          ? <FeedItemMain listing={listing} catType={mainTypeListing} />

          : mainTypeListing === 'article'
            ? <FeedItemArticle listing={listing} catType={mainTypeListing} />

            : <FeedItemTool listing={listing} catType={mainTypeListing} />
      }
    </>

  )
}

export default FeedItemIndex