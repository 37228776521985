import React from 'react'
import { Col, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'


function PriceRange({ handleChange }) {

    const { t } = useTranslation();


    return (
        <>

            <span className='small'>{t('words.range')}</span>
            <br />
            <div className='d-inline-flex w-100'>
                <Form.Control size="sm" type="number" name="min_price" id="min_price" placeholder={t('words.min')} onChange={handleChange} />
                <Col lg={1} className='text-center fw-bold'>&rarr;</Col>
                <Form.Control size="sm" type="number" name="max_price" id="min_price" placeholder={t('words.max')} onChange={handleChange} />
            </div>

        </>
    )
}

export default PriceRange