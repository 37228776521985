import TokenRefresher from "./TokenRefresher";

const changeUserPass = async (setStatus, payload, setLoading) => {


    const url = `${process.env.REACT_APP_USERINFO2_API}/change-password`

    const changePass = async function () {
        try {

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;

            const res = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Authorization': bearer_token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
                credentials: 'include'
            })
            if (res.status >= 200 && res.status <= 299) {

                setStatus("success")

            } else {
                setStatus("danger")

            }

        } catch (error) {
            console.log(error)
            setStatus("danger")

        } finally {
            setLoading(false)

        }
    }
    const sessionFetch = new TokenRefresher();
    sessionFetch.setTokenRefresh(changePass);
}


export default changeUserPass