import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Dropdown } from 'react-bootstrap';
import ClearLocalConditionally from '../../functions/ClearLocalConditionally';
import { useNavigate } from 'react-router-dom';
import { FiSave, FiHeart } from 'react-icons/fi';
import { ImCoinEuro } from 'react-icons/im';
import { BiNote, BiHelpCircle } from 'react-icons/bi';
import { AiOutlineLogout } from 'react-icons/ai';
import { RiAdvertisementLine } from 'react-icons/ri';
import { MdSecurity, MdPersonOutline } from 'react-icons/md';
import { toggleTheme } from '../../Redux/page-slice';
import { toggleNewsletter } from '../../Redux/user-slice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux/es/exports';
import SimplePatchToggle from '../../jwtAPI/SimplePatchToggle';



const UserMenu = ({ luser, darkTheme, mobile = false }) => {

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();


    //Logout
    function logoutFunction() {
        ClearLocalConditionally(true);
        window.location.href = '/';
        //window.location.reload(true);
    }

    //NewLetter
    const newsletter = useSelector(state => state.user.accept_direct_marketing);
    function togNewsLet(e) {
        const url = `${process.env.REACT_APP_USERINFO2_API}/toggle-marketing`;
        const toggleThis = new SimplePatchToggle();
        toggleThis.fetchListings(url);
        dispatch(toggleNewsletter(e.target.checked));
    }

    //Dark theme
    function togTheme(e) {
        const url = `${process.env.REACT_APP_USERINFO2_API}/toggle-dark-theme`;
        const toggleThis = new SimplePatchToggle();
        toggleThis.fetchListings(url);
        dispatch(toggleTheme(e.target.checked));
    }

    //Filter User Profession
    let userProf1 = [];
    let userProf2 = [];

    if (luser.user_types && luser.user_types[0] && luser.user_types[0] !== 0) {
        // array empty or does not exist

        userProf1 = Object.keys(luser.user_types[0]);

        for (let i = 0; i < userProf1.length; i++) {
            if (userProf1[i] !== 'id') {
                userProf2.push(

                    <span key={i} className='mx-1 text-nowrap text-muted small'>{luser.user_types[0][userProf1[i]].name}</span>

                )
            }
        }
    }



    return (
        <>


            <Dropdown.Item onClick={() => navigate("/profile/MyProfile")} >


                <MdPersonOutline size={20} className="me-2" />
                {t('words.my')} {t('title.profile')}


            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item onClick={() => navigate("/profile/MyListings")}>

                <RiAdvertisementLine size={20} className="me-2" />
                {t('profile.menu1')}

            </Dropdown.Item>

            <Dropdown.Item onClick={() => navigate("/profile/Searches")} className={mobile ? 'd-flex text-wrap' : ''}>

                <FiSave size={mobile ? 46 : 20} className="me-2" />
                {t('profile.menu3')}

            </Dropdown.Item>

            <Dropdown.Item onClick={() => navigate("/profile/Favorites")}>

                <FiHeart size={20} className="me-2" />
                {t('words.favs')}

            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item onClick={() => navigate("/profile/MyPlan")}>

                <BiNote size={20} className="me-2" />
                {t('words.my')} {t('words.plan')}

            </Dropdown.Item>

            <Dropdown.Item onClick={() => navigate("/profile/Subscription")} className={mobile ? 'd-flex text-wrap' : ''}>

                <ImCoinEuro size={mobile ? 25 : 20} className="me-2" />
                {t('title.billing')} &amp; {t('title.subscription')}

            </Dropdown.Item>

            <Dropdown.Divider />

            <span className='ps-3 d-flex flex-nowrap flex-row justify-content-between'>
                {i18n.language === 'fr' ? <>{t('words.theme')} {t('words.dark')}</> : <>{t('words.dark')} {t('words.theme')}</>}
                <Form.Check
                    className='ms-2'
                    type="switch"
                    checked={darkTheme}
                    id="switch_menu_dark"
                    onChange={togTheme}
                />
            </span>
            <span className='ps-3 d-flex flex-nowrap flex-row justify-content-between'>
                {t('title.newsletter')}
                <Form.Check
                    className='ms-2'
                    type="switch"
                    checked={newsletter}
                    id="switch_newsletter"
                    onChange={togNewsLet}
                />
            </span>
            <Dropdown.Divider />

            <Dropdown.Item onClick={() => navigate("/profile/Security")} className={mobile ? 'd-flex text-wrap' : ''}>

                <MdSecurity size={mobile ? 25 : 20} className="me-2" />
                {t('title.pass')} &amp; {t('words.security')}

            </Dropdown.Item>

            <Dropdown.Item onClick={() => navigate("/profile/Help")}>

                <BiHelpCircle size={20} className="me-2" />
                {t('title.help')}

            </Dropdown.Item>

            <Dropdown.Item onClick={logoutFunction}>
                <AiOutlineLogout size={20} className="me-2" />
                {t('title.logout')}
            </Dropdown.Item>

        </>
    )
}

export default UserMenu