import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { MdManageSearch } from 'react-icons/md'
import useWindowDimensions from '../functions/GetWidthHeight'

const SearchButtonAnimated = ({ searchFunc, idSearch, loading }) => {

    const { t } = useTranslation();

    const { width } = useWindowDimensions();

    let [openSearch, setOpenSearch] = React.useState(false)
    let toggleSearch = () => setOpenSearch(!openSearch);


    return (

        <div style={width > 1600 ? { width: "30rem" } : { width: "20rem" }} className='d-inline-flex justify-content-end'>
            <OverlayTrigger placement="top"
                overlay={<Tooltip >{t('words.srch')}</Tooltip>}
            >
                <Button onClick={toggleSearch} variant="outline-grayDark" className='border-0 p-1'><MdManageSearch size={30} /></Button>
            </OverlayTrigger>

            <Form className={openSearch ? 'open search' : 'search'} action="">
                <Form.Control disabled={loading} id={idSearch} className="search" type="text" placeholder={t('words.srch')} onChange={searchFunc} />
            </Form>

        </div>

    )
}

export default SearchButtonAnimated