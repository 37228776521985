import React from 'react'
import { Card, Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import User from '../../assets/user-svgrepo-com.svg';
import { Link } from 'react-router-dom';
import useWindowDimensions from '../../functions/GetWidthHeight'

// Redux
import { useSelector } from 'react-redux';


const CardProfile = () => {

  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const luser = useSelector(state => state.user);

  //Filter User Profession
  let userProf1 = [];
  let userProf2 = [];

  if (luser.user_types && luser.user_types[0] && luser.user_types[0] !== 0) {
    // array empty or does not exist

    userProf1 = Object.keys(luser.user_types[0]);

    for (let i = 0; i < userProf1.length; i++) {
      if (userProf1[i] !== 'id') {
        userProf2.push(
          <span key={i} >
            <span className='mx-1 me-3'>&bull; {luser.user_types[0][userProf1[i]].name}</span> <br />
          </span>
        )
      }
    }
  }

  return (

    <Card className='p-3 pt-4 mt-5 position-relative rounded-4 bg-user'>

      <Link to='/profile/MyProfile' className='text-decoration-none mb-0 text-white'>

        <span className='m-1 position-absolute' style={{ top: '-10%', left: '50%', transform: "translate(-50%,-50%)" }}>

          <Image roundedCircle
            style={{ objectFit: 'cover' }}
            width="65px"
            height="65px"
            onError={(e) => { e.target.onerror = null; e.target.src = User }}
            src=
            {luser.profile_picture_url
              ? `${luser.profile_picture_url}`
              : User
            }
            className="me-2 border border-4 rounded-circle border-white bg-white" />

        </span>


        <div className={`align-items-center text-nowrap mt-2 ${width < 576 ? 'flex-row text-center' : 'd-flex flex-column'}`}>

          <span className='mx-1 text-nowrap text-capitalize fw-bold lead'>
            {luser.blog_display_name ? luser.blog_display_name.toLowerCase() : ''}
          </span>

          <span className='text-center'>
            {userProf2}
          </span>

          <span className='mx-1 text-nowrap border px-2 rounded-2'>
            {t('words.view')} {t('title.profile')}
          </span>

        </div>

      </Link>

    </Card>
  )
}

export default CardProfile