import { Col, Container, Row } from 'react-bootstrap';
import LogoSimpleAnimated from '../../assets/LogoSimpleAnimated';
import { useTranslation } from 'react-i18next';


const LoadingData = ({ loadingApi }) => {

  const { t } = useTranslation();

  return (
    <Container fluid="md" className={!loadingApi ? 'd-none' : ''}>
      <Row className='justify-content-center'>
        <Col xs={12} className="text-center my-4">
          <p className='h1'>{t('title.loading')}</p>
        </Col>
        <Col xs={5} className="p-5">
          <LogoSimpleAnimated />
        </Col>
      </Row>
    </Container>
  )
}

export default LoadingData