//import
//const fetchUsers = new FetchUsersByCircle();
//fetchUsers.fetchListings(url, setter, setterSum, group)

import TokenRefresher from "./TokenRefresher";


//Saves and uses json after fetch with the useState of setter listi
export default class FetchUsersByCircle {

    constructor(ac) {
        this.ac = ac
    }

    //Bearer token
    async fetchListings(url, setter, setterSum, group, setLoading) {

        let getListing = async () => {

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;

            let data = {
                "ids": [parseInt(group)]
            }

            fetch(url, {
                method: 'POST',
                signal: this.ac,
                headers: {
                    'Authorization': bearer_token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
                credentials: 'include',
            })
                .then(res => res.json())
                .then(res => {
                    let myData = res;
                    setterSum(myData.length);

                    return myData;
                })
                .then(myData => {
                    setter(myData);
                    if (setLoading) setLoading(false)
                })

                .catch(err => {
                    console.error(err);
                    if (setLoading) setLoading(false);
                })

        }



        //checks and refresh token
        const sessionFetch = new TokenRefresher();
        sessionFetch.setTokenRefresh(getListing);

    }

}

