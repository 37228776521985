import { useEffect, useState } from "react";
import { Col, Form, Image, Row, CloseButton } from "react-bootstrap";
import heic2any from "heic2any";
import LoadingData from "./LoadingData";


const MultiImgUpBtn = ({ setGalleryUris }) => {

  const [galleryPreview, setGalleryPreview] = useState();
  const [selectedPics, setSelectedPics] = useState([]);


  //Image to data URI
  function getDataUrl(img) {
    // Create canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    // Set width and height
    canvas.width = img.width;
    canvas.height = img.height;
    // Draw the image
    ctx.drawImage(img, 0, 0);
    return canvas.toDataURL('image/jpeg');
  }


  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {

    if (!selectedPics) {
      setGalleryPreview(undefined);
      return;
    }


    let urlArray = [];

    async function convertHeic() {

      for (let i = 0; i < selectedPics.length; i++) {

        if (selectedPics[i].type === 'image/heic' || selectedPics[i].name.endsWith('.heic')) {

          heic2any({ blob: selectedPics[i], toType: "image/jpg", quality: 1 })
            .then((newImage) => { return URL.createObjectURL(newImage) })
            .then((data) => {
              setGalleryPreview([...urlArray, data]);
              urlArray.push(data);
            });
        }
        else {
          urlArray.push(
            URL.createObjectURL(selectedPics[i])
          )
        }

      }//end for loop
    }

    convertHeic();
    setGalleryPreview(urlArray);

    for (let i = 0; i < selectedPics.length; i++) {
      return () => URL.revokeObjectURL(selectedPics[i]);
    }

  }, [selectedPics])


  useEffect(() => {

    if (!galleryPreview || galleryPreview.length < 1) {
      setGalleryUris([]);
      return;
    }

    if (galleryPreview.length !== selectedPics.length) {
      return;
    }

    let imgsUriAll = [];

    for (let i = 0; i < galleryPreview.length; i++) {

      const img = document.getElementById("pic-" + i);

      img.addEventListener('load', function () {
        const dataUrl = getDataUrl(img);
        imgsUriAll.push(dataUrl);
      });
    }

    setGalleryUris(imgsUriAll);

    //eslint-disable-next-line
  }, [galleryPreview])




  const onSelectFile = e => {

    let filesAll = e.target.files;

    if (!filesAll || filesAll.length === 0) {
      setSelectedPics([])
      return;
    }

    setSelectedPics(filesAll)
  }

  //Delete
  const deletepic = () => {
    setSelectedPics([]);
    document.getElementById('input_gallery').value = '';
  }


  return (
    <>

      <Form.Control id="input_gallery" type="file" multiple={true} onChange={onSelectFile} accept=".png, .jpg, .jpeg, .gif, .heic" />

      {(selectedPics.length > 0) &&
        <CloseButton
          onClick={deletepic}
          className="position-absolute bg-white opacity-100 border border-4 border-danger"
          aria-label="Hide"
          style={{ zIndex: "1" }}
        />
      }

      {(selectedPics && galleryPreview) &&

        <Row className="gx-0">
          {
            galleryPreview.length !== selectedPics.length
              ? 
              <LoadingData loadingApi={true} />
              
              :
              galleryPreview.map((pic, key) => (

                <Col md={4} key={key}>

                  <Image id={`pic-${key}`} src={pic} className="d-none" />

                  <div className="ratio-cont">
                    <Image
                      className="ratio"
                      src={pic}
                      alt="preview"
                    />
                  </div>
                </Col>
              ))
          }

        </Row>
      }

    </>
  )
}

export default MultiImgUpBtn