/*global google*/
//above comment line is needed for es-lint to load google drawing manager options from google
import React, { useState, useEffect, Fragment } from "react";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  DrawingManager,
  MarkerClusterer,
} from "@react-google-maps/api";

import { Col, Row, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdDeleteForever } from "react-icons/md"
import { FcSearch, FcRadarPlot, FcInfo } from "react-icons/fc";
import CarouselItemListing from "../listings/CarouselItemListing";
import JobsCard from "../toolBox/jobs/JobsCard";
import ComparisonCard from "../toolBox/comparison/ComparisonCard";
import AgendaCard from "../toolBox/agenda/AgendaCard";
import { useTranslation } from 'react-i18next'
import useWindowDimensions from '../../functions/GetWidthHeight'
import ToastTimer from '../ToastTimer';

const silverMap = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#104C82"//"#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#E5E5F7"//"#c9c9c9"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#E5E5F7"//"#9e9e9e"
      }
    ]
  }
]

const polylinesDesign = {
  fillColor: `#2196F3`,
  strokeColor: `#2196F3`,
  fillOpacity: 0.5,
  strokeWeight: 2,
  clickable: true,
  editable: true,
  draggable: true,
  zIndex: 1,
}


function MapMain({ listing, type, setCoorPolygon }) {

  const { t } = useTranslation();
  const { width } = useWindowDimensions();


  useEffect(() => {
    setPlaces(listing);
  }, [listing]);

  let [places2, setPlaces] = useState(null);

  const [drawManage, setDrawManag] = useState(null)
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [markerMap, setMarkerMap] = useState({});
  const [center, setCenter] = useState({ lat: 45.77055, lng: -17.6468483 });
  const [zoom, setZoom] = useState(5);
  const [infoOpen, setInfoOpen] = useState(false);
  const [mapRef, setMapRef] = useState(null);
  const [polygonsRef, setPolygonsRef] = useState([]);
  const [showInfoDraw, setShowInfoDraw] = useState(false);
  let places = [{}];


  if (places2 !== null) {

    places2.forEach(plapla => {

      if (plapla.address && plapla.address.google_map && plapla.address.google_map !== '') {

        const lat_lng = plapla.address.google_map.split(",");
        const id = plapla.id;
        const pos = { lat: parseFloat(lat_lng[0]), lng: parseFloat(lat_lng[1]) }
        const lis = plapla;

        places.push({ id, pos, lis });
      }

    })

    places.shift();


    //filter for dublicate lat & lng and then add some so the markers dont overlap
    const lookup = places.reduce((a, e) => {
      a[e.pos.lat] = ++a[e.pos.lat] || 0;
      a[e.pos.lng] = ++a[e.pos.lng] || 0;
      return a;
    }, {});

    //i dont have any idea how "lookup" works i just copied it
    let dublicateLoc = places.filter(e => lookup[e.pos.lng] && lookup[e.pos.lat]);

    //save only the ids of dublicates
    dublicateLoc = dublicateLoc.map(v => v.id);

    //add a small value
    for (let i = 0; i < dublicateLoc.length; i++) {
      places.find(v => v.id === dublicateLoc[i]).pos.lat += i / 20000;
    }
    //console.log(dublicateLoc);
  }

  useEffect(() => {
    if (mapRef !== null && places2 !== null) {
      fitBounds(mapRef);
    }
    //eslint-disable-next-line
  }, [places2]);

  useEffect(() => {
    if (polygonsRef.length > 0) {
      removePoly();
    }
    //eslint-disable-next-line
  }, [type]);


  // Iterate myPlaces to size, center, and zoom map to contain all markers
  const fitBounds = map => {
    const bounds = new window.google.maps.LatLngBounds();
    if (places.length > 0) {
      places.map(place => {
        bounds.extend(place.pos);
        return place.id;
      });

      map.fitBounds(bounds);
      const zoom = map.getZoom();
      map.setZoom(zoom > 12 ? 12 : zoom);
    }
  };

  const loadHandler = map => {
    // Fit map bounds to contain all markers
    setMapRef(map)
    fitBounds(map);
  };

  // We have to create a mapping of our places to actual Marker objects
  const markerLoadHandler = (marker, place) => {
    return setMarkerMap(prevState => {
      return { ...prevState, [place.id]: marker };
    });
  };

  const markerClickHandler = (event, place) => {
    // Remember which place was clicked
    setSelectedPlace(place);
    // Required so clicking a 2nd marker works as expected
    if (infoOpen) {
      setInfoOpen(false);
    }
    setInfoOpen(true);
    // If you want to zoom in a little on marker click
    if (zoom > 5) {
      setZoom(8);
    }
    // if you want to center the selected Marker
    setCenter(place.pos)
  };


  //Polylines

  const onLoad = drawingManager => {
    setDrawManag(drawingManager)
  }

  const drawPoly = () => {
    drawManage.setDrawingMode(google.maps.drawing.OverlayType.POLYGON)
  }

  const removePoly = () => {

    for (let i = 0; i < polygonsRef.length; i++) {
      polygonsRef[i].setMap(null)
    }
    setPolygonsRef([]);
    setCoorPolygon([]);
  }

  const onPolygonComplete = polygon => {
    setPolygonsRef([...polygonsRef, polygon]);
    drawManage.setDrawingMode(null);
  }

  const searchPolygon = () => {

    if (polygonsRef.length === 0) {
      setShowInfoDraw(true)
      setTimeout(() => {
        setShowInfoDraw(false)
      }, 6000)
    }

    let coordinates = [];

    //This variable gets all bounds of polygon.
    for (let j = 0; j < polygonsRef.length; j++) {

      coordinates[j] = []

      const polygonBounds = polygonsRef[j].getPath();

      for (let i = 0; i < polygonBounds.length; i++) {
        coordinates[j].push([
          polygonBounds.getAt(i).lat(),
          polygonBounds.getAt(i).lng()
        ]);
      }
      coordinates[j].push([
        polygonBounds.getAt(0).lat(),
        polygonBounds.getAt(0).lng()
      ]);
    }//end for for single polygon coordinates

    setCoorPolygon(coordinates)

  }//end for all polygonz

  return (
    <>
      {places2 &&
        <Fragment>

          <GoogleMap
            onLoad={loadHandler}
            //onClick={e => setClickedLatLng(e.latLng.toJSON())}
            center={center}
            zoom={zoom}
            mapContainerStyle={{ height: "100%", width: "100%" }}
            options={{ styles: silverMap }}
            yesIWantToUseGoogleMapApiInternals
          >
            {/* https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m */}
            <MarkerClusterer options={{ imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' }} >
              {clusterer =>
                places.map(place => (
                  <Marker
                    key={place.id}
                    position={place.pos}
                    clusterer={clusterer}
                    onLoad={marker => markerLoadHandler(marker, place)}
                    onClick={event => markerClickHandler(event, place)}
                  />
                ))}
            </MarkerClusterer>

            <DrawingManager
              onLoad={onLoad}
              onPolygonComplete={onPolygonComplete}
              options={{
                drawingControl: true,
                drawingControlOptions: {
                  drawingModes: ["polygon"],
                  position: google && google.maps.ControlPosition.TOP_CENTER,
                },
                polygonOptions: polylinesDesign,

              }}
            />

            {infoOpen && selectedPlace && (
              <InfoWindow
                anchor={markerMap[selectedPlace.id]}
                onCloseClick={() => setInfoOpen(false)}
              >
                {type === 'rent' || type === 'sale' || type === 'request' || type === 'partnership'
                  ? <CarouselItemListing listing={selectedPlace.lis} mainTypeListing={type} />

                  : type === 'job'
                    ? <JobsCard listing={selectedPlace.lis} />

                    : type === 'benchmark'
                      ? <ComparisonCard listing={selectedPlace.lis} />

                      : type === 'event'
                        ? <AgendaCard listing={selectedPlace.lis} />

                        : ''
                }

              </InfoWindow>
            )}
          </GoogleMap>

          <Row className="position-absolute top-0 w-100 gx-0 justify-content-center align-items-center p-1 px-2 px-md-4" style={{ background: "#F5F5F5" }}>

            <Col className="text-center">

              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip> {t('text.polygon1')}</Tooltip>}
              >
                <Button onClick={drawPoly} variant="outline-success" size={width < 576 ? 'sm' : 'lg'} className="mx-lg-5 mx-sm-0 p-2 shadow rounded-circle bg-white">
                  <FcRadarPlot size={width < 576 ? 25 : 35} />
                </Button>

              </OverlayTrigger>

              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip> {t('text.polygon2')}</Tooltip>}
              >
                <Button onClick={removePoly} variant="outline-danger" size={width < 576 ? 'sm' : 'lg'} className="p-2 shadow rounded-circle me-4">
                  <MdDeleteForever size={width < 576 ? 25 : 35} />
                </Button>

              </OverlayTrigger>
            </Col>

            <Col>
              <Button onClick={searchPolygon} variant="outline-success" size={width < 576 ? 'sm' : 'lg'} className="px-4 fw-bold shadow rounded-pill text-nowrap">
                <FcSearch /> {t('text.polygon3')}
              </Button>
            </Col>

            {showInfoDraw &&
              <Col sm={12} >
                <ToastTimer
                  color='info'
                  text={<><FcInfo size={23} /> {t('notif.notif14')}</>}
                  time={6000}
                />
              </Col>
            }
          </Row>

        </Fragment>

      }
    </>
  )

}
export default MapMain

/* Our center position always in state */
/* <h3>
          Center {center.lat}, {center.lng}
        </h3> */

/* Position of the user's map click */
/* {selectedPlace && <h3>Selected Marker: {selectedPlace.id}</h3>} */
