import React from "react";
import ContentLoader from "react-content-loader";
const CirclesLoader = (props) => {
  const width = "100%";
  const height = "30%";
  return (
    <>
      <ContentLoader
        speed={1}
        width={500}
        height={70}
        viewBox="0 0 500 70"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        style={{ maxWidth: "90%", maxHeight: `90%` }}
        {...props}
      >
        <rect x="5%" y="20%" rx="0" ry="0" width={width} height={height} />
        <rect x="5%" y="70%" rx="0" ry="0" width={width} height={height} />
      </ContentLoader>
    </>
  );
};

export default CirclesLoader;
