import React from 'react'
import { Row, Col } from 'react-bootstrap'

import MapMain from '../maps/MapMain'
import ToolPageMain from './ToolPageMain'

import { google_maps_api_key, libraries } from '../../functions/ConstantVariables';
import { Loader } from '@googlemaps/js-api-loader';
import useWindowDimensions from '../../functions/GetWidthHeight'


const ToolboxIndex = ({ typeListing }) => {

    const { width } = useWindowDimensions();

    //google maps
    const [isLoaded, setIsLoaded] = React.useState(false);

    const loader = new Loader({
        apiKey: google_maps_api_key,
        version: "weekly",
        libraries
    });
    // Promise
    loader
        .load()
        .then((google) => { setIsLoaded(true) })

    const [listings, setlists] = React.useState(null);

    //Map Responsive
    const [open, setOpen] = React.useState(width < 1200 ? 1 : 0);

    //google polygon
    const [coorPolygon, setCoorPolygon] = React.useState([]);

    React.useEffect(() => {
        setOpen(typeListing === 'article' || typeListing === 'crm' ? 1 : open)
        if (coorPolygon.length > 0) {
            setCoorPolygon([])
        }
        //eslint-disable-next-line
    }, [typeListing])



    return isLoaded ? (

        <Row className={`g-0 justify-content-end `}>

            <Col id="map-col" className={open === 1 ? 'd-none' : open === 2 ? 'min-vh-100' : ''}
                xl={open === 1 ? 0 : open === 2 ? 12 : 6}
            >
                {(typeListing === "job" || typeListing === "benchmark" || typeListing === "event")
                    && <MapMain listing={JSON.parse(listings)} type={typeListing} setCoorPolygon={setCoorPolygon} />
                }


            </Col>


            <Col className='listingCol' xl={open !== 0 ? 12 : 6} id="top">

                <Row className="m-0">

                    <ToolPageMain
                        open={open}
                        setOpen={setOpen}
                        mainTypeListing={typeListing}
                        listi={listings}
                        setter={setlists}
                        coorPolygon={coorPolygon}
                    />

                </Row>
            </Col>

        </Row>
    ) : <></>
}

export default ToolboxIndex
