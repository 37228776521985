import React from 'react'
import { useTranslation } from 'react-i18next'
//import { ListGroup, Button } from 'react-bootstrap'
//import { TiWarning } from 'react-icons/ti'



const CancelSubscription = () => {

  const { t } = useTranslation();

  /* const resetSteps = [
    { number: 1, text: "Reference site about Lorem Ipsum, giving information on its origins" },
    { number: 2, text: "Reference site about Lorem Ipsum, giving information on its origins" },
    { number: 3, text: "Reference site about Lorem Ipsum, giving information on its origins" },
    { number: 4, text: "Reference site about Lorem Ipsum, giving information on its origins" },
    { number: 5, text: "Reference site about Lorem Ipsum, giving information on its origins" },
  ]; */

  /* const deleteAccount = () => {

    if (window.confirm(t('Are you sure you want to cancel your subscription')) === true) {
      window.location.reload(true)
    }
  } */


  return (
    <>

      <p className='lead text-center'>{t('profile.bill8')}</p>
      {/* {resetSteps.map((step, key) => (

        <ListGroup horizontal="xxl" className="mb-2" key={key}>
          <ListGroup.Item variant="danger" className='text-nowrap'>Step {step.number}</ListGroup.Item>
          <ListGroup.Item className='w-100'>{step.text}</ListGroup.Item>
        </ListGroup>

      ))}

      <Button variant="danger" size="lg" onClick={deleteAccount}>
        <TiWarning size={23} className="me-2" /> Cancel your Subscription
      </Button> */}
    </>
  )
}

export default CancelSubscription