import TokenRefresher from "./TokenRefresher";

const deleteAccountPermantly = async (setStatus, payload, setLoading) => {


    const url = `${process.env.REACT_APP_USERINFO2_API}/delete-self`

    const delAcc = async function () {
        try {

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;

            const res = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Authorization': bearer_token,
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify(payload),
                credentials: 'include'
            })
            if (res.status >= 200 && res.status <= 299) {

                localStorage.clear();
                setLoading(false)
                setStatus("success")
                setTimeout(() => {
                    window.location.href = '/home';
                }, 1000)

            } else {
                setStatus("danger")

            }

        } catch (error) {
            console.log(error)
            setStatus("danger")

        } finally {
            setLoading(false)

        }
    }
    const sessionFetch = new TokenRefresher();
    sessionFetch.setTokenRefresh(delAcc);
}


export default deleteAccountPermantly