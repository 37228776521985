import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from "react-router-dom";

import { Badge, Card, Col, Row, Image } from 'react-bootstrap'
import houseExample from "../../assets/houseExample.jpg"

//Pages
import PropertyPage from '../../pages/PropertyPage';
import FeedItemActions from './FeedItemActions';
import FeedItemAuthor from './FeedItemAuthor';


const FeedItemMain = ({ listing, catType }) => {

  const { t } = useTranslation();

  const lisLink = `${process.env.REACT_APP_URL}/listing/${catType}/${listing.id}/${listing.article.slug}/`

  return (

    <Card className="mt-4 border-0 shadow">
      <Row className='p-3 justify-content-between'>

        <h6><strong>
          {listing.article.title}
          {listing.number
            && <span className='small fw-bold text-muted'>&nbsp;({listing.number})</span>
          }
        </strong></h6>

        <Col>
          <span className='d-inline-flex'>
            <Badge bg="warning" className='text-light me-2 text-capitalize'>

              {catType === 'sale' ? t('cats_tools.sale')
                : catType === 'rent' ? t('cats_tools.rent')
                  : catType === 'request' ? t('cats_tools.request')
                    : t('cats_tools.partnership')}

            </Badge>

            <Badge bg="graylight" className='text-black'>

              {listing.ad_type && listing.ad_type.name}

              {listing.request_type && listing.request_type.name}

              {listing.partner_type && listing.partner_type.name}

            </Badge>
          </span>

        </Col>


        <FeedItemActions
          lisLink={lisLink}
          listingType={catType}
          listing={listing}
        />

      </Row>

      <Link
        component={<PropertyPage />}
        to={`/listing/${catType}/${listing.id}/${listing.article.slug}/`}
        className="text-decoration-none text-dark"
      >
        <Row className='gx-0 px-3 bg-white'>

          <Col sm={6}>
            <div className="ratio-cont">
              <Image
                className="ratio"
                src=
                {
                  listing.article.excerpt_photo_url
                    ? process.env.REACT_APP_DOMAIN + listing.article.excerpt_photo_url + '?photo_height=400&photo_width=400'
                    : houseExample
                }
                alt="listing" />
            </div>
          </Col>



          <Col className="bg-light small px-4 py-2">

            {listing.article.excerpt &&
              <div className='bg-primary p-2 rounded-4 mb-2 text-white'>
                <p className='mb-0'>{listing.article.excerpt}</p>
              </div>
            }


            <div className='bg-white p-2 rounded-4'>
              {listing.price && <><span className='fw-bold'>{t('words.price')}: </span>{listing.price} &euro;<br /></>}

              {listing.ad_type && <><span className='fw-bold'>{t('words.estate')}: </span>{listing.ad_type.name}<br /></>}

              {listing.request_type && <><span className='fw-bold'>{t('words.reqType')}: </span>{listing.request_type.name}<br /></>}

              {listing.partner_type && <><span className='fw-bold'>{t('words.partType')}: </span>{listing.partner_type.name}<br /></>}

              {listing.buy_type && <><span className='fw-bold'>{t('words.saleType')}: </span>{listing.buy_type.name}<br /></>}

              {listing.bail_type && <><span className='fw-bold'>{t('words.lease')}: </span>{listing.bail_type.name}<br /></>}

              {listing.area && <><span className='fw-bold'>{t('text.area_m2')}: </span>{listing.area} &#13217;<br /></>}

              {listing.address && <><span className='fw-bold'>{t('title.loc')}: </span>{listing.address.city ? listing.address.city : listing.address.country}<br /></>}
            </div>

          </Col>

        </Row>

      </Link>
      <hr />

      <FeedItemAuthor author={listing.article.author} />

    </Card >

  )
}

export default FeedItemMain