import { Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


const SuccessRedirect = ({ type, success }) => {

  const { t } = useTranslation();

  return (

    <Container fluid="md">
      <Row className='justify-content-center'>
        <Col xs={12} md={7} lg={5} className="p-5">
          <Card border="warning" className="text-center shadow-lg my-5">
            <Card.Header>{t('title.success')}!</Card.Header>
            <Card.Body className='mb-4'>
              <Card.Title className='py-4'>{t('bigtext.createSuc')}</Card.Title>
              <Card.Text className='pb-4'>
                {t('bigtext.doNext')}
              </Card.Text>

              <Link
                to="/home"
                className="btn btn-outline-warning mx-2 fw-bold rounded-4 border-3 mb-2"
              >
                {t('text.goHome')}
              </Link>

              <Link
                to={`/listing/${type}/${success.id}/${success.article
                  ? success.article.slug
                  : success.slug !== undefined
                    ? success.slug
                    : ''
                  }`}
                className="btn btn-outline-success mx-2 fw-bold rounded-4 border-3 mb-2"
              >
                {t('text.seeLis')}
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>

  )
}

export default SuccessRedirect