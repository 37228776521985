import React from 'react';
import { useTranslation } from 'react-i18next';
import countries from "i18n-iso-countries";
import Select from 'react-select';
import { Form } from 'react-bootstrap';


const LocationDropdown = ({ size, valueCountry, countryExistsEdit, disabled = false, profile = false }) => {

    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/el.json"));

    const { t, i18n} = useTranslation();

    //this is for existing country for edit form
    let country_for_select = {};
    if (countryExistsEdit) {
        const country_label = countries.getName(countryExistsEdit, i18n.language, { select: "official" });

        country_for_select = {
            label: country_label,
            value: countryExistsEdit
        }
    }

    const countryObj = countries.getNames(i18n.language, { select: "official" });

    const countryArr = Object.entries(countryObj).map(([key, value]) => {
        return {
            label: value,
            value: key
        };
    });

    const [selectedOption, setSelectedOption] = React.useState(
        countryExistsEdit
            ? country_for_select
            : null
    );

    React.useEffect(() => {

        if (valueCountry !== null) {
            setSelectedOption(null);
        }

    }, [valueCountry])

    return (
        <>
            {disabled &&
                <Form.Control
                    readOnly
                    type="text"
                    placeholder='Country'
                    maxLength="3"
                    defaultValue={profile ? profile : null}
                    className="noFocus"
                    disabled={true}
                />
            }
            {valueCountry !== null && !disabled
                ?
                <Form.Control
                    readOnly
                    type="text"
                    value={valueCountry.name}
                    maxLength="3"
                    required
                />

                :
                <>
                    {!disabled && <>

                        < Select
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={countryArr}
                            className={size === 'sm' && 'react-select-sm'}
                            placeholder={t('title.country')}

                        />
                        <Form.Control
                            readOnly
                            type="text"
                            id="country_select"
                            value={selectedOption === null ? '' : selectedOption}
                            className='d-none'
                        />
                    </>}
                </>

            }

            {(selectedOption !== null || valueCountry !== null) &&
                <Form.Control
                    readOnly
                    required
                    type="text"
                    id="lisCountry"
                    value={valueCountry !== null ? valueCountry.value : selectedOption.value}
                    maxLength="3"
                    className='d-none'
                />
            }

        </>
    )
}

LocationDropdown.defaultProps = {
    valueCountry: null,
    size: false
}

export default LocationDropdown