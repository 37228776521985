import React from "react";
import ContentLoader from "react-content-loader";
const innerWidth = window.innerWidth;
const viewBoxWidth = `0 0 ${innerWidth * 0.75} 40`;
const width = "20%";
const height = "100%";

const NetworkTabInNetworkPageLoader = (props) => {
  return (
    <>
      <ContentLoader
        speed={1}
        width={innerWidth * 0.75}
        height={40}
        viewBox={viewBoxWidth}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="2%" y="5%" rx="0" ry="0" width={width} height={height} />
        <rect x="24%" y="5%" rx="0" ry="0" width={width} height={height} />
        <rect x="46%" y="5%" rx="0" ry="0" width={width} height={height} />
        <rect x="68%" y="5%" rx="0" ry="0" width={width} height={height} />
      </ContentLoader>
    </>
  );
};

export default NetworkTabInNetworkPageLoader;
