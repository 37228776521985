import React from 'react';
import { Container, FormSelect, Nav, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MyPagination from '../MyPagination';
import FragmentedCircleLoader from '../Loaders/FragmentedCircleLoader';
import SearchKeywordsBlog from './SearchKeywordsBlog';
import FetchBlog from '../../jwtAPI/FetchBlog';
import BlogCard from './BlogCard';

const BlogIndex = () => {

    const { t } = useTranslation();

    const controller = new AbortController();
    const ac = controller.signal;

    const [loading, setLoading] = React.useState(true);

    const url = process.env.REACT_APP_BLOG;

    const [currentPage, setCurrentPage] = React.useState(1);

    const [sumListing, setSumListing] = React.useState(0);

    let [allListings, setAllListings] = React.useState([]);


    // limit
    const [perPage, setPerPage] = React.useState(6);

    // object to send
    const [parameters, setParameters] = React.useState( //setParameters
        {
            "page": currentPage,
            "limit": perPage,
            order_by: "date",
            sort: "desc",
        }
    )

    function changeLimit(limit) {
        setPerPage(limit)
        setParameters({
            ...parameters,
            "limit": limit
        })
    }
    function changePage(num) {
        setCurrentPage(num)
        setParameters({
            ...parameters,
            "page": num
        })
    }

    function setSort(sort) {

        if (sort === "dateasc") {
            setParameters({ ...parameters, "order_by": "date", "sort": "asc" })
        } else if (sort === "datedesc") {
            setParameters({ ...parameters, "order_by": "date", "sort": "desc" })
        } else if (sort === "nameasc") {
            setParameters({ ...parameters, "order_by": "title", "sort": "asc" })
        } else {
            setParameters({ ...parameters, "order_by": "title", "sort": "desc" })
        }
    }

    function changeLang(lang) {
        setParameters({

        })
        if (lang !== "") {
            setParameters({
                ...parameters,
                "language": lang
            })
        } else {
            delete parameters.language
            setParameters({
                ...parameters
            })
        }
    }

    function keywords(e) {
        if (e.length > 0) {
            setParameters({
                ...parameters,
                "keywords": e
            })
        } else {
            setParameters({
                ...parameters,
                "keywords": []
            })
        }
    }


    function cleanUp() {
        controller.abort()
        setLoading(false)
    }
    React.useEffect(() => {
        return () => {
            cleanUp()
        }
        // eslint-disable-next-line
    }, [])



    React.useEffect(() => {

        setLoading(true)
        const fetchLists = new FetchBlog(ac);
        fetchLists.fetchListings(url, setAllListings, parameters, setLoading, setSumListing);

        // eslint-disable-next-line
    }, [url, parameters]);



    return (
        <Container fluid="xxl" className="p-0">

            <Nav className='py-5 justify-content-center align-items-center bg-white shadow'>
                <FormSelect
                    id="myListsLimit"
                    onChange={(e) => changeLimit(e.target.value)}
                    className="w-auto ms-2 ps-1 border-0 rounded-0 bg-white text-primary"
                    style={{ padding: '0 1.6rem 0 0' }}
                >
                    <option value="6">6</option>
                    <option value="12">12</option>
                    <option value="24">24</option>
                    <option value="50">50</option>
                </FormSelect>

                <span className="me-2 py-3">{t('words.per')} {t('words.page')}</span>

                <span className='ms-3 py-3'>{t('words.sortby')}:</span>

                <FormSelect
                    id="profListSort"
                    onChange={(e) => setSort(e.target.value)}
                    className="w-auto ms-2 ps-1 border-0 rounded-0 bg-white text-primary"
                    style={{ padding: '0 1.6rem 0 0' }}
                >
                    <option value="dateasc">{t('title.date')} &darr;</option>
                    <option value="datedesc">{t('title.date')} &uarr;</option>
                    <option value="nameasc">{t('title.name')} &darr;</option>
                    <option value="namedesc">{t('title.name')} &uarr;</option>
                </FormSelect>

                <FormSelect
                    id="locale"
                    onChange={(e) => changeLang(e.target.value)}
                    className="w-auto ms-2 ps-1 border-0 rounded-0 bg-white text-primary"
                    style={{ padding: '0 1.6rem 0 0' }}
                >
                    <option value="">{t('words.lang')} ({t('words.all')})</option>
                    <option value="fr">FR</option>
                    <option value="en">EN</option>
                    <option value="nl">NL</option>
                    <option value="el">EL</option>
                </FormSelect>

                <SearchKeywordsBlog searchFunc={keywords} idSearch='profSearch' loading={loading} />
            </Nav>

            <Row className="bg-light border border-top-0 p-0 p-lg-4 mx-0">

                {loading ?

                    <div style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
                        <FragmentedCircleLoader color={"black"} />
                    </div>
                    : <>

                        {allListings !== [] && allListings.map((listing, key) => (
                            <BlogCard listing={listing} key={key} />
                        ))}

                        {allListings.length < 1 && <p>{t('bigtext.notFoundFilters1')}</p>}
                    </>}


                {(!loading && sumListing > 0) &&
                    <MyPagination
                        itemsCount={sumListing}
                        itemsPerPage={perPage}
                        currentPage={currentPage}
                        setCurrentPage={changePage}
                        alwaysShown={false}
                    />
                }

            </Row>
        </Container>
    )
}

export default BlogIndex