import React from 'react'
import { Col, Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next'
import FetchDropdowns from "../../../jwtAPI/FetchDropdowns";
import { useSelector } from "react-redux/";


const PartnershipDropdown = ({ create, listing, col, getDataDrops, getData }) => {

    //create => create new form
    //listing => edit listing form
    //both false is from filters form

    const { t, i18n } = useTranslation();

    const optionsRedux = useSelector(state => state.dropTypes[i18n.language]["partnership"])

    const [options, setOptions] = React.useState(optionsRedux);

    React.useEffect(() => {

        if (optionsRedux !== undefined) {
            setOptions(optionsRedux)
            return;
        }

        const fetchTypes = new FetchDropdowns();
        fetchTypes.fetchDrops('partnership', setOptions, i18n.language)

        //eslint-disable-next-line
    }, [i18n.language]);


    const [showDrop, setShowDrop] = React.useState(listing ? listing.partner_type.id.toString() : '');
    const handleReqType = (e) => { setShowDrop(e.target.value); getDataDrops(e); }

    //only for filters form
    React.useEffect(() => {

        if (listing || create) { return; }

        //resetValues('start_up_type')
        resetValues('project_development_type')
        resetValues('know_how_type')

        //eslint-disable-next-line
    }, [showDrop]);


    function resetValues(nameDrop) {
        //deletes unecessary fields if the partner type changes by making the value "all" in getDataDrops
        const deleteFromObj = {
            target: {
                name: nameDrop,
                value: 'all',
            }
        }
        getDataDrops(deleteFromObj)
    }

    return (
        <>

            {
                (options && create) || (options && listing)
                    ?
                    <>
                        <Col md={col}>
                            <Form.Label>{t('words.partType')}*</Form.Label>

                            <Form.Select
                                defaultValue={showDrop}
                                required
                                onChange={handleReqType}
                                name="partner_type"
                            >
                                <option></option>
                                {options.partnership.map((option, key) => (
                                    <option key={key} value={option.id}>{option.name}</option>
                                ))}
                            </Form.Select>
                        </Col>

                        {showDrop === '30' &&
                            <Col md={col}>
                                <Form.Label>{t('words.development')}*</Form.Label>

                                <Form.Select
                                    defaultValue={(listing && listing.project_development_type) && listing.project_development_type.id}
                                    required
                                    name="project_development_type"
                                    onChange={getDataDrops}
                                >
                                    <option></option>
                                    {options.project_development_type.map((option, key) => (
                                        <option key={key} value={option.id}>{option.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        }

                        {(showDrop === '31' || showDrop === '32') &&
                            <Form.Group as={Col} md={col} controlId="lisPrice">
                                <Form.Label>{t('words.price')} &euro;</Form.Label>

                                <Form.Control
                                    type="number"
                                    defaultValue={listing && listing.price}
                                    name="price"
                                    onChange={getData}
                                />
                            </Form.Group>
                        }

                        {showDrop === '33' &&
                            <Col md={col}>
                                <Form.Label>{t('words.profType')}*</Form.Label>

                                <Form.Select
                                    defaultValue={(listing && listing.know_how_type) && listing.know_how_type.id}
                                    required
                                    name="know_how_type"
                                    onChange={getDataDrops}
                                >
                                    <option></option>
                                    {options.know_how_type.map((option, key) => (
                                        <option key={key} value={option.id}>{option.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        }

                        {/* {showDrop === '47' &&
                            <Col md={col}>
                                <Form.Label>{t('words.startup')}*</Form.Label>

                                <Form.Select
                                    defaultValue={(listing && listing.start_up_type) && listing.start_up_type.id}
                                    required
                                    name="start_up_type"
                                    onChange={getDataDrops}
                                >
                                    <option></option>
                                    {options.know_how_type.map((option, key) => (
                                        <option key={key} value={option.id}>{option.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        } */}
                    </>

                    : options && !create && !listing
                        ?
                        <>
                            <Col md={col}>
                                <Form.Label className="small mb-0">{t('words.partType')}</Form.Label>

                                <Form.Select name='partner_type' id='partner_type' size="sm" onChange={handleReqType}>
                                    <option value='all'>{t('words.partType')} ({t('words.all')})</option>
                                    {options.partnership.map((option, key) => (
                                        <option key={key} value={option.id}>{option.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>

                            {showDrop === '30' &&
                                <Col md={col}>
                                    <Form.Label className="small mb-0">{t('words.development')}</Form.Label>

                                    <Form.Select
                                        name="project_development_type"
                                        id="project_development_type"
                                        onChange={getDataDrops}
                                        size="sm"
                                    >
                                        <option value='all'>{t('words.development')} ({t('words.all')})</option>
                                        {options.project_development_type.map((option, key) => (
                                            <option key={key} value={option.id}>{option.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                            }

                            {showDrop === '33' &&
                                <Col md={col} className="small mb-0">
                                    <Form.Label>{t('words.profType')}</Form.Label>

                                    <Form.Select
                                        name="know_how_type"
                                        id="know_how_type"
                                        onChange={getDataDrops}
                                        size="sm"
                                    >
                                        <option value='all'>{t('words.profType')} ({t('words.all')})</option>
                                        {options.know_how_type.map((option, key) => (
                                            <option key={key} value={option.id}>{option.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                            }

                            {/* {showDrop === '47' &&
                                <Col md={col} className="small mb-0">
                                    <Form.Label>{t('words.startup')} </Form.Label>

                                    <Form.Select
                                        name="start_up_type"
                                        id="start_up_type"
                                        onChange={getDataDrops}
                                        size="sm"
                                    >
                                        <option value='all'>{t('words.startup')} ({t('words.all')})</option>
                                        {options.start_up_type.map((option, key) => (
                                            <option key={key} value={option.id}>{option.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                            } */}

                        </>

                        : ''
            }

        </>
    )
}

export default PartnershipDropdown