import { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next'
import FetchDropdowns from "../../../jwtAPI/FetchDropdowns";
import { useSelector } from "react-redux/";


function AgendaDropdown({ create, listing, getDataDrops, col }) {

    const { t, i18n } = useTranslation();

    const optionsRedux = useSelector(state => state.dropTypes[i18n.language]["event-types"])

    const [options, setOptions] = useState(optionsRedux);

    useEffect(() => {

        if (optionsRedux !== undefined) {
            setOptions(optionsRedux)
            return;
        }

        const fetchTypes = new FetchDropdowns();
        fetchTypes.fetchDrops('event-types', setOptions, i18n.language)

        //eslint-disable-next-line
    }, [i18n.language]);


    return (
        <>
            {
                (options && create) || (options && listing)
                    ?
                    <>
                        <Col md={col}>
                            <Form.Label>{t('words.eventType')}*</Form.Label>
                            <Form.Select
                                id="eventTypes"
                                defaultValue={listing && listing.event_type.id}
                                required
                                name="event_type"
                                onChange={getDataDrops}
                            >
                                <option></option>
                                {options.events.map((option, key) => (
                                    <option key={key} value={option.id}>{option.name}</option>
                                ))}
                            </Form.Select>
                        </Col>
                    </>

                    : options && !create && !listing
                        ?
                        <>
                            <Col md={col}>
                                <Form.Label className="small mb-0">{t('words.eventType')}</Form.Label>
                                <Form.Select size="sm" onChange={getDataDrops} id="event_type" name="event_type">
                                    <option value="all">{t('words.eventType')} ({t('words.all')})</option>
                                    {options.events.map((option, key) => (
                                        <option key={key} value={option.id}>{option.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </>

                        : ''
            }

        </>
    )
}

export default AgendaDropdown
