import { useTranslation } from 'react-i18next'
import React from 'react'
import { Col, Nav } from 'react-bootstrap';
import FetchGetSetSimpleNetwork from '../../jwtAPI/FetchGetSetSimpleNetwork';
import FetchUsersByCircle from '../../jwtAPI/FetchUsersByCircle';
import FetchNetNumbers from '../../jwtAPI/FetchNetNumbers';
import MyPagination from '../MyPagination';
import UserCard from './UserCard';
import FetchGetSetSimple from '../../jwtAPI/FetchGetSetSimple';
import FragmentedCircleLoader from '../Loaders/FragmentedCircleLoader';
import { useLocation } from "react-router-dom";




const MyNetwork = ({ url, sumNet, setterSum, searchByGroup, circles }) => {

    const { t } = useTranslation();
    const location = useLocation().hash;

    //number for friends, friend req, pending, blocked
    let [sumNetInitial, setSumInitial] = React.useState(null)

    const controller = new AbortController()
    const ac = controller.signal
    let [userNet, setUserNet] = React.useState(null)

    const [currentPage, setCurrentPage] = React.useState(1);
    const [loading, setLoading] = React.useState(true);

    let urlNew;

    const cleanUp = () => {
        setCurrentPage(1)
        setUserNet(null)
        setLoading(false)
        controller.abort()
    }
    //if url with search parameters for name or job else all
    if (!url) {
        urlNew = process.env.REACT_APP_USERBYCIRCLE_API;
    }
    else {
        urlNew = url;
    }

    urlNew = urlNew.replace(/page=1/, 'page=' + currentPage);

    //Changes the url for Navs; if its navigated from notifications goes to friend request
    const [eventKey, setEventKey] = React.useState(location && location === '#2' ? '2' : "1")


    React.useEffect(() => {

        //Needed for fetch
        const type_of_parse_for_fetch = true;

        //No search parameters & All friends
        if (!url && eventKey === "1") {
            setLoading(true)
            const fetchUsers = new FetchUsersByCircle(ac);
            fetchUsers.fetchListings(urlNew, setUserNet, setterSum, searchByGroup, setLoading);
        }
        //Friend requests
        else if (eventKey === "2") {
            setLoading(true)
            const key_url = process.env.REACT_APP_FRIENDPEND_LIST_API.replace(/page=1/, 'page=' + currentPage);
            const fetchLists = new FetchGetSetSimple(ac);
            fetchLists.fetchListings(key_url, setUserNet, setterSum, type_of_parse_for_fetch, setLoading);
        }
        //Users's pending requests
        else if (eventKey === "3") {
            setLoading(true)
            const key_url = process.env.REACT_APP_FRIENDREQ_LIST_API.replace(/page=1/, 'page=' + currentPage);
            const fetchLists = new FetchGetSetSimple(ac);
            fetchLists.fetchListings(key_url, setUserNet, setterSum, type_of_parse_for_fetch, setLoading);
        }
        //Blocked users
        else if (eventKey === "4") {
            setLoading(true)
            const key_url = process.env.REACT_APP_BLOCKEDUSERS_API.replace(/page=1/, 'page=' + currentPage);
            const fetchLists = new FetchGetSetSimple(ac);
            fetchLists.fetchListings(key_url, setUserNet, setterSum, type_of_parse_for_fetch, setLoading);
        }
        //All Friends with or without search parameters
        else if (url && eventKey === "1") {
            setLoading(true)
            const fetchLists = new FetchGetSetSimpleNetwork(ac);
            fetchLists.fetchListings(urlNew.replace(/network_only=false/, 'network_only=true'), setUserNet, setterSum, setLoading);
        }

        // eslint-disable-next-line
    }, [urlNew, currentPage, setterSum, url, searchByGroup, eventKey]);

    React.useEffect(() => {

        const initialNums = new FetchNetNumbers();
        initialNums.fetchListings(setSumInitial);

        return () => {
            cleanUp()
        }
        // eslint-disable-next-line
    }, [])


    return (
        <>

            <>
                {sumNetInitial &&
                    <Col xs={12} id="myNet-tabs">
                        <Nav justify variant="tabs" defaultActiveKey={eventKey} onSelect={(key) => setEventKey(key)} className="fw-bold">
                            <Nav.Item onClick={() => setLoading(true)}>
                                <Nav.Link eventKey="1"

                                >
                                    {t('title.net2')} 
                                    ({eventKey === "1" && sumNet &&!loading ? sumNet : sumNetInitial.number_of_friends})
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="2"
                                    onClick={() => setLoading(true)}
                                >
                                    {t('words.friendreq')}
                                    ({eventKey === "2" && sumNet &&!loading ? sumNet : sumNetInitial.number_of_friend_requests})
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="3"
                                    onClick={() => setLoading(true)}
                                >
                                    {t('words.pending')}&nbsp;{t('words.req2')}
                                    ({eventKey === "3" && sumNet &&!loading ? sumNet : sumNetInitial.number_of_pending_friend_requests})
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="4"
                                    onClick={() => setLoading(true)}
                                >
                                    {t('words.blockedUsers')}
                                    ({eventKey === "4" && sumNet &&!loading ? sumNet : sumNetInitial.number_of_blocked_users})
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                }
                {loading ?
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}> <FragmentedCircleLoader /> </div>
                    : (
                        <>
                            {userNet && sumNet !== 0

                                ?
                                <>
                                    {userNet.map((agent, key) => (
                                        <Col xl={6} className="mt-3" key={key} >
                                            <UserCard
                                                searchByGroup={searchByGroup}
                                                agent={agent}
                                                myNet={true}
                                                setUserNet={setUserNet}
                                                userNet={userNet}
                                                circles={circles}
                                                eventKey={eventKey}
                                            />
                                        </Col>
                                    ))}

                                    {sumNet > 0 &&
                                        <MyPagination
                                            itemsCount={sumNet}
                                            itemsPerPage={12}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            alwaysShown={false}
                                        />
                                    }

                                </>

                                :
                                <Col xs={12} className="mt-3">
                                    {t('bigtext.noUserSearch')}
                                </Col>
                            }
                        </>
                    )}

            </>

        </>
    )
}

export default MyNetwork