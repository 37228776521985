import TokenRefresher from "./TokenRefresher";


//Saves and uses json after fetch with the useState of setter listi
export default class FetchNetNumbers {


    //Bearer token
    async fetchListings(setNumbers) {

        let getListing = async () => {

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;
            const url = `${process.env.REACT_APP_DOMAIN}/en/api/network/count`;

            
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': bearer_token,
                },
                credentials: 'include',
            })
                .then(res => res.json())
                .then(res => {
                    setNumbers(res)
                })

                .catch(err => console.error(err))
        }

        //checks and refresh token
        const sessionFetch = new TokenRefresher();
        sessionFetch.setTokenRefresh(getListing);

    }

}

