import { Card, Col, Row } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import Plans from "./Plans";


const ProfileMyPlan = () => {

    const { t } = useTranslation();

    return (
        <>
            <Card as={Row} border="white" className="flex-row p-4 rounded-4 mx-lg-4 mb-5 justify-content-around" id="your-plan">

                <h5 className='text-center mb-4 mt-4 fw-bold'>{t('text.yourCurPlan')}</h5>

                <Col lg={3} md={4} className="border border-primary col-plan">
                    <p className="title-plan bg-primary">{t('profile.plan1')}</p>
                    <p>{t('profile.plan2')} <strong>{t('profile.plan3')}</strong></p>
                    <p>{t('profile.plan4')}</p>
                </Col>

                <Col lg={3} md={4} className="border border-info col-plan">
                    <p className="title-plan bg-info">{t('words.info')}</p>
                    {/* <p className="mb-1">{t('profile.plan5')}: <strong>//////23/09/2022</strong></p> */}
                    <p>{t('profile.plan6')}</p>
                    {/* <p>Your plan expires: <strong>23/02/2023</strong></p>
                    <p>See below to renew your subcription</p> */}
                </Col>

                <Col lg={3} md={4} className="border border-danger col-plan">
                    <p className="title-plan bg-danger">{t('profile.plan7')}</p>
                    <p>{t('profile.plan8')}</p>
                    {/* <p>To cancel your subscription press the button below. Your subscription will still be active </p>
                <Button variant="outline-danger" className="border-2, .rounded-pill, .fw-bold">
                    Cancel your Subscription
                </Button> */}
                </Col>

            </Card>

            <Plans />
        </>
    )
}

export default ProfileMyPlan