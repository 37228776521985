
const LogoSimpleAnimated = ({ primaryColor }) => {


    return (
        <svg id="Calque_2" data-name="Calque 2" width="100%" viewBox="0 0 175.48 197.14" fill="none" xmlns="http://www.w3.org/2000/svg">

            <path fill="white" d="M167.39,43.24,95.83,1.93C91.38-.64,84.1-.64,79.65,1.93L8.09,43.25C3.64,45.81,0,52.12,0,57.25v82.64c0,5.14,3.64,11.44,8.09,14l71.56,41.32c4.45,2.56,11.73,2.56,16.18,0l71.56-41.32c4.45-2.57,8.09-8.87,8.09-14V57.25C175.48,52.12,171.84,45.81,167.39,43.24Z">

                <animate attributeName="fill" values="white;DeepSkyBlue;MidnightBlue;DeepSkyBlue;white;" dur="2s" repeatCount="indefinite" />

            </path>

            <g>
                <path fill={primaryColor} d="M28.43,118.07V91.73a15.8,15.8,0,0,1,31.6,0V135a5.94,5.94,0,0,0,11.87,0V73.65a15.8,15.8,0,1,1,31.59,0V135a5.94,5.94,0,0,0,11.87,0V92.47c0-9.14,6.48-16.53,15.8-16.53A15.83,15.83,0,0,1,147,91.73v26.34A6,6,0,0,0,152.9,124h22.58V57.25c0-5.13-3.64-11.44-8.09-14L95.83,1.93C91.38-.64,84.1-.64,79.65,1.93L8.09,43.25C3.64,45.81,0,52.12,0,57.25V124H22.5A6,6,0,0,0,28.43,118.07ZM131.16,53.82a9.25,9.25,0,1,1-9.24,9.25A9.25,9.25,0,0,1,131.16,53.82ZM87.7,35.74A9.25,9.25,0,1,1,78.45,45,9.24,9.24,0,0,1,87.7,35.74ZM44.23,53.82A9.25,9.25,0,1,1,35,63.07,9.25,9.25,0,0,1,44.23,53.82Z" />

                <path fill={primaryColor} d="M137.1,118.07V91.73a6,6,0,0,0-5.94-5.93c-3.87,0-5.93,3-5.93,6.67V135a15.8,15.8,0,1,1-31.6,0V73.65a5.94,5.94,0,1,0-11.87,0V135a15.8,15.8,0,0,1-31.6,0V91.73a5.93,5.93,0,1,0-11.86,0v26.34a15.83,15.83,0,0,1-15.8,15.8H0v6c0,5.14,3.64,11.44,8.09,14l71.56,41.32c4.45,2.56,11.73,2.56,16.18,0l71.56-41.32c4.45-2.57,8.09-8.87,8.09-14v-6H152.9A15.83,15.83,0,0,1,137.1,118.07Z" />
            </g>

        </svg>

    );
};



LogoSimpleAnimated.defaultProps = {
    primaryColor: '#18b0e7'
}

export default LogoSimpleAnimated