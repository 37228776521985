import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
// router-dom
import { useParams, Navigate } from 'react-router-dom';

//static
import Footer from '../components/Footer'

import ProfileMenu from '../components/profile/ProfileMenu'
import ProfileHeader from '../components/profile/editProfile/ProfileHeader'
import MyProfile from '../components/profile/editProfile/MyProfile'
import ProfileListings from '../components/profile/listings/ProfileListings'
import ProfileMyToolboxListings from '../components/profile/listings/ProfileMyToolboxListings';
// redux 
import { useSelector } from 'react-redux'
import EditMyProfile from '../components/profile/editProfile/EditMyProfile';
import Personalization from '../components/profile/personalization/Personalization';
import PasswordSecurity from '../components/profile/passAndSecurity/PasswordSecurity';
import Faq from '../components/Faq';
import ContactForm from '../components/ContactForm';
import BillingSubscription from '../components/profile/billAndSub/BillingSubscription';
import ProfileMyPlan from '../components/profile/ProfileMyPlan';
import Favorites from '../components/profile/favorites/Favorites';
import SavedSearches from '../components/profile/savedSearches/SavedSearches';
import NeedLoginPage from './NeedLoginPage';
import LoadingData from '../components/createEdit/LoadingData';


const ProfilePage = () => {

    const user = useSelector(state => state.user)
    const [logged] = React.useState(localStorage.getItem('logged'));

    const { type } = useParams();

    return (
        <>
            {logged ?
                <>
                    <Container fluid="xxl" className="bg-light mb-4 ">

                        <Row className='mx-0'>

                            <Col lg={3} className="pe-4 bg-white">
                                <ProfileMenu type={type} userId={user.id} />
                            </Col>

                            <Col lg={9} className="p-0 p-lg-4">
                                

                                {
                                    type === 'MyProfile' ? <><ProfileHeader /> <MyProfile user={user} /> </>
                                        : type === 'EditProfile' ?  <> <ProfileHeader /> <EditMyProfile user={user} /> </>
                                            : type === 'net' ? <Navigate to="/network" replace={true} />
                                                : type === 'MyListings' ? <ProfileListings />
                                                    : type === 'MyToolBoxListings' ? <ProfileMyToolboxListings />
                                                        : type === 'Searches' ? <SavedSearches />
                                                            : type === 'Favorites' ? <Favorites />
                                                                : type === 'Personalization' ? <Personalization />
                                                                    : type === 'MyPlan' ? <ProfileMyPlan />
                                                                        : type === 'Subscription' ? <BillingSubscription />
                                                                            : type === 'Security' ? <PasswordSecurity />
                                                                                : type === 'Help' ? <><Faq /><ContactForm /></>
                                                                                    : ''
                                }
                            </Col>

                        </Row>

                    </Container>


                    <Footer />

                </>

                : !logged
                    ? <NeedLoginPage />

                    : <LoadingData />
            }
        </>
    )
}

export default ProfilePage