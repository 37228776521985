import React from 'react'
import { NavDropdown, Image, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import { CircleFill, ExclamationCircleFill } from 'react-bootstrap-icons';
import UserPhoto from '../../assets/user-svgrepo-com.svg'
import { FaCheckCircle } from "react-icons/fa";
import { MdAddAlert } from "react-icons/md";
import markAsReadNotif from "../../jwtAPI/MarkNotificationRead"
import { useTranslation } from 'react-i18next'
import DateWithHour from '../../functions/DateWithHour';


const NetworkNotif = ({ noty }) => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const friendreq = async (e) => {
    e.stopPropagation();
    if (!noty.viewed) {
      await markAsReadNotif(noty.id);
      noty.viewed = true;
    }
    if (noty.subtype === 'friend_request') {
      navigate('/network#2')
    }
    else {
      navigate(`/agent/${noty.sender.id}`)
    }
  }

  return (
    <NavDropdown.Item
      onClick={friendreq}
      className='small px-2 text-wrap'
    >
      <Image
        roundedCircle
        style={{ objectFit: 'cover' }}
        width="37px"
        height="37px"
        src={noty.sender.profile_picture_url ? process.env.REACT_APP_DOMAIN + noty.sender.profile_picture_url : UserPhoto}
        className="me-2"
      />
      {
        noty.subtype === 'friend_request' ?
          <>
            <strong>{(t('notif.notif1'))}</strong> {noty.sender.blog_display_name}! {!noty.viewed && <CircleFill className='mb-1 ms-2 text-primary' />}
          </>
          : noty.subtype === 'friend_request' ?
            <>
            <strong>{(t('notif.notif16'))}&nbsp;{noty.sender.email}&nbsp;{(t('notif.notif17'))}&nbsp;{noty.sender.blog_display_name}{(t('notif.notif18'))}</strong> {!noty.viewed && <CircleFill className='mb-1 ms-2 text-primary' />}
          </>
            :
            <>
              {(t('title.userSin2'))} {noty.sender.blog_display_name} <strong>{(t('notif.notif2'))}</strong>! {!noty.viewed && <CircleFill className='mb-1 ms-2 text-primary' />}
            </>
      }
    </NavDropdown.Item>
  )
}

const EmailsNotif = ({ noty, modal }) => {

  const { t } = useTranslation();
  const [showButtons, setShowButtons] = React.useState(false)

  const emailNew = async (e) => {
    e.stopPropagation();
    if (!noty.viewed) {
      await markAsReadNotif(noty.id);
      noty.viewed = true;
    }
    setShowButtons(!showButtons)
  }

  return (
    <NavDropdown.Item
      onClick={emailNew}
      className='small px-2 d-flex text-wrap'
    >
      <Image
        roundedCircle
        style={{ objectFit: 'cover' }}
        width="37px"
        height="37px"
        src={noty.sender.profile_picture_url ? process.env.REACT_APP_DOMAIN + noty.sender.profile_picture_url : UserPhoto}
        className="me-2"
      />
      <div>
        {!showButtons
          ?
          <div className={modal ? 'mt-2' : ''}>
            {(t('title.userSin2'))} {noty.sender.blog_display_name} ({noty.sender.email})
            {!modal && <br />}
            {(t('notif.notif3'))} <strong>email</strong>.
            {!noty.viewed && <CircleFill className='mb-1 ms-2 text-primary' />}
          </div>

          :
          <>
            <Button variant="info" onClick={() => window.location.href = 'mailto:'}>
              {(t('notif.notif4'))}
            </Button>
            <Button variant="success" className='ms-2' onClick={() => navigator.clipboard.writeText(noty.sender.email)}>
              {(t('words.copy'))} email
            </Button>
          </>
        }
      </div>
    </NavDropdown.Item>
  )
}

const ImportantNotif = ({ noty }) => {

  const { t } = useTranslation();
  const [openSet, setOpenSet] = React.useState(false);

  const markViewed = async (e) => {
    e.stopPropagation();
    if (!noty.viewed) {
      await markAsReadNotif(noty.id);
      noty.viewed = true;
    }
  }

  return (
    <NavDropdown.Item
      className='small my-2 text-wrap'
      onClick={markViewed}
    >
      {
        noty.subtype === 'expired_plan' ?
          <>
            <ExclamationCircleFill className='text-danger me-2' size={20} />
            <strong>{(t('notif.notif5'))}: </strong>
            <DateWithHour dateRaw={noty.expiration_date} />
            {!noty.viewed && <CircleFill className='mb-1 ms-2 text-primary' />}
          </>
          :
          <div className='d-flex' onClick={() => setOpenSet(!openSet)}>
            <div className={!openSet ? 'text-truncate' : 'text-wrap'} style={{ maxWidth: "300px" }}>
              <span>
                <ExclamationCircleFill className='text-yellow me-2' size={20} />

                {noty.content} {!noty.viewed && <CircleFill className='mb-1 ms-2 text-primary' />}
              </span>
            </div>
            <span className='cp ms-1 text-secondary'>
              {!openSet ? <>&#9660; {(t('words.more'))}</> : <>&#9650; {(t('words.close'))}</>}
            </span>
          </div>
      }
    </NavDropdown.Item >
  )
}

const OffersNotif = ({ noty }) => {

  const navigate = useNavigate();

  const markViewed = async (e) => {
    e.stopPropagation();
    if (!noty.viewed) {
      await markAsReadNotif(noty.id);
      noty.viewed = true;
    }
    navigate(`${noty.link}?${noty.promotion_code}`)
  }

  return (
    <NavDropdown.Item
      onClick={markViewed}
      className='small my-2 text-wrap'
    >
      <div className='text-wrap' style={{ maxWidth: "350px" }}>
        <FaCheckCircle className='text-success me-2' size={21} />
        {noty.content}
        {!noty.viewed && <CircleFill className='mb-1 ms-2 text-primary' />}
      </div>
    </NavDropdown.Item>
  )
}

const AlertsNotif = ({ noty }) => {

  const navigate = useNavigate();
  const { t } = useTranslation();

  const alertActions = async (e) => {
    e.stopPropagation();
    if (!noty.viewed) {
      await markAsReadNotif(noty.id);
      noty.viewed = true;
    }
    if (noty.subtype === 'saved_search') {
      navigate(`listing/${noty.search_type}/${noty.entity_id}/${noty.entity_slug}`)
    }
    else {
      navigate(`listing/event/${noty.event_id}/${noty.event_slug}`)
    }
  }

  return (
    <NavDropdown.Item
      onClick={alertActions}
      className='small my-2 text-wrap'
    >
      <MdAddAlert className='text-success me-1' size={25} />
      {
        noty.subtype === 'saved_search' ?
          <>
            {(t('notif.notif6'))}&nbsp;
            <strong>{(t('words.saved'))} {(t('words.searches'))}</strong>!
            {!noty.viewed && <CircleFill className='mb-1 ms-2 text-primary' />}

            <div style={{ paddingLeft: "30px" }}>
              {(t('words.type'))}: <strong>{t('cats_tools.' + noty.search_type + '')}</strong>
              <br />{(t('title.name'))}: <strong>{noty.search_name}</strong>
              <br /><span className='text-primary'><u>{(t('notif.notif7'))}</u></span>
            </div>
          </>
          :
          <>
            <strong>{(t('notif.notif8'))}</strong> {(t('notif.notif9'))} {!noty.viewed && <CircleFill className='mb-1 ms-2 text-primary' />}
            <div style={{ paddingLeft: "30px" }}>
              {(t('cats_tools.event'))} {(t('title.name'))}: <strong>{noty.event_name}</strong>
              <br />{(t('cats_tools.event'))} {(t('title.date'))}: <strong><DateWithHour dateRaw={noty.event_date} /></strong>
              <br /><span className='text-primary'><u>{(t('notif.notif10'))}</u></span>
            </div>
          </>
      }
    </NavDropdown.Item>
  )
}


const SingleNotification = ({ noty, modal }) => {

  const type = noty.type;

  return (

    type === 'network'
      ? <NetworkNotif noty={noty} />

      : type === 'email' && noty.sender
        ? <EmailsNotif noty={noty} modal={modal} />

        : type === 'important'
          ? <ImportantNotif noty={noty} />

          : type === 'offers'
            ? <OffersNotif noty={noty} />

            : type === 'alerts'
            && <AlertsNotif noty={noty} />

  )
}

export default SingleNotification