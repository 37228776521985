import React from 'react'
import { Col, ListGroup, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import { FcInfo } from 'react-icons/fc';
import TutorialListing from './TutorialListing';
import TutorialMenu from './TutorialMenu';
import TutorialSubmenu from './TutorialSubmenu';

const Tutorial = ({ setShowTutorial, toolbox }) => {

    const { t } = useTranslation();

    const tips = [
        { number: 1, text: t('tutorial.tip1') },
        { number: 2, text: t('tutorial.tip2') },
        { number: 3, text: t('tutorial.tip3') },
        { number: 4, text: t('tutorial.tip4') },
    ];

    return (
        <Modal
            show={true}
            onHide={() => setShowTutorial(false)}
            size="xl"
            aria-labelledby="tutorial-listings"
        >
            <Modal.Header closeButton>
                <Modal.Title id="tutorial-listings">
                    {t('cats_tools.lisis')} {t('words.tutorial')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='h3 text-warning fw-bold'>Menu</p>
                <ol>
                    <li>{t('tutorial.lis1')}</li>
                </ol>
                <TutorialMenu toolbox={toolbox} />

                <p className='h3 text-warning fw-bold'>{t('words.submenu')}</p>
                <ol>
                    <li>{t('tutorial.lis2')}</li>
                    <li>{t('tutorial.lis3')}</li>
                    <li>{t('tutorial.lis4')}</li>
                </ol>
                <TutorialSubmenu />

                <p className='h3 text-warning fw-bold'>{t('cats_tools.lisis')}</p>
                <Row className='align-items-center'>
                    <Col lg={6}>
                        <ListGroup as="ol" numbered>
                            <ListGroup.Item className="py-3" as="li">{t('tutorial.lis5')}</ListGroup.Item>
                            <ListGroup.Item className="py-3" as="li">{t('tutorial.lis6')}</ListGroup.Item>
                            <ListGroup.Item className="py-3" as="li">{t('tutorial.lis7')}</ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col lg={6}>
                        <TutorialListing />
                    </Col>
                </Row>


                <p className='h3 text-warning fw-bold'>{t('words.tips')}</p>

                {tips.map((step, key) => (
                    <ListGroup horizontal="xl" className="my-2" key={key}>
                        <ListGroup.Item className='bg-success text-nowrap text-white'>
                            <FcInfo size={25} className="me-2 mb-1" />
                            {t('landing.explain16')} {step.number}
                        </ListGroup.Item>
                        <ListGroup.Item className='w-100'>{step.text}</ListGroup.Item>
                    </ListGroup>
                ))}

            </Modal.Body>
        </Modal>
    )
}

export default Tutorial