import { useEffect, useState } from "react";
import { Form, Image, CloseButton } from "react-bootstrap";
import heic2any from "heic2any";


const SingleImgUpBtn = ({ setExcerptUri }) => {

  const [preview, setPreview] = useState();
  const [selectedFile, setSelectedFile] = useState();


  //Image to data URI
  function getDataUrl(img) {
    // Create canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    // Set width and height
    canvas.width = img.width;
    canvas.height = img.height;
    // Draw the image
    ctx.drawImage(img, 0, 0);
    return canvas.toDataURL('image/jpeg');
  }

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {

    if (!selectedFile) {
      setPreview(undefined);
      setExcerptUri('');
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // Select the image for URI
    const img = document.getElementById('single_photo');

    img.addEventListener('load', function (event) {

      const dataUrl = getDataUrl(event.currentTarget);
      setExcerptUri(dataUrl);
    });

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);

    //eslint-disable-next-line
  }, [selectedFile])


  const onSelectFile = (e) => {

    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return;
    }

    let singleFileImg = e.target.files[0]

    if (singleFileImg.type === 'image/heic' || singleFileImg.name.endsWith('.heic')) {

      heic2any({ blob: singleFileImg, toType: "image/jpg", quality: 1 }).then(
        (newImage) => {
          //URL.createObjectURL(newImage);
          setSelectedFile(newImage);
        }
      );
    }
    else {
      setSelectedFile(singleFileImg);
    }

  }

  //Delete
  const deletepic = () => {
    setSelectedFile(false);
    document.getElementById('input_image').value = '';
  }


  return (
    <>

      <Form.Control type="file" id="input_image" onChange={onSelectFile} accept=".png, .jpg, .jpeg, .gif, .heic" />

      {(selectedFile) &&
        <CloseButton
          onClick={deletepic}
          className="position-absolute bg-white opacity-100 border border-4 border-danger"
          aria-label="Hide"
          style={{ zIndex: "1" }}
        />
      }

      {selectedFile &&
        <>
          <Image id="single_photo" src={preview} alt="preview" className="d-none" />

          <div className="ratio-cont">

            <Image
              className="ratio"
              src={preview}
              alt="preview"
            />

          </div>
        </>
      }
    </>
  )
}

export default SingleImgUpBtn