import TokenRefresher from "./TokenRefresher";

const sendEmailFromSite = async (email, name, locale, setSuccess, setLoading) => {

    const emailThis = async function () {

        const url = `${process.env.REACT_APP_DOMAIN}/${locale}/api/network/send-invitation-to-email`
        const bearer_token = `Bearer ${localStorage.getItem('token')}`;

        fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': bearer_token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "receiver_email": email, "receiver_name": name }),
            credentials: 'include',
        })
            .then(userExists)
            .then(res => {
                setSuccess(res)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                console.error(err);
            })
    }

    function userExists(res) {

        if (res.status >= 200 && res.status <= 299) {
            return "success";
        }
        else if (res.status === 302 || res.status === 303) {
            return res.json();
        }
        else {
            setSuccess("danger")
            setLoading(false)
            throw Error(res.statusText);
        }
    }


    const sessionFetch = new TokenRefresher();
    sessionFetch.setTokenRefresh(emailThis);

}


export default sendEmailFromSite