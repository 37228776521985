import { useTranslation } from 'react-i18next';
import { Card, Table } from 'react-bootstrap';



function Address({ address }) {

    const { t } = useTranslation();

    return (
        <>

            <Card className='p-4 border-0 shadow-sm mt-5'>

                <span className='lead mb-3'>{t('title.address')}</span>

                <Table responsive borderless>
                    <tbody>
                        <tr>
                            <td><strong>{t('title.address')}: </strong>{address.street}</td>
                            <td><strong>{t('title.city')}: </strong>{address.city}</td>
                            <td><strong>{t('title.area')}: </strong>{address.state}</td>
                        </tr>
                        <tr>
                            <td><strong>{t('title.state')}/{t('title.county')}: </strong>{address.city}</td>
                            <td><strong>{t('title.zip')}: </strong>{address.postal_code}</td>
                            <td><strong>{t('title.country')}: </strong>{address.country}</td>
                        </tr>
                    </tbody>
                </Table>

                <a
                    className="btn btn-warning btn-sm text-white col-md-4 col-sm-6"
                    target="_blank"
                    rel="noreferrer"
                    href={`https://www.google.com/maps/search/${address.google_map}`}
                >
                    {t('text.openMaps')}
                </a>

            </Card>

        </>
    );
}

export default Address;
