import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spinner } from 'react-bootstrap';
import { Download } from 'react-bootstrap-icons';

import FetchPDF from '../../jwtAPI/FetchPDF';


function SingleListPDF({ docNames, docUrls }) {


    const { t } = useTranslation();

    let [downloading, setDownloading] = React.useState(false)
    //Fetch document onClick
    const pdfDown = (event) => {
        const downloadPdf = new FetchPDF();
        downloadPdf.fetchPdf(event.target.getAttribute('url'), event.target.name, setDownloading);
    }

    return (

        <>

            {docUrls.length > 0 &&
                <>
                    <p className='mt-3'><strong>{t('words.doc')}</strong></p>

                    <div className='d-inline-block'>

                        {docUrls.length > 1 && !downloading
                            ?
                            <>
                                {docUrls.map((file, key) => (
                                    <Button
                                        key={key}
                                        url={process.env.REACT_APP_DOMAIN + file}
                                        name={docNames[key]}
                                        onClick={pdfDown}
                                        variant="light"
                                        className='me-2 btn btn-sm w-auto text-dark border-graylight'
                                    >
                                        {docNames[key]}
                                        <Download size={17} className='ms-2' />
                                    </Button>
                                ))}
                            </>

                            : docUrls.length === 1 && !downloading
                                ?
                                <Button
                                    url={process.env.REACT_APP_DOMAIN + docUrls}
                                    name={docNames}
                                    onClick={pdfDown}
                                    variant="light"
                                    className='me-2 btn btn-sm w-auto text-dark border-graylight'
                                >
                                    {docNames}
                                    <Download size={17} className='ms-2' />
                                </Button>


                                :
                                <Button
                                    variant="light"
                                    className='me-2 btn btn-sm w-auto text-dark'
                                    disabled={downloading ? true : false}
                                >
                                    {downloading
                                        ?
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className='me-2'
                                            />
                                            {t('words.downing')}
                                        </>

                                        : ''
                                    }
                                </Button>
                        }

                    </div>

                </>
            }

        </>

    );
}

export default SingleListPDF;
