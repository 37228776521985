import TokenRefresher from "./TokenRefresher";
import { store } from "../Redux/Store.js"
import { setReduxEditUser } from "../Redux/user-slice";

const uploadUserCv = async (setStatus, payload, setLoading, setShow) => {


    const url = process.env.REACT_APP_USER_UPLOAD_CV



    const uploadCv = async function () {
        try {

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;

            const res = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Authorization': bearer_token,
                    'Content-Type': 'application/json',

                }, 
                body: JSON.stringify(payload),
                credentials: 'include'
            })
            if (res.status >= 200 && res.status <= 299) {

                await store.dispatch(setReduxEditUser({

                    id: "cvUploaded",
                    value: true

                }))

                setStatus("Profile Updated!")
                setShow(true)

            } else {
                setStatus("Something Occurred")
                setShow(true)

            }



        } catch (error) {
            console.log(error)
        } finally {
            setLoading("")

        }
    }
    const sessionFetch = new TokenRefresher();
    sessionFetch.setTokenRefresh(uploadCv);
}


export default uploadUserCv