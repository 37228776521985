import TokenRefresher from "./TokenRefresher";
import { store } from "../Redux/Store.js"
import { setReduxEditUser } from "../Redux/user-slice";

const editUserTypes = async (setStatus, payload, setLoading, setShow, setDisProf, disProf) => {


    const url = process.env.REACT_APP_EDIT_USER_TYPES


    const fetchEditUserTypes = async function () {
        try {

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;

            const res = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Authorization': bearer_token,
                    'Content-Type': 'application/json',

                }, body: JSON.stringify(payload),
                credentials: 'include'
            })
            const data = await res.json()

            if (res.status >= 200 && res.status <= 299) {
                // console.log(data.user_types);
                // if (location_service) {



                await store.dispatch(setReduxEditUser({

                    id: ["user_types"],
                    value: data.user_types

                }))
                // } else {

                //     await store.dispatch(setReduxEditUser({

                //         id: [Object.keys(payload)[0]],
                //         value: Object.values(payload)[0]

                //     }))
                // }
                setDisProf(!disProf)
                setStatus("Profile Updated!")
                setShow(true)

            } else {
                setStatus("Something Occurred")
                setShow(true)
            }

        } catch (error) {
            console.log(error)
        } finally {
            setLoading("")

        }
    }


    const sessionFetch = new TokenRefresher();
    sessionFetch.setTokenRefresh(fetchEditUserTypes);


}


export default editUserTypes
