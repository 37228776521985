import { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next'
import FetchDropdowns from "../../../jwtAPI/FetchDropdowns";
import { useSelector } from "react-redux/";


function CrmDropdown({ create, listing, getDataDrops, col }) {

    const { t, i18n } = useTranslation();

    const optionsRedux = useSelector(state => state.dropTypes[i18n.language]["crm"])

    const [options, setOptions] = useState(optionsRedux);

    useEffect(() => {

        if (optionsRedux !== undefined) {
            setOptions(optionsRedux)
            return;
        }

        const fetchTypes = new FetchDropdowns();
        fetchTypes.fetchDrops('crm', setOptions, i18n.language)

        //eslint-disable-next-line
    }, [i18n.language]);


    return (
        <>
            {
                (options && create) || (options && listing)
                    ?
                    <>
                        <Col md={col}>
                            <Form.Label>{t('words.lang')}*</Form.Label>
                            <Form.Select
                                id="langCrm"
                                defaultValue={listing && listing.language.id}
                                required
                                name="language"
                                onChange={getDataDrops}
                            >
                                <option></option>
                                {options.crm_language.map((option, key) => (
                                    <option key={key} value={option.id}>{option.name}</option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col md={col}>
                            <Form.Label>{t('words.subject')}*</Form.Label>
                            <Form.Select
                                id="subjectCrm"
                                defaultValue={listing && listing.subject.id}
                                required
                                name="subject"
                                onChange={getDataDrops}
                            >
                                <option></option>
                                {options.crm_subject.map((option, key) => (
                                    <option key={key} value={option.id}>{option.name}</option>
                                ))}
                            </Form.Select>
                        </Col>
                    </>

                    : options && !create && !listing
                        ?
                        <>
                            <Col md={col}>
                                <Form.Label className="small mb-0">{t('words.lang')}</Form.Label>
                                <Form.Select size="sm" onChange={getDataDrops} id="language" name="language">
                                    <option value="all">{t('words.lang')} ({t('words.all')})</option>
                                    {options.crm_language.map((option, key) => (
                                        <option key={key} value={option.id}>{option.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col md={col}>
                                <Form.Label className="small mb-0">{t('words.subject')}</Form.Label>
                                <Form.Select size="sm" onChange={getDataDrops} id="subject" name="subject">
                                    <option value="all">{t('words.subject')} ({t('words.all')})</option>
                                    {options.crm_subject.map((option, key) => (
                                        <option key={key} value={option.id}>{option.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </>

                        : ''
            }

        </>
    )
}

export default CrmDropdown
