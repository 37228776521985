import { changeFrequencyRedux } from "../Redux/saved-searches-alerts-slice";
import { store } from "../Redux/Store";
import TokenRefresher from "./TokenRefresher";


const editSavedSearch = async (thisId, payload, setLoading, setStatus) => {

    const url = `${process.env.REACT_APP_DEL_EDIT_SEARCH}/edit/${thisId}`;

    const editSearch = async () => {

        const bearer_token = `Bearer ${localStorage.getItem('token')}`;

        fetch(url, {
            method: 'PATCH',
            headers: {
                'Authorization': bearer_token,
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(payload),
        })
            .then(res => {
                setLoading(false)

                if (res.status >= 200 && res.status <= 299) {
                    store.dispatch(changeFrequencyRedux({ id: thisId, value: payload.alert_frequency }))
                }
                else {
                    setStatus(res)

                    setTimeout(() => {
                        setStatus(null)
                    }, 6000);
                }
            })
            .catch(err => { console.error(err); setLoading(false); })

    }

    const sessionFetch = new TokenRefresher();
    sessionFetch.setTokenRefresh(editSearch);
}


export default editSavedSearch