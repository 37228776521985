//import
//const fetchTypes = new FetchDropdowns();
//fetchTypes.fetchDrops(url, setter)
import { setDropdowns } from "../Redux/dropdowns-types-slice";
import { store } from "../Redux/Store";

export default class FetchDropdowns {

    async fetchDrops(type, setter, lang) {

        let getDrops = async () => {

            const url =
                type !== 'event-types'
                    ? `${process.env.REACT_APP_DOMAIN}/${lang}/api/articletypes/${type}`
                    : `${process.env.REACT_APP_DOMAIN}/${lang}/api/${type}`


            fetch(url)
                .then(res => res.json())
                .then(data => {

                    //Event types returns an array, we do it object like the rest
                    if (type === 'event-types') {

                        const newData = { events: data }
                        setter(newData)
                        newData.events.sort((a, b) => a.name > b.name ? 1 : -1)
                        store.dispatch(setDropdowns({ language: lang, typeAd: type, values: newData }))
                    }
                    else {

                        setter(data)
                        //sort alphabetically only if first letter capitalized
                        Object.keys(data).forEach(function (key, index) {
                            data[key].sort((a, b) => a.name > b.name ? 1 : -1)
                        });
                        store.dispatch(setDropdowns({ language: lang, typeAd: type, values: data }))
                    }

                })
                .catch(err => console.error(err))

        }

        getDrops();

    }

}

