import React from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Button, Row, Image, Alert } from 'react-bootstrap'
import { PersonCircle } from "react-bootstrap-icons"
import { Link } from 'react-router-dom';
import AgentPage from '../../pages/AgentPage';
import GetFetchLoadingSuccess from '../../jwtAPI/GetFetchLoadingSuccess';



const FeedItemAuthor = ({ author }) => {

  const { t } = useTranslation();

  const [success, setSuccess] = React.useState(null);
  const [loading, setLoading] = React.useState(false);


  //Filter User Profession
  let userProf = [];

  if (author.user_types && author.user_types[0] && author.user_types[0] !== 0) {

    userProf = <span className='mx-1 me-3 text-nowrap text-muted'>&bull; {author.user_types[0].pro.name}</span>

  }

  const [profPicUrl, setProfPicUrl] = React.useState(author.profile_picture_url)

  const addToNet = () => {
    setLoading(true);
    const url = `${process.env.REACT_APP_DOMAIN}/en/api/friend-request/${author.id}`
    const addNet = new GetFetchLoadingSuccess();
    addNet.fetchListings(url, setLoading, setSuccess)
  }

  return (


    <Row className='px-3 pb-2 justify-content-between'>
      <Col>

        <Link
          component={<AgentPage />}
          to={`/agent/${author.id}/`}
          className="text-decoration-none text-dark text-capitalize d-inline-flex align-items-center"
        >

          {profPicUrl
            ?
            <Image roundedCircle
              style={{ objectFit: 'cover' }}
              width="35px"
              height="35px"
              src={`${process.env.REACT_APP_DOMAIN + profPicUrl}?photo_height=50&photo_width=50`}
              className="me-2"
              onError={() => { setProfPicUrl(false) }}
            />

            :
            <PersonCircle className='text-graylight me-2' size={33} />
          }

          <div>
            <span className='text-nowrap'>
              {author.blog_display_name.toLowerCase()}
            </span>
            <span className='d-none d-sm-block'>
              {userProf}
            </span>
          </div>

        </Link>

      </Col>

      <Col className="text-end">
        {success === null
          ?
          <Button
            onClick={addToNet}
            variant="outline-secondary"
            className='rounded-4 border-2 fw-bold'
            size="sm"
            disabled={loading ? true : false}
          >
            {loading
              ? t('title.loading')
              : <>{t('words.addto')} &nbsp;{t('title.net')}</>
            }
          </Button>

          : success === 'success'
            ?
            <Alert variant="success" className='m-1 p-1'>
              {t('title.success')}
            </Alert>

            :
            <Alert variant="yellow" className='m-1 p-1 pe-5' onClose={() => setSuccess(null)} dismissible>
              {success}
            </Alert>
        }
      </Col>

    </Row>

  )
}

export default FeedItemAuthor