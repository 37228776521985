import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from 'react-i18next';


const SingleListVideo = ({ link }) => {

    const { t } = useTranslation();

    let [linkEmbed, setLinkEmbed] = React.useState()

    //get embed link from url
    React.useEffect(() => {
        fetch('https://www.youtube.com/oembed?url=' + link + '&format=json', {
        })
            .then(res => res.json())
            .then(res => {

                let resHtlml = res.html
                resHtlml = resHtlml.replace(/width="200" height="113"/, 'width="100%" height="400px"')
                setLinkEmbed(resHtlml)

            })
            .catch(err => {
                console.error(err)
                setLinkEmbed('')
            })

    }, [link]);


    return (
        <>
            {linkEmbed &&
                <Card className='p-4 border-0 shadow-sm mt-5'>

                    <span className='lead mb-3'>{t('words.video')} {t('words.presentation')} </span>

                    <div
                        dangerouslySetInnerHTML={{ __html: linkEmbed }}
                    />

                </Card>
            }
        </>
    )
};

export default SingleListVideo;
