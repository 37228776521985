import React from 'react';
import { Button, Card, Row, Col, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import FetchGetSetSimple from '../jwtAPI/FetchGetSetSimple';

import Footer from '../components/Footer';
import AgentContact from '../components/singleListing/AgentContact';
import SingleListHero from '../components/singleListing/SingleListHero';
import Address from '../components/singleListing/Address';
import SingleListPDF from '../components/singleListing/SingleListPDF';
import ButtonEditLis from '../components/singleListing/ButtonEditLis';
import OverviewTitleShare from '../components/singleListing/OverviewTitleShare';
import NeedLoginPage from './NeedLoginPage';
import LoadingData from '../components/createEdit/LoadingData';
import DateOnly from '../functions/DateOnly';




const JobPage = () => {

    const { t, i18n } = useTranslation();
    const [logged] = React.useState(localStorage.getItem('logged'));
    const { id } = useParams();
    let url = `${process.env.REACT_APP_DOMAIN}/${i18n.language}/api/listing/training-job/${id}`

    const [listing, setter] = React.useState(null);


    React.useEffect(() => {

        const fetchLists = new FetchGetSetSimple();
        fetchLists.fetchListings(url, setter);

        // eslint-disable-next-line
    }, [id, url]);



    return (
        <>
            {(listing && logged) ?

                <>



                    <Container fluid="xxl">
                        <SingleListHero excerptPhoto={listing.article.excerpt_photo_url} gallery={listing.article.photos_urls} />
                    </Container>


                    <Container fluid="xl">

                        <Row className="justify-content-between mt-5">

                            <Col xl={8} lg={12}>

                                <ButtonEditLis idAuthor={listing.article.author.id} type="job" idLis={id} />

                                <Button variant='warning' size="sm" className='w-auto px-2 py-0'>{t('cats_tools.jnt')}</Button>
                                <Button
                                    variant={listing.type === 'offer' ? 'primary' : 'success'}
                                    size="sm" className='w-auto px-2 py-0 ms-2 text-white'
                                >
                                    {listing.type === "offer"
                                        ? <>{t('cats_tools.training-job')} {t('words.offer')}</>
                                        : <>{t('cats_tools.training-job')} {t('cats_tools.request')}</>
                                    }

                                </Button>

                                <h1 className='fw-bold mt-2'>{listing.article.title}</h1>

                                {listing.number
                                    && <p className='fs-6 fw-bold'>ID: ({listing.number})</p>
                                }

                                <p><strong>{t('words.pubDate')}: </strong> <DateOnly dateRaw={listing.article.published_at} /></p>

                                {listing.article.excerpt ? <h5 className='text-muted'>{listing.article.excerpt}</h5> : ''}

                                <Address address={listing.address} />


                                <Card className='p-4 border-0 shadow-sm mt-5'>

                                    <OverviewTitleShare
                                        link={window.location.href}
                                        title={listing.article.title}
                                        isFav={listing.article.favorited_by}
                                        lisId={listing.article.id}
                                        listingType='job'
                                    />

                                    <Row className='row-overview'>

                                        {listing.job
                                            ?
                                            <Col>
                                                <span className='text-warning fs-6'>{t('cats_tools.training-job')}: </span>
                                                <br />
                                                {listing.job.name}
                                            </Col>
                                            : ''
                                        }
                                        {listing.sector
                                            ?
                                            <Col>
                                                <span className='text-warning fs-6'>{t('title.sect')}: </span>
                                                <br />
                                                {listing.sector.name}
                                            </Col>
                                            : ''
                                        }
                                        {listing.work_plan
                                            ?
                                            <Col>
                                                <span className='text-warning fs-6'>{t('title.work')} {t('words.plan')}: </span>
                                                <br />
                                                {listing.work_plan.name}
                                            </Col>
                                            : ''
                                        }

                                    </Row>

                                    {listing.type === "offer"

                                        ?
                                        <>
                                            {listing.profile
                                                ?
                                                <>
                                                    <p className='mt-4 text-warning fs-5'>{t('cats_tools.training-job')} {t('words.desc')}</p>
                                                    <p dangerouslySetInnerHTML={{ __html: listing.profile }} />
                                                </>
                                                : ''
                                            }
                                            {listing.asker_description
                                                ?
                                                <>
                                                    <p className='mt-3 text-warning fs-5'>{t('title.compa')} {t('words.desc')}</p>
                                                    <p dangerouslySetInnerHTML={{ __html: listing.asker_description }} />
                                                </>
                                                : ''
                                            }
                                            {listing.package_offer
                                                ?
                                                <>
                                                    <p className='mt-3 text-warning fs-5'>{t('title.pack_offer')}</p>
                                                    <p dangerouslySetInnerHTML={{ __html: listing.package_offer }} />
                                                </>
                                                : ''
                                            }

                                        </>

                                        :
                                        <>

                                            {listing.profile
                                                ?
                                                <>
                                                    <p className='mt-4 text-warning fs-5'>{t('title.abtme')}</p>
                                                    <p dangerouslySetInnerHTML={{ __html: listing.profile }} />
                                                </>
                                                : ''
                                            }
                                            {listing.asker_description
                                                ?
                                                <>
                                                    <p className='mt-3 text-warning fs-5'>{t('title.compa')} {t('words.desc')}</p>
                                                    <p dangerouslySetInnerHTML={{ __html: listing.asker_description }} />
                                                </>
                                                : ''
                                            }
                                            {listing.package_offer
                                                ?
                                                <>
                                                    <p className='mt-3 text-warning fs-5'>{t('title.wage_claim')}</p>
                                                    <p dangerouslySetInnerHTML={{ __html: listing.package_offer }} />
                                                </>
                                                : ''
                                            }

                                        </>
                                    }

                                    {listing.documents_urls.length > 0 &&

                                        <SingleListPDF docNames={listing.documents_file_names} docUrls={listing.documents_urls} />
                                    }

                                </Card>

                            </Col>

                            <Col xl={3} lg={12}>

                                <AgentContact agent={listing.article.author} />

                            </Col>

                        </Row>

                    </Container>

                    <Footer />

                </>

                : !logged
                    ? <NeedLoginPage />

                    : <LoadingData />
            }
        </>
    )
}

export default JobPage
