import TokenRefresher from "./TokenRefresher";


export default class NotificationsGet {
    constructor(ac) {
        this.ac = ac
    }

    async fetchListings(url, setter, setterSum, setLoading) {

        let getListing = async () => {

            setLoading(true);

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;

            fetch(url, {
                method: 'GET',
                signal: this.ac,
                headers: {
                    'Authorization': bearer_token,
                },
                credentials: 'include',
            })
                .then(res => res.json())
                .then(res => {

                    setter(res.records);
                    if (setterSum) setterSum(res.number_of_records);
                    if (setLoading) setLoading(false);
                })


                .catch(err => { console.error(err); if (setLoading) setLoading(false); })

        }


        //checks and refresh token
        const sessionFetch = new TokenRefresher();
        sessionFetch.setTokenRefresh(getListing);

    }

}

