import React from "react";
import { Carousel, Image, Modal } from "react-bootstrap";
import houseExample from "../../assets/houseExample.jpg"



const SingleListHeroLightbox = ({ index, setIndex, picturesUrls, show, setShow }) => {

    let indicators = true;
    if (picturesUrls.length <= 1) { indicators = false }

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    let pictures = [];

    for (let i = 0; i < picturesUrls.length; i++) {

        pictures.push(
            <Carousel.Item key={i} >
                <Image
                    fluid
                    src={process.env.REACT_APP_DOMAIN + picturesUrls[i]}
                    alt={`${i} slideBox`}
                    onError={(e) => { e.target.onError = null; e.target.src = houseExample }}
                />
            </Carousel.Item>
        );
    }


    return (

        <Modal
            className="light-box"
            show={show}
            onHide={() => setShow(false)}
            centered
            aria-labelledby="lightbox-image-carousel"
        >
            <Modal.Body className="p-0" >

                <Carousel interval={null} activeIndex={index} onSelect={handleSelect} controls={indicators} indicators={indicators}>
                    {pictures}
                </Carousel>

            </Modal.Body>
        </Modal>

    )
};

export default SingleListHeroLightbox;