const MatchingPro = () => {
	return (
		<svg id="matching_pro" data-name="Match Pro" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260.56 32.72">
			<g>
				<path className="match-pro" d="M22.09,9.33c3,0,5.22.67,6.69,2A7.34,7.34,0,0,1,31,17v9.88H27.41V17a4,4,0,0,0-1.24-3.19,6.08,6.08,0,0,0-4-1.1H18V26.89H14.45V12.72H5.54c-.29,0-.44.17-.44.5V26.89H1.58V12.06a3.11,3.11,0,0,1,.56-2.14A2.57,2.57,0,0,1,4,9.33Z" transform="translate(-1.33 -1.47)" />
				<path className="match-pro" d="M47.13,9.33a8,8,0,0,1,2.79.44A4.89,4.89,0,0,1,51.83,11a4.56,4.56,0,0,1,1.08,1.84,7.44,7.44,0,0,1,.34,2.3v8.24A7.48,7.48,0,0,1,53.1,25a2.22,2.22,0,0,1-.55,1.08,2.38,2.38,0,0,1-1.12.62,7,7,0,0,1-1.81.2H41.41c-2.08,0-3.59-.48-4.5-1.43a5.37,5.37,0,0,1-1.38-3.93,6.61,6.61,0,0,1,.31-2.12,3.73,3.73,0,0,1,1-1.58,4.61,4.61,0,0,1,1.82-1,9.6,9.6,0,0,1,2.76-.35h8.35V15.15a2.53,2.53,0,0,0-.62-1.73,2.66,2.66,0,0,0-2.07-.7H36.59V9.33Zm1.69,14.13c.65,0,1-.3,1-.89v-3.3H41.51a2.8,2.8,0,0,0-2,.55A2,2,0,0,0,39,21.34a2.11,2.11,0,0,0,.57,1.54,2.55,2.55,0,0,0,1.89.58Z" transform="translate(-1.33 -1.47)" />
				<path className="match-pro" d="M58.87,9.33V5.18h3.55V9.33h4.86v3.39H62.42V21A3,3,0,0,0,63,23.1a2.44,2.44,0,0,0,1.81.56h2.43v3.23H64.05a8,8,0,0,1-2.34-.3,3.43,3.43,0,0,1-1.61-1,4.19,4.19,0,0,1-.94-1.86,11.87,11.87,0,0,1-.29-2.89V12.72h-2.4V9.33Z" transform="translate(-1.33 -1.47)" />
				<path className="match-pro" d="M86.53,9.33v3.39H77.82A4.13,4.13,0,0,0,74.47,14a6.5,6.5,0,0,0-1,4.12,6.44,6.44,0,0,0,1,4.11,4.15,4.15,0,0,0,3.35,1.24h8.71v3.43h-9a7.31,7.31,0,0,1-5.57-2.16q-2-2.16-2-6.62c0-3,.68-5.17,2-6.62a7.31,7.31,0,0,1,5.57-2.16Z" transform="translate(-1.33 -1.47)" />
				<path className="match-pro" d="M94.24,1.72V9.33h6.18q4.45,0,6.65,2a7.29,7.29,0,0,1,2.2,5.65v9.88h-3.56V17a4,4,0,0,0-1.26-3.15,5.93,5.93,0,0,0-4-1.14H94.24V26.89H90.72V1.72Z" transform="translate(-1.33 -1.47)" />
				<path className="match-pro" d="M117.78,2.72V6.81h-3.56V2.72Zm0,6.61V26.89h-3.53V9.33Z" transform="translate(-1.33 -1.47)" />
				<path className="match-pro" d="M132.51,9.33q4.49,0,6.66,2A7.37,7.37,0,0,1,141.35,17v9.88h-3.56V17a4,4,0,0,0-1.24-3.19,6.06,6.06,0,0,0-4-1.1h-5.75c-.29,0-.44.17-.44.5V26.89H122.8V12.06a3.11,3.11,0,0,1,.56-2.14,2.57,2.57,0,0,1,1.9-.59Z" transform="translate(-1.33 -1.47)" />
				<path className="match-pro" d="M157.94,9.33a6.12,6.12,0,0,1,4.81,1.85q1.63,1.85,1.64,5.67V27a12.87,12.87,0,0,1-.35,3.2,5,5,0,0,1-1.13,2.16,4.54,4.54,0,0,1-2.06,1.21,10.79,10.79,0,0,1-3.11.38H147.3V30.51h10a3.59,3.59,0,0,0,2.73-.83,4,4,0,0,0,.76-2.73v-.79h-7.74a7.48,7.48,0,0,1-5.57-2.08q-2.06-2.08-2.05-6.37t2.05-6.31a7.47,7.47,0,0,1,5.57-2.07ZM149,17.84a5.92,5.92,0,0,0,1.05,3.91A4.18,4.18,0,0,0,153.42,23h7.41V16.81a4.52,4.52,0,0,0-.89-3.12,4,4,0,0,0-3-1h-3.49a4.19,4.19,0,0,0-3.34,1.22A5.91,5.91,0,0,0,149,17.84Z" transform="translate(-1.33 -1.47)" />
				<path className="match-pro" d="M189.43,9.33A7.23,7.23,0,0,1,195,11.51c1.36,1.45,2,3.65,2,6.6s-.69,5.18-2,6.62a7.25,7.25,0,0,1-5.53,2.16h-7.78v7h-3.53V16.85q0-3.83,1.63-5.67a6.1,6.1,0,0,1,4.82-1.85Zm-7.78,14.13h7.41a4.13,4.13,0,0,0,3.34-1.24,6.38,6.38,0,0,0,1-4.11,6.43,6.43,0,0,0-1-4.12,4.11,4.11,0,0,0-3.34-1.27H185.6a4,4,0,0,0-3.06,1,4.87,4.87,0,0,0-.89,3.29Z" transform="translate(-1.33 -1.47)" />
				<path className="match-pro" d="M213.17,9.33v3.39H207A2,2,0,0,0,204.72,15v11.9H201.2V14.45a5.81,5.81,0,0,1,1.08-3.89A4.68,4.68,0,0,1,206,9.33Z" transform="translate(-1.33 -1.47)" />
				<path className="match-pro" d="M227.1,9.33a7.3,7.3,0,0,1,5.55,2.18q2.06,2.18,2.06,6.6t-2.06,6.62a7.33,7.33,0,0,1-5.55,2.16h-3.7a7.28,7.28,0,0,1-5.56-2.16q-2.06-2.16-2.05-6.62c0-3,.68-5.17,2.05-6.62a7.28,7.28,0,0,1,5.56-2.16Zm-3.36,3.39A4.09,4.09,0,0,0,220.4,14a6.43,6.43,0,0,0-1.05,4.12,6.38,6.38,0,0,0,1.05,4.11,4.11,4.11,0,0,0,3.34,1.24h3a4.11,4.11,0,0,0,3.34-1.24,6.38,6.38,0,0,0,1.05-4.11A6.43,6.43,0,0,0,230.1,14a4.09,4.09,0,0,0-3.34-1.27Z" transform="translate(-1.33 -1.47)" />
				<path className="match-pro" d="M251.5,4.48c3.28,0,5.79.92,7.53,2.78s2.61,4.67,2.61,8.46-.87,6.57-2.61,8.43-4.25,2.77-7.53,2.77h-3.06q-4.92,0-7.53-2.77c-1.74-1.86-2.61-4.66-2.61-8.43s.87-6.61,2.61-8.46,4.25-2.78,7.53-2.78Zm-3.06,2.19a7.72,7.72,0,0,0-5.92,2.21q-2.06,2.22-2.06,6.84c0,3.06.69,5.32,2.06,6.8a7.72,7.72,0,0,0,5.92,2.21h3.06a7.65,7.65,0,0,0,5.9-2.21c1.36-1.48,2-3.74,2-6.8s-.68-5.36-2-6.84a7.65,7.65,0,0,0-5.9-2.21Zm2.89,2.83a4.75,4.75,0,0,1,1.73.28,3.31,3.31,0,0,1,1.2.77,3,3,0,0,1,.68,1.13,4.43,4.43,0,0,1,.22,1.38,4.35,4.35,0,0,1-.59,2.14,3,3,0,0,1-1.94,1.41l2.76,4.76h-2.3l-2.72-4.59h-3.09a.27.27,0,0,0-.3.3v4.29h-2.06V16.68a2,2,0,0,1,.44-1.46,2,2,0,0,1,1.42-.43h4.62a1.53,1.53,0,0,0,1.28-.5,1.91,1.91,0,0,0,.38-1.17,1.6,1.6,0,0,0-.4-1.09,1.57,1.57,0,0,0-1.26-.47h-6.52V9.5Z" transform="translate(-1.33 -1.47)" />
			</g>
		</svg>
	);
}
export default MatchingPro;
