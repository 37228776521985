import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Col, Container, Form, Row } from 'react-bootstrap';

import OverviewCE from '../OverviewCE';
import AddressCE from '../AddressCE';
import DescriptionCE from '../DescriptionCE';
import PicturesCE from '../PicturesCE';
import ActionsCE from '../ActionsCE';
import ScopeCE from '../ScopeCE';

import CreateFunctions from '../CreateFunctions';

import SuccessRedirect from '../SuccessRedirect';
import LoadingData from '../LoadingData';
import FetchEditListing from '../../../jwtAPI/FetchEditListing';

//if listing exist its Edit form
const SaleRentEdit = ({ type, listing, listingEdit }) => {

    const { t } = useTranslation();

    //Success Message
    const [success, setSuccess] = React.useState(null);

    //TextEditor Content
    const [content, setContent] = React.useState(listing ? listing.article.content : '');

    //Excerpt pic upload file
    const [excerptUri, setExcerptUri] = React.useState();

    //Gallery upload files
    const [galleryUris, setGalleryUris] = React.useState([]);

    //Files upload
    const [documentsUri, setDocumentsUri] = React.useState([]);

    //Visible to public
    const [checked, setChecked] = React.useState(listing ? listing.article.global : true);

    //Visible to my Network
    const [checkedNet, setCheckedNet] = React.useState(listing ? listing.article.visible_by_network_only : false);

    //Visible to Selected Friends
    const [valueNet, setValueNet] = React.useState(listing ? listing.article.users : []);

    //Visible to Selected Groups
    const [valueGroup, setValueGroup] = React.useState(listing ? listing.article.cercles : []);


    //Validation
    const [validated, setValidated] = React.useState(false);
    //loading until data ready to send them to server
    const [loadingCreatingData, setLoadingCreatingData] = React.useState(false);
    //loading for the whole process
    const [loadingApi, setLoadingApi] = React.useState(false);

    //Near Places
    const [nearPlaces, setNearPlaces] = React.useState(listing.article.nearby_places && listing.article.nearby_places);


    //Article Object
    let [articleObj, setarticleObj] = React.useState(null)

    //Form Data
    /***************Submit with api******************/
    //Prepare json
    const createfunc = new CreateFunctions();

    const [formData, updateFormData] = React.useState();


    //Fields Value
    const handleChange = (e) => {
        createfunc.handleChangeVal(e, updateFormData, formData);
    };

    //Fields Value only for Dropdowns
    const handleChangeDrops = (e) => {
        createfunc.handleChangeDropsVal(e, updateFormData, formData);
    };

    const [formCreateData, setCreateData] = React.useState(listingEdit ? listingEdit : []);

    //runs with button type submit and after this runs function handlesubmit for validation and after useEffect to send data to server
    function jsonData(e) {

        //for useEffect to run
        setLoadingCreatingData(true);
        setLoadingApi(true);
        setValidated(false);


        let usersId = [];
        let groupsId = [];

        const coor = document.getElementById('coordinates') ? document.getElementById('coordinates').value : '';

        if (valueNet.length > 0) {
            usersId = valueNet.map(usid => ({ id: usid }));
        }

        if (valueGroup.length > 0) {
            groupsId = valueGroup.map(groupid => ({ id: groupid }));
        }


        let thisArticleObject = {
            title: document.getElementById('lisTitle').value,
            content: content,
            excerpt: document.getElementById('lisExcerpt').value,
            users: usersId,
            status: e.target.id,
            global: checked,
            visible_by_network_only: checkedNet,
            cercles: groupsId,
            //??EDIT??
            excerpt_photo_data_uri: excerptUri,
            photo_data_uris: galleryUris,
        }


        let addressObj = {
            google_map: coor,
            street: document.getElementById('lisStreet').value,
            complement: document.getElementById('lisStreetExtra').value,
            country: document.getElementById('lisCountry') && document.getElementById('lisCountry').value,
            city: document.getElementById('lisCity').value,
            state: document.getElementById('lisArea').value,
            postal_code: document.getElementById('lisPostal').value
        }


        setCreateData({

            //??EDIT
            documents_data_uri: documentsUri,
            ...formCreateData,
            ...formData,
            article: thisArticleObject,
            address: addressObj,
            negotiable_price:
                (document.getElementById('lisPrice') && document.getElementById('lisPrice').value === "")
                    ? true
                    : (document.getElementById('lisPrice') && document.getElementById('lisPrice').value !== "" && document.getElementById('offer_price'))
                        ? document.getElementById('offer_price').checked
                        : true,

        });


        setarticleObj(thisArticleObject);

        //delay until google maps places

        //it has the country's coor but dont work for google places with only the country
        if (addressObj.street === '') {
            setLoadingCreatingData(false);
        }
        //if they change address
        else if (coor !== listing.address.google_map) {
            createfunc.nearByPlaces(coor, setNearPlaces, setLoadingCreatingData);
        }
        //if it has coor but didnt get the nearby places
        else if (coor !== '' && coor === listing.address.google_map && nearPlaces.length === 0) {
            createfunc.nearByPlaces(coor, setNearPlaces, setLoadingCreatingData);
        }
        else {
            setLoadingCreatingData(false);
        }
    }


    //This is called by the submit button before handle submit for nearby places
    React.useEffect(() => {

        if (loadingCreatingData === true || validated === false) {
            return;
        }

        //useeffect handles those so we can wait for nearby places
        if (nearPlaces.length > 0) {

            articleObj.nearby_places = nearPlaces

            let thisFormCreateData = {
                ...formCreateData,
                article: articleObj,
            }

            setCreateData(thisFormCreateData)
            sendDataToServerEdit(thisFormCreateData);

        }
        else {

            sendDataToServerEdit(formCreateData);
        }

        // eslint-disable-next-line
    }, [loadingCreatingData, validated]);


    //sets validation, if true the above use effect sends data to server
    const handleSubmit = (event) => {

        const form = event.currentTarget;
        setLoadingApi(false);

        //checks country select if empty
        if (document.getElementById("country_select") && document.getElementById("country_select").value === '') {
            event.preventDefault();
            event.stopPropagation();
            document.getElementsByClassName('css-1s2u09g-control')[0].scrollIntoView({ behavior: "smooth", block: "center" });
            document.getElementsByClassName('css-1s2u09g-control')[0].className += " border border-2 border-danger";
            return;
        }
        if (document.getElementById("country_select") && document.getElementById("country_select").value !== '') {
            document.getElementsByClassName('css-1s2u09g-control').className -= " border border-2 border-danger";
        }

        //checks text editor if empty
        if (content === '') {
            event.preventDefault();
            event.stopPropagation();
            document.getElementById('jodit-descip').scrollIntoView({ behavior: "smooth", block: "center" });
            document.getElementById("jodit-descip").parentElement.children[1].children[1].className += " border border-2 border-danger";
            return;
        }
        if (content !== '') {
            document.getElementById("jodit-descip").parentElement.children[1].children[1].className -= " border border-2 border-danger";
        }

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

            if (document.querySelector('select.form-select:invalid')) {
                document.querySelector('select.form-select:invalid').focus();
            }
            else if (document.querySelector('textarea.form-control:invalid')) {
                document.querySelector('textarea.form-control:invalid').focus();
            }
            else if (document.querySelector('input.form-control:invalid')) {
                document.querySelector('input.form-control:invalid').focus();
            }
            return;
        }

        if (form.checkValidity() === true) {
            event.preventDefault();
            setValidated(true);
            setLoadingApi(true);
        }

    };


    async function sendDataToServerEdit(formCreateData) {

        const createFetch = new FetchEditListing();

        await createfunc.deletePicFiles(listing, type, excerptUri);

        createFetch.fetchListings(type, listing.id, formCreateData, setValidated, setSuccess, setLoadingApi);

    }



    return (
        <>
            {
                success !== null
                    ?
                    <SuccessRedirect type={type} success={success} />

                    :
                    <>
                        <LoadingData loadingApi={loadingApi} />

                        <Container fluid="lg" className={loadingApi ? 'd-none' : ''}>

                            <Form noValidate validated={validated} onSubmit={handleSubmit} id="createForm">

                                <Row className="justify-content-between mt-5">

                                    <Col sm={12} className="shadow-sm px-4 pt-3 pb-2 rounded-4 text-secondary bg-white">

                                        <Row className='justify-content-between align-items-center'>
                                            <Col className='fw-bold h5'>
                                                {t('words.edit')} "{listing.article.title}"
                                            </Col>
                                            <Col className='text-end h4'>
                                                <Badge bg="light" className='text-dark border border-2 border-primary'>
                                                    {type === 'sale' ? t('cats_tools.sale') : t('cats_tools.rent')}
                                                </Badge>
                                                <Badge bg="light" className='ms-3 text-dark border border-2 border-success'>
                                                    {t('words.status')}:
                                                    <span className='text-capitalize'>{t('words.' + listing.article.status + '')} </span>
                                                </Badge>
                                            </Col>
                                        </Row>

                                    </Col>

                                    <OverviewCE
                                        type={type}
                                        getData={handleChange}
                                        getDataDrops={handleChangeDrops}
                                        setDocumentsUri={setDocumentsUri}
                                        listing={listing}
                                    />

                                    <AddressCE listing={listing} />

                                    <DescriptionCE
                                        content={content}
                                        setContent={setContent}
                                        type={type}
                                        listing={listing}
                                    />

                                    <PicturesCE
                                        setExcerptUri={setExcerptUri}
                                        setGalleryUris={setGalleryUris}
                                        listing={listing}
                                    />

                                    <ScopeCE
                                        checked={checked}
                                        setChecked={setChecked}
                                        checkedNet={checkedNet}
                                        setCheckedNet={setCheckedNet}
                                        valueNet={valueNet}
                                        setValueNet={setValueNet}
                                        valueGroup={valueGroup}
                                        setValueGroup={setValueGroup}
                                    />

                                    <ActionsCE completeForm={jsonData} edit={true} />

                                </Row>

                            </Form>

                        </Container>

                    </>

            }
        </>
    )
}

export default SaleRentEdit
