import TokenRefresher from "./TokenRefresher";
import { store } from "../Redux/Store.js"
import { setEmailNotif } from "../Redux/news-feed-slice.js";
import { setSiteNotif } from "../Redux/news-feed-slice.js";


const notificationEmail = async (type, payload, setLoading) => {

    const url = process.env.REACT_APP_NOTIF_EMAIL
    const thisBody = {
        "type": type,
        "notifications": payload
    }

    let notifPref = async () => {

        const bearer_token = `Bearer ${localStorage.getItem('token')}`;

        fetch(url, {
            method: 'PATCH',
            headers: {
                'Authorization': bearer_token,
            },
            body: JSON.stringify(thisBody),
            credentials: 'include',
        })
            .then(res => res.json())
            .then(res => {
                setLoading(false)
                if (type === "email"){
                    store.dispatch(setEmailNotif(payload))
                }
                else {
                    store.dispatch(setSiteNotif(payload))
                }
            })

            .catch(err => {
                console.log(err)
                setLoading(false)
            })

    }


    const sessionFetch = new TokenRefresher();
    sessionFetch.setTokenRefresh(notifPref);
}


export default notificationEmail