import {
    FacebookShareButton,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    LinkedinShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    EmailShareButton,
    ViberShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    TelegramIcon,
    WhatsappIcon,
    EmailIcon,
    ViberIcon
} from "react-share";



const ShareToSocialAll = ({ link, title, type }) => {

    const hash = title.replace(/\s/g, '_')

    return (
        <>

            <div className="justify-content-between px-2 py-1 d-flex flex-wrap" style={{ width: '160px' }}>

                <EmailShareButton
                    url={link}
                    subject={title}
                    body="body"
                    className="Demo__some-network__share-button"
                >
                    <EmailIcon size={32} round />
                </EmailShareButton>


                <FacebookShareButton
                    url={link}
                    href={link}
                    quote={title}
                    title={title}
                    description={title}
                    className="Demo__some-network__share-button"
                    hashtag={`#${type.toLowerCase()}_${hash}`}
                >
                    <FacebookIcon size={32} round />
                </FacebookShareButton>

                <FacebookMessengerShareButton
                    url={link}
                    appId="238962881365940"
                    className="Demo__some-network__share-button"
                >
                    <FacebookMessengerIcon size={32} round />
                </FacebookMessengerShareButton>


                <TwitterShareButton
                    url={link}
                    title={title}
                    className="Demo__some-network__share-button"
                >
                    <TwitterIcon size={32} round />
                </TwitterShareButton>


                <TelegramShareButton
                    url={link}
                    title={title}
                    className="Demo__some-network__share-button"
                >
                    <TelegramIcon size={32} round />
                </TelegramShareButton>


                <WhatsappShareButton
                    url={link}
                    title={title}
                    separator=":: "
                    className="Demo__some-network__share-button"
                >
                    <WhatsappIcon size={32} round />
                </WhatsappShareButton>

                <LinkedinShareButton
                    url={link}
                    source={link}
                    title={title}
                    summary={type.toLowerCase()}
                    className="Demo__some-network__share-button"
                >
                    <LinkedinIcon size={32} round />
                </LinkedinShareButton>

                <ViberShareButton
                    url={link}
                    title={title}
                    className="Demo__some-network__share-button"
                >
                    <ViberIcon size={32} round />
                </ViberShareButton>

            </div>
        </>
    )
}

export default ShareToSocialAll