import React from "react";
import ContentLoader from "react-content-loader";
function SideBarLoader(props) {
  const width1 = "45%";
  const height1 = "12%";
  const width2 = "95%";
  const height2 = "7%";
  const height3 = "24%";
  return (
    <>
      <ContentLoader
        speed={1}
        width={1000}
        height={8000}
        viewBox="0 0 300 800"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        style={{ maxWidth: "100%", maxHeight: `100%` }}
        {...props}
      >
        <rect x="0" y="2%" rx="0" ry="0" width={width1} height={height1} />
        <rect x="50%" y="2%" rx="0" ry="0" width={width1} height={height1} />
        <rect x="0" y="16%" rx="0" ry="0" width={width1} height={height1} />
        <rect x="50%" y="16%" rx="0" ry="0" width={width1} height={height1} />
        <rect x="0" y="32%" rx="0" ry="0" width={width2} height={height2} />
        <rect x="0" y="42%" rx="0" ry="0" width={width2} height={height3} />
      </ContentLoader>
    </>
  );
}
export default SideBarLoader;
