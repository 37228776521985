import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Col, Row } from 'react-bootstrap'

import { FcCalendar, FcDataEncryption, FcHighPriority, FcLockLandscape, FcPrivacy } from "react-icons/fc";

import SignInActivity from './SignInActivity'
import ChangePass from './ChangePass'
import DeleteAccount from './DeleteAccount'
import ForgotPass from './ForgotPass'
import privacyEn from '../../../assets/privacyPolicy/cgu_privacy_en.pdf'
import privacyFr from '../../../assets/privacyPolicy/cgu_privacy_fr.pdf'

import useWindowDimensions from '../../../functions/GetWidthHeight'



const PasswordSecurity = () => {

    const { t, i18n } = useTranslation();

    const { width } = useWindowDimensions();

    const [secButtons, setSecButtons] = React.useState('')

    function selectSecBut(e) {
        setSecButtons(e.currentTarget.id)

        if (e.currentTarget.id === 'security_policy') {
            if (i18n.language === "fr") {
                window.open(privacyFr)
            } else {
                window.open(privacyEn)
            }
        }
    }

    const securityChoice = [
        { name: t('text.signActiv'), icon: <FcCalendar size={width < 992 ? 50 : 100} />, action: "security_sign" },
        { name: t('text.changepass'), icon: <FcDataEncryption size={width < 992 ? 50 : 100} />, action: "security_change" },
        { name: t('text.forgotpass'), icon: <FcLockLandscape size={width < 992 ? 50 : 100} />, action: "security_forgot" },
        { name: t('words.privPol'), icon: <FcPrivacy size={width < 992 ? 50 : 100} />, action: "security_policy" },
        { name: t('text.deleteAcc'), icon: <FcHighPriority size={width < 992 ? 50 : 100} />, action: "security_delete" },
    ];

    return (

        <Card as={Row} border="white" className="flex-row g-0 g-sm-2 rounded-4 p-0 p-lg-4 mx-0 mx-lg-4">

            <h5 className='text-center my-5 fw-bold'>{t('title.pass')} &#38; {t('words.security')}</h5>

            {securityChoice.map((choice, key) => (

                <Col key={key}>
                    <Card
                        className={`card-profs my-1 h-100 px-2 ${secButtons !== choice.action ? 'shadow' : 'bg-success text-white'}`}
                        id={choice.action}
                        onClick={selectSecBut}
                    >

                        <div className='text-center mt-3'>
                            {choice.icon}
                        </div>
                        <p className='text-center mt-3 fw-bold'>{choice.name}</p>

                    </Card>
                </Col>

            ))}

            {
                secButtons === 'security_sign'
                    ? <SignInActivity />

                    : secButtons === 'security_change'
                        ? <ChangePass />

                        : secButtons === 'security_forgot'
                            ? <ForgotPass />

                            : secButtons === 'security_delete'
                                ? <DeleteAccount />

                                : ''
            }
        </Card>

    )
}

export default PasswordSecurity