import React from 'react'
import { Col, Row } from 'react-bootstrap'
import CardMainListings from './CardMainListings'
import CardProfile from './CardProfile'
import CardSaved from './CardSaved'
import CardToolbox from './CardToolbox'
import SideBarLoader from '../Loaders/SideBarLoader'
import countLis from '../../jwtAPI/CountListings';
import useWindowDimensions from '../../functions/GetWidthHeight'


const SidebarIndex = () => {

  const [countAds, setCountAds] = React.useState();
  const [loader, setLoader] = React.useState(true);
  const controller = new AbortController();

  const { width } = useWindowDimensions();


  const cleanUp = function () {
    setCountAds()
    controller.abort()
  }

  React.useEffect(() => {

    countLis(setCountAds, setLoader, cleanUp)

    // eslint-disable-next-line
  }, []);


  return (

    width >= 1200
      ?
      <Col className="sidebar-news">

        <CardProfile />
        {loader ? <SideBarLoader /> : (
          <>
            {countAds &&
              <>
                <CardMainListings countAds={countAds} />
                <CardToolbox countAds={countAds} />
              </>
            }
            <CardSaved />
          </>)}


      </Col>
      :

      <Row className='mb-3'>

        <Col lg={3} sm={6} className="align-self-end">
          <CardProfile />
        </Col>

        {loader ? <SideBarLoader /> :
          <>
            {countAds &&
              <>
                <Col lg={4} sm={6} className="align-self-end">
                  <CardMainListings countAds={countAds} />
                </Col>
                <Col lg={3} xs={6} className="mt-2">
                  <CardToolbox countAds={countAds} />
                </Col>
              </>
            }

            <Col lg={2} xs={6} style={{marginTop: "-1rem"}}>
              <CardSaved />
            </Col>

          </>
        }


      </Row>
  )
}

export default SidebarIndex