import React from "react"
import { OverlayTrigger, Tooltip, Modal, ListGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FcInfo } from "react-icons/fc";
import MatchingPro from "../../assets/MatchingPro";


function MatchingProWithInfo() {

    const { t } = useTranslation();

    const [matchingProInfo, setMatchingProInfo] = React.useState(false)


    return (

        <>

            <OverlayTrigger placement="top"
                overlay={<Tooltip >{t('landing.mPro1')}</Tooltip>}
            >
                <div style={{ width: '140px' }} className="cp" onClick={() => setMatchingProInfo(true)}>
                    <MatchingPro />
                </div>
            </OverlayTrigger>


            <Modal show={matchingProInfo} onHide={() => setMatchingProInfo(false)}>
                <Modal.Header closeButton>
                    <Modal.Title><FcInfo className="mb-1 me-2" size={30} />{t('landing.mPro2')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="lead">{t('landing.mPro3')}</p>
                    
                    <p className="text-secondary fw-bold mb-1">{t('landing.mPro4')}</p>

                    <ListGroup>
                        <ListGroup.Item className="p-1">&rarr; {t('landing.mPro5')}</ListGroup.Item>
                        <ListGroup.Item className="p-1">&rarr; {t('landing.mPro6')}</ListGroup.Item>
                        <ListGroup.Item className="p-1">&rarr; {t('landing.mPro7')}</ListGroup.Item>
                    </ListGroup>
                    <p className="mt-3">
                        {t('landing.mPro8')}
                    </p>
                    <p>
                        <strong>{t('words.method')} 1:</strong>
                        <ol>
                            <li>{t('landing.mPro9')}</li>
                            <li>{t('landing.mPro10')}</li>
                            <li>{t('landing.mPro11')}</li>
                        </ol>
                    </p>
                    <p>
                        <strong>{t('words.method')} 2:</strong>
                        <br /> {t('landing.mPro12')}
                        <br /> {t('landing.mPro13')}
                    </p>

                </Modal.Body>
            </Modal>
        </>
    )
}
export default MatchingProWithInfo

