import TokenRefresher from "./TokenRefresher";

export default class SimpleFetchFeed {



    //Bearer token
    async fetchListings(url, optionsFeed) {

        let getListing = async () => {

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;


            fetch(url, {
                method: 'POST',

                headers: {
                    'Authorization': bearer_token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(optionsFeed),
                credentials: 'include',
            })

                .catch(err => console.error(err))

        }

        const sessionFetch = new TokenRefresher();
        sessionFetch.setTokenRefresh(getListing);

    }




}
