import React from "react"
import { Carousel, Col, Image, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Performance from "../../assets/performance-real-estate.jpg"
import Opportunity from "../../assets/realtor-giving-keys-new-owner.jpg"


function ForYourBusiness() {

    const { t } = useTranslation();


    return (
        <>
            <h5 className="my-5">&rarr; {t('landing.forbus1')}</h5>

            <Carousel variant="dark" controls={false} className="shadow-lg">

                <Carousel.Item>
                    <Row>
                        <Col lg={3} className="pt-5 p-4 bg-gray">

                            <h6 className="fw-bold text-uppercase my-4">{t('landing.forbus2')}</h6>
                            <p>{t('landing.forbus3')}</p>

                        </Col>

                        <Col lg={5} className="pt-5 bg-white">
                            <ul className="home-list">
                                <li> {t('landing.forbus4')}</li>
                                <li> {t('landing.forbus5')}</li>
                                <li> {t('landing.forbus6')}</li>
                                <li> {t('landing.forbus7')}</li>
                            </ul>
                        </Col>

                        <Col lg={4} className="p-0">
                            <Image fluid alt="performance" src={Performance} />
                        </Col>
                    </Row>
                </Carousel.Item>

                <Carousel.Item>
                    <Row>
                        <Col lg={3} className="pt-5 p-4 bg-gray">

                            <h6 className="fw-bold text-uppercase my-4">{t('landing.forbus8')}</h6>
                            <p>{t('landing.forbus9')}</p>

                        </Col>

                        <Col lg={5} className="pt-5 bg-white">
                            <ul className="home-list">
                                <li> {t('landing.forbus10')}</li>
                                <li> {t('landing.forbus11')}</li>
                                <li> {t('landing.forbus12')}</li>

                            </ul>
                        </Col>

                        <Col lg={4} className="p-0">
                            <Image fluid alt="performance" src={Opportunity} />
                        </Col>
                    </Row>
                </Carousel.Item>

            </Carousel>


        </>


    )
}
export default ForYourBusiness