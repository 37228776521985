import React from 'react'
import { Card, Row, Col, Badge, Alert } from 'react-bootstrap';
import { EyeFill, TrashFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next'
import Select, { components } from "react-select";
import SavedSearchCard from './SavedSearchCard';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FcInfo } from 'react-icons/fc';


function SavedSearches() {

    const { t } = useTranslation();
    const navigate = useNavigate()


    function goToPageWithFilters(e) {
        if (e.type === 'rent' || e.type === 'sale' || e.type === 'request' || e.type === 'partnership') {
            navigate(`/ad/${e.type}#saved=${e.value}`);
        }
        else {
            navigate(`/tool/${e.type}#saved=${e.value}`);
        }
    }

    const savedSrcDrop = useSelector(state => state.savedSearch.saved_searches_dropdown)//for dropdown
    const savedSrcObj = useSelector(state => state.savedSearch.saved_searches_alerts) //for cards


    const { Option } = components;
    const IconOption = props => (
        <Option {...props}>
            <Badge bg="yellow" className="me-3">{t('cats_tools.' + props.data.type + '')}</Badge>
            {props.data.label}
        </Option>
    );


    return (

        <Card as={Row} border="white" className="flex-row p-4 mx-lg-4 mb-3 text-center rounded-4 justify-content-end">

            <Alert variant="light" className="bg-info text-white m-0 p-2 col-auto rounded-4 small">
                <FcInfo size={25} /> {t('bigtext.savedSearch6')}
            </Alert>

            <Col lg={12} className="mb-5 mt-4 text-center">

                <h5 className='my-4'>{t('bigtext.savedSearch1')}</h5>

                <p><strong>&#x2192;</strong>
                    {t('bigtext.savedSearch2')}
                </p>

                {savedSrcDrop &&
                    <Select
                        isClearable
                        options={savedSrcDrop}
                        components={{ Option: IconOption }}
                        onChange={goToPageWithFilters}
                    />
                }

            </Col>

            <hr />
            <Col lg={12}>


                <h5 className='text-center mb-4'>{t('words.manage')} {t('words.saved')} {t('words.searches')}/{t('words.alerts')}</h5>

                <p><strong>&#x2192;</strong>
                    {t('text.hereYouCan')} {t('words.view2')}
                    <EyeFill
                        size={27}
                        className='mx-2 rounded-4 text-warning bg-lightblue p-1'
                    />,&nbsp;
                    {t('words.or')} {t('words.delete')}
                    <TrashFill
                        size={27}
                        className='mx-2 rounded-4 text-danger bg-lightblue p-1'
                    />
                </p>

                <p><strong>&#x2192;</strong>
                    {t('bigtext.savedSearch3')}
                </p>


            </Col>

            {savedSrcObj && savedSrcObj.map((optionSingle, key) => (

                <Col lg={6} key={key}>
                    <SavedSearchCard optionSingle={optionSingle} />
                </Col>

            ))}


        </Card>
    )
}
export default SavedSearches