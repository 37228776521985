import TokenRefresher from "./TokenRefresher";


const countLis = async (setCountAds, setLoader, cleanUp) => {


    const url = process.env.REACT_APP_COUNT_ADS_API


    const countListings = async function () {
        try {

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;

            const res = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': bearer_token,
                    'Content-Type': 'application/json',

                },
                credentials: 'include'
            })

            const data = await res.json()

            if (res.status >= 200 && res.status <= 299) {
                setCountAds(data);
            } else {
                cleanUp()
            }


        } catch (error) {
            console.log(error)
        } finally {
            setLoader(false)
        }
    }

    const sessionFetch = new TokenRefresher();
    sessionFetch.setTokenRefresh(countListings);
}


export default countLis