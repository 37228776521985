import { NavDropdown } from 'react-bootstrap';
import { PlusCircleFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


const DropdownAddNew = () => {

  const navigate = useNavigate()
  const { t } = useTranslation();

  const categories = [
    { name: t('cats_tools.sale'), value: 'sale' },
    { name: t('cats_tools.rent'), value: 'rent' },
    { name: t('cats_tools.request'), value: 'request' },
    { name: t('cats_tools.partnership'), value: 'partnership' },
    { name: t('cats_tools.training-job'), value: 'job' },
    { name: t('cats_tools.article'), value: 'article' },
    { name: t('cats_tools.benchmark'), value: 'benchmark' },
    { name: t('cats_tools.crm'), value: 'crm' },
    { name: t('cats_tools.event'), value: 'event' },
  ];

  return (

    <NavDropdown align="end" title={
      <>
        <PlusCircleFill size={13} className="mb-1 me-1" />
        {t('words.addNew')}
      </>}
      className='mx-3 mt-1'
      id="add-new"
    >

      {categories.map((cats, key) => (
        <NavDropdown.Item key={key} onClick={() => navigate(`/create-new/${cats.value}`)} >{cats.name}</NavDropdown.Item>
      ))}
    </NavDropdown>

  )
}

export default DropdownAddNew