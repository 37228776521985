import React from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { useSelector } from "react-redux"
import Footer from '../components/Footer';

import FetchGetSetSimple from '../jwtAPI/FetchGetSetSimple';
import SaleRentEdit from '../components/createEdit/createByListing/SaleRentEdit';
import ReqParCE from '../components/createEdit/createByListing/ReqParCE';
import ArticleCE from '../components/createEdit/createByListing/ArticleCE';
import ComparisonCE from '../components/createEdit/createByListing/ComparisonCE';
import AgendaCE from '../components/createEdit/createByListing/AgendaCE';
import CrmCE from '../components/createEdit/createByListing/CrmCE';
import JobCE from '../components/createEdit/createByListing/JobCE';
import NeedLoginPage from './NeedLoginPage';
import LoadingData from '../components/createEdit/LoadingData';


const FetchTheListing = () => {

    const [logged] = React.useState(localStorage.getItem('logged'));

    const [listing, setListing] = React.useState(null)

    let { type, id } = useParams();

    // const luser = JSON.parse(localStorage.getItem('userData'));
    const luser = useSelector(state => state.user)

    let url;
    let lisUsid;

    if (type !== 'article' && type !== 'job') {
        url = `${process.env.REACT_APP_SINGLE_LIS_API}/${type}/${id}`
    }
    else if (type === 'article') {
        url = `${process.env.REACT_APP_SINGLE_ARTICLE_API}/${id}`
    }
    else if (type === 'job') {
        url = `${process.env.REACT_APP_SINGLE_LIS_API}/training-job/${id}`
    }

    React.useEffect(() => {

        const fetchLists = new FetchGetSetSimple();
        fetchLists.fetchListings(url, setListing);

    }, [url, setListing]);


    if (listing && type !== 'article') {
        lisUsid = listing.article.author.id
    }
    else if (listing && type === 'article') {
        //dublicate listing to create article object
        lisUsid = listing.author.id;
        listing.article = listing;
    }

    //Prepare initial edit object without address, article and extras
    let listingEdit = {
        ...listing
    };
    //delete listingEdit.documents;
    //delete listingEdit.documents_file_names;
    //delete listingEdit.documents_urls;
    //delete listingEdit.files_urls;
    //delete listingEdit.article;
    delete listingEdit.address;
    delete listingEdit.ad_extension;
    delete listingEdit.id;
    //Agenda
    if (type === 'event') {
        delete listingEdit.other_fields;
        delete listingEdit.participants;
        delete listingEdit.description;
        delete listingEdit.resume;
        delete listingEdit.url;
    }

    //if not public listing save the ids of friends or group, not the whole object
    if (listing) {
        //it's either groups or friends
        if (listing.article.cercles.length > 0) {
            Object.entries(listing.article.cercles).forEach(([key]) => {
                if (listing.article.cercles[key].id) {
                    listing.article.cercles[key] = listing.article.cercles[key].id
                }
            })
        }
        else if (listing.article.users.length > 0) {
            Object.entries(listing.article.users).forEach(([key]) => {
                if (listing.article.users[key].id) {
                    listing.article.users[key] = listing.article.users[key].id
                }
            })
        }
    }

    //saves only the ids of fields like estate_type not the whole object
    Object.entries(listingEdit).forEach(([key]) => {
        if (listingEdit[key].id) {
            listingEdit[key] = { id: listingEdit[key].id }
        }
    })



    return (
        <>
            {logged && listing && (luser.id === lisUsid) && listingEdit
                ?
                <>

                    {
                        type === 'sale' || type === 'rent' ? <SaleRentEdit type={type} listing={listing} listingEdit={listingEdit} />
                            : type === 'request' || type === 'partnership' ? <ReqParCE type={type} listing={listing} listingEdit={listingEdit} />
                                : type === 'article' ? <ArticleCE type={type} listing={listing} listingEdit={listingEdit} />
                                    : type === 'benchmark' ? <ComparisonCE type={type} listing={listing} listingEdit={listingEdit} />
                                        : type === 'event' ? <AgendaCE type={type} listing={listing} listingEdit={listingEdit} />
                                            : type === 'crm' ? <CrmCE type={type} listing={listing} listingEdit={listingEdit} />
                                                : type === 'job' ? <JobCE type={type} listing={listing} listingEdit={listingEdit} />
                                                    : ''
                    }


                    <Footer />

                </>

                : listing && logged && (luser.id !== lisUsid)
                    ? <h1>You dont have permission to see this page</h1>

                    : !logged
                        ? <NeedLoginPage />

                        : <LoadingData />
            }
        </>
    );
}
export default FetchTheListing
