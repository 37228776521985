import { NavDropdown } from 'react-bootstrap';
import { FcSearch } from 'react-icons/fc';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


const DropdownListings = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();

  const categories = [
    { name: t('cats_tools.sale'), value: 'sale', link: 'ad' },
    { name: t('cats_tools.rent'), value: 'rent', link: 'ad' },
    { name: t('cats_tools.request'), value: 'request', link: 'ad' },
    { name: t('cats_tools.partnership'), value: 'partnership', link: 'ad' },
    { name: t('cats_tools.training-job'), value: 'job', link: 'tool' },
    { name: t('cats_tools.article'), value: 'article', link: 'tool' },
    { name: t('cats_tools.benchmark'), value: 'benchmark', link: 'tool' },
    { name: t('cats_tools.crm'), value: 'crm', link: 'tool' },
    { name: t('cats_tools.event'), value: 'event', link: 'tool' },
  ];

  return (

    <NavDropdown align="center" title={
      <>
        <FcSearch size={17} className="mb-1 me-1" />
        {t('words.srch')}
      </>}
      className='ms-3 mt-1'
      //id="add-new"
    >

      {categories.map((cats, key) => (
        <NavDropdown.Item key={key} onClick={() => navigate(`/${cats.link}/${cats.value}`)} >{cats.name}</NavDropdown.Item>
      ))}
    </NavDropdown>

  )
}

export default DropdownListings