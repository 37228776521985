import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Form, Row, Badge } from 'react-bootstrap';

import OverviewToolsCE from '../OverviewToolsCE';
import DescriptionCE from '../DescriptionCE';
import PicturesCE from '../PicturesCE';
import ActionsCE from '../ActionsCE';
import ScopeCE from '../ScopeCE';

import FetchCreateListing from '../../../jwtAPI/FetchCreateListing';
import SuccessRedirect from '../SuccessRedirect';
import LoadingData from '../LoadingData';
import FetchEditListing from '../../../jwtAPI/FetchEditListing';
import CreateFunctions from '../CreateFunctions';



const ArticleCE = ({ type, listing, listingEdit }) => {

    const { t } = useTranslation();

    //Success Message
    const [success, setSuccess] = React.useState(null);

    //TextEditor Content
    const [content, setContent] = React.useState(listing ? listing.article.content : '');

    //Excerpt pic upload file
    const [excerptUri, setExcerptUri] = React.useState();

    //Gallery upload files
    const [galleryUris, setGalleryUris] = React.useState([]);

    //Visible to public
    const [checked, setChecked] = React.useState(listing ? listing.article.global : true);

    //Visible to my Network
    const [checkedNet, setCheckedNet] = React.useState(listing ? listing.article.visible_by_network_only : false);

    //Visible to Selected Friends
    const [valueNet, setValueNet] = React.useState(listing ? listing.article.users : []);

    //Visible to Selected Groups
    const [valueGroup, setValueGroup] = React.useState(listing ? listing.article.cercles : []);


    //Validation
    const [validated, setValidated] = React.useState(false);
    //loading until data ready to send them to server
    const [loadingCreatingData, setLoadingCreatingData] = React.useState(false);
    //loading for the whole process
    const [loadingApi, setLoadingApi] = React.useState(false);

    //Form Data
    /***************Submit with api******************/

    const [formCreateData, setCreateData] = React.useState(listingEdit ? listingEdit : []);
    const createfunc = new CreateFunctions();


    //runs with button type submit and after this runs function handlesubmit for validation and after useEffect to send data to server
    function jsonData(e) {

        setLoadingCreatingData(true);
        setLoadingApi(true);
        setValidated(false);

        let usersId = [];
        let groupsId = [];

        if (valueNet.length > 0) {
            usersId = valueNet.map(usid => ({ id: usid }));
        }

        if (valueGroup.length > 0) {
            groupsId = valueGroup.map(groupid => ({ id: groupid }));
        }

        let thisArticleObject = {
            title: document.getElementById('lisTitle').value,
            locale: document.getElementById('locale').value,
            content: content,
            excerpt: document.getElementById('lisExcerpt').value,
            users: usersId,
            status: e.target.id,
            global: checked,
            visible_by_network_only: checkedNet,
            cercles: groupsId,
            excerpt_photo_data_uri: excerptUri,
            photo_data_uris: galleryUris,
        }

        if(document.getElementById('video_link').value) thisArticleObject.video_link = document.getElementById('video_link').value

        setCreateData(
            thisArticleObject
        )

        setLoadingCreatingData(false);
    }


    //This is called by the submit button before handle submit for nearby places
    React.useEffect(() => {

        if (loadingCreatingData === true || validated === false) {
            return;
        }

        if (!listing) {
            sendDataToServer(formCreateData);
        }
        else if (listing) {
            sendDataToServerEdit(formCreateData);
        }

        // eslint-disable-next-line
    }, [loadingCreatingData, validated]);


    //sets validation, if true the above use effect sends data to server
    const handleSubmit = (event) => {

        const form = event.currentTarget;

        //checks text editor if empty
        if (content === '') {
            event.preventDefault();
            event.stopPropagation();
            document.getElementById('jodit-descip').scrollIntoView({ behavior: "smooth", block: "center" });
            document.getElementById("jodit-descip").parentElement.children[1].children[1].className += " border border-2 border-danger";
            return;
        }
        if (content !== '') {
            document.getElementById("jodit-descip").parentElement.children[1].children[1].className -= " border border-2 border-danger";
        }

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

            if (document.querySelector('input.form-control:invalid')) {
                document.querySelector('input.form-control:invalid').focus();
            }
            return;
        }

        if (form.checkValidity() === true) {
            event.preventDefault();
            setValidated(true);
            setLoadingApi(true);
        }

    };

    function sendDataToServer(formCreateData) {
        const createFetch = new FetchCreateListing();
        createFetch.fetchListings(type, formCreateData, setValidated, setSuccess, setLoadingApi)
    }

    async function sendDataToServerEdit(formCreateData) {

        const createFetch = new FetchEditListing();

        await createfunc.deletePicFiles(listing, type, excerptUri);

        createFetch.fetchListings(type, listing.id, formCreateData, setValidated, setSuccess, setLoadingApi);

    }


    return (
        <>
            {
                success !== null
                    ?
                    <SuccessRedirect type={type} success={success} />

                    :
                    <>
                        <LoadingData loadingApi={loadingApi} />

                        <Container fluid="lg" className={loadingApi ? 'd-none' : ''}>

                            <Form noValidate validated={validated} onSubmit={handleSubmit} id="createForm">

                                <Row className="justify-content-between mt-5">

                                    <Col sm={12} className="shadow-sm px-4 pt-3 pb-2 rounded-4 text-secondary bg-white">

                                        {!listing
                                            ?
                                            <h5 className='fw-bold'>
                                                {t('words.create')} {t('words.new')} {t('cats_tools.article')}
                                            </h5>

                                            :
                                            <Row className='justify-content-between align-items-center'>
                                                <Col className='fw-bold h5'>
                                                    {t('words.edit')} "{listing.article.title}"
                                                </Col>
                                                <Col className='text-end h4'>
                                                    <Badge bg="light" className='text-dark border border-2 border-primary'>
                                                        {t('cats_tools.article')}
                                                    </Badge>
                                                    <Badge bg="light" className='ms-3 text-dark border border-2 border-success'>
                                                        {t('words.status')}:
                                                        <span className='text-capitalize'>{t('words.' + listing.article.status + '')} </span>                                                    </Badge>
                                                </Col>
                                            </Row>
                                        }
                                    </Col>

                                    <OverviewToolsCE
                                        type={type}
                                        listing={listing}
                                    />

                                    <DescriptionCE
                                        content={content}
                                        setContent={setContent}
                                        type={type}
                                        listing={listing}
                                    />

                                    <PicturesCE
                                        setExcerptUri={setExcerptUri}
                                        setGalleryUris={setGalleryUris}
                                        listing={listing}
                                    />

                                    <ScopeCE
                                        checked={checked}
                                        setChecked={setChecked}
                                        checkedNet={checkedNet}
                                        setCheckedNet={setCheckedNet}
                                        valueNet={valueNet}
                                        setValueNet={setValueNet}
                                        valueGroup={valueGroup}
                                        setValueGroup={setValueGroup}
                                    />

                                    <ActionsCE completeForm={jsonData} edit={listing ? true : false} />

                                </Row>

                            </Form>

                        </Container>

                    </>

            }
        </>
    )
}

export default ArticleCE
