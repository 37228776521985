import TokenRefresher from "./TokenRefresher";

const reportUsLis = async (url, payload, setStatus, setLoading) => {


    const reportThis = async function () {
        try {

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;

            const res = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': bearer_token,
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify(payload),
                credentials: 'include',
            })
            if (res.status >= 200 && res.status <= 299) {

                setStatus("success")

            } else {
                setStatus("danger")

            }

        } catch (error) {
            console.log(error)
            setStatus("danger")

        } finally {
            setLoading(false)

        }
    }
    const sessionFetch = new TokenRefresher();
    sessionFetch.setTokenRefresh(reportThis);
}


export default reportUsLis