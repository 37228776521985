import React from 'react'
import { Badge, Card, Col, Image, Row } from 'react-bootstrap';
import { GeoAltFill, MapFill, PersonCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import houseExample from "../../../assets/houseExample.jpg"
import TutorialOverlayListing from './TutorialOverlayListing';

const TutorialListing = () => {

    const { t } = useTranslation();


    return (
        <Row >
            <div className="w-auto my-2 ms-0 me-3" >

                <div className="position-relative card-listing-container">

                    <Card border="secondary" className="card-listing">

                        <Row className="align-items-end p-0 position-relative">
                            <Col xs={12} className="m-0">
                                <div className="ratio-cont">
                                    <Image
                                        className="ratio"
                                        src={houseExample}
                                        alt="listing"
                                    />
                                </div>
                                <Badge bg="warning" className="badge-type ms-4 mt-4"> {t('cats_tools.rent')}</Badge>
                            </Col>
                        </Row>

                        <Row className="small p-2" style={{ minHeight: "170px" }}>
                            <h5>
                                <strong>
                                    Rent Hotel in Rhodes
                                    <span className='fs-6 fw-bold text-muted'>&nbsp;(LOC000000013)</span>
                                </strong>
                            </h5>

                            <p className="mb-1"><strong>{t('words.estate')}: </strong>Hôtels</p>
                            <p className="mb-1"><strong>{t('words.lease')}: </strong>Bail long terme</p>

                            <span className='text-warning fw-bold mb-1 d-flex justify-content-between'>

                                <span className="pe-3"> 25.000 &euro; </span>

                                <span><MapFill className="mb-1" /> 500 &#13217;</span>

                                <span className="ms-auto"><GeoAltFill /> Rhodes </span>

                            </span>

                            <div className='d-inline-flex align-items-center'>
                                <PersonCircle className='text-warning me-2' size={33} />
                                <span>Maria Papadopoulou</span>
                            </div>

                        </Row>

                    </Card>

                    <TutorialOverlayListing />
                </div>
            </div >
        </Row>

    )
}

export default TutorialListing