import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap'
import { BiMailSend } from 'react-icons/bi';
import { FcInvite } from 'react-icons/fc'
import { Link } from 'react-router-dom';
import sendEmailFromSite from '../../jwtAPI/SendInvitation'


const InviteUser = () => {

    const { t, i18n } = useTranslation();

    const [show, setShow] = React.useState(false);

    const [success, setSuccess] = React.useState('');

    const [loading, setLoading] = React.useState(false);

    const [validated, setValidated] = React.useState(false);

    const handleClose = () => {
        setShow(false);
        setSuccess('');
        setValidated(false)
    }

    const resetForm = () => {
        setSuccess('')
        setValidated(false)
    }

    const handleSubmit = (event) => {

        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);

        if (form.checkValidity() === true) {

            event.preventDefault();
            setLoading(true);

            const locale = i18n.language
            const email = document.getElementById('email_invite').value
            const name = document.getElementById('receiver_name').value

            sendEmailFromSite(email, name, locale, setSuccess, setLoading)
        }
    };


    return (
        <>
            <div className='d-flex justify-content-center mt-4'>
                <Button variant='outline-darkGray' size="sm" className='border-0 shadow' onClick={() => setShow(true)}>
                    <FcInvite size={25} />
                    &nbsp;{t('invite_blog.invite1')}
                </Button>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('invite_blog.invite2')}</Modal.Title>
                </Modal.Header>

                {success === '' && loading === false
                    ?
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Modal.Body className='text-center'>

                            <FcInvite size={35} />
                            <p className='p-2 rounded-4 bg-warning text-white my-4'>
                                {t('invite_blog.invite3')}
                            </p>

                            <Form.Group className="mb-3" controlId="email_invite">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="name@example.com"
                                    autoFocus
                                    required
                                />
                                <Form.Control.Feedback type="invalid">{t('invite_blog.invite10')}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="receiver_name">
                                <Form.Label>{t('invite_blog.invite12')}</Form.Label>
                                <Form.Control
                                    type="text"
                                />
                            </Form.Group>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" type="submit">
                                <BiMailSend size={25} />&nbsp;{t('invite_blog.invite4')}
                            </Button>
                        </Modal.Footer>
                    </Form>

                    : loading === true
                        ?
                        <div className='d-flex justify-content-center text-center p-5'>
                            <Spinner animation="border" variant="primary" />
                            <span className='fw-bold mt-1 ms-2 lead text-primary'>{t('title.loading')}</span>
                        </div>

                        :
                        <Alert
                            variant={success !== 'exist' ? success : 'info'}
                            className="p-4 text-center"
                        >
                            <p>
                                {
                                    success === 'success'
                                        ? t('invite_blog.invite5')

                                        : success === 'danger'
                                            ? t('invite_blog.invite6')

                                            : success === 'Invitation already exists'
                                                ? t('invite_blog.invite11')

                                                : t('invite_blog.invite7')
                                }
                            </p>

                            {success === 'success' || success === 'danger' || success === 'Invitation already exists' ?
                                < Button
                                    variant="outline-success"
                                    size="lg"
                                    onClick={resetForm}
                                    className='border-3'
                                >
                                    {success === 'success'
                                        ? <>{t('invite_blog.invite8')}</>
                                        : <>{t('invite_blog.invite9')}</>
                                    }
                                </Button>

                                :
                                <Link to={`/agent/${success}/`} replace className='text-decoration-none btn btn-outline-primary'>
                                    {t('words.view')}&nbsp;{t('title.profile')}
                                </Link>
                            }
                        </Alert>
                }

            </Modal >
        </>
    )
}

export default InviteUser