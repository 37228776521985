import React from "react";
import { Image } from "react-bootstrap";
import { MdImageSearch } from "react-icons/md";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import houseExample from "../../assets/houseExample.jpg"
import SingleListHeroLightbox from "./SingleListHeroLightbox";



const SingleListHero = ({ excerptPhoto, gallery }) => {

    const [show, setShow] = React.useState(false);

    const [index, setIndex] = React.useState(0);
    function toggleLightbox(selectedIndex) {
        setIndex(selectedIndex);
    };


    const responsive = {
        largeDesktop: {
            breakpoint: { max: 3000, min: 2000 },
            items: 4,
        },
        desktop: {
            breakpoint: { max: 2000, min: 1240 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1240, min: 576 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 576, min: 0 },
            items: 1,
        }
    };


    let picturesUrls = [excerptPhoto];

    if (gallery.length > 0) {

        for (let i = 0; i < gallery.length; i++) {

            picturesUrls.push(gallery[i])
        }
    }


    //Carousel image Hero
    let picturesCar = [];

    for (let i = 0; i < picturesUrls.length; i++) {

        picturesCar.push(
            <div key={i} className="ratio-cont" onClick={() => toggleLightbox(i)}>
                <Image
                    className="ratio"
                    src={process.env.REACT_APP_DOMAIN + picturesUrls[i]}
                    alt={`${i} slide`}
                    onError={(e) => { e.target.onError = null; e.target.src = houseExample }}
                />

                <div className="overlay-open" onClick={() => setShow(true)}>
                    <MdImageSearch className="text-white" size={50} />
                </div>
            </div>
        );
    }



    return (
        <>
            <div className="bg-hero-lis">

                {picturesUrls.length > 1
                    ?
                    <Carousel
                        swipeable={true}
                        draggable={true}
                        showDots={false}
                        responsive={responsive}
                        ssr={false} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={false}
                        keyBoardControl={true}
                        customTransition="transform 0.3s ease-in-out"
                        transitionDuration={300}
                        containerClass="carousel-container"
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                    >
                        {picturesCar}
                    </Carousel>

                    :
                    <Image
                        src={excerptPhoto ? process.env.REACT_APP_DOMAIN + excerptPhoto : houseExample}
                        alt="article image"
                        onError={(e) => { e.target.onError = null; e.target.src = houseExample }}
                        fluid
                    />


                }

            </div>

            <SingleListHeroLightbox index={index} setIndex={setIndex} picturesUrls={picturesUrls} show={show} setShow={setShow} />

        </>
    )
};

export default SingleListHero;