import React from 'react';
import { Row, Tabs, Tab, Col, Alert } from 'react-bootstrap';
import ButtonNewProfList from './ButtonNewProfList';
import { useTranslation } from 'react-i18next'
import CarouselItemListing from '../../listings/CarouselItemListing';
import MyPagination from '../../MyPagination';
import FetchUserListings from '../../../jwtAPI/FetchUserListings';
import { changeTabRedux } from '../../../Redux/page-slice';
import { useDispatch } from 'react-redux';
// Custom Loader
import FragmentedCircleLoader from '../../Loaders/FragmentedCircleLoader';
import { useLocation } from 'react-router-dom';
import { useCallback } from 'react';
import { FcInfo } from 'react-icons/fc';
import Tutorial from './Tutorial';



const ProfileListings = ({ agentId }) => { //if agentId is NOT for my profile but for the agent page

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const typeFromUrl = useLocation().hash.slice(1);

    const controller = new AbortController();

    const ac = controller.signal;

    const [loading, setLoading] = React.useState(true);

    const [showTutorial, setShowTutorial] = React.useState(false);

    const [url, setUrl] = React.useState(`${process.env.REACT_APP_DOMAIN}/${i18n.language}/${process.env.REACT_APP_USER_LISTINGS_API}`);

    const [currentPage, setCurrentPage] = React.useState(1);

    const [sumListing, setSumListing] = React.useState(0);

    // limit
    const [perPage, setPerPage] = React.useState(6);
    // selected Tab
    const [key, setKey] = React.useState(typeFromUrl ? typeFromUrl : 'allListings');
    //User's listings after fetch
    let [allListings, setAllListings] = React.useState([]);
    // array to render
    const [arrayToRender, setArrayToRender] = React.useState([])

    //change language for types
    const handleLanguageChanged = useCallback(() => {
        setUrl(`${process.env.REACT_APP_DOMAIN}/${i18n.language}/${process.env.REACT_APP_USER_LISTINGS_API}`)
        setKey('allListings')
        //eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        i18n.on('languageChanged', handleLanguageChanged);
        return () => {
            i18n.off('languageChanged', handleLanguageChanged);
        };
        //eslint-disable-next-line
    }, [handleLanguageChanged]);


    // object to send

    const [parameters, setParameters] = React.useState( //setParameters
        {
            "page": currentPage,
            "limit": perPage,
            "chosen_ads": [
                "sale",
                "rent",
                "request",
                "partnership",
            ]
            , sort_order_by: "DESC"

        }
    )

    function filterArray(e) {

        setArrayToRender(allListings.filter(listing => listing.article.title.toLowerCase().includes(e.target.value.toLowerCase())))
    }
    function changeLimit(limit) {
        setPerPage(limit)
        setParameters({
            ...parameters,
            "limit": limit
        })
    }
    function changePage(num) {
        setCurrentPage(num)
        setParameters({
            ...parameters,
            "page": num
        })
    }

    function setSort(sort) {

        if (sort === "dateasc") {
            setParameters({ ...parameters, "sort": "", "sort_order_by": "ASC" })
        } else if (sort === "datedesc") {
            setParameters({ ...parameters, "sort": "", "sort_order_by": "DESC" })
        } else if (sort === "nameasc") {
            setParameters({ ...parameters, "sort": "title", "sort_order_by": "ASC" })
        } else {
            setParameters({ ...parameters, "sort": "title", "sort_order_by": "DESC" })
        }
    }

    function cleanUp() {
        controller.abort()
        // setPerPage(6)
        setKey("allListings")

        setAllListings([])
        setLoading(false)
    }
    React.useEffect(() => {
        return () => {
            cleanUp()
        }
        // eslint-disable-next-line
    }, [])


    function changeTab(k) {
        dispatch(changeTabRedux(k))
        setKey(k);

        if (k === 'allListings') {
            setParameters({
                ...parameters,
                "chosen_ads": [
                    "sale",
                    "rent",
                    "request",
                    "partnership",
                ]
            })
        }
        else {
            // this is if for single listing
            setParameters({
                ...parameters,
                "chosen_ads": [
                    k,
                ]
            })
        }
    }


    React.useEffect(() => {

        setLoading(true)
        document.getElementById("profSearch").value = ''
        const fetchLists = new FetchUserListings(ac);

        if (agentId) {
            fetchLists.fetchListings(url.replace(/me/, agentId), setAllListings, setArrayToRender, parameters, setLoading, setSumListing);
        }
        else {
            fetchLists.fetchListings(url, setAllListings, setArrayToRender, parameters, setLoading, setSumListing);
        }

        // eslint-disable-next-line
    }, [url, parameters]);

    // Arrays for Rendering Tabs
    const tabArray = [
        { mainType: 'allListings', title: t('words.all') },
        { mainType: 'sale', title: `${t('words.for2')} ${t('cats_tools.sale')}` },
        { mainType: 'rent', title: `${t('words.for2')} ${t('cats_tools.rent')}` },
        { mainType: 'request', title: t('cats_tools.request') },
        { mainType: 'partnership', title: t('cats_tools.partnership') },
    ]

    return (

        <Row className={`mx-xl-2 justify-content-end ${(arrayToRender.length < 1 && key === 'allListings' && agentId) && 'd-none'}`}>

            {!agentId &&
                <Col sm={12} lg={9} xl={8} className="px-2 px-lg-4 mt-5 mt-xl-3 cp text-center" onClick={() => setShowTutorial(true)}>
                    <Alert variant="light" className="border-0 shadow text-dark m-0 p-2 col-auto rounded-4">
                        <FcInfo size={25} className="me-4" /> {t('tutorial.lisTitle')}
                    </Alert>
                </Col>
            }

            {showTutorial && <Tutorial setShowTutorial={setShowTutorial} toolbox={false} />}

            <Col sm={12} className="px-2 px-lg-4 mt-5">

                <Tabs
                    defaultActiveKey="allListings"
                    id="myListings"
                    className="mt-2 nav-tabs-custom"
                    activeKey={key}
                    onSelect={changeTab}
                >
                    {tabArray.map((tabListing, key) => (

                        <Tab eventKey={tabListing.mainType} title={tabListing.title} key={key} />


                    ))}

                </Tabs>
                <div className="bg-white border border-top-0 px-4 py-2">

                    <ButtonNewProfList
                        agentId={agentId}
                        changeStatus={setUrl}
                        changeSort={setSort}
                        changeLimit={changeLimit}
                        title={key}
                        search={filterArray}
                        loading={loading}
                    />
                </div>
                <>


                    <Row style={{ minHeight: "45vh" }} className="bg-white border border-top-0 p-4 mx-0">
                        {loading ?

                            <div style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}> <FragmentedCircleLoader color={"black"} /> </div>
                            :
                            <>
                                {arrayToRender !== [] && arrayToRender.map((listing, key) => (
                                    <div className="w-auto my-2 ms-0 me-3" key={key}>
                                        <CarouselItemListing
                                            listing={listing}
                                            article={listing.article ? false : true}
                                            mainTypeListing={listing.article ? listing.article.object_type : listing.title}
                                            userEdit={agentId ? false : true}
                                            withCardActions={agentId ? true : false}
                                            status={url}
                                        />
                                        {/* <CarouselItemListing listing={listing} mainTypeListing={listing.mainType} userEdit={true} withCardActions={false} /> */}
                                    </div>
                                ))}
                                {arrayToRender.length < 1 && <p>{t('bigtext.notFoundFilters1')}</p>}
                            </>
                        }


                        {(!loading && sumListing > 0) &&
                            <MyPagination
                                itemsCount={sumListing}
                                itemsPerPage={perPage}
                                currentPage={currentPage}
                                setCurrentPage={changePage}
                                alwaysShown={false}
                            />
                        }

                    </Row>

                </>



            </Col>
        </Row>
    )
}

export default ProfileListings
