import React from 'react';
import { warning, graylight, primary } from '../../scss/custom_vars.module.scss'
import { Nav, Container, Navbar, NavDropdown, Dropdown, ButtonGroup, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux/es/exports';
import { FaGlobeEurope, FaUserTie } from "react-icons/fa";
import { Gear } from 'react-bootstrap-icons';
import Logo from '../../assets/LogoSignature';
import { NavLink, useNavigate } from "react-router-dom"
import DropdownAddNew from './DropdownAddNew';
import UserMenu from './UserMenu';
import Notifications from '../notifications/Notifications';
import DropdownListings from './DropdownListings';
import MainMenuMobile from './MainMenuMobile';
//import Messages from '../messages/Messages';


const MainMenu = () => {

  const navigate = useNavigate()
  const { t, i18n } = useTranslation();

  const lngs = {
    en: { nativeName: 'EN' },
    fr: { nativeName: 'FR' },
    nl: { nativeName: 'NL' },
    el: { nativeName: 'GR' }
  };

  if (i18n.language.length > 2) i18n.language = i18n.language.substring(0, 2);
  if (i18n.language === "gr") i18n.language = "el";
  if (i18n.language !== "en" && i18n.language !== "fr" && i18n.language !== "nl" && i18n.language !== "el") i18n.language = "en";

  const luser = useSelector(state => state.user);
  const [refresh, setRefresh] = React.useState(luser.refreshUserObject)
  //Dark Mode
  const darkTheme = useSelector(state => state.page.darkTheme)

  const [logoColor, setLogo] = React.useState(warning);


  React.useEffect(() => {
    if (darkTheme) {
      setLogo(graylight)
    } else {
      setLogo(warning)
    }
  }, [darkTheme, luser.refreshUserObject]);

  React.useEffect(() => {
    setRefresh(luser.refreshUserObject)
  }, [luser.refreshUserObject]);



  return (

    <>

      <Navbar bg="white" expand="xl" sticky="top" className="shadow py-0 d-none d-xl-block">
        <Container fluid="xxl">

          <Navbar.Brand onClick={() => navigate("/home")} className="cp">
            <Logo primaryColor={logoColor} primaryColor2={primary} primaryColor3={logoColor} />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="main-navbar-nav" />

          <Navbar.Collapse id="main-navbar-nav" className='justify-content-between flex-wrap'>

            <Nav className='fw-bold align-items-baseline flex-row flex-grow-1'>

              <NavLink
                className="nav-link p-0 ms-1 ms-lg-5 me-2 me-lg-3"
                to="/">
                {t('title.home')}
              </NavLink>

              <DropdownListings />

              <NavLink
                className="nav-link p-0 ms-4 ms-lg-5 me-1"
                to="/network">
                {t('title.net')}
              </NavLink>

              <NavLink
                className="nav-link p-0 ms-4 ms-lg-5 me-3"
                to="/blog">
                {t('title.blog')}
              </NavLink>

              <DropdownAddNew />

            </Nav>

            <Nav className='fw-bold align-items-baseline flex-row justify-content-end flex-grow-1'>

              {/* <Messages layout="navdrop" /> */}

              <Notifications />

              <NavDropdown align="center" title={
                <>
                  <FaGlobeEurope size={21} className="mb-1 me-1" />
                  <span className='text-uppercase'>
                    {i18n.language}
                  </span>
                </>}
                className='mx-2'>

                {Object.keys(lngs).map((lng) => (
                  <NavDropdown.Item
                    key={lng}
                    style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }}
                    type="submit"
                    onClick={() => i18n.changeLanguage(lng)}>

                    {lngs[lng].nativeName}
                  </NavDropdown.Item>
                ))}

              </NavDropdown>
              {/* autoClose="inside" <-- this inside the Dropdown below to make it close only by clicking on it */}
              <Dropdown as={ButtonGroup} className='p-0 mx-2 flex-column' >
                <Dropdown.Toggle variant="lightblue" className='text-primary rounded-pill'>

                  {luser.profile_picture_url
                    ?
                    <Image roundedCircle
                      style={{ objectFit: 'cover' }}
                      width="37px"
                      height="37px"
                      // src={`${luser.profile_picture_url}?photo_height=50&photo_width=50`}
                      // photo_height=35&photo_width=35&
                      src={`${luser.profile_picture_url}?date=${refresh}}`}
                      className="me-2"

                    />

                    : <FaUserTie size={35} className="me-2 border rounded-circle" />
                  }
                  <Gear size={20} />

                </Dropdown.Toggle>
                <Dropdown.Menu className="user-menu dropdown-menu-end">
                  <UserMenu luser={luser} darkTheme={darkTheme} />
                </Dropdown.Menu>
              </Dropdown>

            </Nav>
          </Navbar.Collapse>

        </Container>
      </Navbar >

      <Navbar bg="white" sticky="top" className="shadow py-0 d-block d-xl-none">
        <MainMenuMobile />
      </Navbar>

    </>


  )
}

export default MainMenu