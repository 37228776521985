import { Row } from 'react-bootstrap'
import { BiEdit } from 'react-icons/bi'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';


const ButtonEditLis = ({ idAuthor, type, idLis }) => {

    const { t } = useTranslation();

    const luser = useSelector(state => state.user);

    const editUrl = `/edit/${type}/${idLis}`

    return (
        <>
            {luser.id === idAuthor &&
                <Row className='mb-4 justify-content-center'>

                    <Link to={editUrl} replace
                        className='text-decoration-none d-flex w-auto shadow-lg rounded-4 p-3'
                    >

                        <BiEdit size={33} />
                        <span className='fs-4 fw-bold'>{t('words.edit')}&nbsp;{t('cats_tools.lis2')}</span>

                    </Link>

                </Row>
            }
        </>
    )
}

export default ButtonEditLis