import React from "react";
import { Col, Row } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
import { BiBuildingHouse } from "react-icons/bi";
import { MdOutlineDesignServices, MdOutlineGavel } from "react-icons/md";
import { IoConstructOutline, IoDesktopOutline } from "react-icons/io5";
import { RiFundsLine, RiSuitcaseLine } from "react-icons/ri";
import { BsMinecartLoaded, BsFillGrid3X3GapFill } from "react-icons/bs";
import { FaRegHandshake } from "react-icons/fa";


const ProfChooseIcons = ({ profid, size, className }) => {

    return (
        <>
            {profid === 113
                ? <BiBuildingHouse size={size} className={className} />

                : profid === 114
                    ? <MdOutlineDesignServices size={size} className={className} />

                    : profid === 115
                        ? <IoConstructOutline size={size} className={className+' p-1'} />

                        : profid === 116
                            ? <MdOutlineGavel size={size} className={className} />

                            : profid === 117
                                ? <RiFundsLine size={size} className={className} />

                                : profid === 118
                                    ? <BsMinecartLoaded size={size} className={className+' p-1'} />

                                    : profid === 119
                                        ? <IoDesktopOutline size={size} className={className} />

                                        : profid === 120
                                            ? <FaRegHandshake size={size} className={className} />

                                            : profid === 222
                                                ? <RiSuitcaseLine size={size} className={className} />

                                                : <RiSuitcaseLine size={size} className={className} />
            }
        </>
    )
}



const IconsProfession = ({ profeList, getProf }) => {

    const { t } = useTranslation();

    return (

        <Row className="p-2 m-0 gx-3">

            <Col sm={12} className="mt-3">

                <label className="prof-icons p-2">

                    <input
                        type="radio"
                        name="prof_check"
                        value=""
                        className="opacity-0 d-none"
                        defaultChecked={true}
                        onClick={getProf}
                    />

                    <BsFillGrid3X3GapFill size={30} className='text-warning me-2' />
                    {t('title.profession')} ({t('words.all')})
                </label>

            </Col>

            {profeList['pro'].sort().map((prof, key) => (

                <Col xs={4} key={key} className="mt-3">

                    <label className="prof-icons px-2">

                        <input
                            type="radio"
                            name="prof_check"
                            value={prof.id}
                            className="opacity-0 d-none"
                            onClick={getProf}
                        />

                        <ProfChooseIcons profid={prof.id} size={50} className="text-warning" />

                        <br />

                        {t('prof.' + prof.id)}

                    </label>

                </Col>

            ))}
        </Row>


    )
}

export default IconsProfession