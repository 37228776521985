import TokenRefresher from "./TokenRefresher";
import { store } from "../Redux/Store.js"

const PublishUserListing = async (type, id, setLoading, setStatus) => {

    setLoading(true)
    const url = type === "article" ? `${process.env.REACT_APP_PUBLISH_ARTICLE}/${id}/publish` : `${process.env.REACT_APP_DOMAIN}/en/api/listing/${type}/${id}/publish`



    const publishListing = async function () {
        try {


            const bearer_token = `Bearer ${localStorage.getItem('token')}`;

            const res = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Authorization': bearer_token,
                    'Content-Type': 'application/json',

                },
                credentials: 'include',
            })
            if (res.status >= 200 && res.status <= 299) {


                setStatus(200)

            } else {

                setStatus(400)
            }

            setTimeout(() => {
                document.getElementById(`myListings-tab-${store.getState().page.myListingTab}`).click()
            }, 1000)

        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)

        }
    }
    const sessionFetch = new TokenRefresher();
    sessionFetch.setTokenRefresh(publishListing);
}


export default PublishUserListing