import React from 'react'
import { Row, Col, Image } from 'react-bootstrap';
import FetchGetSetSimpleNetwork from '../../jwtAPI/FetchGetSetSimpleNetwork';
import AgentPage from '../../pages/AgentPage';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import MemberLoader from '../Loaders/MemberLoader';
import User from '../../assets/user-svgrepo-com.svg';



const MemberInfo = ({ agent }) => {

    const { t } = useTranslation();

    return (
        <Link
            component={<AgentPage />}
            to={`/agent/${agent.id}/`}
            className="text-decoration-none"
        >

            <div className='text-white text-center text-break d-flex flex-column align-items-center'>

                <p className='text-capitalize fw-bold my-2'>{agent.blog_display_name.toLowerCase()}</p>

                <Image roundedCircle
                    style={{ objectFit: 'cover' }}
                    width="80px"
                    height="80px"
                    onError={(e) => { e.target.onerror = null; e.target.src = User }}
                    src=
                    {agent.profile_picture_url
                        ? `${process.env.REACT_APP_DOMAIN}${agent['profile_picture_url']}?photo_height=90&photo_width=90`
                        : User
                    }
                    className="me-2 border border-2 rounded-circle border-white bg-white" />

                {agent.user_types && agent.user_types[0]
                    ?
                    <div className='mt-1'>
                        <span className='text-light'>{t('words.joinedas')}</span>
                        <p>{agent.user_types[0].pro.name}</p>
                    </div>

                    : agent.attributes[1].value
                        ?
                        <div>
                            <span className='text-primary'>{t('words.joinedas')}</span>
                            <p>{agent.attributes[1].value}</p>
                        </div>

                        : ''}

            </div>

        </Link>
    )
}



const RecentMembers = () => {

    const { t, i18n } = useTranslation();

    let [allNetwork, setAllNetwork] = React.useState(null);
    let [loading, setLoading] = React.useState(true);

    const fakeUsersForLoading = ["1", "2", "3", "4"]
    const controller = new AbortController()
    const ac = controller.signal
    const [sumListing, setterSum] = React.useState(0);
    const url = process.env.REACT_APP_DOMAIN + '/en/api/partial-users?page=1&limit=12&sort=desc&order_by_created_at=1';
    const cleanUp = function () {
        controller.abort()
        setAllNetwork(null)
        setLoading(true)
    }

    React.useEffect(() => {

        const fetchLists = new FetchGetSetSimpleNetwork(ac);
        fetchLists.fetchListings(url, setAllNetwork, setterSum, setLoading);

        return () => {
            cleanUp()
        }
        // eslint-disable-next-line
    }, [url]);



    return (
        <>
            <Row className="gx-0 mt-4">
                {!loading &&
                    <span className='h6 fw-bold p-2 border border-white border-3 rounded-4 w-auto '>
                        {
                            i18n.language === "fr"
                                ? <>{t('words.new')} {t('words.members')} Betweenpros </>
                                : <>{t('words.new')} Betweenpros {t('words.members')}</>
                        }
                    </span>}

            </Row>

            <Row id="newuser_row" className="gx-0 flex-nowrap overflow-scroll position-relative">

                {loading ? <>

                    {fakeUsersForLoading.map((key) => (

                        <Col xl={4} lg={5} md={4} sm={6} xs={6} key={key}

                        >
                            < MemberLoader />
                        </Col>
                    ))}
                </>
                    : (
                        <>
                            {(allNetwork && sumListing) &&

                                allNetwork.map((agent, key) => (

                                    <Col xl={4} lg={5} md={4} sm={6} xs={6} key={key}
                                        className="new-user-column"
                                    >

                                        <MemberInfo agent={agent} hoverCard={false} />


                                    </Col>

                                ))

                            }
                        </>
                    )

                }


            </Row>

        </>
    )
}

export default RecentMembers