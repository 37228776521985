import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import ResetPassStep1 from '../components/singlePage/ResetPassStep1';
import ResetPassStep2 from '../components/singlePage/ResetPassStep2';

const SimplePage = () => {

    const { name } = useParams();
   // const { secondName } = useParams();

    //get confirmation_token
    const location = useLocation();

    return <div style={{ minHeight: '94vh' }} className="d-flex flex-column justify-content-between">


        <Container fluid="xxl">
            <Row className='justify-content-center mt-5 pt-5'>

                {name === "resetpass" && !location.search ?

                    <ResetPassStep1 name={name} />

                    : ''
                }

                {name === "resetpass" && location.search ?

                    <ResetPassStep2 token={location.search} />

                    : ''
                }

                {/* {name === "login" && secondName === "facebook" ?

                    <h1>On Progress</h1>

                    : ''
                } */}

                {/* {name === "login" && secondName === "linkedin" ?

                    <h1>On Progress</h1>

                    : ''
                } */}


            </Row>
        </Container>

        <Footer />

    </div>;
};

export default SimplePage;


