import TokenRefresher from "./TokenRefresher";
import { setAllCustomInOnce } from "../Redux/news-feed-slice.js"
import { store } from "../Redux/Store.js"


export default class PersonalizationProf {

    constructor(ac) {
        this.ac = ac
    }

    //Bearer token
    async fetchListings(url, setLoading, optionsFeed, setShow, setStatus) {

        let getListing = async () => {

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;


            fetch(url, {
                method: 'POST',
                signal: this.ac,
                headers: {
                    'Authorization': bearer_token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(optionsFeed),
                credentials: 'include',
            })
                .then(res => {
                    if (res.status >= 200 && res.status <= 299) {

                        store.dispatch(setAllCustomInOnce(optionsFeed));
                        setStatus("success");
                        setShow(true);
                        setLoading(false);

                    } else {
                        setLoading(false)
                        setStatus("danger");
                        setShow(true);
                        throw Error(res.status);
                    }

                })

                .catch(err => console.error(err))

        }


        //checks and refresh token
        const sessionFetch = new TokenRefresher();
        sessionFetch.setTokenRefresh(getListing);

    }




}

