import React from 'react'
import NewsCustomize from './NewsCustomize'
import FetchFeed from '../../jwtAPI/FetchFeed';
import { useTranslation } from 'react-i18next'
import FeedItemIndex from './FeedItemIndex';
import { Alert, Button, Spinner } from 'react-bootstrap';
import RecentMembers from './RecentMembers';
import ListingLoader from '../Loaders/ListingLoader';
// Redux
import { useSelector } from 'react-redux';
import { useCallback } from 'react';



const NewsFeed = () => {

    const customization = useSelector(state => state.newsFeed.customization)

    const { t, i18n } = useTranslation();

    const [currentPage, setCurrentPage] = React.useState(1);
    //Loading Spinner
    const [loading, setLoading] = React.useState(true);

    //Button Load More text
    const [buttonText, setButtonText] = React.useState(true);


    let url = `${process.env.REACT_APP_DOMAIN}/${i18n.language}/${process.env.REACT_APP_FEED_API}`

    /*********** GET LISTINGS ***********/
    const [listi, setter] = React.useState(null);

    let [optionsFeed, setOptionsFeed] = React.useState(
        {
            "own": false,
            "page": 1,
            "limit": 10,
            // "training-job": customization.training_job,
            ...customization,
            "shop-company-sell": false,
            "tender-call": false,
            "library": false
        }
    );
    const controller = new AbortController();
    const ac = controller.signal



    //change language for types
    const handleLanguageChanged = useCallback(() => {
        setter(null)
    }, []);

    React.useEffect(() => {
        i18n.on('languageChanged', handleLanguageChanged);
        return () => {
            i18n.off('languageChanged', handleLanguageChanged);
        };
        //eslint-disable-next-line
    }, [handleLanguageChanged]);



    React.useEffect(() => {
        setLoading(true)
        const fetchLists = new FetchFeed(ac);
        fetchLists.fetchListings(url, setter, listi, setLoading, optionsFeed, setButtonText);
        //eslint-disable-next-line
    }, [setter, optionsFeed, url]);

    //for scrolling problem on small screens when missing position-fixed from sidebar
    React.useEffect(() => {
        const feedItem = currentPage * 10 - 11;
        const element = document.getElementById('feed_item_' + feedItem);
        if (element) {
            element.scrollIntoView({ behavior: "auto", block: 'center' });
        }
        //eslint-disable-next-line
    }, [loading]);


    React.useEffect(() => {
        return () => {
            //cleanUp()
            controller.abort();
        }
        // eslint-disable-next-line
    }, [])

    /*********** CUSTOMIZE ***********/
    const cards = [
        { name: `${t('words.for2')} ${t('cats_tools.rent')}`, id: 'rent' },
        { name: `${t('words.for2')} ${t('cats_tools.sale')}`, id: 'sale' },
        { name: t('cats_tools.request'), id: 'request' },
        { name: t('cats_tools.partnership'), id: 'partnership' },
        { name: t('cats_tools.training-job'), id: 'training-job' },
        { name: t('cats_tools.article'), id: 'article' },
        { name: t('cats_tools.benchmark'), id: 'benchmark' },
        { name: t('cats_tools.crm'), id: 'crm' },
        { name: t('cats_tools.event'), id: 'event' },
    ];

    const [checkedState, setCheckedState] = React.useState(
        [customization.rent, customization.sale, customization.request, customization.partnership, customization["training-job"], customization.article, customization.benchmark, customization.crm, customization.event]
    )


    function checkedBoxes(position) {

        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);
        localStorage.setItem('alertsDashboard', JSON.stringify(updatedCheckedState));

        setter(null);
        setButtonText(true);

        setOptionsFeed({
            ...optionsFeed,
            recent_members: customization.recent_members,
            // Trimming any whitespace
            page: 1,
            [cards[position].id]: !checkedState[position]
        });
    }

    //alert unchecked types from customized
    const show = checkedState.includes(false) ? true : false;

    //Load More
    function moreListings() {

        setLoading(true);
        setCurrentPage(currentPage + 1);
        setOptionsFeed({
            ...optionsFeed,
            page: currentPage + 1,
        })
    }


    return (

        <>

            <NewsCustomize
                cards={cards}
                checkedState={checkedState}
                checkedBoxes={checkedBoxes}
                newsFeed={optionsFeed}
            />


            {show &&
                <Alert variant="info" className='py-1 text-center pe-2'>
                    <small>{t('bigtext.customFeed3')}</small>
                </Alert>
            }

            {customization.recent_members &&
                <RecentMembers />
            }

            {loading
                ?
                <>
                    <div className='mt-4 border-0 shadow card'>
                        <ListingLoader />
                    </div>
                    <div className='mt-4 border-0 shadow card'>
                        <ListingLoader />
                    </div>
                    <div className='mt-4 border-0 shadow card'>
                        <ListingLoader />
                    </div>
                </>
                :

                listi && listi.map((listing, key) => (
                    <div key={key} id={`feed_item_${key}`}>
                        <FeedItemIndex listing={listing} />
                    </div>
                ))

            }

            <Button
                variant="light"
                className='col-sm-12 my-5 text-dark fw-bold'
                disabled={loading || !buttonText ? true : false}
                onClick={moreListings}
                id="load_more_feed"
            >

                {loading ?
                    <>
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        {t('title.loading')}
                    </>

                    :
                    <>
                        {buttonText
                            ? <>{t('words.load')} {t('words.more')} </>
                            : <>{t('words.no')} {t('words.more')} {t('cats_tools.lisis')}</>
                        }
                    </>
                }

            </Button>


        </>

    )
}

export default NewsFeed