import React from 'react'
import { Card, Collapse, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { EyeFill, TrashFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next'
import deleteSavedSearch from '../../../jwtAPI/DeleteSavedSearch';
import InfoByType from './InfoByType';


function SavedSearchCard({ optionSingle }) {

    const { t } = useTranslation();

    const [openSet, setOpenSet] = React.useState(false);

    const [loading, setLoading] = React.useState(false);


    function handleDelete() {

        if (window.confirm(t('bigtext.deleteSearch'))) {
            setLoading(true)
            deleteSavedSearch(optionSingle.id, setLoading)
        }
    }

    return (

        <Card border="warning" className='mt-4 border-2 rounded-4 text-start'>

            {!loading
                ? <>
                    <div className={!openSet ? 'text-truncate' : ''}>

                        <OverlayTrigger placement="top" overlay={<Tooltip >{t('words.view')}</Tooltip>}  >
                            <EyeFill
                                size={27}
                                className='btn me-2 rounded-4 text-warning bg-lightblue p-1'
                                onClick={() => setOpenSet(!openSet)}
                            />
                        </OverlayTrigger>

                        <OverlayTrigger placement="top" overlay={<Tooltip >{t('words.delete')}</Tooltip>}  >
                            <TrashFill
                                size={27}
                                className='btn me-2 rounded-4 text-danger bg-lightblue p-1'
                                onClick={handleDelete}
                            />
                        </OverlayTrigger>

                        <span onClick={() => setOpenSet(!openSet)}><strong>{optionSingle.name}</strong></span>

                    </div>


                    <Collapse in={openSet} dimension="height">
                        <div>
                            <InfoByType optionSingle={optionSingle} />
                        </div>
                    </Collapse>
                </>

                :
                <div className='bg-light p-4 rounded-4 text-center'>
                    <Spinner animation="border" variant="primary" />
                </div>
            }

        </Card>


    )
}
export default SavedSearchCard