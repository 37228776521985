import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, FormControl, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { TrashFill } from 'react-bootstrap-icons';
import { FaRedoAlt } from 'react-icons/fa';

const DeleteFileListCE = ({ fileNames, fileIds }) => {

    const { t } = useTranslation();



    const [filesToDelete, setFilesToDelete] = React.useState(undefined);
    const [filesShowing, setFilesShowing] = React.useState(fileNames);
    const [filesIdArray, setFilesIdArray] = React.useState(fileIds);


    const removeFile = (e) => {

        const newArray = [...filesShowing];
        const ids = [...filesIdArray]
        let fileDel = [...ids];
        
        fileDel = fileDel.splice(e.target.id, 1); //saves the id of the file to be deleted
        newArray.splice(e.target.id, 1); //removes from render the file name
        ids.splice(e.target.id, 1); //removes the id from ids array so the key(e.target.id) is correct order

        setFilesShowing(newArray);
        setFilesIdArray(ids);


        if (filesToDelete !== undefined) {
            setFilesToDelete(filesToDelete => [...filesToDelete, fileDel[0]['id']]);
        }
        else {
            setFilesToDelete([fileDel[0]['id']]);
        }
    }

    const resetFiles = () => {
        setFilesShowing(fileNames);
        setFilesToDelete(undefined);
        setFilesIdArray(fileIds)
    }

    return (

        <>
            <hr />

            <div className="justify-content-between d-inline-flex w-100 mb-2">
                <span className="h6 px-2 pt-1 fw-bold">{t('text.delFromFiles')}</span>
                <Button variant="lightblue" className="rounded-4" onClick={resetFiles}>
                    <FaRedoAlt className="me-2 mb-1" />{t('text.resetFiles')}
                </Button>
            </div>

            <ul style={{ maxHeight: '145px' }} className="list-group overflow-scroll">
                {
                    filesShowing.map((file, key) => (

                        <div key={key} className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                            <span>{file}</span>

                            <OverlayTrigger placement="top"
                                overlay={<Tooltip >{t('words.delete')} {t('words.file')}</Tooltip>}
                            >
                                <TrashFill
                                    id={key}
                                    size={24}
                                    onClick={removeFile}
                                    className='text-danger p-1'
                                />
                            </OverlayTrigger>

                        </div >

                    ))
                }
            </ul>

            <FormControl
                readOnly
                type="hidden"
                id="filesToDelete"
                aria-describedby="saves the id to be deleted"
                value={filesToDelete !== undefined ? [filesToDelete] : false}
            />

        </>


    )
}

export default DeleteFileListCE