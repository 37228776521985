import React from "react"
import { useLocation, useNavigate } from 'react-router-dom';
//import FetchGetSetSimple from '../../jwtAPI/FetchGetSetSimple';
import FiltersPost from '../../jwtAPI/FiltersPost';
import CarouselItemListing from "./CarouselItemListing";
import MyPagination from "../MyPagination";
import ListingSearch from "./ListingSearch";
import FragmentedCircleLoader from '../Loaders/FragmentedCircleLoader';
import { Button, Col, Collapse, FormSelect, Row, ToggleButton } from "react-bootstrap";
import { useTranslation } from 'react-i18next'
import { Sliders } from "react-bootstrap-icons";
import NoListingsFound from "../generalSearch/NoListingsFound";
import ButtonsMapShow from "../generalSearch/ButtonsMapShow";
import SaveSearchFormModal from "../generalSearch/SaveSearchFormModal";
import useWindowDimensions from '../../functions/GetWidthHeight'




function ListingPageMain({ mainTypeListing, listi, setter, open, setOpen, coorPolygon }) {

    const { t, i18n } = useTranslation();
    const { width } = useWindowDimensions();

    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [sortBy, setSortBy] = React.useState("desc");
    const [orderBy, setOrderBy] = React.useState("date");
    const [perPage, setPerPage] = React.useState(6);
    const url = `${process.env.REACT_APP_DOMAIN}/${i18n.language}/api/listing/${mainTypeListing}/search`


    // it shows the count of listings
    const [sumListing, setterSum] = React.useState(0);

    // gets the saved search from dropdown
    const [filtersFromSaved, setFiltersFromSaved] = React.useState(undefined)

    // set the filter from form data
    const [filtersPayload, setFiltersPayload] = React.useState(undefined);

    //check if filters from url
    const filters_from_url = useLocation().hash;


    const payload = {
        "page": currentPage,
        "limit": perPage,
        "order_by": orderBy,
        "sort": sortBy,
    }

    React.useEffect(() => {

        //if there is filters on url location, await the object of filtersFromSaved to set
        if (filters_from_url && filtersFromSaved === undefined) {
            return;
        }

        setLoading(true);
        const fetchLists = new FiltersPost();
        fetchLists.fetchListings(url, setter, setterSum, payload, filtersPayload ? filtersPayload : filtersFromSaved, coorPolygon, setLoading)

        const top = document.getElementById('top');
        top.scrollTo(0, 0);
        //eslint-disable-next-line
    }, [filtersFromSaved, currentPage, url, setter, perPage, filtersPayload, orderBy, sortBy, coorPolygon.length > 0]);


    let list = JSON.parse(listi);

    //Change Category
    const [radioValue, setRadioValue] = React.useState('');

    const radios = [
        { name: `${t('words.for2')} ${t('cats_tools.rent')}`, value: 'rent' },
        { name: `${t('words.for2')} ${t('cats_tools.sale')}`, value: 'sale' },
        { name: t('cats_tools.request'), value: 'request' },
        { name: t('cats_tools.partnership'), value: 'partnership' },
    ];

    function changeCategory(e) {
        setFiltersFromSaved(undefined)
        setFiltersPayload(undefined)
        setRadioValue(e.currentTarget.value);
        navigate(`/ad/${e.currentTarget.value}`);
        setCurrentPage(1);
    }

    //Per page change
    function funcPerPage(e) {
        setPerPage(e.target.value)
    }

    //sort by
    function sortByFunc(e) {
        switch (e.target.value) {
            case '1':
                setSortBy("desc");
                setOrderBy("date");
                break;
            case '2':
                setSortBy("asc");
                setOrderBy("date");
                break;
            case '3':
                setSortBy("desc");
                setOrderBy("price");
                break;
            case '4':
                setSortBy("asc");
                setOrderBy("price");
                break;
            case '5':
                setSortBy("desc");
                setOrderBy("area");
                break;
            case '6':
                setSortBy("asc");
                setOrderBy("area");
                break;
            default:
                setSortBy("desc");
                setOrderBy("date");
                break;
        }
    }

    //Filters open-close
    const [showFilters, setShowFilters] = React.useState(filters_from_url ? false : true);
    const toggleFilters = () => setShowFilters(!showFilters);

    //Open form to save the selected filters/search
    const [openSaveModal, setOpenSaveModal] = React.useState(false);
    function saveSearch() {
        setOpenSaveModal(!showFilters);
    }

    return (

        <>

            <Col lg={12} className="bg-primary bg-opacity-25 border-bottom border-4 border-white">

                <Row className="p-1 p-md-4 align-items-end justify-content-between">

                    <Col className={`d-inline-flex align-items-center text-nowrap ${width < 576 && 'justify-content-between'}`}>
                        {
                            radios.map((radio, idx) => (

                                <ToggleButton
                                    key={idx}
                                    id={`radio-${radio.value}`}
                                    type="radio"
                                    variant="transparent px-1 fw-bold"
                                    size={mainTypeListing === radio.value ? 'lg' : 'sm mt-2'}
                                    className={mainTypeListing === radio.value && 'text-warning bg-white px-2 py-1'}
                                    value={radio.value}
                                    style={{
                                        fontSize:
                                            mainTypeListing === radio.value && width < 576 ? '1rem'
                                                : mainTypeListing === radio.value && width >= 576 && '1.5rem'
                                    }}
                                    checked={radioValue === radio.value}
                                    onChange={changeCategory}
                                >
                                    {radio.name}
                                </ToggleButton>

                            ))
                        }
                    </Col>

                    <Col className="d-inline-flex align-items-center justify-content-end text-nowrap">

                        <ButtonsMapShow open={open} setOpen={setOpen} />

                        <Button
                            className='py-1 px-2 fw-bold border-2'
                            variant="warning bg-white text-warning"
                            size={width < 576 ? 'sm' : 'lg'}
                            onClick={toggleFilters}
                            aria-controls="filters1"
                            style={{ float: 'right' }}
                        >
                            <Sliders size={15} className="me-2 mb-1" />{t('words.filters')}
                        </Button>

                    </Col>

                </Row>
            </Col>

            {/**********************FILTERS***************************/}
            <Collapse id="filters1" in={!showFilters} dimension="height">
                <Row className="gx-0 justify-content-end">
                    <Col sm={12} xl={open !== 0 ? 6 : 12} className='mb-1 pe-4 pt-3 ps-2 pb-0 shadow-lg border-warning bg-white'>
                        <ListingSearch
                            setFiltersPayload={setFiltersPayload}
                            isOpen={showFilters}
                            setShowFilters={setShowFilters}
                            mainTypeListing={mainTypeListing}
                            saveSearch={saveSearch}
                            filtersFromSaved={filtersFromSaved}
                            setFiltersFromSaved={setFiltersFromSaved}
                        />
                    </Col>
                </Row>
            </Collapse>

            <SaveSearchFormModal
                openSaveModal={openSaveModal}
                setOpenSaveModal={setOpenSaveModal}
                filtersPayload={filtersPayload}
                coorPolygon={coorPolygon}
                mainTypeListing={mainTypeListing}
            />


            {listi && sumListing !== 0 ?

                <div className="d-inline-block px-2 px-md-4 ps-4 ps-xl-5">

                    <div className="d-inline-flex text-nowrap w-100 justify-content-end my-3 pe-3">

                        <FormSelect
                            size="sm"
                            defaultValue="1"
                            onChange={sortByFunc}
                            className="w-auto"
                        >
                            <option value="1">{t('words.newest')} &darr;</option>
                            <option value="2">{t('words.oldest')} &uarr;</option>
                            <option value="3">{t('words.price')} &darr;</option>
                            <option value="4">{t('words.price')} &uarr;</option>
                            <option value="5">{t('title.area')}(m2) &darr;</option>
                            <option value="6">{t('title.area')}(m2) &uarr;</option>
                        </FormSelect>

                        <FormSelect
                            size="sm"
                            defaultValue={perPage}
                            onChange={funcPerPage}
                            className="w-auto ms-2 ps-1 border-0 rounded-0"
                            style={{ padding: '0 1.6rem 0 0' }}
                        >
                            <option value={6}>6</option>
                            <option value={12}>12</option>
                            <option value={24}>24</option>
                            <option value={50}>50</option>
                        </FormSelect>
                        <span className="small" style={{ marginLeft: '-0.5rem', paddingTop: '0.4rem' }}>{t('words.per')} {t('words.page')}</span>

                    </div>

                    {loading
                        ?
                        <div className="d-flex justify-content-center mt-5">
                            <FragmentedCircleLoader color={"black"} />
                        </div>

                        : list.map((listing, key) => (

                            <div className="d-inline-block my-2 ms-0 me-4" key={key}>

                                <CarouselItemListing listing={listing} mainTypeListing={mainTypeListing} />

                            </div>

                        ))
                    }

                </div>

                : listi && sumListing === 0 && !loading
                    ? <NoListingsFound />

                    : ''
            }

            {(!loading && sumListing > 0) &&
                <MyPagination
                    itemsCount={sumListing}
                    itemsPerPage={perPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    alwaysShown={false}
                />
            }


        </>
    )
}

export default ListingPageMain

