import React from 'react'
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap'
import { BellFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next'
import { AiFillSave } from 'react-icons/ai';
import { ImSearch } from 'react-icons/im';
import { google_maps_api_key } from '../../functions/ConstantVariables';

import saveOrEditSearch from '../../jwtAPI/SaveEditSearch'
import AlertsModalDiv from './AlertsModalDiv';
import { useSelector } from 'react-redux';
import { FcInfo } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';




const SaveSearchFormModal = ({ openSaveModal, setOpenSaveModal, filtersPayload, coorPolygon, mainTypeListing }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const countSavedSearches = useSelector(state => state.savedSearch.saved_searches_dropdown)


    //prepare coordinates to enter url for static map
    let polygonForMap = '';

    if (coorPolygon.length > 0) {
        for (let j = 0; j < coorPolygon.length; j++) {
            for (let i = 0; i < coorPolygon[j].length; i++) {
                polygonForMap = polygonForMap + '|' + coorPolygon[j][i];
            }
        }
    }

    const [loading, setLoading] = React.useState(false);
    const [status, setStatus] = React.useState('');
    const [validated, setValidated] = React.useState(false);


    const [radioValue, setRadioValue] = React.useState('daily');

    const hasAlerts = (e) => {
        if (e.target.checked === false) {
            setRadioValue('')
        }
        else {
            setRadioValue('daily')
        }
    }

    const closeModal = () => {
        setOpenSaveModal(false);
        setStatus('');
        setLoading(false);
    }

    const handleSubmit = (event) => {

        setLoading(true)

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setLoading(false)
        }

        setValidated(true);
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === true) {
            const payload = {
                ...filtersPayload,
                type: mainTypeListing,
                name: document.getElementById("nameForSearch").value,
            }

            if (radioValue) {
                payload.alert_frequency = radioValue;
            }

            if (coorPolygon.length > 0) {
                payload.google_polygon = {
                    "geometry": {
                        "type": "Polygon",
                        "coordinates": coorPolygon[0]
                    }
                }
            }

            saveOrEditSearch('POST', payload, setLoading, setStatus)
        }
    };



    return (
        <Modal show={openSaveModal} onHide={closeModal} className='text-center' backdrop={loading ? true : false}>
            <Modal.Header closeButton={loading ? false : true}>
                <Modal.Title><BellFill className='text-yellow' /> {t('text.saveSearch3')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    status !== ''
                        ?
                        <Alert
                            variant={status === 'success' ? 'success' : 'danger'}
                            onClose={closeModal}
                            className="p-4 text-center"
                        >
                            <p>
                                {status === 'success'
                                    ? t('bigtext.savedSearch4')
                                    : t('bigtext.savedSearch5')
                                }
                            </p>

                            <Button variant="secondary" size="lg" onClick={closeModal}>
                                {t('words.close')}
                            </Button>
                        </Alert>

                        : countSavedSearches.length > 9
                            ?
                            <>
                                <Alert variant="light" className="bg-info text-white m-0 p-2 col-auto rounded-4 small mb-3">
                                    <FcInfo size={25} /> {t('bigtext.savedSearch6')}
                                </Alert>
                                <p>{t('bigtext.savedSearch7')}</p>
                                <Button
                                    size="sm"
                                    variant="graylight"
                                    className="border-2 me-4 fw-bold w-100"
                                    type="link"
                                    onClick={() => navigate("/profile/Searches")}
                                >
                                    {t('words.manage')} {t('words.saved')} {t('words.searches')}/{t('words.alerts')}
                                </Button>
                            </>

                            :
                            (filtersPayload !== undefined && filtersPayload !== null) || coorPolygon.length > 0
                                ?
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <Form.Group className="mb-4" >
                                        <Form.Label>{t('text.saveSearch1')} *</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder={t('text.saveSearch2')}
                                            id="nameForSearch"
                                        />
                                    </Form.Group>

                                    {coorPolygon.length > 0 &&
                                        <img src={`https://maps.googleapis.com/maps/api/staticmap?&size=400x200&key=${google_maps_api_key}&format=jpg&path=color:0xff5722|fillcolor:0x03a9f4b8|weight:3${polygonForMap}`} alt="map" />
                                    }


                                    <AlertsModalDiv radioValue={radioValue} setRadioValue={setRadioValue} hasAlerts={hasAlerts} />

                                    <Button variant="success" type="submit" className="mt-4" disabled={loading ? true : false}>
                                        {loading ?
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                {t('title.loading')}
                                            </>

                                            :
                                            <>
                                                <AiFillSave size={25} className="me-2 mb-1" />{t('words.save')} {t('words.srch')}
                                            </>
                                        }
                                    </Button>
                                </Form>

                                :
                                <>
                                    <h5>{t('text.saveSearch7')}</h5>
                                    <ul className='text-start'>
                                        <li className='mb-2'>{t('text.saveSearch8')}</li>
                                        <li>
                                            {t('text.saveSearch9')}
                                            <span className="border-2 rounded-pill fw-bold px-3 py-1 btn btn-success ms-2" >
                                                <ImSearch size={16} className="me-2" />{t('words.srch')}
                                            </span>
                                        </li>
                                        <li>
                                            {t('text.saveSearch10')}
                                            <span className="border-2 rounded-pill fw-bold px-3 py-1 btn btn-yellow ms-2 text-white" >
                                                <BellFill size={16} className="me-2" />{t('words.save')} {t('words.srch')}/{t('words.alert')}
                                            </span>
                                        </li>
                                    </ul>
                                </>
                }
            </Modal.Body>
        </Modal>
    )
}

export default SaveSearchFormModal
