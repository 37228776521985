import { useTranslation } from 'react-i18next'
import { Card, Col, Row } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { FaFileSignature, FaHandshake } from 'react-icons/fa';
import { GiHouse, GiKeyCard } from 'react-icons/gi';
import AdPage from '../../pages/AdPage';
import useWindowDimensions from '../../functions/GetWidthHeight'


const CardMainListings = ({ countAds }) => {

  const { t } = useTranslation();
  const { width } = useWindowDimensions();


  const cats = [
    { name: `${t('words.for2')} ${t('cats_tools.rent')}`, icon: <GiKeyCard size={30} className='me-2 text-yellow mb-1' />, value: 'rent', count: countAds.AdRent },
    { name: `${t('words.for2')} ${t('cats_tools.sale')}`, icon: <GiHouse size={30} className='me-2 text-danger mb-1' />, value: 'sale', count: countAds.Ad },
    { name: t('cats_tools.request'), icon: <FaFileSignature size={30} className='me-2 text-primary mb-1' />, value: 'request', count: countAds.AdRequest },
    { name: t('cats_tools.partnership'), icon: <FaHandshake size={30} className='me-2 text-success mb-1' />, value: 'partnership', count: countAds.AdPartnership },
  ];

  return (
    <Card className='px-3 py-2 py-xl-4 mt-5 border-0 bg-light position-relative'>

      <span
        className='m-1 position-absolute bg-info lead py-1 px-3 border border-white border-4 rounded-4 text-white text-nowrap'
        style={{ top: '-25px', left: '50%', transform: "translateX(-50%)", textAlign: "center", width: "80%" }}
      >
        {t('words.main')}
      </span>

      <Row className='mt-3 mt-xl-2'>

        {cats.map((cat, key) => (

          <Col sm={6} xs={3} className="fw-bold text-center my-1 p-0" key={key}>

            <div className={`border border-white rounded-4 me-1 ${width >= 1200 ? 'border-4 p-2' : 'border-3 py-1 px-0'}`}>

              <Link
                component={<AdPage />}
                to={`/ad/${cat.value}`}
                className={`link-sidebar ${width < 576 && 'small'}`}
              >
                
                {width >= 1200 && <>{cat.icon} <br /></>}
                <span>{cat.name}</span>
                {width < 500 && <br />}
                <span style={{fontSize: "0.75rem"}} className='text-nowrap'>({cat.count})</span>

              </Link>

            </div>

          </Col>

        ))}

      </Row>

    </Card>
  )
}

export default CardMainListings