import React from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'



const AlertsModalDiv = ({ radioValue, setRadioValue, hasAlerts, nameRadioGroup }) => { //if nameRadioGroup it's called on the profile not filters

    const { t } = useTranslation();

    const changeCategory = (e) => setRadioValue(e.currentTarget.value)

    const radios = [
        { name: t('text.saveSearch4'), value: 'immediately' },
        { name: t('text.saveSearch5'), value: 'daily' },
        { name: t('text.saveSearch6'), value: 'weekly' },
    ];


    return (

        <div className='bg-light p-4 mt-4'>

            <div className='d-inline-flex'>
                <Form.Check
                    type="switch"
                    size="lg"
                    defaultChecked={true}
                    onChange={hasAlerts}
                    style={{ transform: "scale(1.3)" }}
                />
                <span className='ms-2 fw-bold'>{t('words.notiFreq')}</span>
            </div>

            <div className='text-start'>
                {radioValue !== '' &&
                    radios.map((radio, idx) => (
                        <Form.Check
                            type="radio"
                            id={`radio-${radio.value}`}
                            key={idx}
                            label={radio.name}
                            name={nameRadioGroup ? nameRadioGroup : "group1"}
                            value={radio.value}
                            checked={radioValue === radio.value}
                            onChange={changeCategory}
                        />
                    ))
                }
            </div>

        </div>
    )
}

export default AlertsModalDiv
