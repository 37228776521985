import TokenRefresher from "./TokenRefresher";


//Saves and uses json after fetch with the useState of setter listi
export default class SimplePatchToggle {


    //Bearer token
    async fetchListings(url) {

        let getListing = async () => {

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;

            fetch(url, {
                method: 'PATCH',
                headers: {
                    'Authorization': bearer_token,
                },
                credentials: 'include',
            })
                .then(handleSubmitError)
                .catch(err => console.error(err))

        }

        function handleSubmitError(res) {
            if (res.status >= 200 && res.status <= 299) {
                return res.status;
            } else {
                return res.json();
            }
        }

        //checks and refresh token
        const sessionFetch = new TokenRefresher();
        sessionFetch.setTokenRefresh(getListing);

    }

}

