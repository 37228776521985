import React from 'react'
import { useTranslation } from 'react-i18next'
import { Badge, Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { MdManageSearch } from 'react-icons/md'
import { IoMdCloseCircle } from 'react-icons/io'

const SearchKeywordsBlog = ({ searchFunc, loading }) => {

    const { t } = useTranslation();

    let [openSearch, setOpenSearch] = React.useState(false)
    let toggleSearch = () => setOpenSearch(!openSearch);

    const [keywords, setKeywords] = React.useState([]);

    async function handleAddKeyword(e) {
        //if keyword exist
        if (e.key === 'Enter' && e.target.value.trim()) {
            const newKeywords = e.target.value
                .split(/[, ]+/)
                .map(keyword => keyword.trim())
                .filter(Boolean);

            setKeywords([...keywords, ...newKeywords]);
            e.target.value = '';

            return newKeywords;
        }
    }

    function handleRemoveKeyword(keyword) {
        const newKeywords = keywords.filter(kw => kw !== keyword);
        setKeywords(newKeywords);
        searchFunc(newKeywords)
    }

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();

        const field = {
            key: "Enter",
            target: document.getElementById('key_words')
        }

        handleAddKeyword(field).then(searchFunc(keywords))
    }

    //remove keys when toggling field
    React.useEffect(() => {
        if (keywords.length > 0) {
            setKeywords([])
            searchFunc([])
        }
        //eslint-disable-next-line
    }, [openSearch]);

    return (
        <div className='d-flex flex-column'>
            <div style={{ width: "23rem" }} className='d-inline-flex justify-content-end'>
                <OverlayTrigger placement="top"
                    overlay={<Tooltip >{t('words.srch')}</Tooltip>}
                >
                    <Button onClick={toggleSearch} variant="outline-grayDark" className='border-0 p-1'><MdManageSearch size={30} /></Button>
                </OverlayTrigger>

                <Form className={openSearch ? 'open search' : 'search'} onSubmit={handleSubmit}>

                    <Form.Control
                        disabled={loading}
                        id="key_words"
                        className="search"
                        type="text"
                        placeholder=""
                        onKeyPress={handleAddKeyword}
                    />

                    {openSearch &&
                        <Button type="submit" variant="success" className='align-self-end'>{t('words.srch')}</Button>
                    }
                </Form>
            </div>

            {(keywords && openSearch) &&
                <div>
                    {keywords.map(keyword => (
                        <span className='h5 mx-1' key={keyword}>
                            <Badge pill bg="primary">
                                {keyword}
                                <IoMdCloseCircle className='text-danger ms-1 lead' onClick={() => handleRemoveKeyword(keyword)}/>
                            </Badge>
                        </span>
                    ))}
                </div>
            }

            {openSearch &&
                <span className='ms-1 small text-muted'>&#x2139; {t('invite_blog.blog1')}</span>
            }
        </div>
    )
}

export default SearchKeywordsBlog