import { useTranslation } from 'react-i18next'
import { Col, Nav, Row, Tab } from 'react-bootstrap'
import { Link } from 'react-router-dom';
//import PaymentsTable from './PaymentsTable';
import BillingInfo from './BillingInfo';
import CancelSubscription from './CancelSubscription';


const BillingSubscription = () => {

    const { t } = useTranslation();

    return (

        <Tab.Container defaultActiveKey="first">
            <Row className='bg-white shadow-sm m-lg-4 p-4' id="billing-tabs">

                <Col sm={12}><h5 className='text-center mt-4 mb-5 fw-bold'>{t('title.billing')} &amp; {t('title.subscription')}</h5></Col>

                <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                            <Nav.Link eventKey="first">{t('profile.bill3')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second">{t('profile.bill4')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="third">{t('profile.bill5')}</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col sm={9}>
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <BillingInfo />
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">

                            <p className='lead text-center'>{t('profile.bill6')}</p>
                            <p className='text-center'>
                                <Link className='btn btn-lg btn-outline-success text-decoration-none' to="/profile/MyPlan" replace >{t('profile.bill7')}</Link>
                            </p>

                            {/* <PaymentsTable /> */}

                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                            <CancelSubscription />
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    )
}

export default BillingSubscription