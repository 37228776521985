import TokenRefresher from "./TokenRefresher";


//Saves and uses json after fetch with the useState of setter listi
export default class SimpleFetchNet {


    //Bearer token
    async fetchListings(url, method, setStatus) {

        let getListing = async () => {

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;
            const methodFetch = method ? method : 'GET'

            fetch(url, {
                method: methodFetch,
                headers: {
                    'Authorization': bearer_token,
                },
                credentials: 'include',
            })
                .then(handleSubmitError)
                .then(res => {

                    if (res >= 200 && res <= 299) {
                        if (setStatus) setStatus('success')
                    }
                    else {
                        if (setStatus) setStatus(res)
                    }

                    setTimeout(() => {
                       setStatus(null)
                    }, 6000);


                })

                .catch(err => console.error(err))

        }

        function handleSubmitError(res) {
            if (res.status >= 200 && res.status <= 299) {
                return res.status;
            } else {
                return res.json();
            }
        }

        //checks and refresh token
        const sessionFetch = new TokenRefresher();
        sessionFetch.setTokenRefresh(getListing);

    }

}

