import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next'
import FetchDropdowns from "../../../jwtAPI/FetchDropdowns";
import { useSelector } from "react-redux/";


function SaleCatDropdown({ create, listing, getDataDrops }) {

    const { t, i18n } = useTranslation();

    const optionsRedux = useSelector(state => state.dropTypes[i18n.language]["sale"])

    const [options, setOptions] = useState(optionsRedux);

    useEffect(() => {

        if (optionsRedux !== undefined) {
            setOptions(optionsRedux)
            return;
        }

        const fetchTypes = new FetchDropdowns();
        fetchTypes.fetchDrops('sale', setOptions, i18n.language)

        //eslint-disable-next-line
    }, [i18n.language]);


    return (
        <>
            {
                (options && create) || (options && listing)
                    ?
                    <>
                        <Form.Label>{t('words.estate')}*</Form.Label>
                        <Form.Select
                            defaultValue={listing && listing.ad_type.id}
                            required
                            name="ad_type"
                            onChange={getDataDrops}
                        >
                            <option></option>
                            {options.sale.map((option, key) => (
                                <option key={key} value={option.id}>{option.name}</option>
                            ))}
                        </Form.Select>
                    </>

                    : options && !create && !listing
                        ?
                        <>
                            <Form.Label className="small mb-0">{t('words.estate')}</Form.Label>
                            <Form.Select
                                required
                                name="ad_type"
                                id="ad_type"
                                onChange={getDataDrops}
                                size="sm"
                            >
                                <option value='all'>{t('words.estate')} ({t('words.all')})</option>
                                {options.sale.map((option, key) => (
                                    <option key={key} value={option.id}>{option.name}</option>
                                ))}
                            </Form.Select>
                        </>

                        : ''
            }

        </>
    )
}

export default SaleCatDropdown
