import React from "react"
import { Col, Container, Row, Tab, Tabs, Button, Card, CloseButton } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import SearchBarLanding from "./SearchBarLanding"

//Google
import { Loader } from '@googlemaps/js-api-loader';
import { google_maps_api_key, libraries } from '../../functions/ConstantVariables';
import { FcInfo } from "react-icons/fc";
import MatchingPro from "../../assets/MatchingPro";
import MatchingProWithInfo from "./MatchingProWithInfo";


function Hero() {

    const { t } = useTranslation();

    const [tabKey, setTabKey] = React.useState('rent');
    const [findPro, setFindPro] = React.useState(false);

    function openRegister() {
        //open login modal
        document.getElementById('open_register').click()
    }

    //when was inside SearchBarLanding it was refreshing on its own??
    let [googlePlaceObj, setGooglePlaceObj] = React.useState(null)

    const tabArray = [
        { mainType: 'rent', title: `${t('words.for2')} ${t('cats_tools.rent')}` },
        { mainType: 'sale', title: `${t('words.for2')} ${t('cats_tools.sale')}` },
        //{ mainType: 'inquiries', title: t('cats_tools.inquiries') },
        //{ mainType: 'findpro', title: "Find a Professional" },
    ]

    //Load google library
    const [isLoaded, setIsLoaded] = React.useState(false);
    const loader = new Loader({
        apiKey: google_maps_api_key,
        version: "weekly",
        libraries
    });
    loader
        .load()
        .then(() => { setIsLoaded(true) })


    return (

        <Container fluid="xl" className="d-flex h-100 p-0">
            <Row className="justify-content-center h-100 w-100 gx-0">

                <Col lg={!findPro ? 7 : 5} className="hero-search mt-6 mb-6" >

                    <Tabs
                        defaultActiveKey="rent"
                        id="landingSearch"
                        className="mt-2"
                        activeKey={tabKey}
                        onSelect={(k) => setTabKey(k)}
                    >
                        {tabArray.map((tabListing, key) => (

                            <Tab
                                eventKey={tabListing.mainType}
                                title={tabListing.title}
                                key={key}
                                className="bg-secondary"
                            >
                                {isLoaded &&
                                    <SearchBarLanding
                                        category={tabListing.mainType}
                                        tabKey={tabKey}
                                        googlePlaceObj={googlePlaceObj}
                                        setGooglePlaceObj={setGooglePlaceObj} />
                                }
                            </Tab>

                        ))}

                    </Tabs>

                </Col>

                {findPro && <Col lg={1} />}

                <Col lg={!findPro ? 3 : 6} className="mt-5">

                    {!findPro
                        ?
                        <Button id="globe-hero" onClick={() => setFindPro(true)}>
                            {t('text.findPro')}
                            <hr className="opacity-100" />
                            <MatchingPro />
                        </Button>

                        :
                        <Card className="p-0 slideLeft rounded-start-5">

                            <div className="text-end bg-secondary text-white p-5 rounded-start-5">
                                <span className="float-start fs-4">{t('text.findPro')}</span>
                                <CloseButton className="bg-white" onClick={() => setFindPro(false)} />
                            </div>

                            <div className="p-5 pt-4">
                                <p> {t('landing.hero4')} </p>
                                <div className="d-inline-flex mb-3">
                                {t('landing.hero5')}&nbsp;
                                    <MatchingProWithInfo />
                                </div>

                                <p>&rarr; {t('landing.hero6')}</p>

                                <p className="small muted">
                                    <FcInfo size={18} className="mb-1" />
                                    &nbsp;{t('landing.hero7')}
                                </p>

                                <div className="d-flex justify-content-between mt-4">
                                    <Button variant="gray rounded-4" onClick={() => setFindPro(false)}>
                                        {t('words.close')}
                                    </Button>
                                    <Button size="lg" variant="success" onClick={openRegister}>
                                        {t('title.createacc')} {t('bigtext.youLogin5')}
                                    </Button>
                                </div>

                            </div>

                        </Card>
                    }
                </Col>

            </Row>
        </Container>

    )
}
export default Hero

