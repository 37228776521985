import { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next'
import FetchDropdowns from "../../../jwtAPI/FetchDropdowns";
import { useSelector } from "react-redux/";


function RentCatDropdown({ create, listing, col, getDataDrops, onlyFirstDrop = false }) {

    const { t, i18n } = useTranslation();

    const optionsRedux = useSelector(state => state.dropTypes[i18n.language]["rent"])

    const [options, setOptions] = useState(optionsRedux);

    useEffect(() => {

        if (optionsRedux !== undefined) {
            setOptions(optionsRedux)
            return;
        }

        const fetchTypes = new FetchDropdowns();
        fetchTypes.fetchDrops('rent', setOptions, i18n.language)

        //eslint-disable-next-line
    }, [i18n.language]);


    return (
        <>

            {
                (options && create) || (options && listing)
                    ?
                    <>
                        <Col md={col}>
                            <Form.Label>{t('words.estate')}*</Form.Label>

                            <Form.Select
                                defaultValue={listing && listing.ad_type.id}
                                required
                                name="ad_type"
                                onChange={getDataDrops}
                            >
                                <option></option>
                                {options.rent.map((option, key) => (
                                    <option key={key} value={option.id}>{option.name}</option>
                                ))}
                            </Form.Select>
                        </Col>

                        <Col md={col}>
                            <Form.Label>{t('words.lease')}*</Form.Label>

                            <Form.Select
                                defaultValue={listing && listing.bail_type.id}
                                required
                                name="bail_type"
                                onChange={getDataDrops}
                            >
                                <option></option>
                                {options.bail_type.map((option, key) => (
                                    <option key={key} value={option.id}>{option.name}</option>
                                ))}
                            </Form.Select>
                        </Col>
                    </>

                    : options && !create && !listing
                        ?
                        <>
                            <Col md={col}>
                                <Form.Label className="small mb-0">{t('words.estate')}</Form.Label>
                                <Form.Select size="sm" onChange={getDataDrops} name="ad_type" id="ad_type">
                                    <option value="all">{t('words.estate')} ({t('words.all')})</option>
                                    {options.rent.map((option, key) => (
                                        <option key={key} value={option.id}>{option.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>

                            {!onlyFirstDrop &&
                                <Col md={col}>
                                    <Form.Label className="small mb-0">{t('words.lease')}</Form.Label>
                                    <Form.Select size="sm" onChange={getDataDrops} name="bail_type" id="bail_type">
                                        <option value="all">{t('words.lease')} ({t('words.all')})</option>
                                        {options.bail_type.map((option, key) => (
                                            <option key={key} value={option.id}>{option.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                            }

                        </>

                        : ''
            }

        </>
    )
}

export default RentCatDropdown
