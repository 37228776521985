
import React from "react"
import { Container, Row, Col } from 'react-bootstrap'
import Footer from '../components/Footer'
import VisitorHome from './VisitorHome'
import SidebarIndex from '../components/sidebar/SidebarIndex'
import NewsFeed from '../components/news/NewsFeed'

// redux 
import { useSelector } from 'react-redux'
import RightCollumn from '../components/news/RightCollumn'


const MainPage = () => {

  const logged = useSelector(state => state.user.loggedIn)


  return (
    <>

      {logged ?

        <>


          <Container fluid="xxl">

            <Row>

              <Col xl={3} className="p-1 pe-3 bg-white">
                <SidebarIndex />
              </Col>


              <Col xl={9} >
                <Row className="ps-1 ps-xl-5 pt-4">

                  <Col md={8} >
                    <NewsFeed />
                  </Col>

                  <Col md={4} className="ps-1 ps-xl-5 pt-2">
                    <RightCollumn />
                  </Col>

                </Row>

                <Row>
                  <Footer />
                </Row>
              </Col>



            </Row>

          </Container>
        </>


        :
        <>
          <VisitorHome />
          <Footer />
        </>

      }

    </>
  )
}

export default MainPage



