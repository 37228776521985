import React from 'react'
import { useTranslation } from 'react-i18next';
import { Card, Row, Col, OverlayTrigger, Tooltip, Button, Collapse, ListGroup, Badge } from 'react-bootstrap'

import { BarChartFill, Percent } from "react-bootstrap-icons";
import { FaBug, FaRegEye } from "react-icons/fa";
import { RiVipCrownFill } from "react-icons/ri";
import FetchGetSetSimple from '../../jwtAPI/FetchGetSetSimple';
import { Link, useNavigate } from 'react-router-dom';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import ProfileUpdator from '../profile/editProfile/ProfileUpdator';


const RightCollumn = () => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate()


    //count all views from user's listings
    const [allViews, setAllViews] = React.useState()
    const allViewsUrl = `${process.env.REACT_APP_DOMAIN}/${i18n.language}/${process.env.REACT_APP_REPORTS}/count-all-views`


    React.useEffect(() => {

        const fetchLists = new FetchGetSetSimple();
        fetchLists.fetchListings(allViewsUrl, setAllViews);

    }, [allViewsUrl, setAllViews]);


    //count the number of user's published listings
    const [listNum, setListNum] = React.useState()
    const listNumUrl = `${process.env.REACT_APP_USERINFO2_API}/count-published-articles`

    React.useEffect(() => {

        const fetchLists = new FetchGetSetSimple();
        fetchLists.fetchListings(listNumUrl, setListNum);

    }, [listNumUrl, setListNum]);

    let [showDetails, setShowDetails] = React.useState(false);
    let toggleDetails = () => { setShowDetails(!showDetails); }
    const detailsNum = ["sale", "rent", "request", "partnership", "training-job", "article", "benchmark", "crm", "event"]

    //the most viewed listing
    const [mostViewed, setMostViewed] = React.useState()
    const mostViewedUrl = `${process.env.REACT_APP_DOMAIN}/${i18n.language}/${process.env.REACT_APP_REPORTS}/find-most-viewed-article-of-user`

    React.useEffect(() => {

        const fetchLists = new FetchGetSetSimple();
        fetchLists.fetchListings(mostViewedUrl, setMostViewed);

    }, [mostViewedUrl, setMostViewed]);

    let lisTitle = ''

    if (mostViewed) {

        const articleObj = mostViewed.article ? mostViewed.article : mostViewed

        lisTitle =
            <Link className="text-decoration-none" to={`/listing/${articleObj.object_type}/${mostViewed.id}/${articleObj.slug}/`}>
                {articleObj.title}
            </Link>
    }


    const statistics = [
        { name: t('rightCol.card1title'), explain: t('rightCol.card1info'), number: allViews, percent: '3.48', when: 'last month', icon: <FaRegEye />, iconbg: 'primary', comparecolor: 'danger' },

        { name: t('rightCol.card2title'), explain: t('rightCol.card2info'), number: listNum && listNum.total, percent: '3.48', when: 'last week', icon: <BarChartFill />, iconbg: 'warning', comparecolor: 'danger' },

        { name: t('rightCol.card3title'), explain: t('rightCol.card3info'), number: lisTitle, icon: <RiVipCrownFill />, iconbg: 'yellow', comparecolor: 'success' },

        { name: t('rightCol.card4title'), explain: t('rightCol.card4info'), number: <><ProfileUpdator time={1} delay={0} classes="text-dark" />%</>, icon: <Percent />, iconbg: 'success', comparecolor: 'success' },
    ]


    return (
        <>

            <Link
                to="/profile/Help#contact-form"
                className="bg-transparent shadow text-decoration-none text-warning m-3 p-2 col-auto rounded-4 small d-flex"
            >
                <FaBug size={25} className="me-2" />
                <span>{t('rightCol.bugReport')}</span>
            </Link>

            {statistics.map((stat, key) => (

                <Card
                    key={key}
                    border={stat.iconbg}
                    className="col-auto rounded-4 flex-grow-1 m-3 mb-4 p-2 border-2"
                    style={{ borderStyle: 'dashed' }}
                >
                    <Card.Body>
                        <Row className='justify-content-between'>

                            <Col className='text-center'>

                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip >{stat.explain}</Tooltip>}
                                >
                                    <p className={`text-uppercase fw-bold mb-2 text-${stat.iconbg}`}>{stat.name}</p>

                                </OverlayTrigger>

                                <span className="fs-5 fw-bold mb-0">{stat.number}</span>
                                <br />

                                {key === 0
                                    &&
                                    <Link to="/profile/MyListings" className='text-decoration-none small'>&rarr; {t('rightCol.viewLisis')} </Link>
                                }

                                {key === 1
                                    &&
                                    <Button
                                        variant="transparent"
                                        onClick={toggleDetails}
                                        aria-controls="scope_groups"
                                        className='p-0 my-2'
                                    >
                                        <span>{t('words.details')}</span>
                                        {showDetails ? <IoIosArrowUp className="ms-1" size={19} /> : <IoIosArrowDown className="ms-1" size={19} />}
                                    </Button>
                                }

                                {key === 3
                                    &&
                                    <Link to="/profile/EditProfile" className="stretched-link" />
                                }

                            </Col>

                            <div className={`icon-shape bg-${stat.iconbg}`}>
                                {stat.icon}
                            </div>
                        </Row>

                        {(key === 1 && listNum) &&
                            <Collapse id="details-numbers" in={showDetails} dimension="height">
                                <div>
                                    <ListGroup>

                                        {detailsNum.map((listing, key) => (

                                            <ListGroup.Item action
                                                onClick={() => navigate(`/profile/${listing !== 'rent' && listing !== 'sale' && listing !== 'request' && listing !== 'partnership'
                                                    ? `MyToolBoxListings#${listing}`
                                                    : `MyListings#${listing}`
                                                    }`)}
                                                key={key}
                                                className="d-flex justify-content-between align-items-start"
                                            >
                                                <span className="fw-bold">{t('cats_tools.' + listing + '')}</span>
                                                <Badge bg="warning" pill>{listNum[listing]}</Badge>
                                            </ListGroup.Item>

                                        ))}
                                    </ListGroup>
                                </div>
                            </Collapse>
                        }

                    </Card.Body>
                </Card>

            ))}
        </>
    )
}

export default RightCollumn