import React from "react"
import { Button, Card, CloseButton, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FcInfo } from "react-icons/fc";
import MatchingProWithInfo from "./MatchingProWithInfo";
import Image1 from "../../assets/pictures/annonces-01.jpg";
import Image2 from "../../assets/pictures/annonces-02.jpg";
import Image3 from "../../assets/pictures/functionality03.jpg";
import Image4 from "../../assets/pictures/appel-offres-01.jpg";
import useWindowDimensions from '../../functions/GetWidthHeight'



function SectionExplain() {

    const { t, i18n } = useTranslation();
    const { width } = useWindowDimensions();

    /* React.useEffect(() => {


        const observer = new IntersectionObserver(function(entries) {
            // isIntersecting is true when element and viewport are overlapping
            // isIntersecting is false when element and viewport don't overlap
            if(entries[0].isIntersecting === true)
                console.log('Element has just become visible in screen');
        }, { threshold: [0] });
        
        observer.observe(document.getElementById("kjjkjkj"));

    }, []); */

    const [cardValue, setCardValue] = React.useState(null)

    const cardsArray = [
        { bg: "dark", value: 0, text: <><h5>{t('landing.explain1')}</h5><hr /></> },
        { bg: "secondary", value: 1, text: <><h5>{t('landing.explain2')}</h5><br /><br /><hr /></> },
        { bg: "warning", value: 2, text: <><h5>{t('landing.explain3')}</h5><br />{(i18n.language === "nl" || i18n.language === "en") && <br />}<hr /></> },
        { bg: "info", value: 3, text: <><h5>{t('landing.explain4')}</h5><br /><hr /></> },
    ]


    const cardsOpen = [
        { img: Image1, title: t('landing.explain19'), text: <> <p className="fw-bold">{t('landing.explain5')}</p><p className="mt-2">{t('landing.explain6')}</p><p>&rarr;{t('landing.explain7')}</p><p>&rarr;{t('landing.explain8')}</p><p>&rarr;{t('landing.explain9')}</p><hr /></> },

        {
            img: Image2, title: t('landing.explain10'), text: <> <p className="fw-bold">{t('landing.explain10')}</p><p className="mt-2">{t('landing.explain6')}</p><p>&rarr;{t('landing.explain11')}</p><hr /></>
        },

        { img: Image3, title: t('landing.explain20'), text: <> <p className="mt-2 fw-bold">{t('landing.explain12')}</p><p>&rarr;{t('landing.explain13')}</p><p>&rarr;{t('landing.explain14')}</p><p>&rarr;{t('landing.explain15')}</p><p className="fw-bold mb-1">{t('landing.explain16')}</p><p>&rarr;{t('landing.explain17')}</p><br /><hr /></> },

        { img: Image4, title: t('text.findPro'), text: <> <p className="fw-bold">{t('landing.hero4')}</p><div className="d-inline-flex mb-3">{t('landing.hero5')} &nbsp;<MatchingProWithInfo />.</div><p>&rarr;{t('landing.hero6')}</p><p className="small muted"> <FcInfo size={18} className="mb-1" />&nbsp;{t('landing.hero7')}</p><br /><hr /></> },
    ]


    function openRegister() {
        //open login modal
        document.getElementById('open_register').click()
    }


    return (

        <>
            <h5 className="my-5 mt-0">&rarr; {t('landing.explain18')}</h5>

            <Row className="justify-content-center gx-4">

                {cardValue === null
                    ?
                    cardsArray.map((card, key) => (

                        <div className="col-lg-3 col-md-6" key={key}>
                            <Card className={`bg-${card.bg} text-light p-4 my-2 trans-2`}>
                                {card.text}
                                <p className="text-end mt-2 mb-0">
                                    <Button onClick={() => setCardValue(card.value)}>{t('text.seeMore')}</Button>
                                </p>
                            </Card>
                        </div>

                    ))

                    :
                    <>
                        <Col lg={3} md={4} sm={6}>
                            <Card className={`bg-${cardsArray[cardValue].bg} text-light p-4 my-2`}>
                                {cardsArray[cardValue].text}
                                <Button onClick={() => setCardValue(null)}>{t('words.close')}</Button>
                            </Card>
                        </Col>
                        <Col lg={9} md={8} >

                            <Card as={Row} className="p-0 slideLeft rounded-start-5 mt-2 flex-row">

                                <div className={`bg-${cardsArray[cardValue].bg} text-end text-white p-5 rounded-start-5`}>
                                    <span className="float-start fs-4">{cardsOpen[cardValue].title}</span>
                                    <CloseButton className="bg-white" onClick={() => setCardValue(null)} />
                                </div>


                                <Col sm={8}>
                                    <div className="p-4 pt-4">

                                        {cardsOpen[cardValue].text}

                                        <div className="d-flex justify-content-between mt-4 text-nowrap">
                                            <Button variant="gray rounded-4" onClick={() => setCardValue(null)}>
                                                {t('words.close')}
                                            </Button>
                                            <Button size={width > 800 && 'lg'} variant="success text-nowrap" onClick={openRegister}>
                                                {t('title.createacc')} {t('bigtext.youLogin5')}
                                            </Button>
                                        </div>

                                    </div>
                                </Col>

                                <Col
                                    sm={4}
                                    style={{ backgroundImage: "url(" + cardsOpen[cardValue].img + ")", backgroundSize: "cover" }}
                                />

                            </Card>
                        </Col>
                    </>
                }

            </Row >


        </>
    )
}
export default SectionExplain

