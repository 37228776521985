import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next'
// fetch
import DeleteUserListing from '../../../jwtAPI/DeleteUserListing.js';


function DeleteConfirmationModal({ onHide, show, type, id, title }) {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false)
    const [typeForBe, setTypeForBe] = useState("")
    const [status, setStatus] = useState(0)

    useEffect(() => {

        switch (type) {
            case "job":
                setTypeForBe("training-job")
                break
            default:
                setTypeForBe(type)
        }

        return () => {
            setLoading(false)
            setTypeForBe("")
            setStatus(0)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Modal
                show={show}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                onHide={loading ? null : onHide}
                centered
            >
                <Modal.Header style={{ color: "white" }} className='bg-danger' closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('words.delete')} {type}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>"{title}"</h4>
                    {status === 0
                        ?
                        <p>{t('profile.prof4')}</p>

                        : status === 200
                            ? <p className='text-success'>{`"${title}" ${t('profile.prof5')}`}</p>

                            : status === 400
                                ? <p className='text-danger'>{t('bigtext.errorWrong')}</p>

                                : ''
                    }

                </Modal.Body>
                <Modal.Footer>
                    {
                        loading
                            ? <Spinner size="sm" animation="border" role="status" />

                            : status === 0 &&
                            <Button variant='success' onClick={() => DeleteUserListing(typeForBe, id, setLoading, setStatus)}>
                                {t('words.yes')}
                            </Button>
                    }

                    {status === 0 && <Button variant='warning' disabled={loading} onClick={onHide}>{t('words.no')}</Button>}

                </Modal.Footer>
            </Modal>
        </>
    )
}
export default DeleteConfirmationModal