import React from 'react';

import { warning, graylight, primary } from '../../scss/custom_vars.module.scss'
import { Nav, Container, Navbar, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import { FaGlobeEurope } from "react-icons/fa";
import Logo from '../../assets/LogoSignature';
import LoginRegisterModal from '../loginRegister/LoginRegisterModal';
import { Link, useNavigate } from 'react-router-dom';


const MainMenuVisitor = () => {

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();


  const lngs = {
    en: { nativeName: 'EN' },
    fr: { nativeName: 'FR' },
    nl: { nativeName: 'NL' },
    el: { nativeName: 'GR' }
  };

  if (i18n.language.length > 2) i18n.language = i18n.language.substring(0, 2);
  if (i18n.language === "gr") i18n.language = "el";
  if (i18n.language !== "en" && i18n.language !== "fr" && i18n.language !== "nl" && i18n.language !== "el") i18n.language = "en";

  //Dark Mode
  let initialColor = localStorage.getItem('themeCol');
  const [logoColor, setLogo] = React.useState(warning);

  React.useEffect(() => {
    if (initialColor === 'dark') {
      setLogo(graylight)
    } else {
      setLogo(warning)
    }
  }, [initialColor]);



  return (

    <Navbar bg="white" sticky="top" className="shadow-sm" expand="md">
      <Container fluid="xxl">
        <Navbar.Brand className="cp" onClick={()=>navigate('/home')}><Logo primaryColor={logoColor} primaryColor2={primary} primaryColor3={logoColor} /></Navbar.Brand>


        <Navbar.Toggle aria-controls="main-navbar-visitor" />
        <Navbar.Collapse id="main-navbar-visitor">
          <Nav className="flex-grow-1 text-center py-0 justify-content-end">

            <NavDropdown align="end" title={<div style={{ display: "inline-block", textTransform: "uppercase" }}>
              <FaGlobeEurope size={27} /> {i18n.language} </div>} >

              {Object.keys(lngs).map((lng) => (
                <NavDropdown.Item key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
                  {lngs[lng].nativeName}
                </NavDropdown.Item>
              ))}

            </NavDropdown>

            <Link
              className="nav-link bg-info bg-opacity-25 rounded-1 lead text-secondary px-1 px-md-2 fw-bold mx-2"
              to="/blog">
              {t('title.blog')}
            </Link>


            <LoginRegisterModal />

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar >

  )
}
export default MainMenuVisitor

