import React from 'react'
import { useTranslation } from 'react-i18next'
import reportUsLis from '../jwtAPI/ReportUserAndList'
import { Button, Dropdown, Modal, Form, Spinner } from 'react-bootstrap'
import { FlagFill } from "react-bootstrap-icons"


const ReportUserOrList = ({ theName, theID, listingType }) => {

    const { t } = useTranslation();

    const [openReport, setOpenReport] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [status, setStatus] = React.useState('')
    const [validated, setValidated] = React.useState(false);

    const [formData, updateFormData] = React.useState('');

    function handleChange(e) {
        updateFormData({
            [e.target.name]: e.target.value
        });
    };

    const adType = listingType && listingType === 'job' ? 'training-job' : listingType
    const url =
        listingType && listingType !== 'article'
            ? `${process.env.REACT_APP_SINGLE_LIS_API}/report/${adType}/${theID}`

            : listingType && listingType === 'article'
                ? `${process.env.REACT_APP_SINGLE_ARTICLE_API}/report/${theID}`

                : `${process.env.REACT_APP_USERINFO2_API}/report/${theID}`


    const handleSubmit = (event) => {

        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);

        if (form.checkValidity() === true) {
            event.preventDefault();
            setValidated(true);
            setLoading(true);
            reportUsLis(url, formData, setStatus, setLoading)
        }
    };



    return (

        <>

            <Dropdown.Item onClick={() => setOpenReport(true)}><FlagFill /> {t('words.report1')}</Dropdown.Item>

            <Modal show={openReport} onHide={() => setOpenReport(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('words.report1')} {theName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {status ?
                        status === 'success'
                            ?
                            <>
                                <p>{t('bigtext.report1')}</p>
                                <hr />
                                <Button variant="success" className="float-end" onClick={() => setOpenReport(false)}>
                                    {t('words.close')}
                                </Button>
                            </>
                            :
                            <>
                                <p>{t('bigtext.report2')}</p>
                                <hr />
                                <Button variant="success" className="float-end ms-3" onClick={() => setStatus('')}>
                                    {t('words.retry')}
                                </Button>
                                <Button variant="danger" className="float-end" onClick={() => setOpenReport(false)}>
                                    {t('words.close')}
                                </Button>
                            </>
                        :
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlTextarea1"
                            >
                                <Form.Label>{t('bigtext.report3')}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    autoFocus
                                    required
                                    name="body"
                                    onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t('bigtext.report4')}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <ul className='small'>
                                <li>{t('bigtext.report5')}</li>
                                <li>{t('bigtext.report6')}</li>
                            </ul>

                            <hr />
                            <Button variant="danger" className="float-end" type="submit" disabled={loading ? true : false}>
                                {loading ?
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        {t('title.loading')}
                                    </>

                                    :  `${t('words.submit')} ${t('words.report2')}`
                                }

                            </Button>
                        </Form>
                    }

                </Modal.Body>
            </Modal>
        </>

    )
}

export default ReportUserOrList