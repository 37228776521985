import TokenRefresher from "./TokenRefresher";


export default class FetchUserListings {

    constructor(ac) {
        this.ac = ac
    }
    //Bearer token
    async fetchListings(url, setAllListings, setArrayToRender, parameters, setLoading, setSumListing) {

        let getListing = async () => {

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;

            fetch(url, {
                method: 'POST',
                signal: this.ac ? this.ac : undefined,
                headers: {
                    'Authorization': bearer_token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(parameters),
                credentials: 'include',
            })
                .then(handleSubmitError)
                .then(myData => {
                    
                    setSumListing(myData.number_of_records)

                    if (Array.isArray(myData.records)) {

                        setAllListings(myData.records);
                        if (setArrayToRender) { setArrayToRender(myData.records) };

                    }
                    else {
                        //convert object to array
                        const values = Object.values(myData.records) // array of values (each value is a listing)
                        const myArr = []
                        values.forEach((value, index, array) => myArr.push(value))
                        setAllListings(myArr)
                        if (setArrayToRender) { setArrayToRender(myArr) };
                    }

                    if (setLoading) setLoading(false)
                })

                .catch(err => {
                    console.error(err)
                    if (setLoading) setLoading(false)
                })

        }

        //Error for Fetch
        function handleSubmitError(res) {

            if (res.status >= 200 && res.status <= 299) {

                return res.json();
            } else {

                throw Error(res.status);
            }
        }

        //getListing();

        //checks and refresh token
        const sessionFetch = new TokenRefresher();
        sessionFetch.setTokenRefresh(getListing);

    }

}

