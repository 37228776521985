import { useRoutes } from "react-router-dom";
//pages import
import MainPage from "./pages/MainPage";
import AdPage from "./pages/AdPage";
//import MessagesCenter from "./pages/MessagesCenter";
import ProfilePage from "./pages/ProfilePage";
import NetworkPage from "./pages/NetworkPage";
import BlogPage from "./pages/BlogPage";
import PropertyPage from "./pages/PropertyPage";
import SimplePage from "./pages/SimplePage";
import AgentPage from "./pages/AgentPage";
import JobPage from "./pages/JobPage";
import AgendaPage from "./pages/AgendaPage";
import CrmPage from "./pages/CrmPage";
import ArticlePage from "./pages/ArticlePage";
import BlogPostPage from "./pages/BlogPostPage";
import CreateNew from "./pages/CreateNew";
import EditListings from "./pages/EditListings";
import ComparisonPage from "./pages/ComparisonPage";
import ToolPage from "./pages/ToolPage";
import NotFound404 from "./pages/NotFound404";
import NeedLoginPage from "./pages/NeedLoginPage";


const AppRoutes = () => {
  let routes = useRoutes([
    
    { path: "/home", element: <MainPage /> },
    { path: "/register", element: <NeedLoginPage /> },
    { path: "/ad/:value", element: <AdPage /> },
    { path: "/tool/:value", element: <ToolPage /> },
    { path: "/profile/:type", element: <ProfilePage /> },
    { path: "/agent/:id", element: <AgentPage /> },
    { path: "/network", element: <NetworkPage /> },
    { path: "/blog", element: <BlogPage /> },
    //{ path: "/message-center", element: <MessagesCenter /> },
    { path: "/listing/:type/:idLis/:slug", element: <PropertyPage /> },
    { path: "page/:name", element: <SimplePage /> },
    { path: "/listing/job/:id/:slug", element: <JobPage /> },
    { path: "/listing/crm/:id/:slug", element: <CrmPage /> },
    { path: "/listing/article/:id/:slug", element: <ArticlePage /> },
    { path: "/listing/blog/:id/:slug", element: <BlogPostPage /> },
    //{ path: "/listing/library/:id/:slug", element: <LibraryPage />},
    { path: "/listing/benchmark/:id/:slug", element: <ComparisonPage /> },
    { path: "/listing/event/:id/:slug", element: <AgendaPage /> },
    { path: "/page/:name/:secondName", element: <SimplePage /> },
    { path: "/create-new/:type", element: <CreateNew /> },
    { path: "/edit/:type/:id", element: <EditListings /> },
    { path: "/", element: <MainPage /> }, //<TestLinks /> },
    { path: "*", element: <NotFound404 /> },

    // ...
  ]);
  return routes;
}
export default AppRoutes