import React from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Loader } from '@googlemaps/js-api-loader';
import { google_maps_api_key, libraries } from '../../../functions/ConstantVariables';
import LocationDropdown from '../../generalSearch/LocationDropdown';






const EditAddress = ({ user, disable, index = false, location_service = false }) => {

    const { t } = useTranslation();
    let coordinatesId = "coordinates"
    let nearby_resultsId = `nearby_results`
    let lisStreetId = `lisStreet`
    let lisStreetExtraId = `lisStreetExtra`
    let lisCityId = `lisCity`
    let lisAreaId = `lisArea`
    let lisPostalId = `lisPostal$`
    if (typeof index === "number") {
        coordinatesId = `coordinates${index}`
        nearby_resultsId = `nearby_results${index}`
        lisStreetId = `lisStreet${index}`
        lisStreetExtraId = `lisStreetExtra${index}`
        lisCityId = `lisCity${index}`
        lisAreaId = `lisArea${index}`
        lisPostalId = `lisPostal${index}`

    }


    const loader = new Loader({
        apiKey: google_maps_api_key,
        version: "weekly",
        libraries
    });

    // Promise
    loader
        .load()
        .then((google) => {

            function initialize() {

                const input = document.getElementById(lisStreetId);
                const autocomplete = new google.maps.places.Autocomplete(input);


                google.maps.event.addListener(autocomplete, 'place_changed', function () {

                    const place = autocomplete.getPlace();

                    if (place.address_components) {
                        //Area
                        let area = place.address_components.filter(item => item.types[0] === "locality");
                        if (area.length === 0) {
                            area = place.address_components.filter(item => item.types[0] === "administrative_area_level_2");
                        }
                        if (area.length === 0) {
                            area = place.address_components.filter(item => item.types[0] === "administrative_area_level_3");
                        }
                        if (area.length === 0) {
                            area = place.address_components.filter(item => item.types[0] === "administrative_area_level_4");
                        }

                        //Country
                        const country = place.address_components.filter(item => item.types[0] === "country");

                        //City
                        let city = place.address_components.filter(item => item.types[0] === "locality");
                        if (city.length === 0) {
                            city = place.address_components.filter(item => item.types[0] === "administrative_area_level_1");
                        }
                        if (city.length === 0) {
                            city = place.address_components.filter(item => item.types[0] === "administrative_area_level_2");
                        }
                        if (city.length === 0) {
                            city = place.address_components.filter(item => item.types[0] === "administrative_area_level_3");
                        }
                        if (city.length === 0) {
                            city = place.address_components.filter(item => item.types[0] === "postal_town");
                        }
                        /*  if (city.length === 0) {
                             city = place.address_components.filter(item => item.types[0] === "locality");
                         } */

                        //ZIP
                        const postal = place.address_components.filter(item => item.types[0] === "postal_code");


                        /********* Insert to Fields *********/
                        //Area
                        if (area.length > 0) { document.getElementById(lisAreaId).value = area[0].long_name }
                        else { document.getElementById(lisAreaId).value = '' }

                        //Country
                        if (country.length > 0) { setValueCountry({ name: country[0].long_name, value: country[0].short_name }) }
                        else { setValueCountry(null) }

                        //City
                        if (city.length > 0) { document.getElementById(lisCityId).value = city[0].long_name; }
                        else { document.getElementById(lisCityId).value = '' }

                        //ZIP
                        if (postal.length > 0) { document.getElementById(lisPostalId).value = postal[0].long_name }
                        else { document.getElementById(lisPostalId).value = '' }

                        //Coordinates
                        document.getElementById(coordinatesId).value = `${place.geometry.location.lat()},${place.geometry.location.lng()}`;

                    }


                });
            }
            initialize();

        })
        .catch(e => {
            // do something
        });


    let [valueCountry, setValueCountry] = React.useState(null)

    function resetCoordinates() {
        document.getElementById(lisAreaId).value = ''
        setValueCountry(null);
        document.getElementById(lisCityId).value = ''
        document.getElementById(lisPostalId).value = ''
        document.getElementById(coordinatesId).value = ''
    }

    return (

        <>
            {!location_service &&
                <Col sm={12} className="fs-5 mt-4  pt-3 pb-2">
                    {t('title.personalAddress')}
                </Col>}


            <Col lg={12} className=" p-4">


                <Row className="gy-3 ">

                    <Form.Control
                        type="text"
                        id={coordinatesId}
                        name="google_map"
                        autoComplete="xcvb"
                        defaultValue={location_service ? null : user.address.google_map}
                        className='d-none'
                    />

                    <Form.Control
                        type="hidden"
                        id={nearby_resultsId}
                        name="nearby_results"
                        autoComplete="xcvb"
                        disabled={disable}
                        defaultValue=""
                    />

                    <Form.Group as={Col} md="4" controlId={lisStreetId}>
                        <Form.Label>{t('title.address')}</Form.Label>
                        <Form.Control
                            type="text"
                            name="street"
                            placeholder={t('words.enter') + ' ' + t('title.loc')}
                            defaultValue={location_service ? null : user.address.street}
                            autoComplete="xcvb"
                            size="50"
                            onKeyUp={resetCoordinates}
                            className="formAddCss"
                            disabled={disable}
                        />
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId={lisStreetExtraId}>
                        <Form.Label>{t('title.address')} 2</Form.Label>
                        <Form.Control
                            type="text"
                            name="complement"
                            defaultValue={location_service ? "" : user.address?.complement}
                            autoComplete="xcvb"
                            className="formAddCss"
                            disabled={disable}
                        />
                    </Form.Group>

                    <Col md={4}>
                        <Form.Label>{t('title.country')}*</Form.Label>
                        <LocationDropdown
                            disabled={disable}
                            valueCountry={valueCountry}
                            profile={location_service ? null : user.address.country}
                            countryExistsEdit={user.address.country && user.address.country}
                            className="formAddCss"
                        />
                    </Col>

                    <Form.Group as={Col} md="4" controlId={lisCityId}>
                        <Form.Label>{t('title.city')}*</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="city"
                            defaultValue={location_service ? "" : user.address.city}
                            autoComplete="xcvb"
                            className="formAddCss"
                            disabled={disable}
                        />
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId={lisAreaId}>
                        <Form.Label>{t('title.area')}</Form.Label>
                        <Form.Control
                            type="text"
                            name="state"
                            defaultValue={location_service ? "" : user.address.state}
                            autoComplete="xcvb"
                            className="formAddCss"
                            disabled={disable}
                        />
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId={lisPostalId}>
                        <Form.Label>{t('title.zip')}</Form.Label>
                        <Form.Control
                            type="text"
                            name="postal_code"
                            defaultValue={location_service ? "" : user.address.postal_code}
                            autoComplete="xcvb"
                            className="formAddCss"
                            disabled={disable}
                        />
                    </Form.Group>

                </Row>

            </Col>
        </>

    )
}

export default EditAddress