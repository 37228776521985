import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';
import React from "react"
import { Archive, Eye, PencilSquare, Share, CloudArrowUp, Trash } from 'react-bootstrap-icons';
import { FaFileDownload } from 'react-icons/fa';
import PropertyPage from "../../../pages/PropertyPage";
import ArchiveConfirmationModal from "./ArchiveConfirmationModal";
import PublishConfirmationModal from "./PublishConfirmationModal";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import FetchReport from '../../../jwtAPI/FetchReport';



const OverlayListing = ({ link, id, edit, type, title, status, articleId }) => {

    const { t, i18n } = useTranslation();

    const [show, setShow] = useState(false);
    const [archiveModalShow, setArchiveModalShow] = useState(false);
    const [publishModalShow, setPublishModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const handleClose = () => setShow(false);

    function copyClipboard() {
        navigator.clipboard.writeText(process.env.REACT_APP_URL + link);
        setShow(true);
    }

    function cleanUp() {
        setShow(false)
        setArchiveModalShow(false)
        setPublishModalShow(false)
        setDeleteModalShow(false)
    }

    React.useEffect(() => {

        return () => {
            cleanUp()
        }
    }, [])

    const url = `${process.env.REACT_APP_DOMAIN}/en/api/article/${articleId}/report-views`;

    let [downloading, setDownloading] = React.useState(false);
    //Fetch document onClick
    const excelDown = () => {
        const downloadRep = new FetchReport();
        downloadRep.fetchReport(url, `${title}-report.xlsx`, setDownloading);
    }

    return (

        <div className='overlay-user-listing'>
            {archiveModalShow && <ArchiveConfirmationModal title={title} type={type} id={id} show={archiveModalShow}
                onHide={() => setArchiveModalShow(false)} />}
            {publishModalShow && <PublishConfirmationModal title={title} type={type} id={id} show={publishModalShow}
                onHide={() => setPublishModalShow(false)} />}
            {deleteModalShow && <DeleteConfirmationModal title={title} type={type} id={id} show={deleteModalShow}
                onHide={() => setDeleteModalShow(false)} />}


            <div className="text-center">
                <Link
                    component={<PropertyPage />}
                    to={link}
                    className="text-decoration-none text-dark btn btn-sm btn-light trans-2"
                >
                    <Eye className="me-1" size={30} /><br />
                    {t('words.view')}
                </Link>
                <Link
                    component={<PropertyPage />}
                    to={edit}
                    className="text-decoration-none text-dark btn btn-sm btn-light trans-2"
                >
                    <PencilSquare className="me-1 p-1" size={30} /><br />
                    {t('words.edit')}
                </Link>

                {status === `${process.env.REACT_APP_DOMAIN}/${i18n.language}/${process.env.REACT_APP_USER_LISTINGS_API}` ?
                    <Button variant="light" className="trans-2 " size="sm" onClick={copyClipboard}>
                        <Share className="me-1 p-1 text-dark" size={30} /><br />
                        <span className="text-nowrap text-dark">{t('words.copy')} {t('words.link')}</span>
                    </Button> :
                    <Button variant="light" className="trans-2" size="sm" onClick={() => setPublishModalShow(true)}>
                        <CloudArrowUp className="me-1 p-1 text-dark" size={30} /><br />
                        <span className="text-nowrap text-dark">{t(`words.publish`)} </span>
                    </Button>
                }

                <Modal show={show} onHide={handleClose} centered size="sm">
                    <Modal.Header closeButton className="border-0">
                        <p>{t('bigtext.copyClipboard')}</p>
                    </Modal.Header>
                </Modal>
                {status === `${process.env.REACT_APP_DOMAIN}/${i18n.language}/${process.env.REACT_APP_USER_LISTINGS_API}` ?
                    <Button id={`arch${type + id}`} onClick={() => setArchiveModalShow(true)} variant="light" className="trans-2 text-dark" size="sm">
                        <Archive className="me-1 p-1 text-dark" size={30} /><br />
                        {t('words.archive')}
                    </Button> : <Button onClick={() => setDeleteModalShow(true)} variant="light" className="trans-2 text-dark" size="sm">
                        <Trash className="me-1 p-1 text-dark" size={30} /><br />
                        {t('words.delete')}
                    </Button>}
            </div>
            <div>
                <Button variant="light" onClick={excelDown} className="trans-2 px-3 text-dark" size="sm">
                    <FaFileDownload className="me-1 p-1 text-dark" size={30} />
                    {downloading ? t('title.loading') : `${t('words.downl')} ${t('words.report2')}`}
                </Button>
            </div>
        </div>
    )
}

export default OverlayListing
