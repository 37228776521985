import React from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Loader } from '@googlemaps/js-api-loader';
import { google_maps_api_key, libraries } from '../../functions/ConstantVariables';
import LocationDropdown from '../generalSearch/LocationDropdown';



const AddressCE = ({ listing }) => {

    const { t } = useTranslation();


    const loader = new Loader({
        apiKey: google_maps_api_key,
        version: "weekly",
        libraries
    });

    // Promise
    loader
        .load()
        .then((google) => {

            function initialize() {

                const input = document.getElementById('lisStreet');
                const autocomplete = new google.maps.places.Autocomplete(input);


                google.maps.event.addListener(autocomplete, 'place_changed', function () {

                    const place = autocomplete.getPlace();

                    if (place.address_components) {
                        //Area
                        let area = place.address_components.filter(item => item.types[0] === "locality");
                        if (area.length === 0) {
                            area = place.address_components.filter(item => item.types[0] === "administrative_area_level_2");
                        }
                        if (area.length === 0) {
                            area = place.address_components.filter(item => item.types[0] === "administrative_area_level_3");
                        }
                        if (area.length === 0) {
                            area = place.address_components.filter(item => item.types[0] === "administrative_area_level_4");
                        }

                        //Country
                        const country = place.address_components.filter(item => item.types[0] === "country");

                        //City
                        let city = place.address_components.filter(item => item.types[0] === "locality");
                        if (city.length === 0) {
                            city = place.address_components.filter(item => item.types[0] === "administrative_area_level_1");
                        }
                        if (city.length === 0) {
                            city = place.address_components.filter(item => item.types[0] === "administrative_area_level_2");
                        }
                        if (city.length === 0) {
                            city = place.address_components.filter(item => item.types[0] === "administrative_area_level_3");
                        }
                        if (city.length === 0) {
                            city = place.address_components.filter(item => item.types[0] === "postal_town");
                        }
                        /*  if (city.length === 0) {
                             city = place.address_components.filter(item => item.types[0] === "locality");
                         } */

                        //ZIP
                        const postal = place.address_components.filter(item => item.types[0] === "postal_code");


                        /********* Insert to Fields *********/
                        //Area
                        if (area.length > 0) { document.getElementById('lisArea').value = area[0].long_name }
                        else { document.getElementById('lisArea').value = '' }

                        //Country
                        if (country.length > 0) { setValueCountry({ name: country[0].long_name, value: country[0].short_name }) }
                        else { setValueCountry(null) }

                        //City
                        if (city.length > 0) { document.getElementById('lisCity').value = city[0].long_name; }
                        else { document.getElementById('lisCity').value = '' }

                        //ZIP
                        if (postal.length > 0) { document.getElementById('lisPostal').value = postal[0].long_name }
                        else { document.getElementById('lisPostal').value = '' }

                        //Coordinates
                        document.getElementById('coordinates').value = `${place.geometry.location.lat()},${place.geometry.location.lng()}`;

                    }


                });
            }
            initialize();

        })
        .catch(e => {
            // do something
        });


    let [valueCountry, setValueCountry] = React.useState(null)

    function resetCoordinates() {
        document.getElementById('lisArea').value = ''
        setValueCountry(null);
        document.getElementById('lisCity').value = ''
        document.getElementById('lisPostal').value = ''
        document.getElementById('coordinates').value = ''
    }

    return (

        <>
            <Col sm={12} className="text-white bg-warning mt-4 px-4 pt-3 pb-2">
                <h6>{t('title.address')}</h6>
            </Col>

            <Col lg={12} className="bg-white shadow-sm p-4">


                <Row className="gy-3">

                    <Form.Control
                        type="text"
                        id="coordinates"
                        name="google_map"
                        autoComplete="xcvb"
                        defaultValue={(listing && listing.address.google_map) && listing.address.google_map}
                        className='d-none'
                    />

                    <Form.Control
                        type="hidden"
                        id="nearby_results"
                        name="nearby_results"
                        autoComplete="xcvb"
                        defaultValue=""
                    />

                    <Form.Group as={Col} md="4" controlId="lisStreet">
                        <Form.Label>{t('title.address')}</Form.Label>
                        <Form.Control
                            type="text"
                            name="street"
                            placeholder={t('words.enter') + ' ' + t('title.loc')}
                            defaultValue={(listing && listing.address.street) && listing.address.street}
                            autoComplete="xcvb"
                            size="50"
                            onKeyUp={resetCoordinates}
                        />
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId="lisStreetExtra">
                        <Form.Label>{t('title.address')} 2</Form.Label>
                        <Form.Control
                            type="text"
                            name="complement"
                            defaultValue={(listing && listing.address.complement) && listing.address.complement}
                            autoComplete="xcvb"
                        />
                    </Form.Group>

                    <Col md={4}>
                        <Form.Label>{t('title.country')}*</Form.Label>
                        <LocationDropdown valueCountry={valueCountry} countryExistsEdit={listing && listing.address.country} />
                    </Col>

                    <Form.Group as={Col} md="4" controlId="lisCity">
                        <Form.Label>{t('title.city')}*</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="city"
                            defaultValue={(listing && listing.address.city) && listing.address.city}
                            autoComplete="xcvb"
                        />
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId="lisArea">
                        <Form.Label>{t('title.area')}</Form.Label>
                        <Form.Control
                            type="text"
                            name="state"
                            defaultValue={(listing && listing.address.state) && listing.address.state}
                            autoComplete="xcvb"
                        />
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId="lisPostal">
                        <Form.Label>{t('title.zip')}</Form.Label>
                        <Form.Control
                            type="text"
                            name="postal_code"
                            defaultValue={(listing && listing.address.postal_code) && listing.address.postal_code}
                            autoComplete="xcvb"
                        />
                    </Form.Group>

                </Row>

            </Col>
        </>

    )
}

export default AddressCE