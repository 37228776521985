import React, { useEffect, useState, useRef } from 'react'
import { useCountUp } from 'react-countup';
import { useSelector } from 'react-redux'



function ProfileUpdator({ styles, classes, delay, time }) {


    const user = useSelector(state => state.user)
    const countUpRef = useRef(null);
    const [completion, setCompletion] = useState(0)
    const [countStart] = useState(0)
    const [countEnd, setCountEnd] = useState(0)

    const { update } = useCountUp({
        ref: countUpRef,
        start: countStart,
        end: countEnd,
        delay: delay,
        duration: time,
        // onReset: () => console.log('Resetted!'),
        // onUpdate: () => console.log('Updated!'),
        // onPauseResume: () => console.log('Paused or resumed!'),
        // onStart: ({ pauseResume }) => console.log(pauseResume),
        // onEnd: ({ pauseResume }) => console.log(pauseResume),
    });
    useEffect(() => {
        profileChanged()
        // eslint-disable-next-line
    }, [user])

    useEffect(() => {
        let sum = calculatePerC()
        setCompletion(sum)

        setCountEnd(sum)
        // eslint-disable-next-line
    }, [])

    function profileChanged() {

        const sum = calculatePerC()

        if (sum !== completion) {
        }
        update(sum)
        setCompletion(sum)


    }
    function calculatePerC() {
        let sum = 0
        if (user.first_name !== "") sum += 10
        if (user.last_name !== "") sum += 10
        if (user.email !== "") sum += 10
        if (user.user_types !== []) sum += 10
        if (user.linkedin_url !== "" || user.twitter_url !== "" || user.facebook_url !== "") sum += 10
        if (user?.description !== "") sum += 10
        if (user.address.city !== "") sum += 10
        if (user.cvUploaded) sum += 20
        if (user?.title !== "") sum += 10
        return sum;
    }
    return (
        <>

            <span className={classes} style={styles} ref={countUpRef} />



        </>
    )
}
export default ProfileUpdator
