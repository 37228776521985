import { deleteSavedSearchRedux } from "../Redux/saved-searches-alerts-slice";
import { store } from "../Redux/Store";
import TokenRefresher from "./TokenRefresher";


const deleteSavedSearch = async (thisId, setLoading) => {


    const url = `${process.env.REACT_APP_DEL_EDIT_SEARCH}/delete/${thisId}`;

    const deleteSearch = async () => {

        const bearer_token = `Bearer ${localStorage.getItem('token')}`;

        fetch(url, {
            method: 'DELETE',
            headers: {
                'Authorization': bearer_token,
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        })
            .then(res => {
                setLoading(false)
                if (res.status >= 200 && res.status <= 299) {
                    store.dispatch(deleteSavedSearchRedux({ id: thisId }))
                } else {
                    alert('There was an error please try again')
                }
            })
            .catch(err => { console.error(err); setLoading(false); })

    }

    const sessionFetch = new TokenRefresher();
    sessionFetch.setTokenRefresh(deleteSearch);
}


export default deleteSavedSearch