import React from 'react';
import { Button, Card, Row, Col, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import FetchGetSetSimple from '../jwtAPI/FetchGetSetSimple';

import Footer from '../components/Footer';
import AgentContact from '../components/singleListing/AgentContact';
import SingleListHero from '../components/singleListing/SingleListHero';
import ButtonEditLis from '../components/singleListing/ButtonEditLis';
import OverviewTitleShare from '../components/singleListing/OverviewTitleShare';
import NeedLoginPage from './NeedLoginPage';
import LoadingData from '../components/createEdit/LoadingData';
import DateOnly from '../functions/DateOnly';
import SingleListVideo from '../components/singleListing/SingleListVideo';




const ArticlePage = () => {

    const { t, i18n } = useTranslation();
    const [logged] = React.useState(localStorage.getItem('logged'));
    const { id } = useParams();
    let url = `${process.env.REACT_APP_DOMAIN}/${i18n.language}/api/article/${id}?format=full`
    const [listing, setter] = React.useState(null);


    React.useEffect(() => {

        const fetchLists = new FetchGetSetSimple();
        fetchLists.fetchListings(url, setter);

        // eslint-disable-next-line
    }, [id, url]);


    return (
        <>
            {(listing && logged) ?

                <>



                    <Container fluid="xxl">
                        <SingleListHero excerptPhoto={listing.excerpt_photo_url} gallery={listing.photos_urls} />
                    </Container>


                    <Container fluid="xl">

                        <Row className="justify-content-between mt-5">

                            <Col xl={8} lg={12}>

                                <ButtonEditLis idAuthor={listing.author.id} type="article" idLis={id} />

                                <Button variant='warning' size="sm" className='w-auto px-2 py-0'>{t('cats_tools.article')}</Button>

                                <h1 className='fw-bold mt-2'>{listing.title}</h1>

                                {listing.number
                                    && <p className='fs-6 fw-bold'>ID: ({listing.number})</p>
                                }

                                {listing.locale
                                    && <p className='fs-6 fw-bold'>{t('words.lang')}: {listing.locale.toUpperCase()}</p>
                                }

                                <p><strong>{t('words.pubDate')}: </strong> <DateOnly dateRaw={listing.published_at} /></p>


                                <Card className='p-4 border-0 shadow-sm my-5'>

                                    <OverviewTitleShare
                                        link={window.location.href}
                                        title={listing.title}
                                        isFav={listing.favorited_by}
                                        lisId={listing.id}
                                        listingType='article'
                                    />

                                    {listing.excerpt && <h5 className='text-muted mt-4'>{listing.excerpt}</h5>}

                                    {listing.content && <p dangerouslySetInnerHTML={{ __html: listing.content }} />}


                                </Card>

                                {listing.video_link &&
                                    <SingleListVideo link={listing.video_link} />
                                }


                            </Col>

                            <Col xl={3} lg={12}>

                                <AgentContact agent={listing.author} />

                            </Col>

                        </Row>

                    </Container>

                    <Footer />

                </>

                : !logged
                    ? <NeedLoginPage />

                    : <LoadingData />
            }
        </>
    )
}

export default ArticlePage
