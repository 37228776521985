import TokenRefresher from "./TokenRefresher";
import { setAllCustomInOnce } from "../Redux/news-feed-slice.js"
import { store } from "../Redux/Store.js"


export default class FetchFeed {

    constructor(ac) {
        this.ac = ac
    }

    //Bearer token
    async fetchListings(url, setter, listi, setLoading, optionsFeed, setButtonText, simpleVersion = false, setShow = null, setStatus = null) {

        let getListing = async () => {

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;


            fetch(url, {
                method: 'POST',
                signal: this.ac,
                headers: {
                    'Authorization': bearer_token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(optionsFeed),
                credentials: 'include',
            })
                .then(handleSubmitError)
                .then(myData => {
                    if (!simpleVersion) {
                        listi !== null ? setter(listi.concat(myData)) : setter(myData)
                        setLoading(false)

                        myData.length < 1 && setButtonText(false);
                    }

                })

                .catch(err => console.error(err))

        }
        // Abort Fetch


        //Error for Fetch
        async function handleSubmitError(res) {

            if (res.status >= 200 && res.status <= 299) {
                await store.dispatch(setAllCustomInOnce(optionsFeed))
                if (simpleVersion) {
                    setStatus('success');
                    setShow(true);
                    setLoading(false);
                }
                return res.json();
            } else {
                setLoading(false)
                if (simpleVersion) {
                    setStatus('danger');
                    setShow(true);
                }
                setButtonText(false)

                if (!simpleVersion) throw Error(res.status);

            }
        }

        //getListing();

        //checks and refresh token
        const sessionFetch = new TokenRefresher();
        sessionFetch.setTokenRefresh(getListing);

    }




}

