import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { PencilFill, TrashFill } from 'react-bootstrap-icons';
import SimpleFetchNet from '../../jwtAPI/SimpleFetchNet';
import EditUserCircle from '../../jwtAPI/EditUserCircle';


const GroupManageCard = ({ circle, updateCircles, circles }) => {

    const { t } = useTranslation();

    let [groupName, setGroupName] = React.useState(circle.title)
    let [groupDesc, setGroupDesc] = React.useState(circle.description ? circle.description : circle.title)

    let getGroupName = (e) => setGroupName(e.target.value);
    let getGroupDesc = (e) => setGroupDesc(e.target.value);

    //Edit Modal
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //Remove Group
    function removeGroup() {

        const url = process.env.REACT_APP_EDITCIRCLE_API + '/remove/' + circle.id

        if (window.confirm(t('bigtext.deleteGroup'))) {
            updateCircles(circles.filter(item => item.id !== circle.id));
            const userCircle = new SimpleFetchNet();
            userCircle.fetchListings(url, 'DELETE');
        }

    }


    //Save Name
    function saveGroup() {

        if (groupName !== circle.title || groupDesc !== circle.description) {

            const data = {
                "title": groupName,
                "description": groupDesc
            }

            const userCircle = new EditUserCircle();
            userCircle.fetchListings('edit', data, circle.id);

        }

        handleClose();
    }


    return (

        <>
            <Button
                type="button"
                className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
            >

                <details>
                    <summary>{circle.title}</summary>
                    <span>{circle.description}</span>
                </details>

                <span>

                    <OverlayTrigger placement="top"
                        overlay={<Tooltip >{t('words.edit')} {t('title.name')}</Tooltip>}
                    >
                        <PencilFill
                            size={23}
                            className='me-2 rounded-4 border-primary text-warning bg-lightblue p-1'
                            id={`edit_userGroup_${circle.id}`}
                            onClick={handleShow}
                        />
                    </OverlayTrigger>

                    <OverlayTrigger placement="top"
                        overlay={<Tooltip >{t('words.delete')} {t('words.group2')}</Tooltip>}
                    >
                        <TrashFill
                            size={24}
                            onClick={removeGroup}
                            className='text-danger p-1'
                        />
                    </OverlayTrigger>

                </span>
            </Button >

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('words.edit')} {t('words.group2')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="editGroupName">
                            <Form.Label>{t('words.group')} {t('title.name')}</Form.Label>
                            <Form.Control
                                type="text"
                                defaultValue={groupName}
                                onChange={getGroupName}
                                autoFocus
                                maxLength="30"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="editGroupDesc">
                            <Form.Label>{t('words.group')} {t('words.desc')}</Form.Label>
                            <Form.Control
                                type="text"
                                defaultValue={groupDesc}
                                onChange={getGroupDesc}
                                autoFocus
                                maxLength="60"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('words.cancel')}
                    </Button>
                    <Button variant="success" onClick={saveGroup}>
                        {t('words.save')} {t('words.changes')}
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default GroupManageCard