import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "user",
    initialState: {
        loggedIn: false,
        id: null,
        username: "",
        email: "",
        last_login: "",
        roles: [],
        blog_display_name: "",
        first_name: "",
        last_name: "",
        profile_picture_url: "",
        cover_photo_url: null,
        news_feed_customization: {
            recent_members: true,
            article: true,
            sale: true,
            rent: true,
            request: true,
            partnership: true,
            crm: true,
            event: true,
            training_job: true,
            benchmark: true
        },
        dark_theme: false,
        user_types: [],
        locale: "en",
        accept_direct_marketing: false,
        confirmed: true,
        confirmed_reminder: 0,
        article_likes: [],
        number_of_favorites: 0,
        address: {
            id: null,
            street: "",
            complement: "",
            postal_code: "",
            city: "",
            name: "",
            state: "",
            country: "",
            google_map: "",
            google_polygon: ""
        },
        title: "",
        enterprise: "",
        professional_phone: "",
        mobile: "",
        description: "",
        website: "",
        video_link: "",
        location_services: [],
        linkedin_url: "",
        twitter_url: "",
        facebook_url: "",
        cvUploaded: false,
        refreshUserObject: 0

    },
    reducers: {
        setReduxUser: (state, action) => {
            state.id = action.payload.id
            state.username = action.payload.username
            state.email = action.payload.email
            state.last_login = action.payload.last_login
            state.roles = action.payload.roles
            state.blog_display_name = action.payload.blog_display_name
            state.first_name = action.payload.first_name
            state.last_name = action.payload.last_name
            state.news_feed_customization = action.payload.news_feed_customization
            state.dark_theme = action.payload.dark_theme
            state.user_types = action.payload.user_types
            state.locale = action.payload.locale
            state.accept_direct_marketing = action.payload.accept_direct_marketing
            state.confirmed = action.payload.confirmed
            state.confirmed_reminder = action.payload.confirmed_reminder
            state.number_of_favorites = action.payload.number_of_favorites 
            state.article_likes = action.payload.article_likes
            state.address = action.payload.address
            state.location_services = action.payload.location_services
            state.cvUploaded = action.payload.cv_url ? true : false
            // state.favorite_articles = action.payload.favorite_articles
            // state.attributes = action.payload.attributes
            state.loggedIn = true
            state.profile_picture_url = action.payload.profile_photo ? `${process.env.REACT_APP_DOMAIN}/en/api/user/${action.payload.id}/avatar` : null
            state.cover_photo_url = (action.payload.cover_photo_url || action.payload.cover_photo) ? `${process.env.REACT_APP_DOMAIN}${action.payload.cover_photo_url}` : null
            state.title = action.payload.attributes.filter(att => att.definition.name === "title")[0].value
            state.enterprise = action.payload.attributes.filter(att => att.definition.name === "enterprise")[0].value
            state.professional_phone = action.payload.attributes.filter(att => att.definition.name === "professional_phone")[0].value
            state.mobile = action.payload.attributes.filter(att => att.definition.name === "mobile")[0].value
            state.description = action.payload.attributes.filter(att => att.definition.name === "description")[0].value
            state.website = action.payload.attributes.filter(att => att.definition.name === "website")[0].value
            state.video_link = action.payload.attributes.filter(att => att.definition.name === "video_link")[0].value
            state.linkedin_url = action.payload.attributes.filter(att => att.definition.name === "linkedin_url")[0].value ? action.payload.attributes.filter(att => att.definition.name === "linkedin_url")[0].value : ""
            state.twitter_url = action.payload.attributes.filter(att => att.definition.name === "twitter_url")[0].value ? action.payload.attributes.filter(att => att.definition.name === "twitter_url")[0].value : ""
            state.facebook_url = action.payload.attributes.filter(att => att.definition.name === "facebook_url")[0].value ? action.payload.attributes.filter(att => att.definition.name === "facebook_url")[0].value : ""



        },
        setReduxLoggedIn: (state, action) => {
            state.loggedIn = action.payload
        },
        setReduxEditUser: (state, action) => {
            state[action.payload.id] = action.payload.value
        }, setReduxAvatarUrl: (state, action) => {
            state.profile_picture_url = action.payload
        }, refreshReduxUserObject: (state) => {
            state.refreshUserObject = Date.now()
        }, setReduxCoverPhotoUrl: (state, action) => {
            state.cover_photo_url = action.payload
        },
        toggleNewsletter: (state) => {
            state.accept_direct_marketing = !state.accept_direct_marketing
        },
        addLocService: (state, action) => {
            state.location_services.push(action.payload)
        },

    }


})

export const { setReduxUser, setReduxLoggedIn, setReduxEditUser, setReduxAvatarUrl, refreshReduxUserObject, setReduxCoverPhotoUrl, toggleNewsletter, addLocService } = userSlice.actions
export default userSlice.reducer