import React from 'react'
import { useTranslation } from "react-i18next";
import countries from "i18n-iso-countries";
import { Badge, Card, Col, Image, Row } from "react-bootstrap"
import { PersonCircle, GeoAltFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import AgentPage from '../../../pages/AgentPage';
import JobPage from '../../../pages/JobPage';
import OverlayListing from "../../profile/listings/OverlayListing";
import ToolCardActions from '../ToolCardActions';


const JobsCard = ({ listing, profile = false, status = null }) => {

    const { t, i18n } = useTranslation();

    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/el.json"));

    let descrip = listing['article']['excerpt'];
    if (descrip && descrip.length > 300) {
        descrip = listing['article']['excerpt'].substring(0, 180) + " [...]"
    }


    return (
        <div className="position-relative card-listing-container">
            <Card className='tool-listing p-3 pt-0'>

                <ToolCardActions
                    listing={listing}
                    listingType="job"
                />

                <Badge bg='success' className='small text-end mb-2'>

                    {listing['type'] === "offer"
                        ? <>{t('cats_tools.training-job')} {t('words.offer')}</>
                        : <>{t('cats_tools.training-job')} {t('cats_tools.request')}</>
                    }

                </Badge>
                <h6><strong>
                    {listing['article']['title']}
                    {listing.number
                        && <span className='small fw-bold text-muted'>&nbsp;({listing.number})</span>
                    }
                </strong></h6>

                <Row className='small align-items-center justify-content-between'>
                    <Col>

                        <Link
                            component={<AgentPage />}
                            to={`/agent/${listing['article']['author']['id']}/`}
                            className="text-decoration-none text-dark text-capitalize"
                        >

                            {listing['article']['author']['profile_picture_url']
                                ? <Image roundedCircle
                                    style={{ objectFit: 'cover' }}
                                    width="35px"
                                    height="35px"
                                    src={`${process.env.REACT_APP_DOMAIN + listing['article']['author']['profile_picture_url']}?photo_height=50&photo_width=50`}
                                    className="me-2" />

                                : <PersonCircle className='text-warning me-2' size={33} />
                            }

                            {listing['article']['author']['blog_display_name'].toLowerCase()}
                        </Link>
                    </Col>

                    <Col className='text-warning text-end'>
                        <GeoAltFill />
                        {listing['address'] && listing['address']['city']
                            ? listing['address']['city']

                            : listing['address'] && listing['address']['country']
                                ? countries.getName(listing['address']['country'], i18n.language, { select: "official" })

                                : ''
                        }
                    </Col>

                    <Link
                        component={<JobPage />}
                        to={`/listing/job/${listing["id"]}/${listing["article"]["slug"]}`}
                        className="text-decoration-none text-dark"
                    >
                        <hr className='mt-3' />
                        <Col xs={12} style={{ minHeight: '57px' }}>
                            {descrip}
                        </Col>

                        <hr className='mt-3' />
                        {listing['sector'] &&
                            <Col xs={12}>
                                <strong>{t('title.sect')}:</strong> {listing['sector']['name']}
                            </Col>
                        }
                        {listing['job'] &&
                            <Col xs={12}>
                                <strong>{t('words.employType')}:</strong> {listing['job']['name']}
                            </Col>
                        }
                        {listing['work_plan'] &&
                            <Col xs={12}>
                                <strong>{t('title.contract')}:</strong> {listing['work_plan']['name']}
                            </Col>
                        }

                    </Link>

                </Row>

            </Card >
            {profile ?
                <OverlayListing
                    title={listing.article.title}
                    type={"job"}
                    link={`/listing/job/${listing.id}/${listing.article.slug}/`}
                    id={listing.id}
                    edit={`/edit/job/${listing.id}`}
                    status={status}
                    articleId={listing.article.id}
                />
                : null}
        </div>
    )
}

export default JobsCard
