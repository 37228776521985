import React from "react";
import ContentLoader from "react-content-loader";
import { useTranslation } from 'react-i18next'


function ThemeCard({ dark }) {
  
  const { t } = useTranslation();

  const width1 = "25%";
  const height1 = "20%";
  const width2 = "100%";
  const height2 = "70%";

  return (
    <>
      <ContentLoader
        speed={1}
        width={"100%"}
        height={"100%"}
        title={dark ? <>{t('words.dark')} {t('words.theme')}</> : <>{t('words.light')} {t('words.theme')}</>}
        viewBox="0 0 400 100"
        backgroundColor={dark ? "#0c3446" : "#d8d4d4"}
        foregroundColor={dark ? "#0c3446" : "#d8d4d4"}
      >
        <rect x="0" y="0" rx="0" ry="0" width={width1} height={height1} />
        <rect x="37.5%" y="0" rx="0" ry="0" width={width1} height={height1} />
        <rect x="75%" y="0" rx="0" ry="0" width={width1} height={height1} />
        <rect x="0" y="40" rx="0" ry="0" width={width2} height={height2} />
        
      </ContentLoader>
    </>
  );
}
export default ThemeCard;
