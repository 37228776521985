import TokenRefresher from "./TokenRefresher";
import { store } from "../Redux/Store.js"
import { setReduxEditUser } from "../Redux/user-slice";

const editUser = async (setStatus, payload, setLoading, setShow, location_service = false, deleteLoc = false, e) => {


    const url = process.env.REACT_APP_EDIT_USER_API


    const fetchEditUser = async function () {
        try {

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;

            const res = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Authorization': bearer_token,
                    'Content-Type': 'application/json',

                }, body: JSON.stringify(payload),
                credentials: 'include'
            })
            const data = await res.json()
            console.log(data)
            if (res.status >= 200 && res.status <= 299) {
                if (location_service) {

                    emptyLocForm(e)

                    await store.dispatch(setReduxEditUser({

                        id: ["location_services"],
                        value: data.location_services

                    }))
                } else {

                    await store.dispatch(setReduxEditUser({

                        id: [Object.keys(payload)[0]],
                        value: Object.values(payload)[0]

                    }))
                }


                setStatus("success")
                setShow(true)
                // close the form when the request is successful
                if (!location_service) document.getElementById(Object.keys(payload)[0]).disabled = true
            } else {
                setStatus("Something Occurred")
                setShow(true)

            }



        } catch (error) {
            console.log(error)
        } finally {
            setLoading("")

        }
    }
    const deleteLocService = async function () {
        try {
            // setStatus, payload, setLoading, setShow, location_service = false, deleteLoc=false)
            const bearer_token = `Bearer ${localStorage.getItem('token')}`;


            const res = await fetch(`${process.env.REACT_APP_DOMAIN}/en/api/user/delete-location-service/${deleteLoc}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': bearer_token,
                    'Content-Type': 'application/json',

                },
                credentials: 'include'
            })

            if (res.status >= 200 && res.status <= 299) {

                const previousLocalServices = store.getState().user.location_services
                const newLocationServices = previousLocalServices.filter(add => add.id !== deleteLoc)
                store.dispatch(setReduxEditUser({

                    id: ["location_services"],
                    value: newLocationServices
                }))


                setStatus("Profile Updated!")
                setShow(true)


            } else {
                setStatus("Something Occurred")
                setShow(true)

            }



        } catch (error) {
            console.log(error)
        } finally {
            setLoading("")

        }
    }
    function emptyLocForm(e) {
        e.target[0].value = ""
        e.target[1].value = ""
        e.target[2].value = ""
        e.target[3].value = ""
        e.target[4].value = ""
        e.target[5].value = ""
        e.target[6].value = ""
        e.target[7].value = ""
        e.target[8].value = ""
        e.target[9].value = ""
    }
    const sessionFetch = new TokenRefresher();
    if (!deleteLoc) sessionFetch.setTokenRefresh(fetchEditUser);
    else sessionFetch.setTokenRefresh(deleteLocService)

}


export default editUser