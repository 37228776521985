import React from "react";
import { Link } from "react-router-dom";
import { Card, Col, Row, Badge, Image } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import ListingCardInfo from "./ListingCardInfo";
import ListingCardActions from "./ListingCardActions";
import PropertyPage from "../../pages/PropertyPage";
import houseExample from "../../assets/houseExample.jpg"
import OverlayListing from "../profile/listings/OverlayListing";




function CarouselItemListing({ listing, mainTypeListing, userEdit, withCardActions = true, status, article = false }) {

  const { t } = useTranslation();

  return (

    <div className="position-relative card-listing-container">

      <Card border="secondary" className="card-listing">

        <Row className="align-items-end p-0 position-relative">

          <Col xs={12} className="m-0">

            <Link
              to={article ? `/listing/${mainTypeListing}/${listing["id"]}/${listing["slug"]}/` : `/listing/${mainTypeListing}/${listing["id"]}/${listing["article"]["slug"]}/`}
              className="text-decoration-none text-dark"
            >

              <div className="ratio-cont">
                {article
                  ?
                  <Image
                    className="ratio"
                    src={houseExample}
                    alt="listing"
                  />

                  :
                  <Image
                    className="ratio"
                    src={
                      listing["article"]["excerpt_photo_url"]
                        ? process.env.REACT_APP_DOMAIN + listing["article"]["excerpt_photo_url"] + '?photo_height=400&photo_width=400'
                        : houseExample
                    }
                    alt="listing"
                  />
                }


              </div>

              <Badge bg="warning" className="badge-type ms-4 mt-4">

                {mainTypeListing === 'sale' ? t('cats_tools.sale')
                  : mainTypeListing === 'rent' ? t('cats_tools.rent')
                    : mainTypeListing === 'request' ? t('cats_tools.request')
                      : t('cats_tools.partnership')}
              </Badge>

            </Link>

          </Col>

          <Col xs={2}
            className='lis-actions bg-light'
          >
            {withCardActions &&
              <ListingCardActions
                listingType={mainTypeListing}
                listing={listing}
              />
            }


          </Col>
        </Row>



        <Row className="small p-2" style={{ minHeight: "170px" }}>

          <Link
            component={<PropertyPage />}
            to={`/listing/${mainTypeListing}/${listing["id"]}/${listing["article"]["slug"]}/`}
            className="text-decoration-none text-dark"
          >
            <h5><strong>
              {listing['article']['title']}
              {listing.number
                && <span className='fs-6 fw-bold text-muted'>&nbsp;({listing.number})</span>
              }
            </strong></h5>
          </Link>

          <ListingCardInfo listing={listing} />


        </Row>

      </Card>

      {userEdit &&
        <OverlayListing
          title={listing.article.title}
          type={mainTypeListing}
          link={`/listing/${mainTypeListing}/${listing.id}/${listing.article.slug}/`}
          id={listing.id}
          edit={`/edit/${mainTypeListing}/${listing.id}`}
          status={status}
          articleId={listing.article.id}
        />
      }
    </div>

  )
}

export default CarouselItemListing