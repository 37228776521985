import React from 'react'
import HeroBlog from '../components/blog/HeroBlog'
import Footer from '../components/Footer'
import BlogIndex from '../components/blog/BlogIndex'

const BlogPage = () => {

  return (
    <>
      <HeroBlog />
      <BlogIndex />
      <Footer />

    </>
  )
}

export default BlogPage