import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { Button, Col, Container, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import { ImSearch } from 'react-icons/im';

import Footer from '../components/Footer';
import MyNetwork from '../components/network/MyNetwork';
import AllNetwork from '../components/network/AllNetwork';
import RegisterDropJob from '../components/loginRegister/RegisterDropJob';
import RegisterDropJobConstruction from '../components/loginRegister/RegisterDropJobConstruction';
import IconsProfession from '../components/network/IconsProfession';
import SearchGroups from '../components/network/SearchGroups';
import EditGroups from '../components/network/EditGroups';

import FetchGetSetSimpleCircles from '../jwtAPI/FetchGetSetSimpleCircles';
// Custom Loaders
import CirclesLoader from '../components/Loaders/CirclesLoader';
import CategoriesLoader from '../components/Loaders/CategoriesLoader';
import NetworkTabInNetworkPageLoader from '../components/Loaders/NetworkTabInNetworkPageLoader';
import DropdownProfession from '../components/loginRegister/DropdownProfession';
import InviteUser from '../components/network/InviteUser';



const NetworkPage = () => {

    const [circlesLoader, setCirclesLoader] = React.useState(true)

    const controller = new AbortController()
    const ac = controller.signal
    const { t, i18n } = useTranslation();


    let [searchName, setSearchName] = React.useState('');
    const handleSearchName = (e) => {
        setSearchName(e.target.value);
    }

    let [searchByGroup, setSearchByGroup] = React.useState('All')

    //My Network sum for groups
    const [sumNet, setterSum] = React.useState(0);

    //Circle/Group Names
    let [circles, updateCircles] = React.useState(null);
    const urlCircles = process.env.REACT_APP_CIRCLES_API;

    React.useEffect(() => {

        const fetchLists = new FetchGetSetSimpleCircles(ac);
        fetchLists.fetchListings(urlCircles, updateCircles, null, setCirclesLoader);
        // eslint-disable-next-line
    }, [urlCircles]);



    /**************Handle Job Drop***************/
    const scrollToDrops = React.useRef(null);

    let [fieldJob, setFieldJob] = React.useState('');
    function getProfession(e) {
        setFieldJob(e.currentTarget.value);
        setDropJob('');
        setDropConstr('');
        apiSearchDropJob('')
        scrollToDrops.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    };

    let [dropJob, setDropJob] = React.useState('');

    function getJobDrop(e) {
        setDropJob(e.currentTarget.value);
        setDropConstr('');
        apiSearchDropJob(e.currentTarget.value);
    };

    let [, setDropConstr] = React.useState('');

    function getConstruction(e) {
        setDropConstr(e.currentTarget.value)
        apiSearchDropJobConstr(e.currentTarget.value)
    };

    //Dropdowns variables for url search
    let [dropApiSearch, setDropApiSearch] = React.useState('');
    function apiSearchDropJob(value) {
        switch (fieldJob) {
            case '114':
                setDropApiSearch(`&architecture=${value}`);
                break;
            case '115':
                setDropApiSearch(`&construction=${value}`);
                break;
            case '119':
                setDropApiSearch(`&serviceIct=${value}`);
                break;
            case '116':
                setDropApiSearch(`&droit=${value}`);
                break;
            case '117':
                setDropApiSearch(`&finance=${value}`);
                break;
            case '113':
                setDropApiSearch(`&immo=${value}`);
                break;
            case '118':
                setDropApiSearch(`&venteMateriel=${value}`);
                break;
            case '151':
                setDropApiSearch(`&construction=151&parachevement=${value}`);
                break;
            case '149':
                setDropApiSearch(`&construction=149&entrepreneurSpe=${value}`);
                break;
            case '150':
                setDropApiSearch(`&construction=150&electroTechnique=${value}`);
                break;
            case '':
                setDropApiSearch('');
                break;
            default:
                setDropApiSearch('');
                break;
        }
    }

    function apiSearchDropJobConstr(value) {
        switch (dropJob) {
            case '151':
                setDropApiSearch(`&construction=151&parachevement=${value}`);
                break;
            case '149':
                setDropApiSearch(`&construction=149&entrepreneurSpe=${value}`);
                break;
            case '150':
                setDropApiSearch(`&construction=150&electroTechnique=${value}`);
                break;
            case '':
                setDropApiSearch('');
                break;
            default:
                setDropApiSearch('');
                break;
        }
    }

    /******************Set url for fetch *****************/
    let url = `${process.env.REACT_APP_USERLIST_API + searchName}&pro=${fieldJob}${dropApiSearch}`;

    const location = useLocation();
    let [showNetOnly, setShowNetOnly] = React.useState(true);

    React.useEffect(() => {
        if (location.hash === '#my-network') {
            setSearchByGroup('All');
            setShowNetOnly(true);
            setFieldJob('');
            setDropJob('');
            setDropApiSearch('');
            document.getElementById('network_search').reset();

        }
        if (location.hash === '#all-users') {
            setShowNetOnly(false);
            setFieldJob('');
            setDropJob('');
            setDropApiSearch('');
            document.getElementById('network_search').reset();
        }
        // eslint-disable-next-line
    }, [location.hash]);


    /***************GET Professions****************/
    const [proffesions, setData] = React.useState(null);
    const urlJob = `${process.env.REACT_APP_DOMAIN}/${i18n.language}/${process.env.REACT_APP_PROFDROP_API}`;

    React.useEffect(() => {
        fetch(urlJob, {
            signal: ac
        })
            .then(res => res.json())
            .then(data => {
                const mydata = JSON.stringify(data);
                setData(mydata);
            })
            .catch(err => console.error(err))

        // eslint-disable-next-line
    }, [urlJob]);

    let profe = JSON.parse(proffesions);


    /*************** Clean Up when Component Is Unmount ****************/

    const cleanUp = function () {
        setSearchName(``)
        setSearchByGroup('All')
        setterSum(0)
        updateCircles(null)
        setFieldJob('');
        setDropJob('');
        setDropConstr('');
        setDropApiSearch('')
        setShowNetOnly(true)
        setData(null)
        controller.abort()
    }
    React.useEffect(() => {

        return () => {
            cleanUp()
        }
        // eslint-disable-next-line
    }, []);

    return (



        <>



            <Container fluid="xxl">
                <div id="net-img-hero"></div>
                <Row className='justify-content-center m-0 bg-white'>

                    <Col xxl={4} md={5} className="bg-white bg-opacity-75 p-0" style={{ marginTop: '-7.8rem' }}>

                        <div className='p-4'>
                            <Button href="/network#my-network" active={showNetOnly} className="line-button mt-4">
                                {t('title.net2')}
                            </Button>

                            <Button href="/network#all-users" active={showNetOnly ? false : true} className="line-button mt-4">
                                {t('title.userMul')}
                            </Button>
                        </div>

                        <InviteUser />
                        {/* (showNetOnly && circles) */}
                        {showNetOnly && circles
                            ?
                            <div className='p-4 pb-0'>

                                <SearchGroups circles={circles} sumNet={sumNet} searchByGroup={searchByGroup} setSearchByGroup={setSearchByGroup} />

                                <br />

                                <EditGroups circles={circles} updateCircles={updateCircles} />

                            </div>

                            : showNetOnly && !circles
                            &&
                            <CirclesLoader />
                        }

                        <Form id="network_search">

                            <InputGroup className="mb-2">
                                <InputGroup.Text id="search-net-name" className='bg-white border-3 border-secondary border-start-0 border-top-0 border-end-0'>
                                    <ImSearch size={25} />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder={`${t('words.srch')} ${t('title.name')} ${t('words.or')} ${t('title.lname')} ${t('words.or')} Email`}
                                    aria-label={`${t('words.srch')} ${t('words.with')} ${t('title.name')} ${t('words.or')} Email`}
                                    aria-describedby="search-net-name"
                                    className='border-3 border-secondary border-start-0 border-top-0 border-end-0 bg-white'
                                    onKeyUp={handleSearchName}
                                />
                            </InputGroup>
                            {/* circlesLoader  <CategoriesLoader />*/}
                            {circlesLoader ? <CategoriesLoader /> : (
                                <>

                                    {profe &&
                                        <>
                                            <div className='d-none d-sm-none d-md-none d-lg-block'>
                                                <IconsProfession getProf={getProfession} profeList={profe} />
                                            </div>

                                            <Form.Group className="mb-2 mx-3 d-block d-lg-none d-xl-none d-xxl-none">
                                                <Form.Label className='text-white fw-bold'>{t('words.srch')} {t('words.with')} {t('title.profession')}</Form.Label>
                                                <DropdownProfession getProf={getProfession} profeList={profe} />
                                            </Form.Group>
                                        </>
                                    }


                                    <div className='p-3' ref={scrollToDrops}>

                                        {fieldJob === '120' || fieldJob === '222' || fieldJob === '' ? ''
                                            : <RegisterDropJob handleChange={getJobDrop} job={fieldJob} profeList={profe} />
                                        }

                                        {dropJob === '149' || dropJob === '150' || dropJob === '151'
                                            ? <RegisterDropJobConstruction handleChange={getConstruction} job={dropJob} profeList={profe} />
                                            : ''
                                        }

                                    </div>
                                </>
                            )}



                        </Form>

                    </Col>

                    <Col xxl={8} md={7} className="pt-4 bg-light p-0 p-md-2">
                        <Row className='p-0 ps-xl-4 gx-0 gx-xl-4'>

                            {/* <NetworkTabInNetworkPageLoader /> */}
                            {
                                showNetOnly && circles && searchByGroup === 'All'
                                    ? <MyNetwork url={url} circles={circles} sumNet={sumNet} setterSum={setterSum} />

                                    : showNetOnly && circles && searchByGroup !== 'All'
                                        ? <MyNetwork sumNet={sumNet} circles={circles} setterSum={setterSum} searchByGroup={searchByGroup} />

                                        : !showNetOnly
                                            ? <AllNetwork url={url} />

                                            : <NetworkTabInNetworkPageLoader />
                            }
                        </Row>
                    </Col>

                </Row>
            </Container>

            <Footer />

        </>






    )
};

export default NetworkPage;


