import React from "react"
import { Image } from "react-bootstrap"
import { GeoAltFill, MapFill, PersonCircle } from "react-bootstrap-icons"
import { useTranslation } from 'react-i18next'
import countries from "i18n-iso-countries";

import { Link } from "react-router-dom";
import AgentPage from "../../pages/AgentPage";


function ListingCardInfo({ listing }) {

    const { t, i18n } = useTranslation();

    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/el.json"));

    const agen = listing['article']['author']['blog_display_name'];
    const agentId = listing['article']['author']['id'];
    const agenImg = listing['article']['author']['profile_picture_url'];
    const pric = listing['price'];
    const met2 = listing['area'];
    const cit =
        listing['address']['city']
            ? listing['address']['city']
            : countries.getName(listing['address']['country'], i18n.language, { select: "official" })
        ;


    return (
        <>
            {
                listing['ad_type'] &&
                <p className="mb-1"><strong>{t('words.estate')}: </strong>{listing['ad_type']['name']}</p>
            }

            {
                listing['bail_type'] &&
                <p className="mb-1"><strong>{t('words.lease')}: </strong>{listing['bail_type']['name']}</p>
            }

            {
                listing['request_type'] &&
                <>
                    <p className="mb-1"><strong>{t('words.reqType')}: </strong>{listing['request_type']['name']}</p>
                    {
                        listing['buy_type'] &&
                        <p className="mb-1"><strong>{t('words.estate')}: </strong>{listing['buy_type']['name']}</p>
                    }
                </>
            }


            {
                listing['partner_type'] &&
                <>
                    <p className="mb-1"><strong>{t('words.partType')}: </strong>{listing['partner_type']['name']}</p>
                    {
                        listing['project_development_type'] &&
                        <p className="mb-1"><strong>{t('words.development')}: </strong>{listing['project_development_type']['name']}</p>
                    }
                    {
                        listing['know_how_type'] &&
                        <p className="mb-1"><strong>{t('words.profType')}: </strong>{listing['know_how_type']['name']}</p>
                    }
                    {
                        listing['start_up_type'] &&
                        <p className="mb-1"><strong>{t('words.startup')}: </strong>{listing['start_up_type']['name']}</p>
                    }
                </>
            }

            <span className='text-warning fw-bold mb-1 d-flex justify-content-between'>

                {pric && <span className="pe-3">{pric} &euro; </span>}

                {met2 ? <span><MapFill className="mb-1" /> {met2} &#13217;</span> : ''}

                {cit ? <span className="ms-auto"><GeoAltFill /> {cit}</span> : ''}

            </span>

            <Link
                component={<AgentPage />}
                to={`/agent/${agentId}/`}
                className="text-decoration-none text-dark text-capitalize"
            >
                {agenImg
                    ? <Image roundedCircle
                        style={{ objectFit: 'cover' }}
                        width="35px"
                        height="35px"
                        src={`${process.env.REACT_APP_DOMAIN + agenImg}?photo_height=50&photo_width=50`}
                        className="me-2" />

                    : <PersonCircle className='text-warning me-2' size={33} />
                }

                {agen.toLowerCase()}
            </Link>
        </>
    )
}

export default ListingCardInfo

