import TokenRefresher from "./TokenRefresher";


//Saves and uses json after fetch with the useState of setter listi
export default class FetchGetSetSimpleNetwork {
    constructor(ac) {
        this.ac = ac
    }

    //Bearer token
    async fetchListings(url, setter, setterSum, setLoading) {

        let getListing = async () => {

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;

            fetch(url, {
                method: 'GET',
                signal: this.ac,
                headers: {
                    'Authorization': bearer_token,
                },
                credentials: 'include',
            })
                .then(res => res.json())
                .then(res => {
                    setter(false);

                    let myData = res;
                    //multiple or single listing
                    if (setterSum) {
                        setterSum(res['filtered_users']);
                        myData = myData['users'];
                    }

                    return myData;
                })
                .then(myData => {

                    setter(myData);
                    if (setLoading) setLoading(false)

                })

                .catch(err => {
                    if (setLoading) setLoading(false)
                })

        }

        //getListing();

        //checks and refresh token
        const sessionFetch = new TokenRefresher();
        sessionFetch.setTokenRefresh(getListing);

    }

}

