//import
//const createfunc = new CreateFunctions();
//createfunc.initialFormData()
import { google_maps_api_key, libraries } from '../../functions/ConstantVariables';
import { Loader } from '@googlemaps/js-api-loader';
import DeletePicture from '../../jwtAPI/DeletePicture';
import DeleteListingFile from '../../jwtAPI/DeleteListingFile';


export default class CreateFunctions {



    /***************Submit with api******************/

    //Fields Value
    handleChangeVal(e, updateFormData, formData) {

        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        });
    };

    //Fields Value for Dropdown listing type array
    handleChangeDropsVal(e, updateFormData, formData) {

        updateFormData({
            ...formData,
            [e.target.name]:
            {
                id: e.target.value.trim()
            }

        })
    };


    toIsoString(date) {
        var tzo = -date.getTimezoneOffset(),
            dif = tzo >= 0 ? '+' : '-',
            pad = function (num) {
                return (num < 10 ? '0' : '') + num;
            };

        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) +
            'T' + pad(date.getHours()) +
            ':' + pad(date.getMinutes()) +
            ':' + pad(date.getSeconds()) +
            dif + pad(Math.floor(Math.abs(tzo) / 60)) +
            ':' + pad(Math.abs(tzo) % 60);
    }



    async deletePicFiles(listing, type, excerptUri) {

        const deleteImg = new DeletePicture();
        const deleteFile = new DeleteListingFile();

        //this on article different
        const changed_excerpt_pic = document.getElementById('changedExcerpt') && document.getElementById('changedExcerpt').value
        const gallery_to_delete = document.getElementById('galleryToDelete') && document.getElementById('galleryToDelete').value
        const files_to_delete = document.getElementById('filesToDelete') && document.getElementById('filesToDelete').value

        const articleobject = type === 'article' ? listing : listing.article

        try {
            if ((changed_excerpt_pic === 'false' || changed_excerpt_pic === null) && excerptUri === '' && articleobject.excerpt_photo) {
                deleteImg.fetchListings(articleobject.id, articleobject.excerpt_photo.id);
            }
        }
        catch (error) {
            console.log('Request failed', error);
        }

        try {
            if (gallery_to_delete !== 'false' && gallery_to_delete !== null) {
                //we get it from input needs conversing to object/array
                const galIds = JSON.parse("[" + gallery_to_delete + "]")

                for (let i = 0; i < galIds.length; i++) {
                    deleteImg.fetchListings(articleobject.id, galIds[i]);
                }
            }
        }
        catch (error) {
            console.log('Request failed', error);
        }

        try {
            if (files_to_delete !== 'false' && files_to_delete !== null) {
                //we get it from input needs conversing to object/array
                const filesIds = JSON.parse("[" + files_to_delete + "]")

                for (let i = 0; i < filesIds.length; i++) {
                    deleteFile.fetchListings(type, listing.id, filesIds[i]);
                }
            }
        }
        catch (error) {
            console.log('Request failed', error);
        }

    }




    nearByPlaces(coor, setNearPlaces, setLoadingCreatingData) {

        coor = coor.split(",");//separate string coordinates to convert to number

        const loader = new Loader({
            apiKey: google_maps_api_key,
            version: "weekly",
            libraries
        });

        let placesArray = [];

        loader
            .load()
            .then((google) => {

                const saveWithName = [
                    'airport',
                    'bus_station',
                    //'transit_station',
                    'hospital',
                    'subway_station',
                ]


                for (let number = 0; number < saveWithName.length; number++) {

                    const request = {
                        location: new google.maps.LatLng(Number(coor[0]), Number(coor[1])),
                        radius: '300',
                        type: saveWithName[number]
                    };


                    const hidden_input_places = document.getElementById('nearby_results');

                    const service = new google.maps.places.PlacesService(hidden_input_places);
                    service.nearbySearch(request, callback);


                    function callback(results, status) {

                        if (status === google.maps.places.PlacesServiceStatus.OK) {

                            for (let i = 0; i < results.length; i++) {

                                hidden_input_places.innerHTML = results[i].name + ' ';

                                if (results[i].business_status === "OPERATIONAL") {
                                    placesArray.push(
                                        {
                                            name: results[i].name,
                                            category: results[i].types
                                        }
                                    )
                                }
                            }
                        }

                    }

                }//end for loop

                return google;

            })
            .then((google) => {

                const saveWithNumber = [
                    'cafe',
                    'bank',
                    'drugstore',
                    'parking',
                    'restaurant',
                    'school',
                    'shopping_mall',
                    'supermarket',
                ]

                //create an initial immediately resolving promise, and then chain new promises as the previous ones resolve
                const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

                let counterWithOrder = 0;


                for (let number = 0, p = Promise.resolve(); number < saveWithNumber.length; number++) {

                    // eslint-disable-next-line
                    p = p.then(() => delay(500))
                        // eslint-disable-next-line
                        .then(() => {
                            const request = {
                                location: new google.maps.LatLng(Number(coor[0]), Number(coor[1])),
                                radius: '300',
                                type: saveWithNumber[number]
                            };


                            const hidden_input_places = document.getElementById('nearby_results');

                            const service = new google.maps.places.PlacesService(hidden_input_places);
                            service.nearbySearch(request, callback);


                            //counts which page of results
                            let pagesGoogle = 0;


                            function callback(results, status, pagination) {


                                if (status === google.maps.places.PlacesServiceStatus.OK) {

                                    if (pagination && pagination.hasNextPage) {

                                        pagesGoogle++;
                                        pagination.nextPage();

                                    }
                                    else {

                                        const intToarray = [(results.length + (pagesGoogle * 20)).toString()]
                                        placesArray.push(
                                            {
                                                name: saveWithNumber[number],
                                                category: intToarray
                                            }
                                        )

                                        setNearPlaces(placesArray);
                                        //number variable doesnt follow order
                                        counterWithOrder++;

                                        //loading until json is ready
                                        if (counterWithOrder === saveWithNumber.length) {
                                            setLoadingCreatingData(false);
                                        }
                                    }

                                }
                                else {

                                    setNearPlaces(placesArray);
                                    counterWithOrder++;

                                    if (counterWithOrder === saveWithNumber.length) {
                                        setLoadingCreatingData(false);
                                    }
                                }

                            }

                        })//end of second then

                }//end for loop

            })
            .catch(e => {
                setLoadingCreatingData(false);
                alert('Something went wrong, please try again or contact us for support');
                window.location.reload(true);
            });


    }


}