import { createSlice } from "@reduxjs/toolkit";


export const newsFeedSlice = createSlice({
    name: "news_feed",
    initialState: {
        customization: {
            article: false,
            sale: false,
            rent: false,
            request: false,
            partnership: false,
            "shop-company-sell": false,
            crm: false,
            "training-job": false,
            "tender-call": false,
            benchmark: false,
            "library": false,
            event: false,
            recent_members: false,

        },
        email_notifications: [
            "network",
            "saved-searches",
            "important",
            "messaging",
            "promotions",
            "newsletter"
        ],
        site_notifications: [
            "network",
            "saved-searches",
            "important",
            "messaging",
            "promotions",
            "newsletter"
        ],

    },
    reducers: {
        toggleFeedCustom: (state, action) => {
            state.customization[action.payload] = !state.customization[action.payload]
        },
        setFeedTrue: (state, action) => {
            state.customization[action.payload] = true
        },
        setAllCustomInOnce: (state, action) => {
            state.customization.article = action.payload.article
            state.customization.sale = action.payload.sale
            state.customization.rent = action.payload.rent
            state.customization.request = action.payload.request
            state.customization.partnership = action.payload.partnership
            state.customization["shop-company-sell"] = action.payload["shop-company-sell"]
            state.customization.crm = action.payload.crm
            state.customization["training-job"] = action.payload["training-job"]
            state.customization["tender-call"] = action.payload["tender-call"]
            state.customization.benchmark = action.payload.benchmark
            state.customization.library = action.payload.library
            state.customization.event = action.payload.event
            state.customization.recent_members = action.payload.recent_members
        },
        setEmailNotif: (state, action) => {
            state.email_notifications = action.payload
        },
        setSiteNotif: (state, action) => {
            state.site_notifications = action.payload
        },
    }
})

export const { toggleFeedCustom, setFeedTrue, setAllCustomInOnce, setEmailNotif, setSiteNotif } = newsFeedSlice.actions
export default newsFeedSlice.reducer