
const Logo = ({ primaryColor, primaryColor2, primaryColor3 }) => {


    return (
        <svg id="Calque_2" data-name="Calque 2" width="100%" viewBox="0 0 688.43 146.03" fill="none" xmlns="http://www.w3.org/2000/svg">

            <g>
                <path fill={primaryColor} d="M168.48,43.14a30.07,30.07,0,0,1,9.07,1.2,16.88,16.88,0,0,1,6.2,3.36,13.08,13.08,0,0,1,3.58,5.18,18.41,18.41,0,0,1,1.15,6.64,14.62,14.62,0,0,1-2.17,8.4,13.41,13.41,0,0,1-6.41,4.87,13.48,13.48,0,0,1,8.85,13.63,18.62,18.62,0,0,1-1.15,6.64A13.1,13.1,0,0,1,184,98.24a17.5,17.5,0,0,1-6.19,3.41,29.38,29.38,0,0,1-9.07,1.23H142.2q-5,0-5-4.86V48c0-3.24,1.65-4.87,5-4.87Zm-21,25.23h21.24q9.19,0,9.2-7.17a7.57,7.57,0,0,0-2.17-5.67c-1.44-1.41-3.76-2.12-6.94-2.12H148.66c-.77,0-1.15.35-1.15,1.06Zm0,23.19c0,.65.38,1,1.15,1H169q4.78,0,7-2a7.39,7.39,0,0,0,2.22-5.75q0-7.69-9.21-7.7h-21.5Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor} d="M225.36,56.15q8.32,0,12,3.76t3.72,10.58c0,4.31-1.22,7.63-3.63,10s-6.5,3.49-12.22,3.49H203.06v3.37A6.61,6.61,0,0,0,204.7,92q1.63,1.81,5.53,1.82h28.58v9.11H210.23a21.23,21.23,0,0,1-7.48-1.15,13.27,13.27,0,0,1-5.05-3.23,12.4,12.4,0,0,1-2.87-4.86,20.28,20.28,0,0,1-.89-6.16V71.55a20.35,20.35,0,0,1,.89-6.1,12.42,12.42,0,0,1,2.87-4.92,13.52,13.52,0,0,1,5.05-3.23,21.46,21.46,0,0,1,7.48-1.15ZM203.06,76.6H225c2.53,0,4.32-.5,5.35-1.51A5.42,5.42,0,0,0,231.91,71a5.76,5.76,0,0,0-1.59-4.25c-1.06-1.06-2.75-1.59-5-1.59h-15q-3.9,0-5.53,1.86a6.67,6.67,0,0,0-1.64,4.6Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor} d="M249.8,56.15V45.09h9.47V56.15h12.92v9H259.27V87.31c0,2.65.48,4.48,1.46,5.48s2.58,1.51,4.82,1.51H272v8.58H263.6a21.58,21.58,0,0,1-6.24-.79,9.3,9.3,0,0,1-4.29-2.66,11.54,11.54,0,0,1-2.48-5,31.59,31.59,0,0,1-.79-7.7V65.18h-6.38v-9Z" transform="translate(-10.08 -12.77)" />
                <g>
                    <path fill={primaryColor} d="M316.19,64c-1.48,0-1.48.77-1.83,2.29l-6.61,28.28a12.35,12.35,0,0,1-4.22,7.23q-3.09,2.37-8.64,2.37a16.57,16.57,0,0,1-5.5-.79,10,10,0,0,1-3.7-2.2,9.67,9.67,0,0,1-2.25-3.39,19.07,19.07,0,0,1-1.14-4.36l-4.85-36.83h10.13l4.14,36.3a3.71,3.71,0,0,0,.93,2.25,3.35,3.35,0,0,0,2.42.74,3.45,3.45,0,0,0,2.33-.66,3.91,3.91,0,0,0,1.11-1.89l6.6-28.19a11.9,11.9,0,0,1,4.14-7c2.06-1.58,3.33-2.38,7-2.38" transform="translate(-10.08 -12.77)" />
                    <path fill={primaryColor} d="M315.55,55.67c3.7,0,5,.8,7,2.38a11.8,11.8,0,0,1,4.14,7l6.61,28.19a3.82,3.82,0,0,0,1.1,1.89,3.47,3.47,0,0,0,2.33.66,3.34,3.34,0,0,0,2.42-.74,3.71,3.71,0,0,0,.93-2.25l4.14-36.3h10.13l-4.84,36.83a19.6,19.6,0,0,1-1.15,4.36,9.79,9.79,0,0,1-2.24,3.39,10.09,10.09,0,0,1-3.7,2.2,16.68,16.68,0,0,1-5.51.79q-5.55,0-8.63-2.37a12.31,12.31,0,0,1-4.23-7.23l-6.61-28.28c-.35-1.52-.34-2.29-1.83-2.29" transform="translate(-10.08 -12.77)" />
                </g>
                <path fill={primaryColor} d="M389.14,56.15q8.31,0,12,3.76t3.72,10.58q0,6.47-3.63,10t-12.21,3.49H366.83v3.37A6.66,6.66,0,0,0,368.47,92c1.09,1.21,2.94,1.82,5.53,1.82h28.59v9.11H374a21.27,21.27,0,0,1-7.48-1.15,13.31,13.31,0,0,1-5-3.23,12.3,12.3,0,0,1-2.88-4.86,20.28,20.28,0,0,1-.88-6.16V71.55a20.35,20.35,0,0,1,.88-6.1,12.32,12.32,0,0,1,2.88-4.92,13.56,13.56,0,0,1,5-3.23A21.5,21.5,0,0,1,374,56.15ZM366.83,76.6h21.95c2.54,0,4.32-.5,5.36-1.51A5.42,5.42,0,0,0,395.69,71a5.73,5.73,0,0,0-1.6-4.25q-1.59-1.59-5-1.59H374c-2.59,0-4.44.62-5.53,1.86a6.72,6.72,0,0,0-1.64,4.6Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor} d="M440.41,56.15q8.32,0,12,3.76t3.72,10.58q0,6.47-3.63,10t-12.21,3.49H418.11v3.37A6.66,6.66,0,0,0,419.75,92q1.63,1.81,5.53,1.82h28.59v9.11H425.28a21.27,21.27,0,0,1-7.48-1.15,13.23,13.23,0,0,1-5-3.23,12.3,12.3,0,0,1-2.88-4.86,20.28,20.28,0,0,1-.89-6.16V71.55a20.35,20.35,0,0,1,.89-6.1,12.32,12.32,0,0,1,2.88-4.92,13.47,13.47,0,0,1,5-3.23,21.5,21.5,0,0,1,7.48-1.15ZM418.11,76.6h21.95q3.81,0,5.35-1.51A5.38,5.38,0,0,0,447,71a5.76,5.76,0,0,0-1.59-4.25q-1.59-1.59-5-1.59H425.28q-3.9,0-5.53,1.86a6.72,6.72,0,0,0-1.64,4.6Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor} d="M486.17,56.15q12,0,17.75,5.36t5.79,15.09v26.28h-9.47V76.6q0-5.58-3.31-8.5t-10.76-2.92H470.86c-.77,0-1.15.44-1.15,1.33v36.37h-9.38V63.41q0-4.15,1.5-5.71t5.05-1.55Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor} d="M545,56.15q9.28,0,14.74,5.8t5.44,17.57q0,11.87-5.44,17.61T545,102.88H524.26v18.77h-9.38V76.16q0-10.18,4.33-15.09t12.84-4.92ZM524.26,93.77H544q6.11,0,8.89-3.32t2.79-10.93c0-5.08-.93-8.73-2.79-11s-4.82-3.36-8.89-3.36h-9.21q-5.76,0-8.14,2.66c-1.6,1.77-2.39,4.69-2.39,8.76Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor} d="M601.82,56.15v9H585.35q-6,0-6,6v31.68H570V69.78q0-7.08,2.87-10.35t9.78-3.28Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor} d="M632.56,56.15q9.29,0,14.78,5.8t5.48,17.57q0,11.87-5.48,17.61t-14.78,5.75h-9.83q-9.37,0-14.82-5.75t-5.45-17.61q0-11.86,5.45-17.61t14.82-5.76Zm-8.94,9q-6.12,0-8.9,3.36c-1.86,2.25-2.79,5.9-2.79,11s.93,8.72,2.79,10.93,4.82,3.32,8.9,3.32h8q6.11,0,8.9-3.32t2.78-10.93c0-5.08-.93-8.73-2.78-11s-4.83-3.36-8.9-3.36Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor} d="M696.66,56.15v9H670.82c-2.19,0-3.75.44-4.7,1.33a5.2,5.2,0,0,0-1.41,4,4.87,4.87,0,0,0,1.37,3.76q1.36,1.29,5.27,1.29h11a25.34,25.34,0,0,1,8,1.06,13,13,0,0,1,5,2.83,9.49,9.49,0,0,1,2.52,4.16,18.07,18.07,0,0,1,.71,5.13,18.54,18.54,0,0,1-.84,5.71A11.08,11.08,0,0,1,695,98.9a12.93,12.93,0,0,1-4.78,2.92,20.48,20.48,0,0,1-7,1.06H656V93.77h27.17c2.18,0,3.74-.4,4.69-1.2a4.9,4.9,0,0,0,1.41-3.94,4.77,4.77,0,0,0-1.55-4c-1-.8-2.73-1.2-5.08-1.2H671.61a24,24,0,0,1-8-1.1,12.44,12.44,0,0,1-4.92-3,10.4,10.4,0,0,1-2.52-4.38,18.76,18.76,0,0,1-.71-5.14q0-6.28,3.54-10t11.69-3.68Z" transform="translate(-10.08 -12.77)" />
            </g>
            <g>
                <path fill={primaryColor2} d="M27.49,85.07V68.94a9.68,9.68,0,0,1,19.35,0V95.4a3.63,3.63,0,1,0,7.26,0V57.87a9.68,9.68,0,1,1,19.35,0V95.4a3.64,3.64,0,1,0,7.27,0v-26c0-5.59,4-10.12,9.67-10.12a9.69,9.69,0,0,1,9.67,9.67V85.07a3.66,3.66,0,0,0,3.64,3.63h13.83V47.83a11,11,0,0,0-5-8.58L68.76,14a11,11,0,0,0-9.91,0L15,39.25a11,11,0,0,0-4.95,8.58V88.7H23.86A3.65,3.65,0,0,0,27.49,85.07Zm62.9-39.34a5.66,5.66,0,1,1-5.66,5.66A5.65,5.65,0,0,1,90.39,45.73ZM63.78,34.66a5.66,5.66,0,1,1-5.66,5.66A5.66,5.66,0,0,1,63.78,34.66ZM37.16,45.73a5.66,5.66,0,1,1-5.66,5.66A5.66,5.66,0,0,1,37.16,45.73Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor2} d="M94,85.07V68.94a3.65,3.65,0,0,0-3.63-3.63c-2.37,0-3.63,1.85-3.63,4.08v26a9.68,9.68,0,1,1-19.35,0V57.87a3.64,3.64,0,1,0-7.27,0V95.4a9.67,9.67,0,1,1-19.34,0V68.94a3.64,3.64,0,0,0-7.27,0V85.07a9.69,9.69,0,0,1-9.67,9.67H10.08v3.69A11,11,0,0,0,15,107l43.82,25.3a11,11,0,0,0,9.91,0L112.57,107a11,11,0,0,0,5-8.57V94.74H103.7A9.69,9.69,0,0,1,94,85.07Z" transform="translate(-10.08 -12.77)" />
            </g>
            <g>
                <path fill={primaryColor3} d="M137.2,140.19a12.08,12.08,0,0,1,12.43-12.26,11.67,11.67,0,0,1,10.26,5.82l-2.1,1.22a9.11,9.11,0,0,0-8.16-4.74,10,10,0,1,0,0,19.91,9.11,9.11,0,0,0,8.16-4.74l2.1,1.19a11.61,11.61,0,0,1-10.26,5.85A12.08,12.08,0,0,1,137.2,140.19Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M162.26,143.57a8.91,8.91,0,1,1,8.91,8.87A8.81,8.81,0,0,1,162.26,143.57Zm15.58,0a6.67,6.67,0,1,0-6.67,6.71A6.6,6.6,0,0,0,177.84,143.57Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M198.36,141.64V152h-2.23v-10.4c0-3-1.66-4.77-4.61-4.77s-5.48,1.76-5.48,6.3V152H183.8V135.11H186v2.64a6.23,6.23,0,0,1,5.68-3.05C195.85,134.7,198.36,137.41,198.36,141.64Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M217.26,141.64V152H215v-10.4c0-3-1.66-4.77-4.6-4.77s-5.49,1.76-5.49,6.3V152H202.7V135.11h2.23v2.64a6.25,6.25,0,0,1,5.69-3.05C214.75,134.7,217.26,137.41,217.26,141.64Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M237.81,143.64a9.26,9.26,0,0,1-.07,1.05H222.88c.47,3.45,3.15,5.59,6.74,5.59a6,6,0,0,0,5.45-2.88l2,1.15a8.47,8.47,0,0,1-7.45,3.89,8.87,8.87,0,1,1-.17-17.74C234.59,134.7,237.81,139,237.81,143.64Zm-14.93-1.12h12.63a6.06,6.06,0,0,0-6.1-5.65A6.32,6.32,0,0,0,222.88,142.52Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M240.35,143.57a8.7,8.7,0,0,1,8.91-8.87,8,8,0,0,1,7.41,4.44l-1.83,1.05a6,6,0,0,0-5.58-3.32,6.71,6.71,0,0,0,0,13.41A6.42,6.42,0,0,0,255,147l1.86,1.08a8.4,8.4,0,0,1-7.58,4.4A8.7,8.7,0,0,1,240.35,143.57Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M264.16,137.27v10.26c0,2.91,1.59,2.68,4.87,2.54v2c-4.57.67-7.11-.44-7.11-4.51V137.27h-3.55v-2.16h3.55V131l2.24-.67v4.74H269v2.16Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M285.32,137.27v10.26c0,2.91,1.59,2.68,4.88,2.54v2c-4.57.67-7.11-.44-7.11-4.51V137.27h-3.56v-2.16h3.56V131l2.23-.67v4.74h4.88v2.16Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M291.89,143.57a8.91,8.91,0,1,1,8.91,8.87A8.8,8.8,0,0,1,291.89,143.57Zm15.58,0a6.67,6.67,0,1,0-6.67,6.71A6.6,6.6,0,0,0,307.47,143.57Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M329.48,134.84V137c-2.71,0-5.35,1.43-5.35,5.62V152H321.9V135.11h2.23v2.81A5.47,5.47,0,0,1,329.48,134.84Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M347.73,143.64a9.26,9.26,0,0,1-.07,1.05H332.8c.47,3.45,3.15,5.59,6.74,5.59A6,6,0,0,0,345,147.4l2,1.15a8.47,8.47,0,0,1-7.45,3.89,8.87,8.87,0,1,1-.17-17.74C344.51,134.7,347.73,139,347.73,143.64Zm-14.93-1.12h12.63a6.05,6.05,0,0,0-6.1-5.65A6.32,6.32,0,0,0,332.8,142.52Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M368.08,135.11V152h-2.23v-3.32a7.85,7.85,0,0,1-6.84,3.72,8.87,8.87,0,0,1,0-17.74,7.86,7.86,0,0,1,6.84,3.73v-3.32Zm-2.23,8.46a6.67,6.67,0,1,0-6.67,6.71A6.6,6.6,0,0,0,365.85,143.57Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M372.83,127.32h2.23V152h-2.23Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M404.49,143.64c0,.34,0,.71-.07,1.05H389.55c.48,3.45,3.15,5.59,6.74,5.59a6,6,0,0,0,5.45-2.88l2,1.15a8.47,8.47,0,0,1-7.45,3.89,8.87,8.87,0,1,1-.17-17.74C401.27,134.7,404.49,139,404.49,143.64Zm-14.94-1.12h12.63a6,6,0,0,0-6.09-5.65A6.32,6.32,0,0,0,389.55,142.52Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M419,147.57c0,2.87-2.5,4.87-6.12,4.87-3.39,0-5.69-1.72-6.54-3.89l1.9-1.12a4.64,4.64,0,0,0,4.64,2.85c2,0,3.89-.71,3.89-2.71,0-4.2-9.89-1.8-9.89-8,0-2.71,2.41-4.84,5.79-4.84a6.28,6.28,0,0,1,5.93,3.45l-1.86,1.09a4.14,4.14,0,0,0-4.07-2.37c-1.76,0-3.55.84-3.55,2.67C409.13,143.74,419,141.34,419,147.57Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M426.16,137.27v10.26c0,2.91,1.59,2.68,4.88,2.54v2c-4.57.67-7.11-.44-7.11-4.51V137.27h-3.56v-2.16h3.56V131l2.23-.67v4.74H431v2.16Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M450.54,135.11V152h-2.23v-3.32a7.86,7.86,0,0,1-6.84,3.72,8.87,8.87,0,0,1,0-17.74,7.87,7.87,0,0,1,6.84,3.73v-3.32Zm-2.23,8.46a6.67,6.67,0,1,0-6.67,6.71A6.61,6.61,0,0,0,448.31,143.57Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M459.38,137.27v10.26c0,2.91,1.59,2.68,4.88,2.54v2c-4.57.67-7.11-.44-7.11-4.51V137.27h-3.56v-2.16h3.56V131l2.23-.67v4.74h4.88v2.16Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M483.19,143.64c0,.34,0,.71-.07,1.05H468.26c.47,3.45,3.14,5.59,6.73,5.59a6,6,0,0,0,5.45-2.88l2,1.15a8.47,8.47,0,0,1-7.45,3.89,8.87,8.87,0,1,1-.17-17.74C480,134.7,483.19,139,483.19,143.64Zm-14.93-1.12h12.62a6,6,0,0,0-6.09-5.65A6.31,6.31,0,0,0,468.26,142.52Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M494.19,143.57a8.91,8.91,0,1,1,8.91,8.87A8.81,8.81,0,0,1,494.19,143.57Zm15.58,0a6.67,6.67,0,1,0-6.67,6.71A6.6,6.6,0,0,0,509.77,143.57Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M533.54,143.57a8.76,8.76,0,0,1-8.73,8.87,7.84,7.84,0,0,1-6.84-3.72v10.09h-2.24v-23.7H518v3.32a7.84,7.84,0,0,1,6.84-3.73A8.76,8.76,0,0,1,533.54,143.57Zm-2.23,0a6.67,6.67,0,1,0-6.67,6.71A6.61,6.61,0,0,0,531.31,143.57Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M555.08,143.57a8.76,8.76,0,0,1-8.74,8.87,7.86,7.86,0,0,1-6.84-3.72v10.09h-2.23v-23.7h2.23v3.32a7.87,7.87,0,0,1,6.84-3.73A8.76,8.76,0,0,1,555.08,143.57Zm-2.24,0a6.67,6.67,0,1,0-6.67,6.71A6.6,6.6,0,0,0,552.84,143.57Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M557.79,143.57a8.91,8.91,0,1,1,8.91,8.87A8.8,8.8,0,0,1,557.79,143.57Zm15.58,0a6.68,6.68,0,1,0-6.67,6.71A6.61,6.61,0,0,0,573.37,143.57Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M586.91,134.84V137c-2.71,0-5.35,1.43-5.35,5.62V152h-2.23V135.11h2.23v2.81A5.47,5.47,0,0,1,586.91,134.84Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M594.39,137.27v10.26c0,2.91,1.6,2.68,4.88,2.54v2c-4.57.67-7.11-.44-7.11-4.51V137.27H588.6v-2.16h3.56V131l2.23-.67v4.74h4.88v2.16Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M617.15,135.11V152h-2.23V149.4a6.27,6.27,0,0,1-5.69,3c-4.13,0-6.64-2.71-6.64-6.94V135.11h2.24V145.5c0,3,1.65,4.78,4.6,4.78s5.49-1.76,5.49-6.3v-8.87Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M636.45,141.64V152h-2.23v-10.4c0-3-1.66-4.77-4.61-4.77s-5.48,1.76-5.48,6.3V152h-2.24V135.11h2.24v2.64a6.24,6.24,0,0,1,5.69-3.05C634,134.7,636.45,137.41,636.45,141.64Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M640.28,129.89a1.63,1.63,0,1,1,1.63,1.63A1.62,1.62,0,0,1,640.28,129.89Zm.51,5.22H643V152h-2.23Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M651.86,137.27v10.26c0,2.91,1.59,2.68,4.88,2.54v2c-4.57.67-7.11-.44-7.11-4.51V137.27h-3.56v-2.16h3.56V131l2.23-.67v4.74h4.88v2.16Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M660,129.89a1.63,1.63,0,0,1,3.25,0,1.63,1.63,0,1,1-3.25,0Zm.5,5.22h2.24V152h-2.24Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M683.66,143.64c0,.34,0,.71-.07,1.05H668.73c.47,3.45,3.14,5.59,6.73,5.59a6,6,0,0,0,5.45-2.88l2,1.15a8.47,8.47,0,0,1-7.45,3.89,8.87,8.87,0,1,1-.17-17.74C680.44,134.7,683.66,139,683.66,143.64Zm-14.93-1.12h12.62a6,6,0,0,0-6.09-5.65A6.32,6.32,0,0,0,668.73,142.52Z" transform="translate(-10.08 -12.77)" />
                <path fill={primaryColor3} d="M698.19,147.57c0,2.87-2.51,4.87-6.13,4.87-3.39,0-5.69-1.72-6.54-3.89l1.9-1.12a4.64,4.64,0,0,0,4.64,2.85c2,0,3.89-.71,3.89-2.71,0-4.2-9.89-1.8-9.89-8,0-2.71,2.41-4.84,5.79-4.84a6.28,6.28,0,0,1,5.93,3.45l-1.86,1.09a4.14,4.14,0,0,0-4.07-2.37c-1.76,0-3.55.84-3.55,2.67C688.3,143.74,698.19,141.34,698.19,147.57Z" transform="translate(-10.08 -12.77)" />
            </g>

        </svg>

    );
};



Logo.defaultProps = {
    primaryColor: '#18b0e7',
    primaryColor2: '#18b0e7',
    primaryColor3: '#18b0e7',
}

export default Logo