//import
//const createFetch = new FetchCreateListing();
//createFetch.fetchListings(type, createFormData, setloading)

import TokenRefresher from "./TokenRefresher";


//Saves and uses json after fetch with the useState of setter listi
export default class FetchCreateListing {


    //Bearer token
    async fetchListings(type, formCreateData, setValidated, setSuccess, setLoadingApi) {

        let getListing = async () => {

            let url;

            if (type === 'job') {
                url = `${process.env.REACT_APP_SINGLE_LIS_API}/new/training-job`;
            }
            else if (type === 'article') {
                url = `${process.env.REACT_APP_SINGLE_ARTICLE_API}/new`
            }
            else {
                url = `${process.env.REACT_APP_SINGLE_LIS_API}/new/${type}`
            }

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;

            fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': bearer_token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formCreateData),
                credentials: 'include',
            })
                .then(handleSubmitError)
                .then(res => {
                    setSuccess(res);
                })
                .catch(err => {
                    alert('Something went wrong, please try again or contact us for support');
                    console.error(err);
                })

        }

        //Error for Fetch
        function handleSubmitError(res) {
            setLoadingApi(false)
            if (res.status >= 200 && res.status <= 299) {
                return res.json();
            } else {
                setValidated(false);
                if (res.status === 400) { alert(res.statusText); }
                throw Error(res.statusText);
            }
        }

        //getListing();

        //checks and refresh token
        const sessionFetch = new TokenRefresher();
        sessionFetch.setTokenRefresh(getListing);

    }

}

