import React from 'react'
import { Col, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'


function SquareMetRange({handleChange}) {

    const { t } = useTranslation();

    return (
        <>
            <span className='small'>{t('text.area_m2')} </span>
            <br />
            <div className='d-inline-flex w-100'>
                <Form.Control size="sm" type="number" name="min_area" id="min_area" placeholder={t('words.min2')} onChange={handleChange}/>
                <Col lg={1} className='text-center fw-bold'>&rarr;</Col>
                <Form.Control size="sm" type="number" name="max_area" id="max_area" placeholder={t('words.max2')} onChange={handleChange}/>
            </div>
        </>
    )
}

export default SquareMetRange
