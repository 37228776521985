import TokenRefresher from "./TokenRefresher";


//Saves and uses json after fetch with the useState of setter listi
export default class FetchEventFollow {
    constructor(ac) {
        this.ac = ac
    }

    //Bearer token
    async fetchListings(url, theMethod, setLoading, setHasJoined) {

        let getListing = async () => {

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;

            fetch(url, {
                method: theMethod,
                signal: this.ac,
                headers: {
                    'Authorization': bearer_token,
                },
                credentials: 'include',
            })
                .then(res => {

                    setLoading(false);

                    if (res.status === 200) {
                        
                        if(theMethod === 'PATCH'){
                            setHasJoined(true);
                        }
                        else if(theMethod === 'DELETE'){
                            setHasJoined(false);
                        }

                    }

                })

                .catch(err => console.error(err))

        }



        //checks and refresh token
        const sessionFetch = new TokenRefresher();
        sessionFetch.setTokenRefresh(getListing);

    }

}

