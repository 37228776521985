import React from "react";
import { Card, Button, Row, Col, Form, FloatingLabel, Image, Alert, CloseButton } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { Facebook, Linkedin, PersonCircle, Twitter } from "react-bootstrap-icons";
import { IoIosSend } from "react-icons/io";
import { ImProfile } from "react-icons/im";
import { Link } from "react-router-dom";
import AgentPage from "../../pages/AgentPage";
//import SimpleFetchFeed from '../../jwtAPI/SimpleFetchFeed';
import sendEmailFromSite from '../../jwtAPI/SendEmail'



const AgentContact = ({ agent }) => {

  const { t } = useTranslation();

  //Native Validation
  const [validated, setValidated] = React.useState(false);
  //Loading Spinner
  const [loading, setloading] = React.useState(false);
  //Either chat or email
  const [mesValue, setMesValue] = React.useState('');
  //Hide email from bots
  const [showingEmail, setShowingEmail] = React.useState(false);
  //error or success on send email
  const [success, setSuccess] = React.useState('')



  //put values to the fields
  const [formData, updateFormData] = React.useState();

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };


  function sendMessage(event) {

    event.preventDefault();
    setloading(true);
    const form = event.currentTarget;

    //Validation for empty fields
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setloading(false);
    }

    setValidated(true);

    if (form.checkValidity() === true) {

      if (mesValue === "email") {

        const url = `${process.env.REACT_APP_DOMAIN}/en/api/user/${agent.id}/email`
        sendEmailFromSite(url, formData, setSuccess, setloading)

      }

    }
  }

  const resetForm = () => {
    setSuccess('')
    updateFormData('')
    setValidated(false)
  }


  return (


    <Card className="shadow-sm mt-4 bg-secondary mx-auto" style={{ maxWidth: '400px' }}>

      {success !== ''

        ?
        <Alert
          variant={success === 'success' ? 'success' : 'danger'}
          onClose={() => setSuccess('')}
          className="p-4 m-4 text-center"
        >
          <div className="d-flex end">
            <CloseButton onClick={() => setSuccess('')} />
          </div>
          <p>
            {success === 'success'
              ? t('bigtext.succontactUser')
              : t('bigtext.failcontact')
            }
          </p>
          <Button
            variant="outline-success"
            size="lg"
            onClick={resetForm}
            className='border-3'
          >
            {success === 'success'
              ? t('bigtext.sendAnotherMesUser')
              : t('text.retryMes')
            }
          </Button>
        </Alert>

        :
        <>

          <Row className="m-0 position-relative">
            <Col xs={4} className="p-3" style={{ maxWidth: '350px' }}>

              {agent.profile_picture_url
                ?
                <Image roundedCircle
                  style={{ objectFit: 'cover' }}
                  alt="agent"
                  width="70px"
                  height="70px"
                  src={`${process.env.REACT_APP_DOMAIN + agent.profile_picture_url}?photo_height=90&photo_width=90`}
                  className="me-2" />

                :
                <PersonCircle className="text-graylight" size={65} />
              }
            </Col>

            <Col xs={8} className="align-self-center p-0 text-white">

              <p className="fs-5 fw-bold mb-2 text-capitalize">{agent.blog_display_name.toLowerCase()}</p>
              {agent.email_canonical
                ?
                <>
                  {showingEmail
                    ?
                    <a href={`mailto:${agent.email_canonical}`} className="text-decoration-none small" >
                      {agent.email_canonical}
                    </a>

                    :
                    <Button size="sm" variant="outline-primary" onClick={() => setShowingEmail(true)}>
                      {t('text.showEmail')}
                    </Button>
                  }
                </>
                : ''
              }

            </Col>

            <Col sm={12} className="align-items-end d-inline-flex shadow-sm mt-2">

              {agent.attributes[11].value !== undefined &&
                <a href={agent.attributes[11].value} target="_blank" rel="noreferrer noopener" className="pt-0 p-2 text-info">
                  <Facebook />
                </a >
              }

              {agent.attributes[9].value !== undefined &&
                <a href={agent.attributes[9].value} target="_blank" rel="noreferrer noopener" className="pt-0 p-2 text-info">
                  <Linkedin />
                </a>
              }

              {agent.attributes[10].value !== undefined &&
                <a href={`https://twitter.com/${agent.attributes[10].value}`} target="_blank" rel="noreferrer noopener" className="pt-0 p-2 text-info">
                  <Twitter />
                </a>
              }

              <Link
                component={<AgentPage />}
                to={`/agent/${agent.id}/`}
                className="text-decoration-none text-dark text-nowrap"
              >
                <Button
                  variant="info"
                  size="sm"
                  className="w-100"
                >
                  <ImProfile className='mb-1 me-1' />
                  {t('words.view')} {t('title.profile')}
                </Button>
              </Link>

            </Col>
          </Row>

          <Row className="m-0 p-2 mt-5">
            <Col md={12}>

              <Form noValidate validated={validated} onSubmit={sendMessage}>

                <p className="fs-6 fw-bold mb-2 text-capitalize ms-1 text-white">{t('words.send')} Email</p>

                <Form.Control
                  required
                  size="sm"
                  className="bg-white mb-2 border-graylight"
                  name="name"
                  type="text"
                  autoComplete="name"
                  placeholder={t('title.name')}
                  onChange={handleChange}
                />

                <Form.Control
                  required
                  size="sm"
                  className="bg-white mb-2 border-graylight"
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder="Email"
                  onChange={handleChange}
                />

                <Form.Control
                  required
                  size="sm"
                  className="bg-white mb-2 border-graylight"
                  name="subject"
                  type="text"
                  autoComplete="subject"
                  placeholder={t('words.subject')}
                  onChange={handleChange}
                />

                <Form.Control
                  size="sm"
                  className="bg-white mb-2 border-graylight"
                  name="phone"
                  type="text"
                  autoComplete="phone"
                  placeholder={`${t('title.phone')} (${t('words.optional')})`}
                  onChange={handleChange}
                />

                <FloatingLabel controlId="floatingTextarea2" label={t('words.msg')}>
                  <Form.Control
                    required
                    size="sm"
                    as="textarea"
                    name="body"
                    placeholder=" "
                    className="mb-2 border-graylight"
                    style={{ height: '100px' }}
                    onChange={handleChange}
                  />
                </FloatingLabel>

                <Button
                  variant="warning"
                  type="submit"
                  className="px-4 w-100 my-4"
                  onClick={() => { setMesValue('email') }}
                  disabled={loading ? true : false}
                >
                  <IoIosSend /> {t('words.send')} Email
                </Button>

              </Form>

            </Col>
          </Row>
        </>
      }
    </Card>

  )
};

export default AgentContact;



