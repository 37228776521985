import React from 'react'
import CarouselItemListing from '../../listings/CarouselItemListing';
import AgendaCard from '../../toolBox/agenda/AgendaCard';
import ArticleCard from '../../toolBox/arcticles/ArcticleCard';
import ComparisonCard from '../../toolBox/comparison/ComparisonCard';
import CrmCard from '../../toolBox/crm/CrmCard';
import JobsCard from '../../toolBox/jobs/JobsCard';


const FavoritesListingType = ({ listing }) => {

    let mainTypeListing = 'article';

    if (listing.article) {

        switch (listing.article.object_type) {
            case "sale":
                mainTypeListing = 'sale';
                break;
            case "rent":
                mainTypeListing = 'rent';
                break;
            case "request":
                mainTypeListing = 'request';
                break;
            case "partnership":
                mainTypeListing = 'partnership';
                break;
            case "crm":
                mainTypeListing = 'crm';
                break;
            case "training-job":
                mainTypeListing = 'job';
                break;
            case 'benchmark':
                mainTypeListing = "benchmark";
                break;
            case "event":
                mainTypeListing = 'event';
                break;
            default:
                break;
        }

    } else {
        mainTypeListing = 'article';
    }




    return (

        <>
            {
                mainTypeListing === 'rent' || mainTypeListing === 'sale' || mainTypeListing === 'request' || mainTypeListing === 'partnership'
                    ? <CarouselItemListing listing={listing} mainTypeListing={mainTypeListing} />

                    : mainTypeListing === 'job'
                        ? <JobsCard listing={listing} />

                        : mainTypeListing === 'article'
                            ? <ArticleCard listing={listing} />

                            : mainTypeListing === 'benchmark'
                                ? <ComparisonCard listing={listing} />

                                : mainTypeListing === 'crm'
                                    ? <CrmCard listing={listing} />

                                    : mainTypeListing === 'event'
                                        ? <AgendaCard listing={listing} />
                                        : ''

            }
        </>

    )
}

export default FavoritesListingType