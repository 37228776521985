import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Badge, Card, Col, Image, Row } from 'react-bootstrap';
import { BiMoney } from 'react-icons/bi';
import { PersonCircle } from 'react-bootstrap-icons';
import AgentPage from '../../../pages/AgentPage';
import CrmPage from '../../../pages/CrmPage';
import OverlayListing from "../../profile/listings/OverlayListing";
import ToolCardActions from '../ToolCardActions';


const CrmCard = ({ listing, profile = false, status = null }) => {

    const { t } = useTranslation();

    let descrip = listing['article']['excerpt'];
    if (descrip.length > 180) {
        descrip = listing['article']['excerpt'].substring(0, 180) + " [...]"
    }


    return (
        <div className="position-relative card-listing-container">
            <Card className='tool-listing p-3 pt-0'>

                <ToolCardActions
                    listing={listing}
                    listingType="crm"
                />

                <Badge bg='warning' className='small text-end mb-2'>{t('cats_tools.crm')}</Badge>
                <h6><strong>
                    {listing['article']['title']}
                    {listing.number
                        && <span className='small fw-bold text-muted'>&nbsp;({listing.number})</span>
                    }
                </strong></h6>

                <Row className='small align-items-center justify-content-between'>
                    <Col>

                        <Link
                            component={<AgentPage />}
                            to={`/agent/${listing['article']['author']['id']}/`}
                            className="text-decoration-none text-dark text-capitalize"
                        >

                            {listing['article']['author']['profile_picture_url']
                                ? <Image roundedCircle
                                    style={{ objectFit: 'cover' }}
                                    width="35px"
                                    height="35px"
                                    src={`${process.env.REACT_APP_DOMAIN + listing['article']['author']['profile_picture_url']}?photo_height=50&photo_width=50`}
                                    className="me-2" />

                                : <PersonCircle className='text-warning me-2' size={33} />
                            }

                            {listing['article']['author']['blog_display_name'].toLowerCase()}
                        </Link>
                    </Col>

                    <Col className='text-warning text-end'>
                        <BiMoney size={18} className="mb-1 me-1" />
                        {listing['price'] ? listing['price'] : ''} {listing['price_currency'] ? listing['price_currency'] : ''}
                    </Col>

                    <Link
                        component={<CrmPage />}
                        to={`/listing/crm/${listing["id"]}/${listing["article"]["slug"]}`}
                        className="text-decoration-none text-dark"
                    >

                        <hr className='mt-3' />
                        <Col xs={12} style={{ minHeight: '57px' }}>
                            {descrip}
                        </Col>

                        <hr className='mt-3' />

                        {listing['language'] &&
                            <Col xs={12}>
                                <strong>{t('words.lang')}:</strong> {listing['language']['name']}
                            </Col>
                        }
                        {listing['subject'] &&
                            <Col xs={12}>
                                <strong>{t('words.topic')}:</strong> {listing['subject']['name']}
                            </Col>
                        }

                    </Link>

                </Row>

            </Card>
            {
                profile ?
                    <OverlayListing
                        title={listing.article.title}
                        type={"crm"}
                        link={`/listing/crm/${listing.id}/${listing.article.slug}/`}
                        id={listing.id}
                        edit={`/edit/crm/${listing.id}`}
                        status={status}
                        articleId={listing.article.id}
                    />
                    : null
            }
        </div>
    )
}

export default CrmCard