import TokenRefresher from "./TokenRefresher";

//if action mark as read all notifications else with id
const markAsReadNotif = async (id, action) => {

    const notyRead = async function () {
        try {

            let url;

            if (action === 'PATCH') url = `${process.env.REACT_APP_DOMAIN}/en/api/notifications/read`;
            else if (action === 'DELETE') url = `${process.env.REACT_APP_DOMAIN}/en/api/notifications/delete-all`;
            else url = `${process.env.REACT_APP_DOMAIN}/en/api/notification/${id}`;

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;

            await fetch(url, {
                method: action ? action : 'GET',
                headers: {
                    'Authorization': bearer_token,
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            })

        } catch (error) {
            console.log(error)

        } finally {
            return id;
        }
    }
    const sessionFetch = new TokenRefresher();
    sessionFetch.setTokenRefresh(notyRead);
}


export default markAsReadNotif