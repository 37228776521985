import React from "react";
import ContentLoader from "react-content-loader";
export default function CategoriesLoader(props) {
  const innerWidth = window.innerWidth;
  const innerHeight = window.innerHeight;
  const viewBoxWidth = `0 0 ${innerWidth / 3} ${innerHeight / 3}`;
  const width = "100%";
  const height = "10%";
  const width1 = "30%";
  const height1 = "20%";
  return (
    <>
      <ContentLoader
        speed={1}
        width={innerWidth / 3}
        height={innerHeight / 3}
        viewBox={viewBoxWidth}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        style={{ maxWidth: "100%", maxHeight: `100%` }}
        {...props}
      >
        <rect x="0" y="0" rx="0" ry="0" width={width} height={height} />
        <rect x="0" y="20%" rx="0" ry="0" width={width1} height={height1} />
        <rect x="35%" y="20%" rx="0" ry="0" width={width1} height={height1} />
        <rect x="70%" y="20%" rx="0" ry="0" width={width1} height={height1} />
        <rect x="0" y="50%" rx="0" ry="0" width={width1} height={height1} />
        <rect x="35%" y="50%" rx="0" ry="0" width={width1} height={height1} />
        <rect x="70%" y="50%" rx="0" ry="0" width={width1} height={height1} />
        <rect x="0" y="80%" rx="0" ry="0" width={width1} height={height1} />
        <rect x="35%" y="80%" rx="0" ry="0" width={width1} height={height1} />
        <rect x="70%" y="80%" rx="0" ry="0" width={width1} height={height1} />
      </ContentLoader>
    </>
  );
}
