import React from 'react'
import { Col, Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next'
import FetchDropdowns from "../../../jwtAPI/FetchDropdowns";
import { useSelector } from "react-redux/";


const RequestDropdown = ({ create, listing, col, getDataDrops }) => {

    //create => create new form
    //listing => edit listing form
    //both false is from filters form

    const { t, i18n } = useTranslation();

    const optionsRedux = useSelector(state => state.dropTypes[i18n.language]["request"])

    const [options, setOptions] = React.useState(optionsRedux);

    React.useEffect(() => {

        if (optionsRedux !== undefined) {
            setOptions(optionsRedux)
            return;
        }

        const fetchTypes = new FetchDropdowns();
        fetchTypes.fetchDrops('request', setOptions, i18n.language)

        //eslint-disable-next-line
    }, [i18n.language]);


    const [showDrop, setShowDrop] = React.useState(listing ? listing.request_type.id.toString() : '');
    const handleReqType = (e) => { setShowDrop(e.target.value); getDataDrops(e); }


    //only for filters form
    React.useEffect(() => {

        if (listing || create) { return; }

        resetValues('development_type')
        resetValues('rent_type')
        resetValues('buy_type')

        //eslint-disable-next-line
    }, [showDrop]);


    function resetValues(nameDrop) {
        //deletes unecessary fields if the request type changes by making the value "all" in getDataDrops
        const deleteFromObj = {
            target: {
                name: nameDrop,
                value: 'all',
            }
        }
        getDataDrops(deleteFromObj)
    }


    return (
        <>

            {
                (options && create) || (options && listing)
                    ?
                    <>
                        <Col md={col}>
                            <Form.Label>{t('words.reqType')}*</Form.Label>
                            <Form.Select
                                id='requestType'
                                defaultValue={showDrop}
                                required
                                onChange={handleReqType}
                                name="request_type"
                            >
                                <option></option>
                                {options.request.map((option, key) => (
                                    <option key={key} value={option.id}>{option.name}</option>
                                ))}
                            </Form.Select>
                        </Col>

                        {showDrop === '194' &&
                            <Col md={col}>
                                <Form.Label>{t('words.rentType')}*</Form.Label>
                                <Form.Select
                                    id='requestRent'
                                    defaultValue={(listing && listing.rent_type) && listing.rent_type.id}
                                    required
                                    name="rent_type"
                                    onChange={getDataDrops}
                                >
                                    <option></option>
                                    {options.rent_type.map((option, key) => (
                                        <option key={key} value={option.id}>{option.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        }

                        {showDrop === '195' &&
                            <Col md={col}>
                                <Form.Label>{t('words.development')}*</Form.Label>
                                <Form.Select
                                    id='requestDevelopment'
                                    defaultValue={(listing && listing.development_type) && listing.development_type.id}
                                    required
                                    name="development_type"
                                    onChange={getDataDrops}
                                >
                                    <option></option>
                                    {options.development_type.map((option, key) => (
                                        <option key={key} value={option.id}>{option.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        }

                        {showDrop === '193' &&
                            <Col md={col}>
                                <Form.Label>{t('words.saleType')}*</Form.Label>
                                <Form.Select
                                    id='requestSale'
                                    defaultValue={(listing && listing.buy_type) && listing.buy_type.id}
                                    required
                                    name="buy_type"
                                    onChange={getDataDrops}
                                >
                                    <option></option>
                                    {options.sale_type.map((option, key) => (
                                        <option key={key} value={option.id}>{option.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        }
                    </>

                    : options && !create && !listing
                        ?
                        <>
                            <Col md={col}>
                                <Form.Label className="small mb-0">{t('words.reqType')}</Form.Label>

                                <Form.Select name='request_type' id='request_type' size="sm" onChange={handleReqType}>
                                    <option value='all'>{t('words.reqType')} ({t('words.all')})</option>
                                    {options.request.map((option, key) => (
                                        <option key={key} value={option.id}>{option.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>

                            {showDrop === '194' &&
                                <Col md={col}>
                                    <Form.Label className="small mb-0">{t('words.rentType')}*</Form.Label>

                                    <Form.Select
                                        name="rent_type"
                                        id="rent_type"
                                        onChange={getDataDrops}
                                        size="sm"
                                    >
                                        <option value='all'>{t('words.rentType')} ({t('words.all')})</option>
                                        {options.rent_type.map((option, key) => (
                                            <option key={key} value={option.id}>{option.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                            }

                            {showDrop === '195' &&
                                <Col md={col}>
                                    <Form.Label className="small mb-0">{t('words.development')}</Form.Label>

                                    <Form.Select
                                        name="development_type"
                                        id="development_type"
                                        onChange={getDataDrops}
                                        size="sm"
                                    >
                                        <option value='all'>{t('words.development')} ({t('words.all')})</option>
                                        {options.development_type.map((option, key) => (
                                            <option key={key} value={option.id}>{option.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                            }

                            {showDrop === '193' &&
                                <Col md={col}>
                                    <Form.Label className="small mb-0">{t('words.saleType')}</Form.Label>

                                    <Form.Select
                                        id="buy_type"
                                        name="buy_type"
                                        onChange={getDataDrops}
                                        size="sm"
                                    >
                                        <option value='all'>{t('words.saleType')} ({t('words.all')})</option>
                                        {options.sale_type.map((option, key) => (
                                            <option key={key} value={option.id}>{option.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                            }

                        </>

                        : ''
            }

        </>
    )
}

export default RequestDropdown