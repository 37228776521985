import { useEffect, useState } from "react";
import { Form, Image, CloseButton, Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { FaRedoAlt } from 'react-icons/fa';
import heic2any from "heic2any";


const SingleImgEdit = ({ setExcerptUri, picture }) => {

  const { t } = useTranslation();

  const [preview, setPreview] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [editHasPicture, setEditHasPicture] = useState(process.env.REACT_APP_DOMAIN + picture);

  //Image to data URI
  function getDataUrl(img) {
    // Create canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    // Set width and height
    canvas.width = img.width;
    canvas.height = img.height;
    // Draw the image
    ctx.drawImage(img, 0, 0);
    return canvas.toDataURL('image/jpeg');
  }

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {

    if (!selectedFile) {
      setPreview(undefined);
      setExcerptUri('');
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // Select the image for URI
    const img = document.getElementById('single_photo');

    img.addEventListener('load', function (event) {

      const dataUrl = getDataUrl(event.currentTarget);
      setExcerptUri(dataUrl);
    });

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);

    //eslint-disable-next-line
  }, [selectedFile])


  const onSelectFile = (e) => {

    setEditHasPicture();

    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return;
    }
    
    let singleFileImg = e.target.files[0]

    if (singleFileImg.type === 'image/heic' || singleFileImg.name.endsWith('.heic')) {

      heic2any({ blob: singleFileImg, toType: "image/jpg", quality: 1 }).then(
        (newImage) => {
          setSelectedFile(newImage);
        }
      );
    }
    else {
      setSelectedFile(singleFileImg);
    }
  }

  //Delete
  const deletepic = () => {
    setSelectedFile(false);
    setEditHasPicture();
    document.getElementById('input_image').value = '';
  }

  const resetPic = () => {
    setSelectedFile(false);
    setEditHasPicture(process.env.REACT_APP_DOMAIN + picture);
    document.getElementById('input_image').value = '';
  }


  return (
    <>

      <Form.Control type="file" id="input_image" onChange={onSelectFile} accept=".png, .jpg, .jpeg, .gif, .heic" />

      {(selectedFile || editHasPicture) &&
        <CloseButton
          onClick={deletepic}
          className="position-absolute bg-white opacity-100 border border-4 border-danger"
          aria-label="Hide"
          style={{ zIndex: "1" }}
        />
      }

      {selectedFile &&
        <>
          <Image id="single_photo" src={preview} alt="preview" className="d-none" />

          <div className="ratio-cont">

            <Image
              className="ratio"
              src={preview}
              alt="preview"
            />

          </div>
        </>
      }

      {editHasPicture
        ?
        <>
          <Image id="single_photo" src={editHasPicture} alt="preview" className="d-none" />

          <div className="ratio-cont">

            <Image
              className="ratio"
              src={editHasPicture}
              alt="preview"
            />

          </div>
        </>
        :
        <Button variant="lightblue" className="w-100 mt-2 rounded-4" onClick={resetPic}>
          <FaRedoAlt className="me-2 mb-1" />{t('text.resetPic')}
        </Button>
      }

      <Form.Control
        readOnly
        type="hidden"
        id="changedExcerpt"
        aria-describedby="if picture changes or deleted completely it is false"
        value={editHasPicture !== undefined ? editHasPicture : false}
      />

    </>
  )
}

export default SingleImgEdit