import React from 'react'
// Redux 
import { useSelector, useDispatch } from "react-redux"
import { toggleFeedCustom } from '../../Redux/news-feed-slice';
import { Accordion, Form } from 'react-bootstrap'
import { GearFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next'
import SimpleFetchFeed from '../../jwtAPI/SimpleFetchFeed';



const NewsCustomize = ({ cards, checkedState, checkedBoxes, newsFeed }) => {

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch()
    const luser = useSelector(state => state.user);
    const recent_members = useSelector(state => state.newsFeed.customization.recent_members)

    let url = `${process.env.REACT_APP_DOMAIN}/${i18n.language}/${process.env.REACT_APP_FEED_API}`

    async function handleChange() {

        await dispatch(toggleFeedCustom("recent_members"));
        let objToSend = {

            ...newsFeed,
            recent_members: !recent_members
        }
        try {
            const fetchLists = new SimpleFetchFeed();
            fetchLists.fetchListings(url, objToSend,);
        } catch (error) {
            console.log(error);
        }


    }

    return (

        <Accordion id="news-custom" defaultActiveKey="0">

            <Accordion.Item eventKey="1" className="bg-white border-0 rounded-0">

                <Accordion.Header>
                    <span className='text-success fw-bold text-capitalize'>{t('words.welcome')}&nbsp;{luser.blog_display_name ? luser.blog_display_name.toLowerCase() : ''}!</span>
                    <span className='setting'>{t('bigtext.customFeed1')}<GearFill size={16} className="ms-2" /></span>
                </Accordion.Header>


                <Accordion.Body>
                    <div className='pb-1'>
                        <p className='small'>{t('bigtext.customFeed1')}&nbsp;{t('bigtext.customFeed2')}</p>

                        <div className="mb-3">

                            <Form.Check
                                inline
                                label={`${t('words.recent')} ${t('words.members')}`}
                                type='checkbox'
                                checked={recent_members}
                                onChange={handleChange}
                            />

                            {cards.map((card, index) => (
                                <Form.Check
                                    key={index}
                                    inline
                                    label={card.name}
                                    type='checkbox'
                                    checked={checkedState[index]}
                                    value={card.id}
                                    name={card.id}
                                    id={'newsfeed_' + card.id}
                                    onChange={() => checkedBoxes(index)}
                                />
                            ))}

                        </div>

                    </div>
                </Accordion.Body>

            </Accordion.Item>
        </Accordion >

    )
}

export default NewsCustomize