import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from "./locales/en/en.json";
import fr from "./locales/fr/fr.json";
import nl from "./locales/nl/nl.json";
import el from "./locales/el/el.json";

const resources = {
    en,
    fr,
    nl,
    el
}

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        supportedLngs: ["en", "fr", "nl", "el"],
        lowerCaseLng: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources
        /* resources: {
            en: {
                translation: {
                    // here we will place our translations...
                    title: {
                        login: 'Login',
                        createacc: 'Create an Account'
                    }
                }
            }
        } */
    });

export default i18n;