import TokenRefresher from "./TokenRefresher";


//Saves and uses json after fetch with the useState of setter listi
export default class DeletePicture {


    //Bearer token
    async fetchListings(articleId, photoId) {

        let getListing = async () => {

            const bearer_token = `Bearer ${localStorage.getItem('token')}`;

            const url = `${process.env.REACT_APP_DELETE_PHOTO}/${articleId}/${photoId}`

            fetch(url, {
                method: 'DELETE',
                headers: {
                    'Authorization': bearer_token,
                },
                credentials: 'include',
            })
                .catch(err => console.error(err))

        }

        //checks and refresh token
        const sessionFetch = new TokenRefresher();
        sessionFetch.setTokenRefresh(getListing);

    }

}

