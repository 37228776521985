import React from 'react'
import { Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DeleteFileListCE from './DeleteFileListCE';


const FilesUpBtn = ({ setDocumentsUri, listing }) => {

    const { t } = useTranslation();


    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.name = file.name;
        reader.onload = () => resolve(reader);
        reader.onerror = error => reject(error);
    });

    async function convertToUri(e) {

        setDocumentsUri([]);

        const filesAll = e.target.files;

        if (filesAll.length === 1) {

            if (((filesAll[0].size) / 1048576).toFixed(3) > 2) {

                document.getElementById(e.target.id).value = '';
                setDocumentsUri([]);
                alert(t('bigtext.file2mb'));
                return;
            }

            const fileSingle = filesAll[0];
            const fileUri = await toBase64(fileSingle);
            let fileWithName = {
                [fileUri.name]: fileUri.result
            }
            setDocumentsUri(fileWithName);

        }

        if (filesAll.length > 1) {

            setDocumentsUri({});

            let multi_docs = {};

            for (let i = 0; i < filesAll.length; i++) {

                /* if (((filesAll[i].size) / 1048576).toFixed(3) > 2) {
                    document.getElementById(e.target.id).value = '';
                    alert(t('bigtext.file2mb'));
                    return;
                } */

                const file = filesAll[i];
                const fileDataUri = await toBase64(file);
                const name = fileDataUri.name;
                const result = fileDataUri.result;

                //add each to the object
                multi_docs[name] = result;

            }
            setDocumentsUri(multi_docs);
        }
    }


    return (

        <Col>
            <Form.Group controlId="documents_data_uri">
                <Form.Label>{t('words.add')} {t('words.files')}</Form.Label>
                <Form.Control
                    type="file"
                    multiple
                    onChange={convertToUri}
                    name="documents_data_uri"
                    accept=".pdf, .doc, .docx, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
            </Form.Group>

            {(listing && listing.documents_file_names.length > 0) &&

                <DeleteFileListCE fileNames={listing.documents_file_names} fileIds={listing.documents} />

            }
        </Col>

    )
}

export default FilesUpBtn