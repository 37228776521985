import { createSlice } from "@reduxjs/toolkit";

export const pageSlice = createSlice({
    name: "page",
    initialState: {
        loader: true,
        darkTheme: false,
        myListingTab: "allListings",
        // change the "testEnv" to true if you run the site locally but don't forget to set it back to false when you finish and want to upload   
        testEnv: false

    },
    reducers: {
        setGlobalLoader: (state, action) => {
            state.loader = action.payload
        },
        toggleTheme: (state) => {
            state.darkTheme = !state.darkTheme
        }
        ,
        changeTabRedux: (state, action) => {
            state.myListingTab = action.payload
        }
    }
})

export const { setGlobalLoader, toggleTheme, changeTabRedux } = pageSlice.actions
export default pageSlice.reducer