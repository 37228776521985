import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MdMarkEmailRead } from "react-icons/md";

import SingleListPDF from './SingleListPDF';
import OverviewTitleShare from './OverviewTitleShare';


function Overview({ property, type }) {


    const { t } = useTranslation();


    return (

        <Card className='p-4 border-0 shadow-sm mt-5'>

            <OverviewTitleShare
                link={window.location.href}
                title={property.article.title}
                isFav={property.article.favorited_by}
                lisId={property.article.id}
                listingType={type}
            />

            <Row className='row-overview'>

                {(property.price || property.negotiable_price)
                    &&
                    <Col>
                        <span className='text-warning fs-6'>{t('words.price')}: </span>
                        <br />

                        {property.price !== -1 && <>{property.price}&euro;</>}

                        {property.lease_per &&
                            <span className='small text-muted me-1'>
                                /{property.lease_per}
                            </span>
                        }

                        {(property.negotiable_price === true) &&

                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>{t('bigtext.priceNego')}</Tooltip>}
                            >
                                <span>
                                    <MdMarkEmailRead size={23} className='ms-1 text-success' />
                                </span>

                            </OverlayTrigger>

                        }

                    </Col>
                }

                {property.area &&

                    <Col>
                        <span className='text-warning fs-6'>{t('words.surface')}: </span>
                        <br />
                        {property.area} &#13217;
                    </Col>

                }

                {type === 'sale'

                    ?
                    <Col>
                        <span className='text-warning fs-6'>{t('words.type')}: </span>
                        <br />
                        {property.ad_type.name}
                    </Col>

                    : type === 'rent' ?
                        <>
                            <Col>
                                <span className='text-warning fs-6'>{t('words.type')}: </span>
                                <br />
                                {property.ad_type.name}
                            </Col>
                            <Col>
                                <span className='text-warning fs-6'>{t('words.lease')}: </span>
                                <br />
                                {property.bail_type.name}
                            </Col>
                        </>

                        : type === 'request' ?
                            <>
                                <Col>
                                    <span className='text-warning fs-6'>{t('words.reqType')}: </span>
                                    <br />
                                    {property.request_type.name}
                                </Col>
                                <Col>
                                    <span className='text-warning fs-6'>{t('words.estate')}: </span>
                                    <br />
                                    {
                                        property.buy_type
                                            ? property.buy_type.name

                                            : property.rent_type
                                                ? property.rent_type.name

                                                : property.development_type
                                                    ? property.development_type.name

                                                    : ''
                                    }
                                </Col>
                            </>

                            :
                            <>
                                <Col>
                                    <span className='text-warning fs-6'>{t('words.partType')}: </span>
                                    <br />
                                    {property.partner_type.name}
                                </Col>

                                {property.partner_type.id === 47
                                    ?
                                    <Col>
                                        <span className='text-warning fs-6'>{t('words.startup')}: </span>
                                        <br />
                                        {property.start_up_type.name}
                                    </Col>

                                    : property.partner_type.id === 33
                                        ?
                                        <Col>
                                            <span className='text-warning fs-6'>{t('words.profType')}: </span>
                                            <br />
                                            {property.know_how_type.name}
                                        </Col>

                                        : property.partner_type.id === 30
                                            ?
                                            <Col>
                                                <span className='text-warning fs-6'>{t('words.development')}: </span>
                                                <br />
                                                {property.project_development_type.name}
                                            </Col>

                                            : ''
                                }
                            </>
                }

            </Row>

            <p className='mt-3'><strong>{t('words.desc')}</strong></p>
            <p dangerouslySetInnerHTML={{ __html: property.article.content }} />



            {property.documents_urls.length > 0 &&

                <SingleListPDF docNames={property.documents_file_names} docUrls={property.documents_urls} />

            }

        </Card>

    );
}

export default Overview;
