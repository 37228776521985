import React from "react";
import { Form } from "react-bootstrap"
//import { useTranslation } from 'react-i18next'


const DropdownProfession = ({ profeList, getProf, defaultValue1 = false, disabled = false }) => {

    //const { t } = useTranslation();

    const userProf = defaultValue1 !== null ? defaultValue1.toString() : false

    return (

        <Form.Select disabled={disabled} className="form-control" id="pro" name="pro" defaultValue={userProf} required onChange={getProf}>
            <option></option>
            {
                profeList['pro'].map((prof, key) => (
                    <option key={key} id={prof.id} value={prof.id}>{/* {t('prof.' + prof.id)} */}{prof.name}</option>
                ))
            }

        </Form.Select>
    )
}

export default DropdownProfession