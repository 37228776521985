import { useTranslation } from 'react-i18next';
import countries from "i18n-iso-countries";

const DateOnly = ({ dateRaw }) => {

    const { i18n } = useTranslation();


    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/el.json"));

    const formatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    //first new Date and then format, in one line they crush
    const d1 = new Date(dateRaw);
    const dateFormatted = d1.toLocaleDateString(i18n.language, formatOptions)

    return dateFormatted;
}

export default DateOnly
