import React from 'react'
import { Badge, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import countries from "i18n-iso-countries";
import { google_maps_api_key } from '../../../functions/ConstantVariables';
import AlertsModalDiv from '../../generalSearch/AlertsModalDiv';
import editSavedSearch from '../../../jwtAPI/EditSavedSearch';
import ToastTimer from '../../ToastTimer';


function InfoByType({ optionSingle }) {

    const { t, i18n } = useTranslation();

    const [loading, setLoading] = React.useState(false)
    const [status, setStatus] = React.useState(null)


    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/el.json"));

    //for static map
    let polygonForMap = '';

    if (optionSingle.google_polygon && optionSingle.google_polygon.geometry.coordinates.length > 0) {
        for (let i = 0; i < optionSingle.google_polygon.geometry.coordinates.length; i++) {
            polygonForMap = polygonForMap + '|' + optionSingle.google_polygon.geometry.coordinates[i];
        }
    }


    //FOR ALERTS
    const [radioValue, setRadioValue] = React.useState(optionSingle.alert_frequency);

    const hasAlerts = (e) => {
        if (e.target.checked === false) {
            setRadioValue('')
        }
        else {
            setRadioValue(optionSingle.alert_frequency)
        }
    }

    //change alert freq
    React.useEffect(() => {

        if (optionSingle.alert_frequency === radioValue) {
            return;
        }
        setLoading(true)
        const payload = { alert_frequency: radioValue }
        editSavedSearch(optionSingle.id, payload, setLoading, setStatus)

        //eslint-disable-next-line
    }, [radioValue]);


    return (
        <div className='p-4'>

            <h5>
                <Badge bg="yellow">{t('cats_tools.' + optionSingle.type + '')}</Badge>
            </h5>

            {optionSingle.keywords && <><strong>{t('words.keywrds')}</strong>: {optionSingle.keywords}<br /></>}

            {
                optionSingle.type === 'sale' ?
                    optionSingle.ad_type && <><strong>{t('words.estate')}</strong>: {optionSingle.ad_type.name} <br /></>

                    : optionSingle.type === 'rent' ?
                        <>
                            {optionSingle.ad_type && <><strong>{t('words.estate')}</strong>: {optionSingle.ad_type.name}<br /></>}
                            {optionSingle.bail_type && <><strong>{t('words.lease')} </strong>: {optionSingle.bail_type.name}<br /></>}
                        </>

                        : optionSingle.type === 'request' ?
                            <>
                                {optionSingle.request_type && <><strong>{t('words.reqType')}</strong>: {optionSingle.request_type.name}<br /></>}
                                {optionSingle.rent_type && <><strong>{t('words.rentType')}</strong>: {optionSingle.rent_type.name}<br /></>}
                                {optionSingle.development_type && <><strong>{t('words.development')}</strong>: {optionSingle.development_type.name}<br /></>}
                                {optionSingle.buy_type && <><strong>{t('words.saleType')}</strong>: {optionSingle.buy_type.name}<br /></>}
                            </>

                            : optionSingle.type === 'partnership' ?
                                <>
                                    {optionSingle.partner_type && <><strong>{t('words.partType')}</strong>: {optionSingle.partner_type.name}<br /></>}
                                    {optionSingle.project_development_type && <><strong>{t('words.development')}</strong>: {optionSingle.project_development_type.name}<br /></>}
                                    {optionSingle.know_how_type && <><strong>{t('words.profType')}</strong>: {optionSingle.know_how_type.name}<br /></>}
                                    {optionSingle.start_up_type && <><strong>{t('words.startup')}</strong>: {optionSingle.start_up_type.name}<br /></>}
                                </>

                                : optionSingle.type === 'job' ?
                                    <>
                                        {optionSingle.sector && <><strong>{t('title.sect')}</strong>: {optionSingle.sector.name}<br /></>}
                                        {optionSingle.job && <><strong>{t('words.employType')}</strong>: {optionSingle.job.name}<br /></>}
                                        {optionSingle.work_plan && <><strong>{t('title.work')} {t('words.plan')}</strong>: {optionSingle.work_plan.name}<br /></>}
                                    </>

                                    : optionSingle.type === 'benchmark' ?
                                        <>
                                            {optionSingle.estate_type && <><strong>{t('words.estate')}</strong>: {optionSingle.estate_type.name}<br /></>}
                                            {optionSingle.transaction && <><strong>{t('words.transType')}</strong>: {optionSingle.transaction.name}<br /></>}
                                        </>

                                        : optionSingle.type === 'event' ?
                                            <>
                                                {optionSingle.event_type && <><strong>{t('words.eventType')}</strong>: {optionSingle.event_type.name}<br /></>}
                                                {optionSingle.is_active === 'true' && <><strong>{t('text.eventActive')}</strong><br /></>}
                                            </>

                                            : optionSingle.type === 'crm' ?
                                                <>
                                                    {optionSingle.language && <><strong>{t('words.lang')}</strong>: {optionSingle.language.name}<br /></>}
                                                    {optionSingle.subject && <><strong>{t('words.subject')}</strong>: {optionSingle.subject.name}<br /></>}
                                                </>

                                                : ''
            }

            {optionSingle.min_area && <><strong>{t('text.area_m2')} {t('words.min2')}</strong>: {optionSingle.min_area}<br /></>}
            {optionSingle.max_area && <><strong>{t('text.area_m2')} {t('words.max2')}</strong>: {optionSingle.max_area}<br /></>}

            {(optionSingle.type === 'sale' || optionSingle.type === 'rent' || optionSingle.type === 'benchmark') &&
                <>
                    {optionSingle.min_price && <><strong>{t('words.price')} {t('words.min')}</strong>: {optionSingle.min_price}<br /></>}
                    {optionSingle.max_price && <><strong>{t('words.price')} {t('words.max')}</strong>: {optionSingle.max_price}<br /></>}
                </>
            }

            {(optionSingle.type !== 'article' || optionSingle.type === 'crm') &&
                <>
                    {(optionSingle.location && optionSingle.location.country)
                        && <>
                            <strong>{t('title.country')}</strong>:
                            {countries.getName(optionSingle.location.country, i18n.language, { select: "official" })}
                            <br />
                        </>
                    }

                    {(optionSingle.location && optionSingle.location.street)
                        && <>
                            <strong>{t('words.place')} (by Google Maps)</strong>:
                            {optionSingle.location.street}
                            <br />
                        </>
                    }

                    {optionSingle.place_google && <><strong>{t('words.place')} (by Google Maps)</strong>: {optionSingle.place_google}<br /></>}

                    {(optionSingle.google_polygon && polygonForMap !== 0) &&
                        <img src={`https://maps.googleapis.com/maps/api/staticmap?&size=400x200&key=${google_maps_api_key}&format=jpg&path=color:0xff5722|fillcolor:0x03a9f4b8|weight:3${polygonForMap}`} alt="map" className='img-fluid'/>
                    }
                </>
            }

            {status !== null && !loading
                ?
                <ToastTimer color='danger' text={t('bigtext.errorWrong')} time={6000} />

                : status === null && !loading
                    ?
                    <AlertsModalDiv
                        radioValue={radioValue}
                        setRadioValue={setRadioValue}
                        hasAlerts={hasAlerts}
                        nameRadioGroup={optionSingle.id}
                    />

                    :
                    <div className='bg-light p-4 rounded-4 text-center'>
                        <Spinner animation="border" variant="primary" />
                    </div>
            }

        </div>
    )
}
export default InfoByType