import React from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import LoadingData from '../components/createEdit/LoadingData';
import ListingsIndex from '../components/listings/ListingsIndex';
import NeedLoginPage from './NeedLoginPage';


const AdPage = () => {

    const [logged] = React.useState(localStorage.getItem('logged'));

    const { value } = useParams();

    return (
        <>

            {logged
                ?
                <Container fluid="xxl" className='p-0'>
                    <ListingsIndex typeListing={value} />
                </Container>
                
                : !logged
                    ? <NeedLoginPage />

                    : <LoadingData />
            }
        </>
    )
};

export default AdPage;


