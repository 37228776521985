import React from 'react'
import { Toast, ToastContainer } from 'react-bootstrap'

//{ show, setShow, color, text }

const ToastTimer = ({ color, text, time }) => {

    //Success & Error message
    const [show, setShow] = React.useState(true);

    return (
        <div>
            <ToastContainer className={show && 'p-3'} >

                <Toast bg={color} onClose={() => setShow(false)} show={show} delay={time} autohide>

                    <Toast.Body className='text-white fw-bold' >
                        {text}
                    </Toast.Body>

                </Toast>

            </ToastContainer>
        </div>
    )
}


ToastTimer.defaultProps = {
    time: 3000,
}

export default ToastTimer