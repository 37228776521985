
import React from 'react';
import { Button, Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
//Redux
import { useSelector } from 'react-redux';

import UserPhoto from '../../../assets/user-svgrepo-com.svg'
import Banner from '../../../assets/banner-empty.jpg'
import { TiCameraOutline } from 'react-icons/ti';
import ImageEditor from "./ImageEditor"

const ProfileHeader = () => {

    const { t } = useTranslation();

    const luser = useSelector(state => state.user);
    let userAvatar = luser?.profile_picture_url
    const [show, setShow] = React.useState(false)
    const [refresh, setRefresh] = React.useState(luser.refreshUserObject)
    const [profile, setProfile] = React.useState()
    const [refCover, setRefCover] = React.useState(Date.now())

    React.useEffect(() => {
        setRefresh(luser.refreshUserObject)
    }, [luser.refreshUserObject])


    const openImageEditor = (bool) => {
        setProfile(bool)
        setShow(true)

    }


    return (
        <>
            <div className='position-relative'>
                {show && <ImageEditor show={show} user={luser} setShow={setShow} profile={profile} refreshCover={setRefCover} />}

                <Image
                    id="cover_img"
                    src={luser.cover_photo_url ? `${luser.cover_photo_url}?date=${refCover}&photo_width=1374` : Banner}
                    height="198px"
                    width="100%"
                    // className=' bg-gradient'
                    className='bg-warning bg-gradient'
                    style={{ objectFit: 'contain' }}
                />

                <input type="hidden" id="cover_photo_uri" />

                <div
                    className="position-absolute mask1 bg-warning"
                    style={{ left: '15.5px', width: "210px", height: "240px", top: "47.5%" }}
                />

                <Image
                    className="position-absolute top-50 mask1"
                    key={refresh}
                    style={{ left: '20px', objectFit: 'cover' }}
                    width="200px"
                    height="230px"
                    id="profile_img"
                    src={userAvatar ? `${luser.profile_picture_url}?${refresh}` : UserPhoto}
                />


                <input type="hidden" id="profile_photo_uri" />

                <Button
                    variant="warning"
                    className="position-absolute rounded-4 px-2 py-1"
                    style={{ left: '178px', top: '61%' }}
                    onClick={() => openImageEditor(true)}
                >
                    <TiCameraOutline size="30" className='mb-1' />
                </Button>

                <Button
                    variant="warning"
                    className="position-absolute rounded-4 px-2 py-0"
                    style={{ left: '20px', top: '10%' }}
                    onClick={() => openImageEditor(false)}
                >
                    <TiCameraOutline size="30" className='mb-1 me-2' />
                    {t('text.coverPhoto')}
                </Button>


                <h2
                    className='position-absolute bg-white p-2 text-capitalize fw-bold text-warning'
                    style={{ top: "100%", left: "227px" }}
                >

                    {luser.first_name && luser.last_name ? luser.first_name.toLowerCase() + " " + luser.last_name.toLowerCase() :
                        luser.blog_display_name ? luser.blog_display_name.toLowerCase() : ''
                    }

                </h2>

            </div>

            <div style={{ height: "140px" }} />


        </>
    )
}

export default ProfileHeader