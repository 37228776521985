import React from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Col, Collapse, Form, FormControl, InputGroup, Row, ToggleButton, ToggleButtonGroup } from "react-bootstrap";

import FetchGetSetSimpleCircles from '../../jwtAPI/FetchGetSetSimpleCircles';
import FetchGetSetSimpleNetwork from '../../jwtAPI/FetchGetSetSimpleNetwork';

import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { Search } from 'react-bootstrap-icons';



const ScopeCE = ({ checked, setChecked, checkedNet, setCheckedNet, valueNet, setValueNet, valueGroup, setValueGroup }) => {

    const { t } = useTranslation();
    const [, setLoading] = React.useState(true);
    //Checkboxes default value

    /************ PUBLIC ************/
    const handleChecked = () => {
        if (!checked) {
            setCheckedNet(false);
            setValueNet([]);
            setValueGroup([]);
        }
        setChecked(!checked);
    }

    /************ ALL MY NET CHECKBOX ************/
    const handleCheckedNet = () => {
        if (!checkedNet) {
            setChecked(false);
            setValueNet([]);
            setValueGroup([]);
        }
        setCheckedNet(!checkedNet);
    }

    /************ GROUPS ************/
    let [circles, updateCircles] = React.useState(null);
    const urlCircles = process.env.REACT_APP_CIRCLES_API;

    //Toggle Groups
    let [showGroups, setShowGroups] = React.useState(false);
    let toggleGroups = () => { setShowGroups(!showGroups); }

    //Groups check
    const handleChangeGroup = (val) => {
        if (val.length > 0) {
            setChecked(false);
            setCheckedNet(false);
            setValueNet([]);
        }
        setValueGroup(val)
    };

    //Fetch Groups
    React.useEffect(() => {

        const fetchLists = new FetchGetSetSimpleCircles();
        fetchLists.fetchListings(urlCircles, updateCircles);

    }, [urlCircles]);

    /************ MY NETWORK ************/
    //Toggle Net
    let [showNet, setShowNet] = React.useState(false);
    let toggleNet = () => { setShowNet(!showNet); }

    //Net check
    const handleChangeNet = (val) => {
        if (val.length > 0) {
            setChecked(false);
            setCheckedNet(false);
            setValueGroup([]);
        }
        setValueNet(val)
    }

    //Search Net by Name
    let [searchName, setSearchName] = React.useState('');
    const handleSearchName = (e) => {
        setSearchName(e.target.value);
    }

    //Fetch Net
    let [userNet, setUserNet] = React.useState(true);
    let urlNet = process.env.REACT_APP_USERNETALL_API + searchName;

    React.useEffect(() => {

        const fetchLists = new FetchGetSetSimpleNetwork();
        fetchLists.fetchListings(urlNet, setUserNet, null, setLoading);

    }, [urlNet, setUserNet]);

    return (
        <>

            <Col sm={12} className="text-white bg-warning mt-4 px-4 pt-3 pb-2">
                <h6>{t('text.scope_view')}</h6>
            </Col>

            <Col lg={12} className="bg-white shadow-sm p-4">

                <Row className='gy-4'>
                    <Col md={4}>
                        <Form.Check
                            type="checkbox"
                            id="public"
                            label={<><strong>{t('words.public2')} {t('cats_tools.lis')}</strong> <span className='small'>({t('text.allUsers')})</span></>}
                            checked={checked}
                            onChange={handleChecked}
                        />
                    </Col>

                    <Col md={4}>
                        <Button
                            variant="transparent"
                            onClick={toggleGroups}
                            aria-controls="scope_groups"
                            className='p-0 ps-2 mb-2'
                        >
                            <span className='fw-bold'>{t('words.my')}&nbsp;{t('words.groups')}</span>
                            {showGroups ? <IoIosArrowUp className="ms-1" size={19} /> : <IoIosArrowDown className="ms-1" size={19} />}
                        </Button>

                        <Collapse id="scope_groups" in={showGroups} dimension="height">
                            <div>
                                {circles &&

                                    <ToggleButtonGroup
                                        vertical
                                        type="checkbox"
                                        value={valueGroup}
                                        className="w-100 border border-1 border-secondary"
                                        onChange={handleChangeGroup}
                                    >
                                        {circles.map((group, key) => (

                                            <ToggleButton
                                                key={key}
                                                id={`group_${group.id}`}
                                                value={group.id}
                                                variant="outline-success"
                                                className='border-0 text-start fw-bold'
                                            >
                                                {group.title}
                                            </ToggleButton>

                                        ))}
                                    </ToggleButtonGroup>
                                }
                            </div>
                        </Collapse>
                    </Col>

                    <Col md={4}>
                        <Button
                            variant="transparent"
                            onClick={toggleNet}
                            aria-controls="scope_net"
                            className='p-0 mb-2 ps-2'
                        >
                            <span className='fw-bold'>{t('title.net2')}</span>
                            {showGroups ? <IoIosArrowUp className="ms-1" size={19} /> : <IoIosArrowDown className="ms-1" size={19} />}
                        </Button>

                        <Collapse id="scope_groups" in={showNet} dimension="height">
                            <div>

                                <Form.Check
                                    type="checkbox"
                                    id="scope_all_net"
                                    className='ms-2'
                                    label={t('bigtext.allNetSee')}
                                    checked={checkedNet}
                                    onChange={handleCheckedNet}
                                />

                                <InputGroup className="mt-2 border-bottom">
                                    <FormControl
                                        placeholder={`${t('title.name')} ${t('words.or')} ${t('title.lname')} ${t('words.or')} Email`}
                                        aria-label={`${t('words.srch')} ${t('words.with')} ${t('title.name')} ${t('words.or')} Email`}
                                        aria-describedby="search-net-name"
                                        className='border-0'
                                        onKeyUp={handleSearchName}
                                    />
                                    <InputGroup.Text id="search-net-name" className='bg-light border-0'>
                                        <Search />
                                    </InputGroup.Text>
                                </InputGroup>

                                {userNet.users &&
                                    <div>

                                        <ToggleButtonGroup
                                            vertical
                                            type="checkbox"
                                            value={valueNet}
                                            className="w-100 border border-1 border-secondary collapse-height-scroll"
                                            onChange={handleChangeNet}
                                        >

                                            {userNet.users.map((friend, key) => (

                                                <ToggleButton
                                                    key={key}
                                                    id={`friend_${friend.id}`}
                                                    value={friend.id}
                                                    variant="outline-success"
                                                    className='border-0 text-start fw-bold text-capitalize'
                                                >
                                                    {friend.blog_display_name.toLowerCase()}
                                                </ToggleButton>

                                            ))}

                                        </ToggleButtonGroup>

                                    </div>
                                }

                            </div>
                        </Collapse>
                    </Col>
                </Row>


            </Col>

        </>
    )
}

export default ScopeCE