import React from 'react'
import { Button, Col, FormSelect } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';



const SavedSearchesDrop = ({ typeListing, setFiltersFromSaved }) => {

    const { t } = useTranslation();
    const navigate = useNavigate()

    const options = useSelector(state => state.savedSearch.saved_searches_dropdown)
    const optionByType = options.filter(obj => obj.type === typeListing)

    const savedSrch = useSelector(state => state.savedSearch.saved_searches_alerts)

    const [selectValue, setSelectValue] = React.useState('all')

    //if from profile we take the id of the saved search
    let filters_from_url = useLocation().hash;
    if (filters_from_url) filters_from_url = filters_from_url.replace('#saved=', '')

    //resets select value
    React.useEffect(() => {
        setSelectValue('all')
        //eslint-disable-next-line
    }, [typeListing]);


    //we change the drop and the filters
    React.useEffect(() => {

        if (!filters_from_url) return;

        setTimeout(function () {
            const pff = { target: { value: filters_from_url } }
            searchWithSaved(pff)
        }, 1);
        //eslint-disable-next-line
    }, [filters_from_url]);


    const searchWithSaved = (e) => {

        if (e.target.value === 'all') {
            setFiltersFromSaved(undefined)
            setSelectValue("all")
            navigate(`/ad/${typeListing}`); //deletes location from url        
            return;
        }

        setSelectValue(e.target.value)

        const index = savedSrch.findIndex(obj => obj.id === parseInt(e.target.value));

        const newObjForFilters = { ...savedSrch[index] }

        if (newObjForFilters.location) {
            if (newObjForFilters.location.country && !newObjForFilters.location.google_map) {
                newObjForFilters.location = { country: newObjForFilters.location.country }
            }
            else {
                newObjForFilters.location = {
                    google_map: newObjForFilters.location.google_map,
                    country: newObjForFilters.location.country,
                    street: newObjForFilters.location.street
                }
            }
        }

        delete newObjForFilters.id
        delete newObjForFilters.name
        delete newObjForFilters.alert_frequency
        delete newObjForFilters.type

        //saves only the ids of fields like estate_type not the whole object
        Object.entries(newObjForFilters).forEach(([key]) => {
            if (newObjForFilters[key].id) {
                newObjForFilters[key] = { id: newObjForFilters[key].id }
            }
        })
        setFiltersFromSaved(newObjForFilters)
    }

    return (
        optionByType.length > 0 &&
        <>

            <span className='mt-3 text-warning'><strong>&#9654;{t('text.searchSaved')}</strong></span>

            <Col md={6} className="mt-0">
                <FormSelect size="sm" onChange={searchWithSaved} value={selectValue}>
                    <option value="all">{t('text.searchSaved1')}</option>
                    {optionByType.map((option, key) => (
                        <option key={key} value={option.value}>{option.label}</option>
                    ))}
                </FormSelect>
            </Col>

            <Col md={6} className="mt-0">
                <Button
                    size="sm"
                    variant="graylight"
                    className="border-2 me-4 fw-bold w-100"
                    type="link"
                    onClick={() => navigate("/profile/Searches")}
                >
                    {t('words.manage')} {t('words.saved')} {t('words.searches')}/{t('words.alerts')}
                </Button>
            </Col>
        </>

    )
}

export default SavedSearchesDrop
