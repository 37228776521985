import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Card, CloseButton, Col, Form, Spinner } from 'react-bootstrap';

const ResetPassStep1 = ({ name }) => {

    const { t } = useTranslation();

    const [validated, setValidated] = React.useState(false);
    const [loading, setloading] = React.useState(false);
    const [showErr, setShowErr] = React.useState(false);
    const [showSend, setShowSend] = React.useState(false);

    const [email, setEmail] = React.useState('');
    const handleChange = (e) => { setEmail(e.target.value.trim()) }


    const handleSubmitValidation = (event) => {

        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);

        if (form.checkValidity() === true) {
            handleSubmit(event);
        }

    };

    /************* Reset Pass ***************/

    function handleSubmitError(res) {
        if (res.status >= 200 && res.status <= 299) {
            return;
        } else {
            setloading(false);
            setValidated(false);
            setShowErr(true);
            throw Error(res.statusText);
        }
    }

    let handleSubmit = async (e) => {

        setloading(true);
        e.preventDefault();

        const formData = {
            "username": email,
            "link": `${process.env.REACT_APP_URL}/page/${name}`
        }

        fetch(process.env.REACT_APP_FORGOTPASS_API, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then(handleSubmitError)
            .then(() => {
                setloading(false);
                setShowSend(true);
            })
            .catch(err => console.error(err))
    };


    return (

        <Col md={6} lg={5} >
            <Card bg="light" border="warning" className='p-4 text-center shadow-lg'>

                <h4 className='mb-4'>{t('text.askPass')}</h4>

                {showErr ?
                    <Alert variant="danger" onClose={() => setShowErr(false)}>
                        <div className="d-flex justify-content-end">
                            <CloseButton onClick={() => setShowErr(false)} />
                        </div>
                        <p>
                            {t('bigtext.failogin')}
                        </p>
                    </Alert>

                    : ''
                }

                {showSend

                    ?

                    <Alert variant="success" className='text-start'>
                        <h5>
                            {t('bigtext.emailPass1')}
                            <br />
                            <br />
                            {t('bigtext.emailPass2')}
                            <br />
                            <br />
                            {t('bigtext.emailPass3')}
                        </h5>
                    </Alert>

                    :

                    <Form noValidate validated={validated} onSubmit={handleSubmitValidation}>

                        <Form.Control
                            placeholder={t('text.enterEmail')}
                            required
                            name="username"
                            type="email"
                            autoComplete="username"
                            className='mb-4'
                            onChange={handleChange}
                        />

                        <Button variant="warning" type="submit" disabled={loading ? true : false} className="text-white rounded-pill">
                            {loading ?
                                <>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    {t('title.loading')}
                                </>

                                :
                                t('text.resetPass')
                            }
                        </Button>

                    </Form>

                }

            </Card>
        </Col>

    );
};

export default ResetPassStep1;
