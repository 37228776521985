import React from 'react'
import { FormSelect, Nav, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import SearchButtonAnimated from '../../SearchButtonAnimated';

const TutorialSubmenu = () => {

    const { t } = useTranslation();

    return (
        <Nav className='mt-1 border border-gray mb-5 px-4 py-2 mx-4'>

            <FormSelect
                id="myListsLimitTut"
                className="w-auto ms-2 ps-1 border-0 rounded-0 bg-white text-primary"
                style={{ padding: '0 1.6rem 0 0' }}
            >
                <option value="6">6</option>
                <option value="12">12</option>
                <option value="24">24</option>
                <option value="50">50</option>
            </FormSelect>

            <span className="me-2 py-3">{t('words.per')} {t('words.page')}</span>

            <span className='ms-3 py-3'>{t('words.sortby')}:</span>

            <FormSelect
                id="profListSortTut"
                className="w-auto ms-2 ps-1 border-0 rounded-0 bg-white text-primary"
                style={{ padding: '0 1.6rem 0 0' }}
            >
                <option value="dateasc">{t('title.date')} &darr;</option>
                <option value="datedesc">{t('title.date')} &uarr;</option>
                <option value="nameasc">{t('title.name')} &darr;</option>
                <option value="namedesc">{t('title.name')} &uarr;</option>
            </FormSelect>

            <SearchButtonAnimated />

            <ToggleButtonGroup
                type="radio"
                name="lisStatusTut"
                className='m-auto'
            >
                <ToggleButton variant="outline-warning" >
                    {t('words.published')}
                </ToggleButton>
                <ToggleButton variant="outline-warning" >
                    {t('words.draft')}
                </ToggleButton>
                <ToggleButton variant="outline-warning" >
                    {t('words.archived')}
                </ToggleButton>
            </ToggleButtonGroup>

        </Nav>

    )
}

export default TutorialSubmenu