import React from "react";
import { Briefcase, Building, ThreeDots } from 'react-bootstrap-icons';
import { FaPlus } from 'react-icons/fa';
import { CgUnblock } from 'react-icons/cg';
import { Col, Image, Row, Dropdown, Button, FormSelect, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
import AgentPage from '../../pages/AgentPage';
import User from '../../assets/user-svgrepo-com-gray.svg';
import EditUserCircle from '../../jwtAPI/EditUserCircle';
import SimpleFetchNet from '../../jwtAPI/SimpleFetchNet';
import ReportUserOrList from '../ReportUserOrList';
import AgentContact from "../singleListing/AgentContact";
import ToastTimer from "../ToastTimer";


const UserCard = ({ agent, myNet, searchByGroup, setUserNet, userNet, circles, allNetwork, setAllNetwork, eventKey }) => {

    const { t } = useTranslation();

    const [status, setStatus] = React.useState(null)


    //Filter User Profession
    let userProf1 = [];
    let userProf2 = [];

    const [emailModal, setEmailModal] = React.useState(false)

    if (agent.user_types && agent.user_types[0] && agent.user_types[0] !== 0) {
        // array empty or does not exist

        userProf1 = Object.keys(agent.user_types[0]);

        for (let i = 0; i < userProf1.length; i++) {
            if (userProf1[i] !== 'id') {
                userProf2.push(

                    <li key={i} className='mx-1 me-3 '>&bull; {agent.user_types[0][userProf1[i]].name}</li>

                )
            }
        }
    }

    function removeUserFromGroup() {

        if (window.confirm(t('bigtext.removeUserFromGroup'))) {

            setUserNet(userNet.filter(item => item.id !== agent.id));

            const data = {
                "user_ids": [agent.id]
            }

            const userCircle = new EditUserCircle();
            userCircle.fetchListings('remove-users', data, parseInt(searchByGroup));
        }

    }

    function addUserToGroup(e) {

        const data = {
            "user_ids": [agent.id]
        }

        const groupId = document.getElementById('group_add_' + agent.id).value;

        const userCircle = new EditUserCircle();
        userCircle.fetchListings('add-users', data, parseInt(groupId));
    }

    function addUserNet() {
        const url = `${process.env.REACT_APP_DOMAIN}/en/api/friend-request/${agent.id}`;
        const userCircle = new SimpleFetchNet();
        userCircle.fetchListings(url, 'GET', setStatus);
    }

    function removeUserNet() {

        if (window.confirm(t('bigtext.removeUserFromNet'))) {

            const url = `${process.env.REACT_APP_DOMAIN}/en/api/remove-friend/${agent.id}`;
            const userCircle = new SimpleFetchNet();
            userCircle.fetchListings(url, 'DELETE', setStatus);

            setUserNet(userNet.filter(item => item.id !== agent.id));

        }
    }

    function blockUser() {

        if (window.confirm(t('bigtext.blockUser'))) {

            const url = `${process.env.REACT_APP_DOMAIN}/en/api/block-user/${agent.id}`;
            const userCircle = new SimpleFetchNet();
            userCircle.fetchListings(url, 'GET', setStatus);

            if (myNet) {
                setUserNet(userNet.filter(item => item.id !== agent.id));
            }
            else {
                setAllNetwork(allNetwork.filter(item => item.id !== agent.id));
            }
        }

    }

    function unBlockUser() {

        const url = `${process.env.REACT_APP_DOMAIN}/en/api/unblock-user/${agent.id}`;
        const userCircle = new SimpleFetchNet();
        userCircle.fetchListings(url, 'GET', setStatus);
        setUserNet(userNet.filter(item => item.id !== agent.id));

    }

    function acceptUser() {

        const url = `${process.env.REACT_APP_DOMAIN}/en/api/friend-accept/${agent.id}`;
        const userCircle = new SimpleFetchNet();
        userCircle.fetchListings(url, 'GET', setStatus);
        setUserNet(userNet.filter(item => item.id !== agent.id));

    }


    return (

        <>
            <Row>
                {status !== null &&
                    <ToastTimer color={status === 'success' ? 'success' : 'danger'} text={status === 'success' ? t('title.success') : status} time={6000} />
                }
            </Row>

            <Row className='m-2 ms-0 py-3 px-2 bg-white align-items-center shadow-sm'>
                <Col xs={2}>

                    <Link
                        component={<AgentPage />}
                        to={`/agent/${agent.id}/`}
                        className="text-decoration-none text-dark"
                        style={{ pointerEvents: eventKey === "4" && 'none' }}
                    >

                        <Image
                            roundedCircle
                            style={{ objectFit: 'cover' }}
                            width="55px"
                            height="55px"
                            src=
                            {agent['profile_picture_url']
                                ? `${process.env.REACT_APP_DOMAIN}${agent['profile_picture_url']}?photo_height=75&photo_width=75`
                                : User
                            }
                            onError={(e) => { e.target.onerror = null; e.target.src = User }}
                        />

                    </Link>

                </Col>


                <Col xs={7}>
                    <span className="fw-bold text-capitalize">{agent['blog_display_name'] ? agent['blog_display_name'].toLowerCase() : 'No Name'}</span>

                    <ul className="list-agent-info2 mb-1">

                        {userProf2}

                        {agent['attributes'][0]['value']
                            ?
                            <li><Building />&nbsp;&nbsp;{t('title.enterprise')}:&nbsp;{agent['attributes'][0]['value']}</li>
                            : ''
                        }
                        {agent['attributes'][1]['value']
                            ?
                            <li><Briefcase />&nbsp;&nbsp;{t('title.protyp')}:&nbsp;{agent['attributes'][1]['value']}</li>
                            : ''
                        }
                    </ul>
                </Col>

                {eventKey === "4"
                    ?
                    <Col xs={2}>

                        <OverlayTrigger placement="top"
                            overlay={<Tooltip >{t('words.unblock')} {t('title.userSin')}</Tooltip>}
                        >
                            <Button
                                variant="outline-success"
                                className='border-3 rounded-4 p-1 fw-bold'
                                onClick={unBlockUser}
                            >
                                <CgUnblock size={25} />
                            </Button>
                        </OverlayTrigger>

                    </Col>


                    :
                    <>
                        <Col xs={1}>

                            <Dropdown>
                                <Dropdown.Toggle variant="outline-success" className='border-3 rounded-4 p-1'>
                                    <FaPlus size={20} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {!myNet
                                        ?
                                        <Dropdown.Item onClick={addUserNet}>
                                            {t('title.addyour')}&nbsp;{t('title.net')}
                                        </Dropdown.Item>

                                        : eventKey === "2"
                                            ?
                                            <Dropdown.Item onClick={acceptUser}>
                                                {t('words.accept')}&nbsp;{t('words.friendreq')}
                                            </Dropdown.Item>

                                            : <>

                                                <Dropdown.Item disabled className="fw-bold text-secondary">{t('title.net')} {t('words.groups')}</Dropdown.Item>


                                                <FormSelect id={`group_add_${agent.id}`}>
                                                    {circles.map((group, key) => (

                                                        <option key={key} value={group.id}> {group.title}</option>

                                                    ))}

                                                </FormSelect>

                                                <Button
                                                    variant="outline-success"
                                                    className='w-100 px-2 mt-2'
                                                    onClick={addUserToGroup}
                                                >
                                                    {t('words.addto2')}&nbsp;{t('words.group')}
                                                </Button>

                                            </>
                                    }
                                </Dropdown.Menu>

                            </Dropdown>

                        </Col>

                        <Col xs={1}>

                            <Dropdown>
                                <Dropdown.Toggle variant="outline-warning" className='border-3 rounded-4 p-1'>
                                    <ThreeDots size={20} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link
                                        component={<AgentPage />}
                                        to={`/agent/${agent.id}/`}
                                        className="dropdown-item"
                                    >
                                        {t('words.view')}&nbsp;{t('title.profile')}
                                    </Link>

                                    <Dropdown.Item onClick={() => { setEmailModal(true) }}>{t('words.send')} Email</Dropdown.Item>

                                    <Modal show={emailModal} onHide={() => setEmailModal(false)} backdrop="static">
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                {t('text.sendEmailUser')}&nbsp;
                                                <span className="fw-bold text-capitalize">{agent['blog_display_name'] ? agent['blog_display_name'].toLowerCase() : 'No Name'}</span>
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="bg-secondary p-0">
                                            <AgentContact agent={agent} />
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={() => setEmailModal(false)}>
                                                {t('words.close')}
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    {myNet &&
                                        <>
                                            {eventKey === "2" || eventKey === "3"
                                                ?
                                                <Dropdown.Item onClick={removeUserNet}>
                                                    {t('words.removeFrom')} {t('words.req2')}
                                                </Dropdown.Item>
                                                :
                                                <Dropdown.Item onClick={removeUserNet}>
                                                    {t('words.removeFrom')} {t('title.net2')}
                                                </Dropdown.Item>
                                            }
                                        </>
                                    }

                                    <Dropdown.Item onClick={blockUser}>
                                        {t('words.block')}&nbsp;{t('title.userSin')}
                                    </Dropdown.Item>

                                    <ReportUserOrList theName={agent['blog_display_name']} theID={agent.id} />

                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>

                    </>
                }

                {
                    searchByGroup &&
                    <Col sm={12} className="text-end pe-3">
                        <Button
                            size="sm"
                            variant="outline-danger"
                            onClick={removeUserFromGroup}
                            className='border-0 px-1 py-0'
                        >
                            {t('words.removeFrom')} {t('words.group')}
                        </Button>
                    </Col>
                }

            </Row >

        </>

    )
}

export default UserCard
