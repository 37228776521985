import React from 'react'
import { Col, Row, Card, Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

//Pictures
import realestate from "../../assets/pictures/real-estate.jpg"
import architecture from "../../assets/pictures/architecture.jpg"
import construction from "../../assets/pictures/construction.jpg"
import consultant from "../../assets/pictures/consultant.jpg"
import itservices from "../../assets/pictures/it-services.jpg"
import law from "../../assets/pictures/law.jpg"
import finance from "../../assets/pictures/finance.jpg"

//Backround
import background from "../../assets/animated-svg-ff.svg"


const SectionProfessions = () => {

    const { t, i18n } = useTranslation();

    let cards = [];

    const professions = [
        { name: t('prof.113'), icon: realestate },
        { name: t('prof.114'), icon: architecture },
        { name: t('prof.115'), icon: construction },
        { name: t('prof.120'), icon: consultant },
        { name: t('prof.119'), icon: itservices },
        { name: t('prof.116'), icon: law },
        { name: t('prof.117'), icon: finance },
    ];

    for (let i = 0; i < 7; i++) {

        if (i === 0 || i === 3 || i === 6) {

            cards.push(

                <Col key={i}>

                    <Card className="card-profs">

                        <Card.Img variant="top" src={professions[i].icon} />
                        <Card.Body>
                            <Card.Title>{professions[i].name}</Card.Title>
                            {/* <Card.Text>
                                Some quick example text to build on the card title and make up the
                                bulk of the card's content.
                            </Card.Text> */}
                        </Card.Body>

                    </Card>

                </Col>
            )
        }
        else if (i === 1 || i === 4) {

            cards.push(

                <Col key={i}>

                    <Card className="card-profs">

                        <Card.Img variant="top" src={professions[i].icon} />
                        <Card.Body>
                            <Card.Title>{professions[i].name}</Card.Title>
                            {/* <Card.Text>
                                Some quick example text to build on the card title and make up the
                                bulk of the card's content.
                            </Card.Text> */}
                        </Card.Body>

                    </Card>

                    <Card className="card-profs mt-2 fs-6">

                        <Card.Img variant="top" src={professions[i + 1].icon} />
                        <Card.Body>
                            <Card.Title>{professions[i + 1].name}</Card.Title>
                            {/* <Card.Text>
                                Some quick example text to build on the card title and make up the
                                bulk of the card's content.
                            </Card.Text> */}
                        </Card.Body>

                    </Card>

                </Col>

            )

        }
    }

    return (
        <>
            <h5 className="my-5">&rarr;
                {i18n.language === "fr" || i18n.language === "el"
                    ? <>{t('title.net')} {t('words.professionals')}</>
                    : <>{t('words.professionals')} {t('title.net')}</>
                }
            </h5>

            <Row className='justify-content-between align-items-center mb-5 py-5 position-relative'>

                <Image fluid src={background} className="position-absolute" />

                {cards &&
                    cards
                }

            </Row>

        </>
    )
}

export default SectionProfessions