import { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next'
import FetchDropdowns from "../../../jwtAPI/FetchDropdowns";
import { useSelector } from "react-redux/";


function ComparisonDropdown({ create, listing, getDataDrops, col }) {

    const { t, i18n } = useTranslation();

    const optionsRedux = useSelector(state => state.dropTypes[i18n.language]["benchmark"])

    const [options, setOptions] = useState(optionsRedux);

    useEffect(() => {

        if (optionsRedux !== undefined) {
            setOptions(optionsRedux)
            return;
        }

        const fetchTypes = new FetchDropdowns();
        fetchTypes.fetchDrops('benchmark', setOptions, i18n.language)

        //eslint-disable-next-line
    }, [i18n.language]);


    return (
        <>
            {
                (options && create) || (options && listing)
                    ?
                    <>
                        <Col md={col}>
                            <Form.Label>{t('words.estate')}*</Form.Label>
                            <Form.Select
                                id="estateComparison"
                                defaultValue={listing && listing.estate_type.id}
                                required
                                name="estate_type"
                                onChange={getDataDrops}
                            >
                                <option></option>
                                {options.estate_type.map((option, key) => (
                                    <option key={key} value={option.id}>{option.name}</option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col md={col}>
                            <Form.Label>{t('words.transType')}*</Form.Label>
                            <Form.Select
                                id="transaComparison"
                                defaultValue={listing && listing.transaction.id}
                                required
                                name="transaction"
                                onChange={getDataDrops}
                            >
                                <option></option>
                                {options.transaction_type.map((option, key) => (
                                    <option key={key} value={option.id}>{option.name}</option>
                                ))}
                            </Form.Select>
                        </Col>
                    </>

                    : options && !create && !listing
                        ?
                        <>
                            <Col md={col}>
                                <Form.Label className="small mb-0">{t('words.estate')}</Form.Label>
                                <Form.Select size="sm" onChange={getDataDrops} id="estate_type" name="estate_type" defaultValue="all">
                                    <option value="all">{t('words.estate')} ({t('words.all')})</option>
                                    {options.estate_type.map((option, key) => (
                                        <option key={key} value={option.id}>{option.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col md={col}>
                                <Form.Label className="small mb-0">{t('words.transType')}</Form.Label>
                                <Form.Select size="sm" onChange={getDataDrops} id="transaction" name="transaction" defaultValue="all">
                                    <option value="all">{t('words.transType')} ({t('words.all')})</option>
                                    {options.transaction_type.map((option, key) => (
                                        <option key={key} value={option.id}>{option.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </>

                        : ''
            }

        </>
    )
}

export default ComparisonDropdown
