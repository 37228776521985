import React from "react";

import { Dropdown, Button, OverlayTrigger, Tooltip, Modal } from "react-bootstrap"
import { PlusCircleFill, PrinterFill } from "react-bootstrap-icons"
import { useTranslation } from 'react-i18next'
import { TiPlus } from "react-icons/ti";
import { IoHeart, IoHeartOutline, IoShareSocialSharp } from "react-icons/io5";
import ShareToSocialAll from "../ShareToSocialAll";
import FavoriteToggle from "../../jwtAPI/FavoriteToggle";
import ReportUserOrList from "../ReportUserOrList";


function ToolCardActions({ listing, listingType }) {

    const { t } = useTranslation();

    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const [onError, setOnError] = React.useState(0)


    const lisTitle = listing['article'] ? listing['article']['title'] : listing['title'];
    const lisIdArticle = listing['article'] ? listing['article']['id'] : listing['id'];
    const lisId = listing['id']
    const lisSlug = listing['article'] ? listing['article']['slug'] : listing['slug'];
    const lisLink = `${process.env.REACT_APP_URL}/listing/${listingType}/${lisId}/${lisSlug}`;

    const [lisFav, setLisFav] = React.useState(
        listing.article && listing.article.has_user_favorited === true
            ? true

            : listing.has_user_favorited === true
                ? true

                : false
    )


    React.useEffect(() => {

        if (onError > 0) {
            alert(t('bigtext.errorWrong'))
            setLisFav(!lisFav)
            setOnError(0)
        }

        // eslint-disable-next-line
    }, [onError])


    function favor() {

        setLisFav(!lisFav)

        const url = `${process.env.REACT_APP_FAVORITE_API}/${lisIdArticle}`
        const togFavor = new FavoriteToggle();
        togFavor.fetchListings(url, lisIdArticle, onError, setOnError);
    }

    function copyClipboard() {
        navigator.clipboard.writeText(lisLink);
        setShow(true);
    }

    function printElem() {

        const myWin = window.open(
            lisLink,
            "_blank",
            "width=300,height=viewportheight,left=(viewportwidth - 300),top=0,screenX=0,screenY=0");

        myWin.focus();
        myWin.scrollTo(0, 0);

        myWin.onload = function (e) {
            setTimeout(function () {
                myWin.print();
                myWin.close();
            }, 4000);
        }
    }

    return (
        <div className="d-flex flex-wrap justify-content-center">

            <OverlayTrigger placement="top"
                overlay={<Tooltip >{!lisFav ? t('text.addFav') : t('text.removeFav')}</Tooltip>}
            >
                <Button variant="white" onClick={favor} >

                    {!lisFav
                        ? <IoHeartOutline className="icon-hover" size={29} />
                        : <IoHeart color='red' size={29} />
                    }

                </Button>
            </OverlayTrigger>


            <OverlayTrigger placement="top"
                overlay={<Tooltip >{t('title.shareTo')} ...</Tooltip>}
            >
                <Dropdown align="end">
                    <Dropdown.Toggle variant="transparent">
                        <IoShareSocialSharp className="icon-hover" size={26} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>

                        <ShareToSocialAll link={lisLink} title={lisTitle} type={t('cats_tools.' + listingType + '')}/>

                    </Dropdown.Menu>
                </Dropdown>
            </OverlayTrigger>

            <OverlayTrigger placement="top"
                overlay={<Tooltip >{t('title.moract')}</Tooltip>}
            >
                <Dropdown align="end">
                    <Dropdown.Toggle variant="transparent">
                        <TiPlus className="icon-hover" size={30} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={printElem}><PrinterFill /> {t('words.print')}</Dropdown.Item>
                        <Dropdown.Item onClick={copyClipboard}><PlusCircleFill /> {t('words.copy')} {t('words.link')}</Dropdown.Item>
                        <ReportUserOrList theID={lisId} theName={lisTitle} listingType={listingType} />
                    </Dropdown.Menu>
                </Dropdown>
            </OverlayTrigger>

            <Modal show={show} onHide={handleClose} centered size="sm">
                <Modal.Header closeButton className="border-0">
                    <p>{t('bigtext.copyClipboard')}</p>
                </Modal.Header>
            </Modal>

            {/* <OverlayTrigger placement="top"
                overlay={<Tooltip >{t('text.sendChat')}</Tooltip>}
            >
                <Button variant="white" className="p-1" href={lisLink}>
                    <AiFillMessage className="icon-hover" />
                </Button>
            </OverlayTrigger> */}

        </div>
    )
}

export default ToolCardActions

