//import
//const mixedFetch = new FetchPostMixed();
//mixedFetch.fetchListings(reqMixed, setter)


//Saves and uses json after fetch with the useState of setter listi
export default class FetchPostMixed {


    async fetchListings(reqMixed, setter) {


        let catTypes = [];
        Object.entries(reqMixed).forEach(key => {
            const val = key[1]['type'];
            const val2 = val.charAt(0) + val.slice(1);
            catTypes.push([val, val2]);
        })


        let mixedListing = async () => {

            const language =
                (localStorage.getItem('i18nextLng') &&
                    (localStorage.getItem('i18nextLng') === 'en' || localStorage.getItem('i18nextLng') === 'fr' || localStorage.getItem('i18nextLng') === 'nl' || localStorage.getItem('i18nextLng') === 'de'))
                    ? localStorage.getItem('i18nextLng')
                    : 'en';


            const url = `${process.env.REACT_APP_DOMAIN}/${language}/${process.env.REACT_APP_API_PUBLIC_API}/mixed`


            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reqMixed),
            })
                .then(res => res.json())
                .then(res => {
                    let myData = [res];//important extra step to convert to array?
                    return myData;
                })
                .then(myData => {
                    let myData2 = [{}];

                    //automatically maps through listings, adds the maintype as key (capitalize) and add all objects to an array
                    catTypes.forEach(key => {

                        let before = myData2;
                        let after = myData[0][key[0]]["records"].map(v => ({ ...v, mainTypeListing: key[1] }))
                        myData2 = before.concat(after);

                    })

                    //first time the before variable is empty
                    myData2.shift()
                    return myData2;

                })
                .then(myData2 => setter(myData2))

                .catch(err => console.error(err))


        }

        mixedListing();

    }

}


